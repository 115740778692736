import gql from "graphql-tag";

export const getUnits = gql`
  query units($locationId: String!, $unitTypesId: String) {
    units(locationId: $locationId, unitTypesId: $unitTypesId) {
      edges {
        node {
          id
          unitTypesId
          number
          deposit
          nonRefundable
        }
      }
    }
  }
`;

// Create bulk Unit
export const createBulkUnits = gql`
  mutation bulkCreateUnit($input: [UnitInput!]) {
    bulkCreateUnit(unit: $input) {
      response
    }
  }
`;

export const updateBulkUnits = gql`
  mutation bulkUpdateUnit($input: [UnitUpdateInput!]) {
    bulkUpdateUnit(unit: $input) {
      response
    }
  }
`;
