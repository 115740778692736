import React, { useMemo, useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import {
  Checkbox,
  Button,
  Label,
  Dimmer,
  Header,
  Icon,
  Grid,
  Step,
  Modal,
  Popup,
} from "semantic-ui-react";
import ApplicationTable from "../Application/ApplicationTable";
import {
  NUMBER_ZERO,
  NUMBER_TEN,
  DATE_FORMAT,
  ROUTES,
  BLUEMOON_API,
  LEASE_MANAGER,
  LEASE_MANAGER_LOCAL,
  DOCUSIGN,
} from "../../utils/constants";
import { leasesGql, updateBulkEdit } from "../../store/person/leases";
import FinalLeaseModal from "../Modals/FinalLeaseModal";
import { dataFilterByColumn } from "../../utils/common";
import "../Leads/LeadsExtra.scss";
import { leaseSign } from "../../store/bluemoon/bluemoon";
import { getClient } from "../../store/auth";
import {
  leaseDocuments,
  RecipientView,
  leaseDocumentsLocal,
} from "../../store/person/leases";
import { getAuthToken } from "../../store/auth/authUtility";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import { PageLoader } from "../../components/Loader/PageLoader";
import mixpanel from "mixpanel-browser";
const bluemoonApi = getClient(BLUEMOON_API);
const leaseManager = getClient(LEASE_MANAGER);
const LeaseApi = getClient(DOCUSIGN);
const SetDateFormat = ({ date }) => (
  <Moment format={DATE_FORMAT}>{date}</Moment>
);

const getLease = (item) => {
  try {
    LeaseApi.mutate({
      mutation: RecipientView,
      variables: {
        input: {
          leaseId: item.node.id,
        },
      },
    })
      .then((response) => {
        const rec = response.data.recipientView.docusign.url;
        window.open(rec, "_blank");
      })
      .catch((e) => {});
  } catch (e) {}
};
const SetSignType = (type) => {
  if (type.sign.localLease && Object.keys(type.sign.localLease).length !== 0) {
    return (
      <img
        style={{
          height: "45px",
          width: "45px",
          boxShadow: "0 0 8px 8px white inset",
        }}
        src="/assets/img/localLease.png"
      />
    );
  } else if (type.sign.extLeaseInfo) {
    if (type.sign.extLeaseInfo.type === "bluemoon") {
      return (
        <img
          style={{
            height: "45px",
            width: "45px",
            boxShadow: "0 0 8px 8px white inset",
          }}
          src="/assets/img/bluemoon-logo2.png"
        />
      );
    }
    if (type.sign.extLeaseInfo.type === "docusign") {
      return (
        <img
          style={{ height: "45px", width: "45px" }}
          src="/assets/img/docu-logo2.png"
        />
      );
    } else {
      return (
        <Label
          size="mini"
          style={{
            background: "grey",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          None
        </Label>
      );
    }
  } else {
    return "";
  }
};

export default function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const [multiFilterData, setMultiFilterData] = useState([]);
  const [data, setData] = useState([]);
  /* eslint-disable */
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [unitNumberFilter, setUnitNumberFilter] = React.useState([]);
  /* eslint-enable */
  const [currentPageSize, setPageSize] = useState(NUMBER_TEN);
  const [currentPageIndex, setPageIndex] = useState(NUMBER_ZERO);
  const [open, setOpen] = useState(false);

  const [previewLoader, setPreviewLoader] = useState(false);
  const [docusignLoader, setDocusignLoader] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const [pageCount, setPageCount] = React.useState(0);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [selectedLease, setSelectedLease] = useState(false);
  const [setDimmer, setDimmerDim] = useState(false);
  const {
    lease,
    multipleHandling,
    selectedAppsForBulkEdit,
    onViewDetails,
    history,
  } = props;

  const raiseUp = (item) => {
    setSelectedLease(item.node.id);
    setOpen(true);
  };

  const MoveIn = async (value) => {
    // this.setState({ sending: true, loading: true });
    setLocalLoader(true)
    try {
      mixpanel.track("Manager Lease Action", {
        sub: "Bulk Update",
      });
      await leaseManager
        .mutate({
          mutation: updateBulkEdit,
          variables: {
            input: {
              lease: {
                leaseIds: [selectedLease],
                status: "Signed",
              },
            },
          },
        })
        .then((res) => {
          setDimmerDim(false)
          setLocalLoader(false)
          setTimeout(() => {
            FinalizeMoveIn()
          }, 2000);
        })
        .catch(() => {
          toastFailMsg("Failed to sign.");
          setDimmerDim(false)
          setLocalLoader(false)
        });
    } catch (e) {
      setDimmerDim(false)
      setLocalLoader(false)
      toastFailMsg(e.toString().replace("GraphQL error:", ""));
    }
  };
  const FinalizeMoveIn = async (value) => {
    // this.setState({ sending: true, loading: true });
    setDimmerDim(false)
    try {
      mixpanel.track("Manager Lease Action", {
        sub: "Bulk Update",
      });
      await leaseManager
        .mutate({
          mutation: updateBulkEdit,
          variables: {
            input: {
              lease: {
                leaseIds: [selectedLease],
                status: "MoveIn",
              },
            },
          },
        })
        .then((res) => {
          setDimmerDim(false)
          toastSuccessMsg("Lease Moved In!");
          setTimeout(() => {
            props.getLease();
            setLocalLoader(false)
          }, 200);
        })
        .catch(() => {
          toastFailMsg("Failed to sign.");
          setDimmerDim(false)
          setLocalLoader(false)
        });
    } catch (e) {
      setLocalLoader(false)
      setDimmerDim(false)
      toastFailMsg(e.toString().replace("GraphQL error:", ""));
    }
  };


  const multipleColumnsSearch = (inputValue, data) => {
    const filteredData = data.filter(
      (value) =>
        value.node.person.firstName
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
        value.node.person.lastName
          .toLowerCase()
          .includes(inputValue.toLowerCase())
    );
    return filteredData;
  };

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(value, lease);
    setData(filteredData);
    setFilterInput(value);
    setPageCount(filteredData.length);
    if (value === "") {
      const startRow = currentPageSize * currentPageIndex;
      const endRow = startRow + currentPageSize;
      setData(lease.slice(startRow, endRow));
    }
    setTimeout(() => {
      setFilterApplied(true);
      setMultiFilterData(filteredData);
    }, 2000);
  };

  const filterByColumn = (
    filteredData,
    filter,
    column,
    arrayToFilter,
    filteredArray,
    key
  ) => {
    filter[0].value.forEach((ele) => {
      filteredData = dataFilterByColumn(ele || "", arrayToFilter, column, key);
      filteredData.forEach((ele) => {
        filteredArray.push(ele);
        return null;
      });
    });
    setData(filteredArray);
    setMultiFilterData(filteredArray);
  };

  /* function to check the filtered data on the basis of the column on which filter is applied */
  const updateFilterByPreviousValue = () => {
    const filteredData = [];
    const statusFilterData = [];
    const unitNumberFilterData = [];
    let isStatusFilter = false;
    if (statusFilter.length) {
      isStatusFilter = true;
      filterByColumn(
        filteredData,
        statusFilter,
        "status",
        lease,
        statusFilterData
      );
    }
    if (unitNumberFilter.length) {
      let dataToSend = [];
      if (isStatusFilter) {
        dataToSend = statusFilterData;
      } else {
        dataToSend = lease;
      }
      filterByColumn(
        filteredData,
        unitNumberFilter,
        "unit",
        dataToSend,
        unitNumberFilterData,
        "number"
      );
    }
  };

  /* function to check if the filter is applied, then calls updateFilterByPreviousValue
      else sets the data to the original one */
  const handleMultipleFiltering = () => {
    if (statusFilter.length || unitNumberFilter.length) {
      setFilterApplied(true);
      updateFilterByPreviousValue();
    } else {
      setData(props.lease);
      setMultiFilterData([]);
    }
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const redirectToEditPage = (status, id, item) => {
    if (status === "renewal") {
      return history.push({
        pathname: `${ROUTES.leaseRenewal}/leaseId:${id}`,
        state: item.node,
        status: "edit",
      });
    }
    return history.push(ROUTES.editLease.replace(":leaseId", id));
  };

  const PopupExampleFlowing = (item) => {
    const signers =
      item.sign && item.sign.signers && Object.values(item.sign.signers);
    const filteredSigners =
      signers && signers.filter((item) => item.isManager !== true);
    if (
      item.sign.localLease &&
      Object.keys(item.sign.localLease).length !== 0
    ) {
      const obja = item.sign.localLease;
      for (const key in obja) {
        if (obja.hasOwnProperty(key)) {
          const value = obja[key];
          // Check if value is not "Accepted"
          if (value !== "Accepted") {
            return (
              <Popup trigger={<Icon circular name="hourglass half" />}>
                <Popup.Header>Status</Popup.Header>
                <Popup.Content>
                  {Object.keys(obja).map((name) => {
                    const status = obja[name];
                    return (
                      <div key={name}>
                        <p>
                          {name}: {status}
                        </p>
                      </div>
                    );
                  })}
                </Popup.Content>
              </Popup>
            );
          }
        }
      }
      if (
        Object.values(obja).every((val) => val === "Accepted") &&
        item.sign.moveInReady === true
      ) {
        return (
          <Icon circular name="truck" />
        );
      }
      return <Icon circular name="handshake outline" />;
    } else {
      return (
        <Popup
          size="mini"
          trigger={
            (!item.sign && <Icon circular name="paper plane outline" />) ||
            (item &&
              item.sign &&
              item.sign.status == "partial" &&
              filteredSigners &&
              filteredSigners.every((item) => {
                if (item.status !== "completed") return true;
              }) && <Icon circular name="hourglass half" />) ||
            (item &&
              item.sign &&
              item.sign.status == "initiated" &&
              filteredSigners &&
              filteredSigners.every((item) => {
                if (item.status !== "completed") return true;
              }) && <Icon circular name="hourglass half" />) ||
            (filteredSigners &&
              filteredSigners.every((item) => {
                if (item.status == "completed") return true;
              }) &&
              item.sign &&
              item.sign.status !== "completed" && (
                <Icon circular name="handshake outline" />
              )) ||
            (item.sign && item.sign.status == "completed" && (
              <Icon circular name="truck" />
            )) || <Icon circular name="paper plane outline" />
          }
          flowing
          hoverable
        >
          <Step.Group size="mini" className="popup-step">
            <Step active={!item.sign || !item.sign.status}>
              <Icon name="paper plane outline" />
              <Step.Content>
                <Step.Title>Begin</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={
                ((item.sign && item.sign.status == "initiated") ||
                  (item.sign && item.sign.status == "partial")) &&
                filteredSigners &&
                filteredSigners.every((item) => {
                  if (item.status !== "completed") return true;
                })
              }
            >
              <Icon name="hourglass half" />
              <Step.Content>
                <Step.Title>Waiting</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={
                filteredSigners &&
                filteredSigners.every((item) => {
                  if (item.status == "completed") return true;
                }) &&
                item.sign &&
                item.sign.status !== "completed"
              }
            >
              <Icon name="handshake outline" />
              <Step.Content>
                <Step.Title>Manager</Step.Title>
              </Step.Content>
            </Step>
            <Step active={item.sign && item.sign.status == "completed"}>
              <Icon name="truck" />
              <Step.Content>
                <Step.Title>Move In</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </Popup>
      );
    }
  };

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Select",
        accessor: (item) => (
          <Checkbox
            onChange={(e) => multipleHandling(item, e)}
            checked={selectedAppsForBulkEdit.includes(item.node.id)}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "First Name",
        accessor: "node.person.firstName",
      },
      {
        Header: "Last Name",
        accessor: "node.person.lastName",
      },
      {
        Header: "Amount",
        accessor: "node.amount",
        Cell: ({ cell: { value } }) =>
          value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: "Frequency",
        accessor: "node.leaseFrequency",
      },
      {
        Header: "Unit",
        accessor: "node.unit.number",
        filter: "includes",
      },
      {
        Header: "Start Date",
        accessor: "node.startDate",
        Cell: ({ cell: { value } }) => <SetDateFormat date={value} />,
      },
      {
        Header: "End Date",
        accessor: "node.endDate",
        Cell: ({ cell: { value } }) => <SetDateFormat date={value} />,
      },
      {
        Header: "Status",
        accessor: (item) => <PopupExampleFlowing sign={item.node} />,
      },
      {
        Header: "Type",
        accessor: "node",
        Cell: ({ cell: { value } }) => <SetSignType sign={value} />,
      },
      {
        Header: "View",
        accessor: (item) => (
          <Button
            className="dark-button"
            icon="eye"
            size="mini"
            onClick={() => onViewDetails(item)}
          />
        ),
      },
      {
        Header: "Edit",
        accessor: (item) => (
          <Button
            size="mini"
            icon="edit"
            className="dark-button"
            disabled={
              item.node.status !== "created" && Object.keys(item.node.localLease).length !== 0
            }
            onClick={() =>
              redirectToEditPage(item.node.status, item.node.id, item)
            }
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: (item) => {
          if (
            item.node.lgCompleted === true &&
            item.node.insuranceCompleted === true
          ) {
            if (item.node.extLeaseInfo) {
              if (
                item.node.localLease &&
                Object.keys(item.node.localLease).length !== 0
              ) {
                const obja = item.node.localLease;
                for (const key in obja) {
                  if (obja.hasOwnProperty(key)) {
                    const value = obja[key];
                    if (value !== "Accepted") {
                      // If any value is not "Accepted", return something else
                      return "";
                    }
                  }
                }
                if (
                  Object.values(obja).every((val) => val === "Accepted") &&
                  item.node.moveInReady === true
                ) {
                  return (
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#BenefitsModal"
                      onClick={() => setSelectedLease(item.node.id)}
                    >
                    <Button
                      positive
                      style={{
                        fontSize: ".8rem",
                        padding: "5px 10px",
                        textTransform: "capitalize",
                        height: "auto",
                      }}
                    >
                      Move In
                    </Button>
                  </a>
                  )
                }
                return (
                  <FinalLeaseModal
                    getLease={() => props.getLease()}
                    item={item.node}
                  />
                );
              }
              if (!item.node.extLeaseInfo.status) {
                return (
                  <Button
                    positive
                    onClick={() => raiseUp(item)}
                    style={{
                      fontSize: ".8rem",
                      padding: "5px 10px",
                      textTransform: "capitalize",
                      height: "auto",
                    }}
                  >
                    Begin Signing
                  </Button>
                );
              }
              if (item.node.extLeaseInfo.status) {
                if (item.node.extLeaseInfo.status === "signed") {
                  return (
                    <Button
                      style={{
                        fontSize: ".8rem",
                        padding: "5px 10px",
                        textTransform: "capitalize",
                        height: "auto",
                      }}
                      positive
                      onClick={() => finalizeLease(item)}
                    >
                      Finalize Lease
                    </Button>
                  );
                }
                if (item.node.extLeaseInfo.status === "partial") {
                  return (
                    <Button
                      positive
                      onClick={() => getLease(item)}
                      style={{
                        fontSize: ".8rem",
                        padding: "5px 10px",
                        textTransform: "capitalize",
                        height: "auto",
                      }}
                    >
                      Finalize Lease
                    </Button>
                  );
                }
                if (
                  item.node.extLeaseInfo.status === "initial" ||
                  item.node.extLeaseInfo.status === "expired" ||
                  item.node.extLeaseInfo.status === "sent"
                ) {
                  return (
                    <Button
                      positive
                      onClick={() => raiseUp(item)}
                      style={{
                        fontSize: ".8rem",
                        padding: "5px 10px",
                        textTransform: "capitalize",
                        height: "auto",
                      }}
                    >
                      Begin Signing
                    </Button>
                  );
                }
                if (item.node.extLeaseInfo.status === "completed") {
                  return (
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#BenefitsModal"
                      onClick={() => setSelectedLease(item.node.id)}
                    >
                      <Button
                        positive
                        style={{
                          fontSize: ".8rem",
                          padding: "5px 10px",
                          textTransform: "capitalize",
                          height: "auto",
                        }}
                      >
                        Completed
                      </Button>
                    </a>
                  );
                } else {
                  return "";
                }
              }
            } else return "";
          }
        },
        disableSortBy: true,
      },
    ],
    [props, selectedAppsForBulkEdit]
  );
  /* eslint-disable */

  const signLeaseLocal = (item) => {
    mixpanel.track("Manager Lease Action", {
      sub: "Send Email to Sign Lease",
    });
    setDimmerDim(true);
    if (item == false) {
      setPreviewLoader(true);
    }
    if (item == true) {
      setLocalLoader(true);
    }
    leaseManager
      .mutate({
        mutation: leaseDocumentsLocal,
        variables: {
          input: {
            leaseId: selectedLease,
            notify: item,
          },
        },
      })
      .then((res) => {
        if (item == false) {
          preview();
          setPreviewLoader(false);
          setDimmerDim(false);
        } else {
          toastSuccessMsg("Email has been sent, the lease signing has begun.");
          SetRecip();
          props.getLease();
          setOpen(false);
          setDimmerDim(false);
          setLocalLoader(false);
        }
      })
      .catch((e) => {
        setPreviewLoader(false);
        setLocalLoader(false);
        if(e){
          toastFailMsg("Something went wrong");
        }
        setDimmerDim(false);
      });
  };

  const preview = () => {
    fetch(LEASE_MANAGER_LOCAL + selectedLease, {
      method: "GET",
      headers: {
        Authorization: getAuthToken(),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setPreviewLoader(false);
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.error(error);
        setPreviewLoader(false);
      });
  };
  const signLeaseDocu = (item) => {
    mixpanel.track("Manager Lease Action", {
      sub: "Send Email to Sign Lease",
    });
    setDimmerDim(true);
    setDocusignLoader(true);
    leaseManager
      .mutate({
        mutation: leaseDocuments,
        variables: {
          input: {
            lease: {
              leaseId: selectedLease,
            },
          },
        },
      })
      .then((res) => {
        toastSuccessMsg("Email has been sent, the lease signing has begun.");
        setDocusignLoader(false);
        SetRecip();
        props.getLease();
        setOpen(false);
        setDimmerDim(false);
      })
      .catch((e) => {
        setDocusignLoader(false);
        toastFailMsg("Something went wrong");
        setDimmerDim(false);
      });
  };

  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(0);
  const [lastPage, setLastPage] = useState();

  const calculateLastPage = ({ pageSize, controlledPageCount }) => {
    const result = controlledPageCount / pageSize;
    setLastPage(Math.ceil(result));
  };
  const finalizeLease = (item) => {
    setDimmerDim(true);
    mixpanel.track("Manager Lease Action", {
      sub: "Finalize Lease",
    });
    bluemoonApi
      .mutate({
        mutation: leaseSign,
        variables: {
          input: {
            leaseId: item.node.id,
          },
        },
      })
      .then(() => {
        toastSuccessMsg("Lease Signing Finalized.");
        setTimeout(props.getLease(), 3000);
        setTimeout(setDimmerDim(false), 3000);
      })
      .catch(() => {
        toastFailMsg("Failed to sign.");
        setDimmerDim(false);
      });
  };

  /* eslint-disable */
  const fetchData = useCallback(
    ({ pageSize, pageIndex, filteredValue }) => {
      setPageSize(pageSize);
      setPageIndex(pageIndex);
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      setFilterApplied(false);
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          const startRow = pageSize * pageIndex;
          const endRow = lease.length;
          if (statusFilter.length || unitNumberFilter.length || filteredValue) {
            setData(multiFilterData.slice(startRow, endRow));
            setPageCount(multiFilterData.length);
          } else {
            setData(lease.slice(startRow, endRow));
            setPageCount(lease.length);
          }
          setStartRowIndex(startRow);
          setEndRowIndex(endRow);
          setLoading(false);
        }
      }, 2000);
    },
    [multiFilterData, lease]
  );
  /* eslint-enable */

  useEffect(() => {
    setData(lease);
  }, [lease]);

  return (
    <div className="table-container">
      {setDimmer && (
        <Dimmer active inverted>
          <PageLoader text />
        </Dimmer>
      )}
      <div
        className="modal fade completed-modal"
        id="BenefitsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src="/assets/img/logo-color-2.svg" alt="bluemoon-logo" />
              <h5>What Now?</h5>
              <p>Now that both parties have signed heres what will happen.</p>
              <div className="content-wrap">
                <ul>
                  <li>
                    <p>
                      The Renter will be moved into the Residents tab on the
                      assigned Start Date. You will no longer see them in Leases
                      and instead you will be able to interact with them on
                      Residents.
                    </p>
                  </li>
                  <li>
                    <p>
                      Invoices will start from the assigned Start Date. The
                      invoices will be prorated accordingly and the user will be
                      able to make payments towards them as soon as the Start
                      Date occurs.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you wish to move the user in early you can do so by
                      clicking the button below. This will change their start
                      date to the day the button below was clicked. Everything
                      above still applies to this as well.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              {
                localLoader?
                <a
                // onClick={() => MoveIn()}
                target="_blank"
                rel="noopener noreferrer"
                type="button"
                className="btn btn-primary"
                >
                Loading...
              </a>:
                <a
                onClick={() => MoveIn()}
                target="_blank"
                rel="noopener noreferrer"
                type="button"
                className="btn btn-primary"
                >
                Start Early
              </a>
          }
            </div>
          </div>
        </div>
      </div>
      {open && (
        <Modal
          className="semanticModal"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          size="mini"
        >
          <Modal.Content>
            <Modal.Description>
              <Header>Select Lease Type</Header>
              <p>
                Select what you would like to send to the Renter. They will be
                notified on their renter portal.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button.Group style={{ maxHeight: "30px" }} fluid compact>
              <Button
                loading={docusignLoader}
                disabled={docusignLoader}
                style={{ maxHeight: "30px" }}
                onClick={() => signLeaseDocu()}
              >
                Docusign
              </Button>
              <Button
                loading={previewLoader}
                disabled={previewLoader}
                style={{ maxHeight: "30px" }}
                onClick={() => signLeaseLocal(false)}
              >
                Preview Local
              </Button>
              <Button
                loading={localLoader}
                disabled={localLoader}
                style={{ maxHeight: "30px" }}
                onClick={() => signLeaseLocal(true)}
              >
                Send Local
              </Button>
            </Button.Group>
          </Modal.Actions>
        </Modal>
      )}
      <ApplicationTable
        filteredValue={filterInput}
        startRow={startRowIndex}
        defaultColumn={defaultColumn}
        endRow={endRowIndex}
        onChange={handleFilterChange}
        columns={columns}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        completeData={lease}
        calculateLastPage={calculateLastPage}
        lastPage={lastPage}
        isFilterApplied={isFilterApplied}
        handleMultipleFiltering={handleMultipleFiltering}
      />
    </div>
  );
}