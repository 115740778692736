import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import { Formik } from "formik";
import * as Yup from "yup";
import {  toast } from "react-toastify";
import { ROUTES } from "../../utils/constants";
import { loginAction, handleMangerLogin } from "../../store/auth";
import { Button, Form, Icon, Confirm } from "semantic-ui-react";
import "./signin.scss";
import ForgotPasswordForm from "./Forgot/ForgotPasswordForm";
import { PERSON_URL } from "../../utils/constants";
import mixpanel from "mixpanel-browser";
import { getClient } from "../../init-apollo-googleFn";
import { verifyPerson } from "../../store/person/person";
const personClient = getClient(PERSON_URL);
const MIN_PASS_LENGTH = 8;
export const formValidate = {
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(MIN_PASS_LENGTH, "Password is too short - should be 8 chars minimum."),
};

const SignInForm = (props) => {
  useEffect(() => {
    mixpanel.track("Manager Sign In Page Render", {
      sub: "Forgot Password",
    });
  }, []);
  const { client } = props;
  const [showPassword, onTogglePassword] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(false);

  const handleLogin = async (values) => {
    setEmail(values.email);
    try {
      mixpanel.track("Manager Log In Click", {
        sub: "Forgot Password",
      });
      await loginAction(values, client).then((res) => {
        if (res) handleUserLogin();
        else {
          mixpanel.track("Manager Wrong Password or Email Response", {
            sub: "Forgot Password",
          });
          getErrorToast();
          setLoading(false);
        }
      });
    } catch (error) {
      mixpanel.track("Manager Misc Login Error Response", {
        sub: "Forgot Password",
      });
      getErrorToast();
      setLoading(false);
    }
  };

  const handleUserLogin = async () => {
    await handleMangerLogin(client).then((res) => {
      if (res) {
        if (res === "Verify") {
          setOpen(true);
          setLoading(false);
        } else {
          toast.success("User logged in successfully.", {
            position: "top-center",
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            hideProgressBar: true,
          });
          props.setLoading(true);
        }
      } else {
        getErrorToast();
        setLoading(false);
      }
    });
  };
  const getErrorToast = () => {
    toast.error("User e-mail id or password is incorrect.", {
      position: "top-center",
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleConfirm = () => {
    resendEmail();
    setOpen(false);
  };
  const resendEmail = () => {
    mixpanel.track("Manager Login Action", { sub: "Resend Verify Email" });
    personClient
      .mutate({
        mutation: verifyPerson,
        variables: {
          input: {
            email: email,
          },
        },
      })
      .then((results) => {
        toast.success("Verification Email Sent.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {});
  };
  const handleCancel = () => setOpen(false);
  return (
    <>
      <div class="main-container signin-container">
        <div class="signup-section">
          <div class="signup-left d-lg-flex">
            <div
              id="carouselExampleCaptions"
              class="carousel slide carousel-fade"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleCaptions"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleCaptions"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleCaptions"
                  data-slide-to="2"
                ></li>
                <li
                  data-target="#carouselExampleCaptions"
                  data-slide-to="3"
                ></li>
                <li
                  data-target="#carouselExampleCaptions"
                  data-slide-to="4"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="content-wrap">
                    <img
                      src="/assets/img/bg-signin-left.jpg"
                      alt="Left bitmap"
                      class="full-section-image"
                    />
                    <a href={ROUTES.LANDING} className="brand-image">
                    <img src="assets/img/logo-white-2.png" alt="Logo" />
                    </a>
                    <div class="text">
                      <p class="big" style={{ color: "white" }}>
                        Welcome to a new life on leasing.
                      </p>
                      <p class="small" style={{ color: "white" }}>
                        Find a new home, explore long-term furnished housing, or
                        dream big by browsing vacation spots.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="content-wrap">
                    <img
                      src="/assets/img/bg-signin-left.jpg"
                      alt="Left bitmap"
                      class="full-section-image"
                    />
                    <a
                      href={ROUTES.LANDING}
                      activeClassName="active"
                      className="brand-image"
                    >
                      <img src="assets/img/logo-white-2.png" alt="Logo" />
                    </a>
                    <div class="text">
                      <p class="big" style={{ color: "white" }}>
                        Renter empowerment for the modern age.
                      </p>
                      <p class="small" style={{ color: "white" }}>
                        We've worked tirelessly to provide access to the
                        services and brands we all love.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="content-wrap">
                    <img
                      src="/assets/img/bg-signin-left.jpg"
                      alt="Left bitmap"
                      class="full-section-image"
                    />
                    <a
                      href={ROUTES.LANDING}
                      activeClassName="active"
                      className="brand-image"
                    >
                      <img src="assets/img/logo-white-2.png" alt="Logo" />
                    </a>
                    <div class="text">
                      <p class="big" style={{ color: "white" }}>
                        REM is your best fit.
                      </p>
                      <p class="small" style={{ color: "white" }}>
                        We have created a new business model for properties and
                        a new lifestyle of empowerment.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="content-wrap">
                    <img
                      src="/assets/img/bg-signin-left.jpg"
                      alt="Left bitmap"
                      class="full-section-image"
                    />
                    <a
                      href={ROUTES.LANDING}
                      activeClassName="active"
                      className="brand-image"
                    >
                      <img src="assets/img/logo-white-2.png" alt="Logo" />
                    </a>
                    <div class="text">
                      <p class="big" style={{ color: "white" }}>
                        Enter a new era of rental management.
                      </p>
                      <p class="small" style={{ color: "white" }}>
                        Thanks to REM, you can now handle flexible leasing
                        opportunities and engage a supportive network.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="content-wrap">
                    <img
                      src="/assets/img/bg-signin-left.jpg"
                      alt="Left bitmap"
                      class="full-section-image"
                    />
                    <a
                      href={ROUTES.LANDING}
                      activeClassName="active"
                      className="brand-image"
                    >
                     <img src="assets/img/logo-white-2.png" alt="Logo" />
                    </a>
                    <div class="text">
                      <p class="big" style={{ color: "white" }}>
                        A Marketplace of Value.
                      </p>
                      <p class="small" style={{ color: "white" }}>
                        Win the hearts of more consumers.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="login-container">
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={async (values) => {
                setLoading(!isloading);
                await handleLogin(values);
              }}
              validationSchema={Yup.object().shape({ ...formValidate })}
              render={({
                values,
                setFieldValue,
                errors,
                touched,
                handleSubmit,
              }) => (
                <Form size="large" className="form-container content">
                  <div class="signup-top-section">
                    <h1>Sign In</h1>
                    <p class="signup-detail">Welcome to the Manager portal.</p>
                    <div className="form-group">
                      <Form.Field required>
                        <label>Email</label>
                        <Form.Input
                          required
                          error={
                            touched.email && errors.email
                              ? { content: errors.email }
                              : null
                          }
                          type="email"
                          value={values["email"]}
                          placeholder="Enter your e-mail"
                          onChange={({ target: { value } }) => {
                            setFieldValue("email", value);
                          }}
                        />
                      </Form.Field>
                    </div>
                    <div className="form-group">
                      <Form.Field required>
                        <label>Password</label>
                        <Form.Input
                          required
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          value={values["password"]}
                          onChange={({ target: { value } }) => {
                            setFieldValue("password", value);
                          }}
                          error={
                            touched.password && errors.password
                              ? { content: errors.password }
                              : null
                          }
                          icon={
                            <Icon
                              name={showPassword ? "eye slash" : "eye"}
                              link
                              onClick={() => onTogglePassword(!showPassword)}
                            />
                          }
                        />
                      </Form.Field>
                    </div>
                    <div class="last-row">
                      {/* eslint-disable */}
                      <a
                        href="#"
                        class="term-text"
                        title="Forgot Password?"
                        data-toggle="modal"
                        data-target="#forgotPassword"
                      >
                        Forgot Password?
                      </a>
                      {/* eslint-enable */}
                    </div>

                    <Button
                      type="submit"
                      className="btn btn-block btn-color modal-large-btn"
                      onClick={handleSubmit}
                      loading={isloading}
                    >
                      {!isloading ? "Sign In" : ""}
                    </Button>
                  </div>
                </Form>
              )}
            />
            <Confirm
              className="semanticModal semanticConfirm"
              size="mini"
              open={open}
              header="Email Unverified"
              content="Would you like to resend the link?"
              onCancel={handleCancel}
              onConfirm={handleConfirm}
            />
          </div>
        </div>
      </div>
      <ForgotPasswordForm
      />
    </>
  );
};

export default withApollo(SignInForm);
