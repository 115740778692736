import React, { Component } from "react";
import { qpTooltipPopover } from "../../utils/misc";
import { COMING_SOON } from "../../utils/constants";

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: [],
      notToExceed: "100",
      formSubmit: false,
      errors: {},
    };
    this.modalClose = React.createRef();
  }

  componentDidMount() {
    qpTooltipPopover();
  }

  onFormFieldUpdate = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState((state) => ({
      [name]: value,
    }));
  };

  render() {
    const { notToExceed } = this.state;
    return (
      <div
        className="modal fade settings"
        id="settingsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              Maintenance Settings
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={this.modalClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group row">
                  <label
                    htmlFor="pinFormLabel"
                    className="col-md-5 col-form-label"
                  >
                    Not to Exceed ($)
                  </label>
                  <div className="col-md-7">
                    <input
                      type="text"
                      className="form-control"
                      id="pinFormLabel"
                      name="notToExceed"
                      onChange={(e) => this.onFormFieldUpdate(e)}
                      value={notToExceed}
                    />
                    <p className="legal-entry">
                      Changing the NTE does not impact work-orders in progress
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary view-btn btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary view-btn btn-sm"
                data-toggle="tooltip"
                data-placement="top"
                title={COMING_SOON}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingsModal;
