import React from "react";

const ProfileCard = ({ primaryApplicant }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Goverment ID Type</div>
            <div className="profile-label-value">
              {primaryApplicant.drivingLicence &&
                primaryApplicant.drivingLicence.idType}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Drivers License/ID Number</div>
            <div className="profile-label-value">
              {primaryApplicant.drivingLicence &&
                primaryApplicant.drivingLicence.number}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Issuing State</div>
            <div className="profile-label-value">
              {primaryApplicant.drivingLicence &&
                primaryApplicant.drivingLicence.issuingState}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Date of Birth</div>
            <div className="profile-label-value">
              {primaryApplicant.drivingLicence && primaryApplicant.dateOfBirth}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Marital Status</div>
            <div className="profile-label-value">
              {primaryApplicant.maritalStatus === ""
                ? "N/A"
                : primaryApplicant.maritalStatus}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
