import React, { useState } from "react";
import { Button, Modal, Header } from "semantic-ui-react";
import "./moveLeadModal.scss";
import { ROUTES } from "../../utils/constants";
import { NavLink } from "react-router-dom";

export default function PropertyChangeWarning(props) {
  const [open, setOpen] = useState(true);
  return (
    <Modal
      className="semanticModal modal-ui modal-radius overflow-y-visible"
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky"
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          Change Property
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description className="w-100">
          {props.type === "application" ? (
            <>
              {" "}
              <p>
                Changing property will take you to applications listing page of
                the selected property and all the added information will be lost
              </p>
              <span>Do you still want to continue?</span>
            </>
          ) : (
            <>
              {" "}
              <p>
                Changing property will take you to leads listing page of the
                selected property.
              </p>
              <span>Do you want to continue?</span>
            </>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Cancel"
          onClick={() => props.closeModal()}
          positive
        />
        <NavLink
          className="button-link text-white ml-2"
          exact
          to={props.type === "application" ? ROUTES.applications : ROUTES.leads}
          onClick={() => props.changeProperty()}
        >
          Confirm
        </NavLink>
      </Modal.Actions>
    </Modal>
  );
}
