import React from 'react';
import { Modal, Header } from 'semantic-ui-react';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils';

function LeaseRenewalSummaryModal({
  changedAmount,
  originalAmount,
  fetchRenewLeaseApi,
  locationData,
  unitNumberText,
  firstName,
  lastName,
  startDate,
  endDate,
  previousStartDate,
  previousEndDate,
  floorPlan,
  previousFrequency,
  frequency,
}) {
  const [open, setOpen] = React.useState(true);

  const getLocationAddress = () => {
    const address = locationData.addresses[0];
    return `${address.streetOne},${address.state},${address.country},${address.zip}`;
  };

  const calculateRentDiff = () => changedAmount - originalAmount;

  const renderLeaseData = () => (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="summary-label">Unit</div>
          <div className="summary-value">{floorPlan}</div>
        </div>
        <div className="col-md-4">
          <div className="summary-label">Unit Number</div>
          <div className="summary-value">{unitNumberText}</div>
        </div>
        <div className="col-md-4">
          <div className="summary-label">Tenant Name</div>
          <div className="summary-value">{`${firstName} ${lastName}`}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="summary-label">Lease Start Date</div>
          <div className="summary-value">{moment(startDate).format(DATE_FORMAT)}</div>
        </div>
        <div className="col-md-4">
          <div className="summary-label">Lease End Date</div>
          <div className="summary-value">{moment(endDate).format(DATE_FORMAT)}</div>
        </div>
        <div className="col-md-4">
          <div className="summary-label">Rent</div>
          <div className="summary-value">{changedAmount}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="summary-label">Previous Lease Start</div>
          <div className="summary-value">{moment(previousStartDate).format(DATE_FORMAT)}</div>
        </div>
        <div className="col-md-4">
          <div className="summary-label">Previous Lease End</div>
          <div className="summary-value">{moment(previousEndDate).format(DATE_FORMAT)}</div>
        </div>
        <div className="col-md-4">
          <div className="summary-label">Previous Rent</div>
          <div className="summary-value">{originalAmount}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="summary-label">Percent Difference</div>
          <div className="summary-value">{`${Math.round(((calculateRentDiff() * 100) / originalAmount), 3)} %`}</div>
        </div>
        <div className="col-md-4">
          <div className="summary-label">Dollar Difference</div>
          <div className="summary-value">{`$${Math.round((calculateRentDiff() + Number.EPSILON) * 100) / 100}`}</div>
        </div>
        <div className="col-md-4">
          <div className="summary-label">Status</div>
          <div className="summary-value">Pending</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="summary-label">Previous Frequency</div>
          <div className="summary-value">{previousFrequency}</div>
        </div>
        <div className="col-md-3">
          <div className="summary-label">Changed Frequency</div>
          <div className="summary-value">{frequency}</div>
        </div>
      </div>
    </>
  );

  return (
    <Modal
      className="semanticModal custom-modal unit-modal renewal-summary-modal"
      closeIcon
      open={open}
      size="mini"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>
        <Header>Lease Renewal Summary</Header>
      </Modal.Header>
      <Modal.Content>
        <div className="prop-address">{locationData.name}</div>
        <div className="prop-subaddress">{getLocationAddress()}</div>
        <div className="renewal-summary-col">
          {renderLeaseData()}
        </div>
        <div className="d-flex mt-5 add-property-container">
          <button type="button" className="btn cancel-btn w-50 ml-0 mr-2" onClick={() => setOpen(false)}>Previous</button>
          <button type="button" className="btn next-btn btn-gradient w-50 mr-0 ml-2 mt-0" onClick={() => fetchRenewLeaseApi('renew')}>
            Send Lease Offer
          </button>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default LeaseRenewalSummaryModal;
