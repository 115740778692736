import React, { Component } from "react";
import { Button, Image, Label, Icon, List } from "semantic-ui-react";

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: { name, city, zip, streetOne, state, customId, totalUnits },
    } = this.props;

    return (
      <div>
        <h1 className="ui centered">Confirm your Details</h1>
        <p>
          Click Confirm if the following details have been correctly entered
        </p>
        <List>
          <List.Item>
            <List.Icon name="users" />
            <List.Content>Property Name: {name}</List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="users" />
            <List.Content>
              Address: {streetOne}
              {", " + city}
              {", " + state} {", " + zip}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="users" />
            <List.Content>Custom Id: {customId}</List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="calendar" />
            <List.Content>Total Units: {totalUnits}</List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="marker" />
            <List.Content>
              Photos:{" "}
              <Image.Group size="tiny">
                {this.props.values.photos &&
                  this.props.values.preview.map((photo, index) => {
                    return (
                      <Image size="small" src={photo}>
                        <Label
                          size="mini"
                          floating
                          onClick={() => this.removePhoto(index)}
                        >
                          <Icon name="cancel" />
                        </Label>
                        <Image src={photo} />
                      </Image>
                    );
                  })}
              </Image.Group>
            </List.Content>
          </List.Item>
        </List>

        <Button onClick={this.back}>Back</Button>
        <Button onClick={this.props.createLocation}>Confirm</Button>
      </div>
    );
  }
}

export default Confirmation;
