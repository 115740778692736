import React, { useEffect } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

export default function ApplicationTable({
  calculateLastPage,
  columns,
  data,
  fetchData,
  filteredValue,
  filterTypes,
  defaultColumn,
  pageCount: controlledPageCount,
  sortDesc,
  sortId,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: false, // If this works for you this was the fix
      columns,
      filterTypes,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: sortId,
            desc: sortDesc,
          },
        ],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  /* eslint-disable */
  useEffect(() => {
    fetchData({ pageIndex, pageSize, filteredValue });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    calculateLastPage({ pageSize, controlledPageCount });
  }, [calculateLastPage, pageSize, controlledPageCount]);
  /* eslint-enable */

  // Render the UI for your table

  return (
    <table  {...getTableProps()} className="application-fifth-step leases-fourth-step">
      <thead className="sticky-header-tables">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th style={{opacity:'1'}}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <br />
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
      <tbody {...getTableBodyProps()}>
        {page.length === 0 ? (
          <td colSpan="10" className="text-center">
            No Results Found
          </td>
        ) : (
          page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}
