import React from "react";

const Employment = ({ primaryApplicant }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="profile-label-row">
            <div>
              <span className="profile-label-title">Employment</span>
            </div>
            <div className="profile-label-line"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Occupation</div>
            <div className="profile-label-value">
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].title}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Employer/Company</div>
            <div className="profile-label-value">
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].employerName}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Monthly Income</div>
            <div className="profile-label-value">
              $
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].monthlyIncome}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">From Date</div>
            <div className="profile-label-value">
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].startDate}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">To Date</div>
            <div className="profile-label-value">
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].endDate}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Supervisor Name</div>
            <div className="profile-label-value">
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].supervisorName}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Address</div>
            <div className="profile-label-value">
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].address.streetLine1}
              ,{" "}
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].address.city}
              ,{" "}
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].address.state}
              ,{" "}
              {primaryApplicant &&
                primaryApplicant.employmentHistory &&
                primaryApplicant.employmentHistory[0] &&
                primaryApplicant.employmentHistory[0].postcode}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Supervisor Email</div>
            <div className="profile-label-value">
              {primaryApplicant &&
              primaryApplicant.employmentHistory &&
              primaryApplicant.employmentHistory[0] &&
              primaryApplicant.employmentHistory[0].supervisorEmail !== "" ? (
                <a
                  href={`mailto:${
                    primaryApplicant &&
                    primaryApplicant.employmentHistory &&
                    primaryApplicant.employmentHistory[0] &&
                    primaryApplicant.employmentHistory[0].supervisorEmail
                  }`}
                >
                  {primaryApplicant &&
                    primaryApplicant.employmentHistory &&
                    primaryApplicant.employmentHistory[0] &&
                    primaryApplicant.employmentHistory[0].supervisorEmail}
                </a>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Supervisor Phone</div>
            <div className="profile-label-value">
              {primaryApplicant &&
              primaryApplicant.employmentHistory &&
              primaryApplicant.employmentHistory[0] &&
              primaryApplicant.employmentHistory[0].supervisorPhone !== "" ? (
                <a
                  href={`tel:${
                    primaryApplicant &&
                    primaryApplicant.employmentHistory &&
                    primaryApplicant.employmentHistory[0] &&
                    primaryApplicant.employmentHistory[0].supervisorPhone
                  }`}
                >
                  {primaryApplicant &&
                    primaryApplicant.employmentHistory &&
                    primaryApplicant.employmentHistory[0] &&
                    primaryApplicant.employmentHistory[0].supervisorPhone}
                </a>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employment;
