import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import { Provider as ReduxProvider } from "react-redux";
import reduxStore from "./store/redux";
import ReactGA from "react-ga";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactGA.initialize("UA-131695635-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HashRouter>
    <ScrollToTop>
      <ReduxProvider store={reduxStore}>
        <App />
      </ReduxProvider>
    </ScrollToTop>
  </HashRouter>
);
