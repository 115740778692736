import React, { useState } from "react";
import { Button, Modal, Header, Form, Icon, Grid } from "semantic-ui-react";
import get from "lodash/get";
import { getClient } from "../../init-apollo-googleFn";
import { toast } from "react-toastify";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import "./moveLeadModal.scss";
import {
  ChargeAccountingMutation,
  LedgerAccountingRoll,
  TaxAccountingRoll,
  UpdateAccountingMutation,
} from "../../store/person/accounting";
import { ACCOUNTING, CHARGE_TYPES } from "../../utils/constants";
import { parseGraphQLErrors } from "../../utils/common";

const chargeClient = getClient(ACCOUNTING);

export default function ChargeCodeModal(props) {
  const [open, setOpen] = useState(false);
  const [charge, chargeCode] = useState(false);
  const [name, nameCode] = useState(false);
  const [description, descCode] = useState(false);
  const [loading, setLoader] = useState(false);

  const [internal, internalCode] = useState(false);
  const [amount, amountCode] = useState(false);
  const [chargeType, chargeTypeCode] = useState(false);
  const [selectedLedger, chosenLedgerCode] = useState(false);
  const [ledger, ledgerData] = useState([]);
  const [taxType, taxTypeCode] = useState(false);
  const [selectedTax, chosenTaxCode] = useState(false);

  const createCharge = (e) => {
    setLoader(true);
    e.preventDefault();
    if (props.props) {
      try {
        chargeClient
          .mutate({
            mutation: UpdateAccountingMutation,
            variables: {
              input: {
                location: props.propertyId,
                extLedgerId: selectedLedger,
                code: charge,
                name,
                desc: description,
                descInternal: internal,
                id: props.props.node.id,
                amountDefault: parseFloat(amount).toFixed(2),
              },
            },
          })
          .then((response) => {
            if (props.clickable) {
              toastSuccessMsg("Charge Code Added")
              props.fetchLeaseCharges();
            } else {
              props.updateInvoice(response);
              props.success();
            }
            setLoader(false);
            setOpen(false);
          })
          .catch((error) => {
            toast.error(parseGraphQLErrors(error));
          });
      } catch (e) {
        toast.error("Issues found in form");
      }
    } else {
      try {
        chargeClient
          .mutate({
            mutation: ChargeAccountingMutation,
            variables: {
              input: {
                location: props.propertyId,
                extLedgerId: selectedLedger,
                type: chargeType,
                code: charge,
                name,
                extTaxcodeId: selectedTax,
                desc: description,
                descInternal: internal,
                amountDefault: parseFloat(amount).toFixed(2),
              },
            },
          })
          .then((response) => {
            if (props.clickable) {
              toastSuccessMsg("Charge Code Added")
              props.fetchLeaseCharges();
            } else {
              props.updateInvoice(response);
              props.dataPush && props.dataPush();
              props.success();
            }
            setLoader(false);
            setOpen(false);
          })
          .catch((error) => {
            toast.error(parseGraphQLErrors(error));
          });
      } catch (e) {
        toast.error("Issues found in form");
      }
    }
  };

  const getLedger = (event) => {
    console.log(props);
    event.preventDefault();
    ledgerData([]);
    chargeCode("");
    nameCode("");
    descCode("");
    internalCode("");
    amountCode("");
    chargeTypeCode("");
    chosenLedgerCode("");
    taxTypeCode("");
    chosenTaxCode("");
    if (props.props) {
      chargeCode(props.props.node.code);
      nameCode(props.props.node.name);
      descCode(props.props.node.desc);
      internalCode(props.props.node.descInternal);
      amountCode(props.props.node.amountDefault);
      chargeTypeCode(props.props.node.type);
      chosenLedgerCode(props.props.node.extLedgerId);
    }
    console.log(1);
    try {
      chargeClient
        .query({
          query: TaxAccountingRoll,
          variables: {
            location: props.propertyId,
            offset: 0,
            limit: 1000,
          },
        })
        .then((res) => {
          taxTypeCode(get(res, "data.slLocationTaxCodes.data", null));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {}
    console.log(2);
    try {
      chargeClient
        .query({
          query: LedgerAccountingRoll,
          variables: {
            location: props.propertyId,
            offset: 0,
            limit: 1000,
          },
        })
        .then((res) => {
          if (res.data) {
            ledgerData(get(res, "data.slLocationLedgerAccounts.data", null));
            if (props.props) {
              chargeCode(props.props.node.code);
              nameCode(props.props.node.name);
              descCode(props.props.node.desc);
              // ledgerData(props.props.node.extLedgerId);
              internalCode(props.props.node.descInternal);
              amountCode(props.props.node.amountDefault);
              chargeTypeCode(props.props.node.type);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {}
  };

  return (
    <Modal
      className="semanticModal custom-modal unit-modal charge-code-modal"
      onOpen={() => setOpen(true)}
      open={open}
      // closeIcon
      trigger={
        props.clickable ? (
          <Icon fitted name="plus circle" link onClick={getLedger} />
        ) : (
          <Button
          onClick={getLedger}
            className="ml-3 button-hover-universal"
            icon={props.props ? true : false}
            compact
            id="getLedger"
          >
            {props.props ? <Icon name="edit" /> : <span>Create</span>}
          </Button>
        )
      }
    >
      <Modal.Header>
        <Header>
          {props.props ? "Update Charge Code" : "Create Charge Code"}
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Form>
            <Grid>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="form-group">
                  <div className="has-float-label">
                    <input
                      className="form-control"
                      id="chargecode"
                      name="chargecode"
                      type="text"
                      placeholder="Code"
                      autoComplete="off"
                      value={charge || ""}
                      onChange={(e) => chargeCode(e.target.value)}
                    />
                    <label
                      htmlFor="chargecode"
                      title="Charge codes are entities that are added to a resident’s account/invoice.  Examples include Rent, Parking Fee, Pet Fee, Cleaning Fee, etc. Charge codes are linked to a ledger account for financial reporting purposes."
                    >
                      Code
                    </label>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="form-group">
                  <div className="has-float-label">
                    <input
                      className="form-control"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      autoComplete="off"
                      value={name || ""}
                      onChange={(e) => nameCode(e.target.value)}
                    />
                    <label htmlFor="name">Name</label>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="form-group">
                  <div className="has-float-label">
                    <input
                      className="form-control"
                      id="amount"
                      name="amount"
                      placeholder="Amount"
                      autoComplete="off"
                      value={amount || ""}
                      type="number"
                      onChange={(e) => amountCode(e.target.value)}
                    />
                    <label htmlFor="amount">Amount</label>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="form-group">
                  <div className="has-float-label">
                    <select
                      className="form-control"
                      defaultValue={chargeType}
                      id="leaseLength"
                      onChange={(e) => chargeTypeCode(e.target.value)}
                    >
                      <option value=""></option>
                      <option value={CHARGE_TYPES.Revenue}>Revenue</option>
                      <option value={CHARGE_TYPES.Credit}>Credit</option>
                      <option value={CHARGE_TYPES.Payment}>Payment</option>
                      <option value={CHARGE_TYPES.Refund}>Refund</option>
                    </select>
                    <label htmlFor="leaseLength">Type</label>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="form-group">
                  <div className="has-float-label">
                    <select
                      className="form-control"
                      id="leaseLength"
                      onChange={(e) => chosenLedgerCode(e.target.value)}
                    >
                      <option value=""></option>
                      {Array.isArray(ledger) &&
                        ledger.map((item) => {
                          return <option value={item._id}>{item.name}</option>;
                        })}
                    </select>
                    <label htmlFor="leaseLength">Ledger Account</label>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="form-group">
                  <div className="has-float-label">
                    <textarea
                      className="form-control"
                      type="text"
                      name="description"
                      id="description"
                      placeholder="Description"
                      autoComplete="off"
                      value={description || ""}
                      onChange={(e) => descCode(e.target.value)}
                    />
                    <label htmlFor="description">Description</label>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="form-group">
                  <div className="has-float-label">
                    <textarea
                      className="form-control"
                      type="text"
                      name="intdescription"
                      id="intdescription"
                      placeholder="intDescription"
                      autoComplete="off"
                      value={internal || ""}
                      onChange={(e) => internalCode(e.target.value)}
                    />
                    <label htmlFor="intdescription">Internal Description</label>
                  </div>
                </div>
              </Grid.Column>
            </Grid>

            {/* <Form.Field required>
              <label title="Charge codes are entities that are added to a resident’s account/invoice.  Examples include Rent, Parking Fee, Pet Fee, Cleaning Fee, etc. Charge codes are linked to a ledger account for financial reporting purposes.">
                Code
              </label>
              <input
                value={charge || ""}
                onChange={(e) => chargeCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Name</label>
              <input
                value={name || ""}
                onChange={(e) => nameCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Description</label>
              <input
                value={description || ""}
                onChange={(e) => descCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Internal Description</label>
              <input
                value={internal || ""}
                onChange={(e) => internalCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Amount</label>
              <input
                value={amount || ""}
                type="number"
                style={{ width: "93%" }}
                onChange={(e) => amountCode(e.target.value)}
              />
            </Form.Field>
            {!props.props && (
              <>
                <Form.Field required>
                  <label>Type</label>
                  <select
                    className="form-control"
                    defaultValue={chargeType}
                    id="leaseLength"
                    onChange={(e) => chargeTypeCode(e.target.value)}
                  >
                    <option value=""></option>
                    <option value={CHARGE_TYPES.Revenue}>Revenue</option>
                    <option value={CHARGE_TYPES.Credit}>Credit</option>
                    <option value={CHARGE_TYPES.Payment}>Payment</option>
                    <option value={CHARGE_TYPES.Refund}>Refund</option>
                  </select>
                </Form.Field>
                <Form.Field required>
                  <label>Ledger Account</label>
                  <select
                    className="form-control"
                    id="leaseLength"
                    onChange={(e) => chosenLedgerCode(e.target.value)}
                  >
                    <option value=""></option>
                    {ledger &&
                      ledger.map((item) => {
                        return <option value={item._id}>{item.name}</option>;
                      })}
                  </select>
                </Form.Field>
                <Form.Field >
                  <label>Tax Code {!taxType && <Link to='/accounting/tax'>Create Tax Code</Link>}</label>
                  <select
                    className="form-control"
                    id="leaseLength"
                    onChange={(e) => chosenTaxCode(e.target.value)}
                  >
                    {!taxType && <option value=''>No Available Tax Codes!</option>}
                    <option value=''></option>
                    {taxType &&
                      taxType.map((item) => {
                        return <option value={item._id}>{item.code}</option>;
                      })}
                  </select>
                </Form.Field>
              </>
            )} */}
            {props.props && props.props.node && (
              <>
                <div className="edit-charge-code">
                  <div className="code-col">
                    <div className="code-label">Type</div>
                    <div className="code-value">
                      {props.props.node.type &&
                        props.props.node.type.charAt(0) +
                          props.props.node.type.substring(1).toLowerCase()}
                    </div>
                  </div>
                  <div className="code-col">
                    <div className="code-label">Code</div>
                    <div className="code-value">
                      {props.props.node.code && props.props.node.code}
                    </div>
                  </div>
                  <div className="code-col">
                    <div className="code-label">Ledger ID</div>
                    <div className="code-value">
                      {props.props.node.extLedgerId &&
                        props.props.node.extLedgerId}
                    </div>
                  </div>
                </div>
                {/* <Form.Field>
                  <label>
                    Type:
                    {props.props.node.type &&
                      props.props.node.type.charAt(0) +
                        props.props.node.type.substring(1).toLowerCase()}
                  </label>
                </Form.Field>
                <Form.Field>
                  <label>
                    Code:
                    {props.props.node.code && props.props.node.code}
                  </label>
                </Form.Field>
                <Form.Field>
                  <label>
                    Ledger Id:
                    {props.props.node.extLedgerId &&
                      props.props.node.extLedgerId}
                  </label>
                </Form.Field> */}
              </>
            )}
          </Form>
          <div className="d-flex add-property-container mt-3">
            <button
              className="btn cancel-btn w-50 mr-3"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              className="btn next-btn w-50 m-0 ml-3"
              disabled={
                props.props
                  ? (chargeType && !chargeType.trim()) ||
                    (name && !name.trim()) ||
                    (description && !description.trim()) ||
                    !chargeType ||
                    !name ||
                    !description ||
                    !amount
                  : !selectedLedger ||
                    !taxType ||
                    (chargeType && !chargeType.trim()) ||
                    (name && !name.trim()) ||
                    (description && !description.trim()) ||
                    !chargeType ||
                    !name ||
                    !description ||
                    !amount
              }
              onClick={createCharge}
              positive
            >
              {props.props ? "Update" : "Create Charge Code"}
            </button>
          </div>
        </Modal.Description>
      </Modal.Content>
      {/* <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        {loading ? (
          <Button loading>Loading</Button>
        ) : (
          <>
            <Button
              className="modal-save-button"
              content={props.props ? "Update" : "Create Charge Code"}
              disabled={
                props.props
                  ? (chargeType && !chargeType.trim()) ||
                    (name && !name.trim()) ||
                    (description && !description.trim()) ||
                    !chargeType ||
                    !name ||
                    !description ||
                    !amount
                  : !selectedLedger ||
                    !taxType ||
                    (chargeType && !chargeType.trim()) ||
                    (name && !name.trim()) ||
                    (description && !description.trim()) ||
                    !chargeType ||
                    !name ||
                    !description ||
                    !amount
              }
              onClick={createCharge}
              positive
            />
          </>
        )}
      </Modal.Actions> */}
    </Modal>
  );
}