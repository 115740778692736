import React from 'react';
import {
  Button, Header, Modal, Form, Radio,
} from 'semantic-ui-react';

function AddUnitModal({ callApi, vacantUnit }) {
  const [open, setOpen] = React.useState(false);
  return (
    <Modal
      className="semanticModal custom-modal unit-modal add-property-modal"
      closeIcon
      open={open}
      size="mini"
      trigger={<div className="card-header-title" onClick={callApi}>Suggest new unit</div>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>
        <Header>Suggest new unit</Header>
      </Modal.Header>
      <Modal.Content>
        <div className="suggest-units-wrap scroll-content">
          {vacantUnit && vacantUnit.map((ele) => (
            <>
              <div className="suggest-units-row">
                <div className="custom-control custom-radio no-radio">
                  <div className="custom-control-label">
                    <div className="unit-info">
                      <div className="unit-img">
                        <img src="https://media-cdn.tripadvisor.com/media/photo-s/13/d8/ea/1b/a-room-at-the-beach.jpg" alt="image" />
                      </div>
                      <div>
                        <div className="unit-title">
                          Unit
                          {ele.node.number}
                        </div>
                        <div className="unit-subtitle">
                          $$
                          {ele.node.price}
                          {' '}
                          /month
                        </div>
                      </div>
                      <div className="unit-detail">
                        <div className="info-col">
                          {ele.node.bedrooms}
                          + Beds
                        </div>
                        <div className="dot-separator" />
                        <div className="info-col">
                          {ele.node.bathrooms}
                          + Baths
                        </div>
                        <div className="dot-separator" />
                        <div className="info-col">
                          {ele.node.sqft}
                          {' '}
                          sq.ft
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
        <button type="button" className="btn next-btn mt-5 btn-gradient w-100" onClick={() => setOpen(false)}>
          Done
        </button>
      </Modal.Content>
    </Modal>
  );
}

export default AddUnitModal;