import React from "react";
import { amountFormatter } from "../../utils/common";

import "./ApplicationPropertyCard.scss";
const ApplicationPropertyCard = ({ property }) => {
  const { streetOne, streetTwo, city, state, postcode, unitNum } =
    property.location;
  const {
    bedrooms,
    bathrooms,
    size,
    photos,
    price,
    petsPermitted,
    maxOccupancy,
    securityDeposit,
    cleaningDeposit,
  } = property.place;

  return (
    <div className="card">
      <div className="card-body p-0">
        <div className="grid-container">
          <div>
            <img src={photos[0].url} alt="Property" className="property-img" />
          </div>
          <div>
            <div className="address">
              {streetOne} {streetTwo} <br />
              {city}, {state} {postcode} <br />
              {unitNum && <span>Unit: {unitNum}</span>}
            </div>
            <span className="property-size__item">
              <img src="assets/icons/bedrooms.svg" alt="bath icon" />
              <span> {bedrooms}</span>
            </span>
            <span className="property-size__item">
              <img src="assets/icons/bath.svg" alt="bedroom icon" />
              <span> {bathrooms}</span>
            </span>
            <span className="property-size__item">
              <img src="assets/icons/size.svg" alt="size icon" />
              <span> {size} sq.ft</span>
            </span>
          </div>
          <div className="responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Rent</th>
                  <th>Security</th>
                  <th>Cleaning</th>
                  <th>Total</th>
                  <th>Empty Since</th>
                  <th>Max Occupancy</th>
                  <th>Pets</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-target="Rent">{amountFormatter(price)}</td>
                  <td data-target="Security">
                    {amountFormatter(securityDeposit)}
                  </td>
                  <td data-target="Cleaning">
                    {amountFormatter(cleaningDeposit)}
                  </td>
                  <td data-target="Total">
                    {amountFormatter(
                      price * 2 + securityDeposit + cleaningDeposit
                    )}
                  </td>
                  <td data-target="Empty Since">?</td>
                  <td data-target="Max Occupancy">{maxOccupancy || "?"}</td>
                  <td data-target="Pets">{petsPermitted || "?"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationPropertyCard;
