import React, { useState, useEffect } from "react";
import {
  useTable,
  useExpanded,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "../../containers/Accounting/Accounting.scss";

export default function SimpleTable({
  columns: userColumns,
  data,
  totalItems,
  getLedger,
  sortId,
  sortDesc,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    lastPage,
    state: { pageIndex },
    toggleAllRowsExpanded,
    previousPage,
    nextPage,
    gotoPage,
    page,
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: {
        pageSize: 1000,
        sortBy: [
          {
            id: sortId,
            desc: sortDesc,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    usePagination
  );
  React.useMemo(() => toggleAllRowsExpanded(true), [toggleAllRowsExpanded]);

  const [navNums, setNavNums] = useState([0, 1, 2, 3, 4]);
  const [ellipsisAnim, setEllipsisAnim] = useState(false);
  const [rightEllipsis, setRightEllipsis] = useState(0);

  const incrementNavNums = () => {
    const incremented = navNums.map((k) => {
      return k + 5;
    });
    setNavNums(incremented);
  };

  const decrementNavNums = () => {
    const decremented = navNums.map((k) => {
      return k - 5;
    });
    setNavNums(decremented);
  };

  // creates iterable array for mapping ui buttons.
  /* eslint-disable */
  const pageLength = Array.apply(
    null,
    Array(!isNaN(lastPage) ? lastPage : 0)
  ).map(() => { });
  /* eslint-enable */



  useEffect(() => {
    setEllipsisAnim(false);
  }, [data]);

  /* eslint-disable */
  useEffect(() => {
    gotoPage(navNums[0]);
  }, [rightEllipsis]);
  /* eslint-enable */
  //

  // Render the UI for your table\
  return (
    <>
      <table
        style={{
          width: "100%",
          flex: "0 0 100%",
          position: "relative",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({

                  })}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page &&
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          {rows && rows.length === 0 && (
            <td colSpan={15} style={{ textAlign: "center" }}>
              No Results Found
            </td>
          )}
        </tbody>
      </table>
    </>
  );
}
