import React, { useState, useEffect } from "react";
import { AccountingMetrics } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import "../../containers/Accounting/Accounting.scss";
import _ from "lodash";
import { Card, Header, Image, Grid } from "semantic-ui-react";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { Line, Bar } from "react-chartjs-2";

const ledgerClient = getClient(ACCOUNTING);

export default function AccountingGraphs(props) {
  const [Metrics, setMetrics] = useState(false);
  const [Losses, setLosses] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    getLedger()
  }, [props && props.selectedPrimary]);

  const getLedger = async () => {
    const Metrics = await ledgerClient.query({
      query: AccountingMetrics,
      variables: {
        location:
          props && props.selectedPrimary.id
      },
    });
    if (Metrics.data.slMetrics && Metrics.data.slMetrics.edges.length !== 0) {
      const Value = Metrics.data && Metrics.data.slMetrics && Metrics.data.slMetrics.edges && Metrics.data.slMetrics.edges[0] && Metrics.data.slMetrics.edges[0].node;
      const exp = Value && Value.expensesTotalGraph
      const losses = {}
      if (typeof exp == "object") {
        for (const [key, value] of Object.entries(exp)) {
          const newa = value * -1;
          losses[key] = newa;
        }
      }
      setMetrics(Value)
      setLosses(losses)
    } else {
    }
  };

  return (
    <>

      <div className="accounting-wrapper">
        <div className="table-rw">
          {
            !props.dashboard && Metrics == null ?
              <>
                <Image
                  src="/assets/icons/NoData.svg"
                  size="medium"
                  centered
                />
                <div className="no-data-wrapper">
                  <br></br>
                  <Header
                    as="h3"
                    size="large"
                  >
                    No Data
                  </Header>
                </div>
              </> :
              <>
                {Metrics && !props.dashboard && (
                  <>
                    <Card fluid className="assets-wrapper accounting-second-step">
                      <Card.Content>
                        <Grid className="left-wrap">
                          <Card.Header>Total Assets</Card.Header>
                        </Grid>

                        <Grid className="right-wrap">
                          <Grid.Column>
                            <Header>
                              $
                              {(
                                Math.round(
                                  (Metrics &&
                                    Metrics.assetsTotal30 + Number.EPSILON) *
                                  100
                                ) / 100
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Header>
                            Last 30 Days
                          </Grid.Column>
                          <span className="line-seperator"></span>
                          <Grid.Column>
                            <Header>
                              $
                              {(
                                Math.round(
                                  (Metrics &&
                                    Metrics.assetsTotal365 + Number.EPSILON) *
                                  100
                                ) / 100
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Header>
                            Last 365 Days
                          </Grid.Column>
                        </Grid>
                      </Card.Content>
                    </Card>
                  </>
                )}
                <Card.Group
                  itemsPerRow={2}
                  stackable
                  className="graph-data-wrapper-dashboard accounting-third-step"
                >
                  <Card>
                    {Metrics && (
                      <Card.Content>
                        <Card.Header>
                          <div className="left-col">
                            <h4>Income</h4>
                          </div>
                          <div className="right-col"></div>
                        </Card.Header>
                        <Grid columns="2" padded className="stripe-data">
                          <Grid.Column>
                            <span>
                              $
                              {Metrics.incomeTotal30
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <br></br>
                            <label>Last 30 days</label>
                          </Grid.Column>
                          <Grid.Column>
                            <span>
                              {" "}
                              $
                              {Metrics.incomeTotal365
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <br></br>
                            <label>Last 365 days</label>
                          </Grid.Column>
                        </Grid>
                        <Line
                          data={{
                            labels: Object.keys(Metrics.incomeTotalGraph),
                            datasets: [
                              {
                                label: "$",
                                fill: true,
                                data: Object.values(Metrics.incomeTotalGraph),

                                backgroundColor: [
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                ],
                              },
                            ],
                          }}
                          width={150}
                          height={75}
                          options={{
                            maintainAspectRatio: true,
                            legend: {
                              display: false,
                            },
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    color: "rgba(0, 0, 0, 0)",
                                    drawOnChartArea: false,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  afterTickToLabelConversion: function (q) {
                                    for (var tick in q.ticks) {
                                      q.ticks[tick] =
                                        "$" +
                                        q.ticks[tick].replace(
                                          /(\d)(?=(\d\d\d)+(?!\d))/g,
                                          "$1,"
                                        );
                                    }
                                  },
                                  ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 10,
                                    suggestedMax: 100,
                                    max:
                                      Math.ceil(
                                        (_.max(
                                          Object.values(
                                            Metrics.incomeTotalGraph
                                          ),
                                          function (o) {
                                            return Metrics.incomeTotalGraph[o];
                                          }
                                        ) *
                                          1.5) /
                                        1000
                                      ) * 1000,
                                  },
                                  gridLines: {
                                    color: "rgb(220,220,220)",
                                  },
                                },
                              ],
                            },
                          }}
                        ></Line>
                      </Card.Content>
                    )}
                  </Card>
                  <Card>
                    {Losses && (
                      <Card.Content>
                        <Card.Header>
                          <div className="left-col">
                            <h4>Income and Expenses</h4>
                          </div>
                          <div className="right-col"></div>
                        </Card.Header>
                        <Grid columns="2" padded className="stripe-data">
                          <Grid.Column>
                            <span>
                              $
                              {Metrics &&
                                Metrics.incomeTotal365
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <br></br>
                            <label>Total Income (Last 365 Days)</label>
                          </Grid.Column>
                          <Grid.Column>
                            <span>
                              $
                              {Metrics &&
                                Metrics.expensesTotal365
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <br></br>
                            <label>Total Expenses (Last 365 Days)</label>
                          </Grid.Column>
                        </Grid>
                        <br />
                        <Line
                          data={{
                            labels: Object.keys(Losses),
                            datasets: [
                              {
                                label: "Income($)",
                                fill: true,
                                data:
                                  Metrics &&
                                  Object.values(
                                    Metrics && Metrics.incomeTotalGraph
                                  ),

                                backgroundColor: [
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                ],
                              },
                              {
                                label: "Losses($)",
                                fill: true,
                                data: Object.values(Losses),

                                backgroundColor: "#ff525266",
                              },
                            ],
                          }}
                          width={150}
                          height={75}
                          options={{
                            maintainAspectRatio: true,
                            legend: {
                              display: false,
                            },
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    color: "rgba(0, 0, 0, 0)",
                                    drawOnChartArea: false,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  afterTickToLabelConversion: function (q) {
                                    for (var tick in q.ticks) {
                                      q.ticks[tick] =
                                        "$" +
                                        q.ticks[tick].replace(
                                          /(\d)(?=(\d\d\d)+(?!\d))/g,
                                          "$1,"
                                        );
                                    }
                                  },
                                  ticks: {
                                    beginAtZero: true,
                                    max:
                                      Math.ceil(
                                        (_.max(
                                          Metrics &&
                                          Object.values(
                                            Metrics &&
                                            Metrics.incomeTotalGraph
                                          ),
                                          function (o) {
                                            return (
                                              Metrics &&
                                              Metrics.incomeTotalGraph[o]
                                            );
                                          }
                                        ) *
                                          1.5) /
                                        1000
                                      ) * 1000,
                                  },
                                  gridLines: {
                                    color: "rgb(220,220,220)",
                                  },
                                },
                              ],
                            },
                          }}
                        ></Line>
                      </Card.Content>
                    )}
                  </Card>
                </Card.Group>
                {Metrics && (
                  <Card.Group
                    itemsPerRow={2}
                    stackable
                    className="graph-data-wrapper-dashboard accounting-fourth-step"
                  >
                    <Card>
                      <Card.Content>
                        <Card.Header>
                          <div className="left-col">
                            <h4>Expenses</h4>
                          </div>
                          <div className="right-col"></div>
                        </Card.Header>
                        <Grid columns="2" padded className="stripe-data">
                          <Grid.Column>
                            <span>
                              $
                              {Metrics.expensesTotal30
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <br></br>
                            <label>Last 30 days</label>
                          </Grid.Column>
                          <Grid.Column>
                            <span>
                              $
                              {Metrics.expensesTotal365
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <br></br>
                            <label>Last 365 days</label>
                          </Grid.Column>
                        </Grid>
                        <Bar
                          data={{
                            labels: Object.keys(Metrics.expensesTotalGraph),
                            datasets: [
                              {
                                label: "$",
                                fill: false,
                                data: Object.values(Metrics.expensesTotalGraph),

                                backgroundColor: [
                                  "#97399933", // $qp-color-purple
                                  "#97399944", // $qp-color-purple
                                  "#97399944", // $qp-color-purple
                                  "#97399955", // $qp-color-purple
                                  "#97399955", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399977", // $qp-color-purple
                                  "#97399977", // $qp-color-purple
                                  "#97399988", // $qp-color-purple
                                  "#97399999", // $qp-color-purple
                                  "#97399999", // $qp-color-purple
                                ],
                              },
                            ],
                          }}
                          width={150}
                          height={75}
                          options={{
                            maintainAspectRatio: true,
                            legend: {
                              display: false,
                            },
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    color: "rgba(0, 0, 0, 0)",
                                    drawOnChartArea: false,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  afterTickToLabelConversion: function (q) {
                                    for (var tick in q.ticks) {
                                      q.ticks[tick] =
                                        "$" +
                                        q.ticks[tick].replace(
                                          /(\d)(?=(\d\d\d)+(?!\d))/g,
                                          "$1,"
                                        );
                                    }
                                  },
                                  ticks: {
                                    beginAtZero: true,
                                    steps: 10,
                                    stepValue: 5,
                                    max:
                                      Math.ceil(
                                        (_.max(
                                          Object.values(
                                            Metrics.expensesTotalGraph
                                          ),
                                          function (o) {
                                            return Metrics.expensesTotalGraph[
                                              o
                                            ];
                                          }
                                        ) *
                                          1.5) /
                                        1000
                                      ) * 1000,
                                  },
                                  gridLines: {
                                    color: "rgb(220,220,220)",
                                  },
                                },
                              ],
                            },
                          }}
                        ></Bar>
                        <br />
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>
                          <div className="left-col">
                            <h4>Invoices</h4>
                          </div>
                          <div className="right-col"></div>
                        </Card.Header>
                        <Grid columns="2" padded className="stripe-data">
                          <Grid.Column>
                            <span>
                              $
                              {(
                                Math.round(
                                  (Metrics.invoicesTotal30 + Number.EPSILON) * 100
                                ) / 100
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <br></br>
                            <label>Last 30 days</label>
                          </Grid.Column>
                          <Grid.Column>
                            <span>
                              $
                              {(
                                Math.round(
                                  (Metrics.invoicesTotal365 + Number.EPSILON) *
                                  100
                                ) / 100
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <br></br>
                            <label>Last 365 days</label>
                          </Grid.Column>
                        </Grid>
                        <Bar
                          data={{
                            labels: Object.keys(Metrics.invoicesTotalGraph),
                            datasets: [
                              {
                                label: "$",
                                fill: false,
                                data: Object.values(Metrics.invoicesTotalGraph),

                                backgroundColor: [
                                  "#97399933", // $qp-color-purple
                                  "#97399944", // $qp-color-purple
                                  "#97399944", // $qp-color-purple
                                  "#97399955", // $qp-color-purple
                                  "#97399955", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399966", // $qp-color-purple
                                  "#97399977", // $qp-color-purple
                                  "#97399977", // $qp-color-purple
                                  "#97399988", // $qp-color-purple
                                  "#97399999", // $qp-color-purple
                                  "#97399999", // $qp-color-purple
                                ],
                              },
                            ],
                          }}
                          width={150}
                          height={75}
                          options={{
                            maintainAspectRatio: true,
                            legend: {
                              display: false,
                            },
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    color: "rgba(0, 0, 0, 0)",
                                    drawOnChartArea: false,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  afterTickToLabelConversion: function (q) {
                                    for (var tick in q.ticks) {
                                      q.ticks[tick] =
                                        "$" +
                                        q.ticks[tick].replace(
                                          /(\d)(?=(\d\d\d)+(?!\d))/g,
                                          "$1,"
                                        );
                                    }
                                  },
                                  ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 12,
                                    stepSize: 1000,
                                    precision: 1,
                                    max:
                                      Math.ceil(
                                        (_.max(
                                          Object.values(
                                            Metrics.invoicesTotalGraph
                                          ),
                                          function (o) {
                                            return Metrics.invoicesTotalGraph[o];
                                          }
                                        ) *
                                          1.5) /
                                        1000
                                      ) * 1000,
                                  },
                                  gridLines: {
                                    color: "rgb(220,220,220)",
                                  },
                                },
                              ],
                            },
                          }}
                        ></Bar>
                      </Card.Content>
                    </Card>
                  </Card.Group>)}</>}
        </div>
      </div>
    </>

  );
}