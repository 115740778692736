import React, { Component } from "react";
import { Switch } from "react-router-dom";
import "./App.scss";
import { ApolloProvider } from "react-apollo";
import { withRouter, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { client } from "./init-apollo";
import {
  ROUTES,
  PERSON_URL,
  USER_EVENT,
  PRIMARY_LOCATION_URL,
  LEASES_URL,
  INVOICES_URL,
  PERSON_PAYMENT,
  MAINTENANCE_URL,
  LEADS_URL,
  AFFORDABLE_HOUSING,
  APPLICATION_MANAGER,
  LOCATIONAPI,
  getEmitter,
  EXPIRE_TIME,
  SESSION_TIMEOUT_THRESHOLD,
  RENTER,
  MANAGER,
  SERVICEPROFESSIONAL,
  SCHEDULEINTEGRATE,
} from "./utils";
import { personGql } from "./store/person/person";
import Profile from "./containers/Profile/Profile";
import { PublicRoute, PrivateRoute } from "./routes";
import Cookie from "js-cookie";
import Dashboard from "./containers/Dashboard/Dashboard";
import Explore from "./containers/Explore/Explore";
import Applications from "./containers/Applications/Applications";
import ExistingApplications from "./containers/Applications/ExistingApplications";
import Lease from "./containers/Lease/Lease";
import LeaseDetails from "./containers/Lease/LeaseDetails";
import EditLease from "./containers/Lease/EditLease";
import CreateLease from "./containers/Lease/CreateLeasePage";
import { ApplicantDetail } from "./containers/Applications/ApplicantDetail";
import TenantDetail from "./containers/Tenants/TenantDetail";
import Maintenance from "./containers/Maintenance/Maintenance";
import MaintenanceDetail from "./containers/Maintenance/MaintenanceDetail";
import Loyalty from "./containers/Loyalty/Loyalty";
import Tenants from "./containers/Tenants/Tenants";
import OldTenants from "./containers/Tenants/OldTenants";
import Settings from "./containers/Settings/Settings";
import TourManagement from "./containers/Settings/Routes/TourManagement";

import PropertiesComponent from "./components/Properties/PropertiesComponent";
import PropertyListing from "./components/Properties/PropertyListing";
import PropertyDetails from "./components/Properties/AddProperty/ReviewPropertyDetails";
import BluemoonSettings from "./components/Properties/AddProperty/Settings.jsx";
import AddProperty from "./components/Properties/AddProperty";
import Leads from "./containers/Leads/Leads";
import Schedule from "./containers/Schedule/Schedule";
import FileManager from "./containers/FileManager/FileManager";
import Accounting from "./containers/Accounting/Accounting";
import Ledger from "./containers/Accounting/Ledger";
import BoxScore from "./containers/Accounting/BoxScore";
import Charge from "./containers/Accounting/Charge";
import Invoices from "./containers/Accounting/Invoices";
import WorkOrder from "./containers/Accounting/WorkOrder";
import RentRoll from "./containers/Accounting/RentRoll";
import Delinquency from "./containers/Accounting/Delinquency";
import PayBills from "./containers/Accounting/PayBills";
import Bills from "./containers/Accounting/Bills";
import Vendors from "./containers/Accounting/Vendors";
import Credits from "./containers/Accounting/Credits";
import JournalReport from "./containers/Accounting/JournalReport";
import Journal from "./containers/Accounting/Journal";
import TrialReport from "./containers/Accounting/TrialReport";
import ARCash from "./containers/Accounting/ARCash";
import Aging from "./containers/Accounting/Aging";
import Transactions from "./containers/Accounting/Transactions";
import ProfitLoss from "./containers/Accounting/ProfitLoss";
import Reconcile from "./containers/Accounting/Reconcile";
import Tax from "./containers/Accounting/Tax";
import Financials from "./containers/Accounting/Financials";
import EditLocation from "./containers/Properties/EditLocation";
import Leases from "./containers/Leases/Leases";
import AddUnit from "./containers/Properties/AddUnit";
import ViewLead from "./containers/Leads/ViewLead";
import ViewApplication from "./containers/Applications/viewApplication";
import ApplicationForm from "./containers/Applications/ApplicationForm";
import { Error404 } from "./containers/Error404/Error404";
import Signin from "./containers/SignIn";
import ReportsData from "./components/Reports/ReportsData";
import BalanceSheet from "./containers/Accounting/BalanceSheet";
import {
  getClient,
  getAuthToken,
  getUserRoles,
  logoutAction,
} from "./store/auth";
import { getCookie } from "./store/auth/authUtility";
import TopNav from "./layout/TopNav/TopNav";
import LeftNav from "./layout/LeftNav/LeftNav";
import "./assets/fomantic/dist/semantic.css";
import { primaryLocation, primaryLocations } from "./store/person/properties";
import { auth } from "./containers/Auth/Firebase";
import { PageLoader } from "./components/Loader/PageLoader";
import { List, Button } from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import { NavContextProvider } from "./layout/NotificationContext";
import NotificationPage from "./components/NotificationsDropdown/NotificationsPage";
import Messages, {
  MOBILE_CHAT_ROOM_VIEW,
  MOBILE_CHAT_VIEW,
} from "./containers/Messages/Messages";
import {
  getIdTokenResult,

} from "firebase/auth";

import LeaseRenewal from "./containers/Tenants/LeaseRenewal";
import {
  GOOGLE_CA_ACCESS_CODE_KEY,
  GOOGLE_CA_ACCESS_TOKEN_KEY,
} from "./components/Modals/ScheduleIntegration";
import { AddGoogleToken } from "./store/person/schedule";
import ThirdpartApi from "./containers/ThirdpartyApi/ThirdpartApi";
import NewPushNotification from "./containers/Notifications/NewPushNotification";

const eventEmit = getEmitter();

const personMainClient = getClient(PERSON_URL);
const primaryLocationClient = getClient(PRIMARY_LOCATION_URL);

const NewPrimaryLocations = getClient(LOCATIONAPI);

const leaseClient = getClient(LEASES_URL);
const maintenanceClient = getClient(MAINTENANCE_URL);
const invoicesClient = getClient(INVOICES_URL);
const personClient = getClient(PERSON_PAYMENT);
const leadsClient = getClient(LEADS_URL);
const affordableHousingClient = getClient(AFFORDABLE_HOUSING);
const applicationManager = getClient(APPLICATION_MANAGER);

class App extends Component {
  _initAuth = false;
  _userPresent = false;
  constructor(props) {
    super(props);
    this.state = {
      leftNavCollapsed: window.innerWidth < 600 ? true : false,
      rightSidebarVisible: false,
      primaryLocations: null,
      organizationId: null,
      user: null,
      selectedPrimary: [],
      isLoading: false,
      isLoggedIn: false,
      legalEntityVerification: null,
    };
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  componentDidMount = async () => {
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      if (window.performance) {
        if (window.navigation.type === 1) {
          return;
        }
      } else {
        this.deleteSiteCookies();
      }
    });
    eventEmit.on(USER_EVENT, (data) => this.setLoggedStatus(data));
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_PROD}`);
    await this.getPersonInfo();
    this.authInit();
    this.gethubSpot();
  };

  componentDidMount = async () => {
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      if (window.performance) {
        if (window.navigation.type === 1) {
          return;
        }
      } else {
        this.deleteSiteCookies();
      }
    });
    eventEmit.on(USER_EVENT, (data) => this.setLoggedStatus(data));
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_PROD}`);
    await this.getPersonInfo();
    this.authInit();
    this.gethubSpot();

  };

  gethubSpot = () => {
    var x = document.getElementsByClassName("widget-app-container");
  };

  componentWillUnmount() {
    eventEmit.removeEventListener(USER_EVENT, this.setLoggedStatus);
  }

  componentDidUpdate() {
    this.getUserRole();
    this.authInit();
    
  }

  setLoading = (status) => {
    this.setState({ isLoading: status });
  };

  // Handle toggling left navigation - Expanded or Collapsed
  leftNavToggle = async () => {
    this.setState({ leftNavCollapsed: !this.state.leftNavCollapsed });
  };

  // Handle toggling right side bar (show/hide)
  rightSidebarToggle = async () => {
    this.setState({ rightSidebarVisible: !this.state.rightSidebarVisible });
  };

  // Hide right side bar
  rightSidebarHide = async () => {
    this.setState({ rightSidebarVisible: false }); //if we pass in to force hide it, then hide it, else reverse it
  };

  setLoggedStatus = async (data) => {
    const cookie = getCookie();

    /* eslint-disable */
    await this.setState((prevState) => ({
      isLoggedIn: (prevState.isLoggedIn =
        (Boolean(getAuthToken()) && cookie.manager === "true") ||
        cookie.serviceProfessional === "true"),
      user: data.data.edges && data ? data.data.edges[0].node : null,
    }));
    /* eslint-enable */
    await this.getMyProperties();
    if (!this.state.isLoggedIn) {
      this.props.history.push("/");
    }
  };

  getUserRole = () => {
    if (auth && auth.auth && auth.auth.currentUser && !this._userPresent) {
      this._userPresent = true;
      if (
        Cookie.get(RENTER) === "undefined" &&
        Cookie.get(MANAGER) === "undefined" &&
        Cookie.get(SERVICEPROFESSIONAL) === "undefined"
      ) {
        getUserRoles(auth);
      }
    }
  };
  goToProperties = () => {
    this.props.history.push({
      pathname: ROUTES.propertiesListing,
    });
  }

  authInit = () => {
    const currentTime = new Date().getTime();
    const expireTime = parseInt(Cookie.get(EXPIRE_TIME));

    // refresh token when there are 5 minutes left for expiry or has exceeded it
    if (expireTime - currentTime <= SESSION_TIMEOUT_THRESHOLD * 1000) {
      // onRequestSuccess(client, RELOAD)
      this.deleteAllCookies();
      this.setLoading(false);
      logoutAction(client).then(() =>
        setTimeout(() => {
          this.props.history.push("/");
        }, 1000)
      );
    }
    
  };

  getPersonInfo = () => {
    if (
      getCookie().manager === "true" ||
      getCookie().serviceProfessional === "true"
    ) {
      const newClient = getClient(PERSON_URL);
      this.setLoading(true);
      try {
        newClient
          .query({
            query: personGql,
          })
          .then((res) => {
            this.setState({
              user: res.data.person.edges[0].node,
            });
            const user = res.data.person.edges[0].node;
            mixpanel.identify(user.ndbId);
            mixpanel.track("Manager Log In Success", {
              Email: user.email,
              ndbId: user.ndbId,
            });
            mixpanel.register({
              Email: user.email,
              Name: user.firstName + user.lastName,
              ndbId: user.ndbId,
              Points: user.pointsBalance,
            });
            mixpanel.people.set({
              Email: user.email,
              Name: user.firstName + user.lastName,
              ndbId: user.ndbId,
              Points: user.pointsBalance,
            });
            this.getMyProperties();
            return res;
          })
          .catch((e) => {
            this.setLoading(false);
            console.log(e);
            return;
          });
      } catch (e) {
        this.setLoading(false);
        return;
      }
    }
  };

  getMyProperties = async () => {
    if (
      getCookie().manager === "true" ||
      getCookie().serviceProfessional === "true"
    ) {
      this.setState({ isLoading: true });
      try {
        await NewPrimaryLocations.query({
          query: primaryLocations,
          variables: {
            status: "publish",
          },
        }).then((res) => {
          if (res.data.locations.edges.length === 0) {
            eventEmit.emit("New User");
          }
          let setLoc = localStorage.getItem("LOC");
          let locations = res.data.locations.edges;
          let organizationId =
            locations &&
            locations[0] &&
            locations[0].node &&
            locations[0].node.organizationId;
          locations = locations.filter(
            (item) => !item.node.status || item.node.status === "publish"
          );
          this.setState(
            {
              organizationId: organizationId,
              primaryLocations: locations,
              selectedPrimary: !setLoc ? locations[0] : JSON.parse(setLoc),
            },
            () => { }
          );
          this.setLoading(false);
        });
      } catch (e) {
        this.setLoading(false);
        console.log(e);
      }
    }
  };

  setGASync = (locationId) => {
    const ScheduleIntegrate = getClient(SCHEDULEINTEGRATE);
    const access_token = localStorage.getItem(GOOGLE_CA_ACCESS_TOKEN_KEY);
    const code = localStorage.getItem(GOOGLE_CA_ACCESS_CODE_KEY);
    if (access_token && code) {
      try {
        ScheduleIntegrate.mutate({
          mutation: AddGoogleToken,
          variables: {
            input: {
              personId: this.state.user.ndbId,
              locationId: locationId,
              accessToken: access_token,
              refreshToken: code,
            },
          },
        }).then((res) => {
          // fetchIntegrations();
        });
      } catch (error) {
        //
      }
    } else {
      console.warn("Google Calendar is not sync!");
    }
  };

  setLocation = async (e) => {
    if (e) {
      const { primaryLocations } = this.state;
      primaryLocations.forEach((locationMatch) => {
        if (e === locationMatch.node.customId) {
          localStorage.setItem("LOC", JSON.stringify(locationMatch));
          this.setState((prevState) => ({
            selectedPrimary: (prevState.selectedPrimary = locationMatch),
          }));
          this.setGASync(locationMatch.node.id);
          eventEmit.emit("PurchasePoints");
        }
      });
    }
  };

  render() {
    const {
      selectedPrimary,
      primaryLocations,
      user,
      isLoading,
      organizationId,
    } = this.state;
    const isAuthenticated = user && getAuthToken();
    return (
      <ApolloProvider client={personMainClient}>
        <div className={isAuthenticated ? "dashboard-content" : ""}>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
          {isAuthenticated && (
            <NavContextProvider>
              <div className="noPrint">
                <TopNav
                  leftNavToggle={this.leftNavToggle}
                  authInit={this.authInit}
                  getUserRole={this.getUserRole}
                  personMainClient={personMainClient}
                  isLoading={isLoading}
                  setLoading={this.setLoading}
                  leftNavCollapsed={this.state.leftNavCollapsed}
                  selectedPrimary={selectedPrimary}
                  selectedPoints={
                    selectedPrimary &&
                    selectedPrimary.node &&
                    selectedPrimary.node.pointsBalance
                  }
                  setLocation={this.setLocation}
                  primaryLocations={primaryLocations}
                  rightSidebarToggle={this.rightSidebarToggle}
                  primaryLocationClient={primaryLocationClient}
                  user={user}
                />
                <LeftNav
                  primaryLocations={primaryLocations}
                  collapsed={this.state.leftNavCollapsed}
                  user={user}
                />
              </div>
            </NavContextProvider>
          )}
          {isLoading || (getAuthToken() && !user) ? (
            <PageLoader text />
          ) : (
            <div className="container-fluid top-container-fluid ">
              <div className="row">
                <div
                  className={`right-column w-80 ${this.props.location.pathname === ROUTES["addProperty"]
                    ? "properties-outer-container"
                    : ""
                    }`}
                >
                  <div className={isAuthenticated ? "app-content doPrint" : ""}>

                    <Switch>
                      <PublicRoute
                        restricted={true}
                        component={Signin}
                        path={ROUTES["home"]}
                        exact
                        setLoading={this.setLoading}
                        primaryLocations={primaryLocations}
                      />
                      <PrivateRoute
                        component={Explore}
                        path="/explore"
                        exact
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        user={user}
                        path={ROUTES.bluemoonSettings}
                        component={BluemoonSettings}
                      />
                      <PrivateRoute
                        component={Dashboard}
                        path="/dashboard"
                        exact
                        newUserSafe
                        user={user}
                        isLoading={isLoading}
                        isServicePrvider={true}
                        maintenanceClient={maintenanceClient}
                        leadsClient={leadsClient}
                        primaryLocationClient={primaryLocationClient}
                        selectedPrimary={selectedPrimary}
                        primaryLocations={primaryLocations}
                      />
                      <PrivateRoute
                        exact
                        path={`${ROUTES.messages}/chat-rooms`}
                        component={(props) => (
                          <Messages {...props} mode={MOBILE_CHAT_ROOM_VIEW} />
                        )}
                        selectedPrimary={selectedPrimary}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path={`${ROUTES.messages}/chat/:chatRoomId`}
                        component={(props) => (
                          <Messages {...props} mode={MOBILE_CHAT_VIEW} />
                        )}
                        selectedPrimary={selectedPrimary}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path={ROUTES.messages}
                        component={(props) => (
                          <Messages {...props} mode={MOBILE_CHAT_ROOM_VIEW} />
                        )}
                        selectedPrimary={selectedPrimary}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path={`${ROUTES.messages}/archived`}
                        component={(props) => (
                          <Messages {...props} archived={true} />
                        )}
                        selectedPrimary={selectedPrimary}
                        user={user}
                      />
                      <PrivateRoute
                        exact
                        path="/notifications"
                        user={user}
                        oldClient={client}
                      >
                        <NavContextProvider>
                          <NotificationPage />
                        </NavContextProvider>
                      </PrivateRoute>
                      {primaryLocation.length !== 0 && (
                        <>
                          <PrivateRoute
                            exact
                            path={ROUTES["applications"]}
                            component={Applications}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["existingapplications"]}
                            component={ExistingApplications}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/residents"
                            component={Tenants}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            leaseClient={leaseClient}
                            invoicesClient={invoicesClient}
                            personClient={personClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/residents/prior"
                            component={OldTenants}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            leaseClient={leaseClient}
                            invoicesClient={invoicesClient}
                            personClient={personClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["maintenance"]}
                            component={Maintenance}
                            user={user}
                            isServicePrvider={true}
                            primaryLocationClient={primaryLocationClient}
                            maintenanceClient={maintenanceClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/maintenanceDetail/location=:location/id=:maintenanceId"
                            component={MaintenanceDetail}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            maintenanceClient={maintenanceClient}
                            isServiceProf={true}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/applicantDetail/:applicationId/:tenantId"
                            component={ApplicantDetail}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/tenantDetail/tenantId:tenantId/applicationId:applicationId"
                            component={TenantDetail}
                            leaseClient={leaseClient}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            invoicesClient={invoicesClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["leads"]}
                            component={Leads}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["waitlist"]}
                            component={Leads}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["schedule"]}
                            component={Schedule}
                            isServiceProf={true}
                            user={user}
                            maintenanceClient={maintenanceClient}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["fileManager"]}
                            component={FileManager}
                            isServiceProf={true}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/vendors"
                            component={Vendors}
                            isServiceProf={true}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/viewLead/location=:location/id=:leadId"
                            component={ViewLead}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/viewApplication/location=:location/id=:applicationId"
                            component={ViewApplication}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES.propertiesListing}
                            component={PropertyListing}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                            organizationId={organizationId}
                            getMyProperties={this.getMyProperties}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES.propertyDetails}
                            component={PropertyDetails}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                            isPropertyDetails
                          />
                          <PrivateRoute
                            exact
                            path={[
                              ROUTES.addProperty,
                              // ROUTES.editPropertyDetails,
                            ]}
                            component={AddProperty}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            callLocationApi={this.getMyProperties}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                            organizationId={organizationId}
                          />
                          <PrivateRoute
                            exact
                            path="/profile"
                            component={Profile}
                            oldClient={client}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            isLoading={isLoading}
                          />
                          <PrivateRoute
                            exact
                            path="/leases/:leaseId"
                            component={Leases}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/editLease/:leaseId"
                            component={EditLease}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/createLease/location=:location/id=:id"
                            component={CreateLease}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/accounting"
                            component={Accounting}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["rentroll"]}
                            component={RentRoll}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["delinquency"]}
                            component={Delinquency}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["ledger"]}
                            component={Ledger}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["boxscore"]}
                            component={BoxScore}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["charge"]}
                            component={Charge}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["invoices"]}
                            component={Invoices}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["vendors"]}
                            component={Vendors}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["workorder"]}
                            component={WorkOrder}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["loyalty"]}
                            component={Loyalty}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["paybills"]}
                            component={PayBills}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["bills"]}
                            component={Bills}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["credits"]}
                            component={Credits}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["incomestatement"]}
                            component={ProfitLoss}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["journal"]}
                            component={Journal}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["arcash"]}
                            component={ARCash}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["journalreport"]}
                            component={JournalReport}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["trialreport"]}
                            component={TrialReport}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["balancesheet"]}
                            component={BalanceSheet}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/leaseRenewal/leaseId:leaseId"
                            component={LeaseRenewal}
                            leaseClient={leaseClient}
                            user={user}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["aging"]}
                            component={Aging}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["tax"]}
                            component={Tax}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["transactions"]}
                            component={Transactions}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["reconcile"]}
                            component={Reconcile}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            path="/accounting/reconcile/:id"
                            component={Reconcile}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            path="/unit/:locationId/:unitId?"
                            component={AddUnit}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["financials"]}
                            printDiv={this.printDiv}
                            component={Financials}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/applicationForm"
                            component={ApplicationForm}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            affordableHousingClient={affordableHousingClient}
                            applicationManager={applicationManager}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/manageLocation/:locationId?"
                            component={EditLocation}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path="/manageLocation/location=:location"
                            component={EditLocation}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["lease"]}
                            component={Lease}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                           <PrivateRoute
                            exact
                            path={ROUTES["settings"]+
                            "/tourManagement"}
                            component={TourManagement}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            newUserSafe
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES.leaseDetails}
                            component={LeaseDetails}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES["settings"]}
                            component={Settings}
                            primaryLocationClient={primaryLocationClient}
                            user={user}
                            newUserSafe
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES.reportsData}
                            component={ReportsData}
                            user={user}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES.balanceSheet}
                            component={BalanceSheet}
                            user={user}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES.thirdparty}
                            component={ThirdpartApi}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={
                              ROUTES.pushNotification +
                              "/:filter_type/:filter_id"
                            }
                            user={user}
                            component={NewPushNotification}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                          <PrivateRoute
                            exact
                            path={ROUTES.pushNotification}
                            user={user}
                            component={NewPushNotification}
                            primaryLocationClient={primaryLocationClient}
                            selectedPrimary={selectedPrimary}
                            primaryLocations={primaryLocations}
                          />
                        </>
                      )}
                      {/* <PrivateRoute
												exact
												path={ROUTES['properties']}
												component={Properties}
												primaryLocationClient={primaryLocationClient}
												user={user}
												newUserSafe
												selectedPrimary={selectedPrimary}
												primaryLocations={primaryLocations}
											/> */}
                      <PrivateRoute
                        exact
                        path={ROUTES["addProperties"]}
                        component={PropertiesComponent}
                        primaryLocationClient={primaryLocationClient}
                        user={user}
                        newUserSafe
                        selectedPrimary={selectedPrimary}
                        primaryLocations={primaryLocations}
                      />
                      <PrivateRoute component={Error404} path="*" />
                      <PublicRoute component={Error404} path="*" />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          )}
          {user && (
            <div className="footer noPrint">
              <div className="left-side">
                &copy; 2021-2022 <a href="https://www.leasera.com/">Leasera</a>{" "}
                Inc. All rights reserved.
              </div>
              <div className="right-links">
                <List>
                  <List.Item>
                    <a href="https://leasera.com/about-us" target="_blank">
                      About
                    </a>
                  </List.Item>
                  <List.Item>
                    <a href="https://leasera.com/Terms" target="_blank">
                      Terms & Conditions
                    </a>
                  </List.Item>
                  <List.Item>
                    <a href="https://leasera.com/Privacy" target="_blank">
                      Privacy Policy
                    </a>
                  </List.Item>
                </List>
              </div>
            </div>
          )}
          <div
            class="modal fade"
            id="exploreMore"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exploreMoreLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content success-modal">
                <div class="modal-header">

                  <h5 class="modal-title" id="exploreMoreLabel">
                    Attention new users:
                  </h5>
                  <p class="modal-sutitle-new">
                    Thank you for choosing Leasera as your property management solution. Please be aware that some functionality of the portal may be limited until you complete the property onboarding process.
                    <br></br>
                    <Button
                      size="tiny"
                      style={{ marginLeft: '25%', width: '50%' }}
                      className="modal-small-btn"
                      compact
                      onClick={this.goToProperties}
                      data-toggle="modal" data-target="#exploreMore"
                    >
                      Get Started
                    </Button>
                    <br></br>
                    To initiate full functionality, please go to the "Property" tab in the left hand navigation and walk through the required steps in the onboarding process. You may skip any steps that are listed as optional.
                    <br></br><br></br>
                    <a style={{ color: 'blue' }} href="https://www.leasera.com/marketing-demo/" target="_blank">Still got questions? Book a demo</a>
                    <br></br><br></br>
                    We appreciate your cooperation and look forward to fully supporting your property management needs. If you have any questions or need assistance with the onboarding process, please do not hesitate to reach out to our support team.
                    <br></br><br></br>
                    If you need further assistance, please start a help ticket in the bottom right or email us at < a style={{ color: 'blue' }} href="mailto:support@leasera.com">support@leasera.com</a> . We're excited to serve you in the next evolution of property management with Leasera.
                  </p>
                  <button
                    type="submit"
                    class="btn modal-large-btn"
                    data-toggle="modal" data-target="#exploreMore"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ApolloProvider>
    );
  }
}

export default withRouter(App);
