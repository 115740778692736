import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import moment from "moment";
import WorkOrderModal from "../Modals/WorkOrderModal";
import WorkOrderDetailModal from "../Modals/WorkOrderDetailModal";
import NewBillsModal from "../../components/Modals/NewBillsModal";
import ReceiveOrder from "../../components/Modals/ReceiveOrder";

export function TableBalance(props) {
  const [workOrderCache, setWorkOrderCache] = useState(props.workOrderCache);
  const [location, setLocation] = useState(props.propertyId);
  const [idSL, setidSL] = useState(props.id);
  const [chosenSort, setChosenSort] = useState(false);
  const [direction, setDirection] = useState(false);

  const Sorting = (value) => {
    console.log(props)
    if (direction == false) {
      setDirection(true);
      if (value === "id") {
        setChosenSort("id");
        setWorkOrderCache(
          workOrderCache.sort(
            (a, b) => (a, b) => parseFloat(a._id) - parseFloat(b._id)
          )
        );
      }
      if (value === "warehouse") {
        setChosenSort("warehouse");
        setWorkOrderCache(
          workOrderCache.sort(
            (a, b) => (a, b) =>
              parseFloat(a.WarehouseId) - parseFloat(b.WarehouseId)
          )
        );
      }
      if (value === "status") {
        setChosenSort("status");
        setWorkOrderCache(
          workOrderCache.sort((a, b) => a.status.localeCompare(b.status))
        );
      }
      if (value === "issued") {
        setChosenSort("issued");
        setWorkOrderCache(
          workOrderCache.sort(
            (a, b) => (a, b) => new Date(a.issuedDate) - new Date(b.issuedDate)
          )
        );
      }
      if (value === "delivery") {
        setChosenSort("delivery");
        setWorkOrderCache(
          workOrderCache.sort(
            (a, b) => (a, b) =>
              new Date(a.deliveryDate) - new Date(b.deliveryDate)
          )
        );
      }
      if (value === "amount") {
        setChosenSort("amount");
        setWorkOrderCache(
          workOrderCache.sort(
            (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
          )
        );
      }
    }
    if (direction == true) {
      setDirection(false);
      if (value === "id") {
        setChosenSort("id");
        setWorkOrderCache(
          workOrderCache.reverse(
            (a, b) => (a, b) => parseFloat(a._id) - parseFloat(b._id)
          )
        );
      }
      if (value === "warehouse") {
        setChosenSort("warehouse");
        setWorkOrderCache(
          workOrderCache.reverse(
            (a, b) => (a, b) =>
              parseFloat(a.WarehouseId) - parseFloat(b.WarehouseId)
          )
        );
      }
      if (value === "status") {
        setChosenSort("status");
        setWorkOrderCache(
          workOrderCache.reverse((a, b) => a.status.localeCompare(b.status))
        );
      }
      if (value === "issued") {
        setChosenSort("issued");
        setWorkOrderCache(
          workOrderCache.reverse(
            (a, b) => (a, b) => new Date(a.issuedDate) - new Date(b.issuedDate)
          )
        );
      }
      if (value === "delivery") {
        setChosenSort("delivery");
        setWorkOrderCache(
          workOrderCache.reverse(
            (a, b) => (a, b) =>
              new Date(a.deliveryDate) - new Date(b.deliveryDate)
          )
        );
      }
      if (value === "amount") {
        setChosenSort("amount");
        setWorkOrderCache(
          workOrderCache.reverse(
            (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
          )
        );
      }
    }
  };

  function TableRender(props) {
    return (
      <>
        <Table celled sortable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell
                width={1}
                onClick={() => Sorting("id")}
                style={{ background: "#555555", color: "white" }}
              >
                Id{" "}
                {chosenSort === "id" &&
                  (direction === true ? (
                    <span>&#x2191;</span>
                  ) : (
                    <span>&#x2193;</span>
                  ))}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                onClick={() => Sorting("warehouse")}
                style={{ background: "#555555", color: "white" }}
              >
                Warehouse{" "}
                {chosenSort === "warehouse" &&
                  (direction === true ? (
                    <span>&#x2191;</span>
                  ) : (
                    <span>&#x2193;</span>
                  ))}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                onClick={() => Sorting("status")}
                style={{ background: "#555555", color: "white" }}
              >
                Status{" "}
                {chosenSort === "status" &&
                  (direction === true ? (
                    <span>&#x2191;</span>
                  ) : (
                    <span>&#x2193;</span>
                  ))}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                onClick={() => Sorting("issued")}
                style={{ background: "#555555", color: "white" }}
              >
                Issued{" "}
                {chosenSort === "issued" &&
                  (direction === true ? (
                    <span>&#x2191;</span>
                  ) : (
                    <span>&#x2193;</span>
                  ))}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                onClick={() => Sorting("delivery")}
                style={{ background: "#555555", color: "white" }}
              >
                Delivery{" "}
                {chosenSort === "delivery" &&
                  (direction === true ? (
                    <span>&#x2191;</span>
                  ) : (
                    <span>&#x2193;</span>
                  ))}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                onClick={() => Sorting("amount")}
                style={{ background: "#555555", color: "white" }}
              >
                Amount{" "}
                {chosenSort === "amount" &&
                  (direction === true ? (
                    <span>&#x2191;</span>
                  ) : (
                    <span>&#x2193;</span>
                  ))}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                style={{ background: "#555555", color: "white" }}
              >
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
        <div style={{ maxHeight: "750px", overflowY: "overlay" }}>
          <Table>
            <Table.Body>
              {workOrderCache &&
                workOrderCache.map((order, index) => {
                  return (
                    <>
                      <Table.Row
                        textAlign="center"
                        className="secondTableToggle"
                      >
                        <Table.Cell width={1}>
                          <WorkOrderDetailModal order={order} />
                        </Table.Cell>
                        <Table.Cell width={1}>{order.WarehouseId}</Table.Cell>
                        <Table.Cell
                          width={1}
                          style={{ textTransform: "capitalize" }}
                        >
                          {order.status}
                        </Table.Cell>
                        <Table.Cell width={1}>
                          {moment(order.issueDate).format("MM/DD/YYYY")}
                        </Table.Cell>
                        <Table.Cell width={1}>
                          {moment(order.deliveryDate).format("MM/DD/YYYY")}
                        </Table.Cell>
                        <Table.Cell width={1}>
                          $ {parseFloat(order.amount).toFixed(2)}
                        </Table.Cell>
                        <Table.Cell width={1}>
                          {order.status !== "created" ? (
                            <>
                            {
                              order.status == 'issued' &&
                              <ReceiveOrder order={order} getLedger={props.getLedger}/>
                            }
                            <NewBillsModal
                              fromWorkOrder={true}
                              user={props.user}
                              dataWorkOrder={order._id}
                              propertyId={
                                location
                              }
                              id={
                                idSL
                              }
                            />
                            </>
                          ) : (
                            <WorkOrderModal
                              updateInvoice={props.updateInvoice}
                              edit={true}
                              data={order}
                              propertyId={location}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </>
                  );
                })}
              {workOrderCache.length === 0 && (
                <td colSpan={15} style={{ textAlign: "center" }}>
                  No Results Found
                </td>
              )}
            </Table.Body>
          </Table>
        </div>
      </>
    );
  }

  return (
    <>
      <TableRender props={workOrderCache} updateInvoice={props.updateInvoice} getLedger={props.getLedger}/>
    </>
  );
}
