import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { RentRollGQL } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING_REPORTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import { Button } from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const invoiceClient = getClient(ACCOUNTING_REPORTING);

class RentRoll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      rollCache: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Rent Roll",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  CapitalizeFirstLetter = ({ value }) => {
    return (
      <p style={{ textTransform: "capitalize", color: "black" }}>{value}</p>
    );
  };
  stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = [
      "Unit",
      "BD/BA",
      "Tenant",
      "Status",
      "Sqft",
      "Pet",
      "Utilities",
      "Parking",
      "Other",
      "Rent",
      "Total",
    ];

    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Rent Roll", 14, 15);
    console.log(this.state.rollCache);
    // Convert data to array of arrays
    const data = this.state.rollCache.map((item) => [
      item.Unit,
      item["BD/BA"],
      item.Tenant,
      item.Status,
      item.Sqft,
      item["Pet Rent"],
      item.Utilities,
      item["Parking Charges"],
      item["Other Charges"],
      item.Rent,
      item.Total,
    ]);

    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });

    // Output PDF
    doc.save("RentRoll.pdf");
  };

  getLedger = (data) => {
    try {
      this.setState({ loading: true });
      invoiceClient
        .query({
          query: RentRollGQL,
          variables: {
            locationId: this.props.selectedPrimary.node.id,
          },
        })
        .then((res) => {
          if (res.data && res.data.rentRoll) {
            const dataToPass = get(res, "data.rentRoll", []).map((k, i) => {
              const pet = `$${Number.parseFloat(k["Pet Rent"]).toLocaleString(
                "en"
              )}`;
              const utilities = `$${Number.parseFloat(
                k.Utilities
              ).toLocaleString("en")}`;
              const parking = `$${Number.parseFloat(
                k["Parking Charges"]
              ).toLocaleString("en")}`;
              const other = `$${Number.parseFloat(
                k["Other Charges"]
              ).toLocaleString("en")}`;
              const rent = `$${Number.parseFloat(k.Rent).toLocaleString("en")}`;
              const total = `$${Number.parseFloat(k.Total).toLocaleString(
                "en"
              )}`;
              return (({ Unit, "BD/BA": BdBa, Tenant, Status, Sqft }) => ({
                Unit,
                "BD/BA": BdBa,
                Tenant,
                Status,
                Sqft,
                "Pet Rent": pet,
                Utilities: utilities,
                "Parking Charges": parking,
                "Other Charges": other,
                Rent: rent,
                Total: total,
              }))(k);
            });
            this.setState({
              rollCache: dataToPass,
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      journalCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.journalCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { journalCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        journalCache: journalCache.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      journalCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ journalCache: array });
    }
  };

  render() {
    const { loading } = this.state;
    const mainHeader = [
      "Unit",
      "BD/BA",
      "Tenant",
      "Status",
      "Sqft",
      "Pet",
      "Utilities",
      "Parking",
      "Other",
      "Rent",
      "Total",
    ];

    return (
      <>
        <main className="main-content balance-sheet-layout" role="main">
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="page-header flex-row">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="page-header-title pl-0">Rent Roll</div>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <Button
                    onClick={() => this.stateDataToPDF()}
                    compact
                    className="ml-4 noPrint noPrint-button"
                    style={{
                      backgroundImage:
                        "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                    }}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <Loader text inTable />
          ) : (
            <>
              <div className="balance-sheet-table">
                <GeneralAccountingTable
                  mainHeader={mainHeader}
                  mainCellData={this.state.rollCache}
                />
              </div>
            </>
          )}
        </main>
      </>
    );
  }
}

export default withApollo(RentRoll);
