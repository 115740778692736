import React from "react";
import { Button, Modal } from "semantic-ui-react";
import MicrositeModal from "../../Modals/MicrositeModal";
import { getClient } from "../../../init-apollo-googleFn";
import { LOCATIONAPI } from "../../../utils";
import { toastSuccessMsg, toastFailMsg } from "../../../utils/common";
import { AlterLocation } from "../../../store/person/properties";

const locationClient = getClient(LOCATIONAPI);

const PropertyCardModal = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { locationTypeId, locationId, organizationId, modalTrigger, onRerender } = props;

  // sets the template id.
  const setTemplateMutation = (templateId, closeModal, msg) => {
    try {
      locationClient
        .mutate({
          mutation: AlterLocation,
          variables: {
            input: {
              id: locationId,
              templateId,
            },
          },
        })
        .then((response) => {
            msg == true &&  toastSuccessMsg("Template Successfully Selected");
            msg == true &&props.getPropertiesList()
            closeModal && handleModalClose()
            return;
        })
        .catch((error) => {
            msg == true && toastFailMsg("An error has occurred");
            console.log(error);
            return
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleModalClose = () => {
    // setTemplateMutation(null, true);
    setModalOpen(false);
  };

  return (
    <Modal
        centered
        className="semanticModal"
        size="tiny"
        onClose={() => handleModalClose()}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
        trigger={modalTrigger}
    >
      <Modal.Content>
        {/* <Modal.Content> */}
        <MicrositeModal
          locationTypeId={locationTypeId}
          locationId={locationId}
          organizationId={organizationId}
          setTemplateMutation={setTemplateMutation}
        />
      </Modal.Content>
      {/* <Modal.Actions className="microsites-modal-actions">
        <Button
          className="microsites-modal-close"
          onClick={() => handleModalClose()}
          >
          CLOSE
        </Button>
        <Button
          className="microsites-modal-close"
          onClick={() => handleModalClose()}
          >
          SET
        </Button>
        
      </Modal.Actions> */}
    </Modal>
  );
};

export default PropertyCardModal;
