import React from "react";

const NoConversation = () => {
  return (
    <div className="no-conversation">
      <div className="mw-container">
        <img src="/assets/img/no-conversation.png" alt="img" />
        <div className="title">NO CONVERSATION YET!</div>
        <div className="description">
          You haven’t messaged anyone. Please start a new conversation by
          clicking on the top <b>New Conversation</b> button.
        </div>
      </div>
    </div>
  );
};

export default NoConversation;
