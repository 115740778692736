import React, { Component } from "react";
import Slider from "react-slick";
import { Grid, Icon } from "semantic-ui-react";
import "./Explore.scss";
import { ROUTES } from "../../utils";
import PropertyTypeSelection from "../../components/Properties/PropertyListing/PropertyTypeSelection";
import mixpanel from "mixpanel-browser";
import Tour from 'reactour'
import { exploreSteps } from "../../utils/tourSteps";
import { getCookie } from "../../store/auth/authUtility";
class Explore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPropertySelection: false,
      isTourOpen: false
    };
  }

  componentDidMount() {
    mixpanel.track("Manager Page Load", {
      sub: "Explore",
    });
  }
  componentDidMount() {
    const cookie = getCookie();
    if (!/\d/.test(cookie.locations)) {
      document.getElementById('start-property').click();
    }
    else return
  }

  onSelectPropertyType = (propertyType, res) => {
    this.setState({ isPropertySelection: false });
    this.props.history.push({
      pathname: ROUTES.addProperty,
      search: `propertyType=${propertyType}&locationId=${res.id}`,
    });
  };

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const { isPropertySelection } = this.state;
    return (
      <>

        <div className="homepage">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="heading heading-md explore-first-step">Explore  <Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} />
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="property-manager-wrapper">
            <Slider {...settings} className="home-banner explore-second-step">
              <div>
                <img
                  src="assets/img/marketing/property-manager-banner.jpg"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption">
                  <h5>Enter a new era of rental management. </h5>
                  <p>
                    Your software can no longer just offer maintenance and
                    accounting. Thanks to REM, you can now handle flexible
                    leasing opportunities and engage a supportive network.
                    You’re now part of an experience.{" "}
                  </p>
                </div>
              </div>

            </Slider>
            <Grid divided="vertically half-image-section property-section explore-third-step">
              <Grid.Row columns={2}>
                <Grid.Column mobile={16} computer={8}>
                  <div className="image-container">
                    <img
                      src="assets/img/marketing/pm_image_10.jpg"
                      alt="Illustration"
                      className="renter-image"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} computer={8}>
                  <h3>Property Management Software that Wow's</h3>
                  <p className="text">
                    If you’re seeking powerful, margin boosting, service rich,
                    consumer wow’ing property management software, welcome! Fill
                    listings with a single, affordable click.
                  </p>
                  <p className="text">
                    Our sophisticated digital approach frees you to focus
                    precisely and exclusively on information that will improve
                    time, energy and financial efficiencies. We’ve worked hard
                    to cut unnecessary costs and stress for you.
                  </p>
                  <div className="mt-3 mt-md-5">
                    {/* eslint-disable */}
                    <a
                      className="homebtn"
                      id="start-property"
                      onClick={() =>
                        this.setState({ isPropertySelection: true })
                      }
                    >
                      Add your property
                    </a>
                    {/* eslint-enable */}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid divided="vertically half-image-section activity-section explore-fourth-step">
              <Grid.Row columns={2} className="flex-column-reverse flex-sm-row">
                <Grid.Column mobile={16} computer={8}>
                  <h3>Digitally manage your leasing activity and beyond</h3>
                  <p className="text">
                    You've probably noticed, property management isn't just
                    about the property or accounting anymore. Get more with
                    REM.
                  </p>
                  <p className="text">
                    We'll handle all of your administrative and reporting needs
                    from one single location, to an entire portfolio of
                    rentals..
                  </p>
                </Grid.Column>
                <Grid.Column mobile={16} computer={8}>
                  <div className="image-container">
                    <img
                      src="assets/img/marketing/pm_image_8.png"
                      alt="Illustration"
                      className="renter-image"
                    />
                    <img
                      src="assets/img/marketing/pm_image_5.png"
                      alt="Illustration"
                      className="fixed-left-image"
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid divided="vertically manage-properties-section half-image-section">
              <Grid.Row columns={2} className="flex-column-reverse flex-sm-row">
                <Grid.Column mobile={16} computer={8}>
                  <p className="badge-btn">Property Management</p>
                  <h3>
                    Powerful system to manage all properties, units &amp; renter
                    needs.
                  </h3>
                  <p>
                    Today's era of leasing activities go far beyond maintenance,
                    lead generation or accounting. You need software that goes
                    beyond as well, supporting you, your team and offering you
                    an affordable way of scaling operations.
                  </p>
                </Grid.Column>
                <Grid.Column mobile={16} computer={8}>
                  <div className="image-container">
                    <img
                      src="assets/img/marketing/pm_image_7.png"
                      alt="Illustration"
                      className="renter-image"
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
        <PropertyTypeSelection
          isOpen={isPropertySelection}
          onClose={() => this.setState({ isPropertySelection: false })}
          onSelect={this.onSelectPropertyType}
        />
        <Tour
          steps={exploreSteps}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
          className="helper"
          startAt={0}
          rounded={5}
        />
      </>
    );
  }
}

export default Explore;
