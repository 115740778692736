import React from "react";
import {
  Button,
  Header,
  Image,
  Modal,
  Table,
  Grid,
  Tab,
  Dropdown,
  Progress,
  Segment,
  TextArea,
  Input,
  Select,
} from "semantic-ui-react";
import {
  slLocationInvoicePayments,
  InvoiceInfoAccounting,
  slLocationInvoiceTransactions,
  VoidInvoiceMutation,
  slLocationBillPayments,
  slLocationTransactions,
  approveSLPaymentToBillMutation,
  deleteSLPaymentToBillMutation,
  voidSLPaymentToBillMutation,
  BillAccounting,
  voidSLBillMutation,
} from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import { INVOICE_ACCOUNTING_PAYMENT } from "../../utils/constants";
import moment from "moment";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import mixpanel from "mixpanel-browser";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
const paymentClient = getClient(INVOICE_ACCOUNTING_PAYMENT);

function BillDetailsModal(props) {
  const [open, setOpen] = React.useState(false);
  const [payments, setPayments] = React.useState([]);
  const [paymentsBackup, setPaymentsBackup] = React.useState([]);
  const [transactionsBackup, setTransactionsBackup] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);
  const [prior, setPriorCodes] = React.useState([]);
  const [secondOpen, setSecondOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [notes, setNotes] = React.useState('');
  const [paymentOption, setPaymentOption] = React.useState(false);
  const [paymentData, setPaymentData] = React.useState(false);
  const [bill, setSingleBill] = React.useState(false);
  const [direction, setDirection] = React.useState(false);
  const [chosenSort, setChosenSort] = React.useState(false);
  const [chosenSort2, setChosenSort2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [chosenTrans, setChosenTrans] = React.useState(false);
  const [chosenTSearch, setChosenTSearch] = React.useState(false);
  const [chosenPayments, setChosenPayments] = React.useState(false);
  const [chosenPSearch, setChosenPSearch] = React.useState(false);

  const setTransactionFilter = (e) => {
    setChosenTrans(e.target.textContent);
  };
  const setPaymentsFilter = (e) => {
    setChosenPayments(e.target.textContent);
  };

  const setTransactionFilterSearch = (e) => {
    setChosenTSearch(e.target.value.toString());
  };
  const setPaymentsFilterSearch = (e) => {
    setChosenPSearch(e.target.value.toString());
  };

  // Temp Transaction filter
  const startFilter = () => {
    if (chosenTrans === "Subtype") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.LedgerAccount_subtype.includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Number") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.Journal.number.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Status") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.Journal.status.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Ledger") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.Journal.sourceLedger.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Location") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.Location_id.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Account") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.LedgerAccount_number.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "TX Date") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.transactionDate.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Post Date") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.postedDate.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Description") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.description.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Debit") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.debit.toString().includes(chosenTSearch)
        )
      );
    }
    if (chosenTrans === "Credit") {
      setTransactions(
        transactionsBackup.filter((t) =>
          t.credit.toString().includes(chosenTSearch)
        )
      );
    }
  };
  // Temp Payments Filter
  const startFilterP = () => {
    if (chosenPayments === "Id") {
      setPayments(
        paymentsBackup.filter((t) => t._id.toString().includes(chosenPSearch))
      );
    }
    if (chosenPayments === "Type") {
      setPayments(
        paymentsBackup.filter((t) => t.type.toString().includes(chosenPSearch))
      );
    }
    if (chosenPayments === "Status") {
      setPayments(
        paymentsBackup.filter((t) =>
          t.status.toString().includes(chosenPSearch)
        )
      );
    }
    if (chosenPayments === "Location") {
      setPayments(
        paymentsBackup.filter((t) =>
          t.Location.name.toString().includes(chosenPSearch)
        )
      );
    }
    if (chosenPayments === "Bill") {
      setPayments(
        paymentsBackup.filter((t) =>
          t.BillId.toString().includes(chosenPSearch)
        )
      );
    }
    if (chosenPayments === "Credit") {
      setPayments(
        paymentsBackup.filter((t) =>
          t.VendorCredit.toString().includes(chosenPSearch)
        )
      );
    }
    if (chosenPayments === "Ledger") {
      setPayments(
        paymentsBackup.filter(
          (t) =>
            t.LedgerAccount.name.toString().includes(chosenPSearch) ||
            t.LedgerAccount.number.toString().includes(chosenPSearch)
        )
      );
    }
    if (chosenPayments === "Payment Date") {
      setPayments(
        paymentsBackup.filter((t) =>
          t.paymentDate.toString().includes(chosenPSearch)
        )
      );
    }
    if (chosenPayments === "Posted Date") {
      setPayments(
        paymentsBackup.filter((t) =>
          t.postedDate.toString().includes(chosenPSearch)
        )
      );
    }
    if (chosenPayments === "Memo") {
      setPayments(
        paymentsBackup.filter((t) => t.memo.toString().includes(chosenPSearch))
      );
    }
    // if (chosenPayments === "Check") {
    //   setPayments(
    //     paymentsBackup.filter((t) =>
    //       t.checkNumber.toString().includes(chosenPSearch)
    //     )
    //   );
    // }
    if (chosenPayments === "Amount") {
      setPayments(
        paymentsBackup.filter((t) =>
          t.amount.toString().includes(chosenPSearch)
        )
      );
    }
  };
  // Second modal confirmation switch
  const setSecondOpenModal = (value, data) => {
    console.log(data)
    setSecondOpen(true);
    setPaymentData(data);
    if (value === "approve") {
      setPaymentOption("approve");
    }
    if (value === "refund") {
      setPaymentOption("refund");
    }
    if (value === "delete") {
      setPaymentOption("delete");
    }
  };
  // Void bill function
  const voidBill = () => {
    setLoading(true);
    try {
      if (props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Void Bill",
        });
      }
      if (props.invoice) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Void Invoice",
        });
      }
      paymentClient
        .mutate({
          mutation: props.bills ? voidSLBillMutation : VoidInvoiceMutation,
          variables: props.bills
            ? {
              input: {
                BillId: props.bill._id,
                location: props.location.selectedPrimary.node.id,
              },
            }
            : {
              input: {
                id: props.bill._id,
                location: props.location.selectedPrimary.node.id,
              },
            },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.voidSlBill) {
            if (
              response.data.voidSlBill.response ===
              'b\'{"error":"Bill not found"}\''
            ) {
              toastFailMsg(
                "Bill not found, please check that this is a functional bill."
              );
            } else if (
              response.data.voidSlBill.response ===
              'b\'{"error":"Cannot void, linked payments"}\''
            ) {
              toastFailMsg(
                "In order to void bill please void all linked payments within the bill first."
              );
            } else if (
              response.data.voidSlBill.response !==
              'b\'{"error":"Cannot void, linked payments"}\'' ||
              response.data.voidSlBill.response !==
              'b\'{"error":"Bill not found"}\''
            ) {
              toastSuccessMsg("Bill Voided");
              props.getLedger("skip");
              setOpen(false);
            } else {
              return;
            }
          } else {
            toastSuccessMsg("Bill Voided");
            props.getLedger("skip");
            setOpen(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toastFailMsg("This bill is unable to be voided:" + error);
        });
    } catch (e) {
      setLoading(false);
      alert(e);
    }
  };
  // Temporary sorting Mechanism
  const Sorting = (value) => {
    if (!direction) {
      setDirection(true);
      if (value === "subtype") {
        setChosenSort("subtype");
        setTransactions(
          transactions.sort((a, b) =>
            a.ICAccount_subtype.localeCompare(b.ICAccount_subtype)
          )
        );
      }
      if (value === "status") {
        setChosenSort("status");
        setTransactions(
          transactions.sort((a, b) =>
            a.ICAccount_subtype.localeCompare(b.ICAccount_subtype)
          )
        );
      }
      if (value === "number") {
        setChosenSort("number");
        setTransactions(
          transactions.sort(
            (a, b) =>
              parseFloat(a.Journal.number) - parseFloat(b.Journal.number)
          )
        );
      }
      if (value === "ledger") {
        setChosenSort("ledger");
        setTransactions(
          transactions.sort((a, b) =>
            a.ICAccount_subtype.localeCompare(b.ICAccount_subtype)
          )
        );
      }
      if (value === "location") {
        setChosenSort("location");
        setTransactions(
          transactions.sort(
            (a, b) => parseFloat(a.Location_id) - parseFloat(b.Location_id)
          )
        );
      }
      if (value === "account") {
        setChosenSort("account");
        setTransactions(
          transactions.sort(
            (a, b) =>
              parseFloat(a.LedgerAccount_number) -
              parseFloat(b.LedgerAccount_number)
          )
        );
      }
      if (value === "tx") {
        setChosenSort("tx");
        setTransactions(
          transactions.sort(
            (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate)
          )
        );
      }
      if (value === "post") {
        setChosenSort("post");
        setTransactions(
          transactions.sort(
            (a, b) => new Date(a.postedDate) - new Date(b.postedDate)
          )
        );
      }
      if (value === "description") {
        setChosenSort("description");
        transactions.forEach(function (x) {
          if (x.description === null) {
            x.description = "";
            return x;
          }
        });
        setTransactions(
          transactions.sort(
            (a, b) =>
              a.description !== null &&
              a.description.localeCompare(b.description)
          )
        );
      }
      if (value === "debit") {
        setChosenSort("debit");
        setTransactions(
          transactions.sort((a, b) => parseFloat(a.debit) - parseFloat(b.debit))
        );
      }
      if (value === "credit") {
        setChosenSort("credit");
        setTransactions(
          transactions.sort(
            (a, b) => parseFloat(a.credit) - parseFloat(b.credit)
          )
        );
      } else return;
    }
    if (direction) {
      setDirection(false);
      if (value === "subtype") {
        setChosenSort("subtype");
        setTransactions(
          transactions.reverse((a, b) =>
            a.ICAccount_subtype.localeCompare(b.ICAccount_subtype)
          )
        );
      }
      if (value === "status") {
        setChosenSort("status");
        setTransactions(
          transactions.reverse((a, b) =>
            a.ICAccount_subtype.localeCompare(b.ICAccount_subtype)
          )
        );
      }
      if (value === "number") {
        setChosenSort("number");
        setTransactions(
          transactions.reverse(
            (a, b) =>
              parseFloat(a.Journal.number) - parseFloat(b.Journal.number)
          )
        );
      }
      if (value === "ledger") {
        setChosenSort("ledger");
        setTransactions(
          transactions.reverse((a, b) =>
            a.ICAccount_subtype.localeCompare(b.ICAccount_subtype)
          )
        );
      }
      if (value === "location") {
        setChosenSort("location");
        setTransactions(
          transactions.reverse(
            (a, b) => parseFloat(a.Location_id) - parseFloat(b.Location_id)
          )
        );
      }
      if (value === "account") {
        setChosenSort("account");
        setTransactions(
          transactions.reverse(
            (a, b) =>
              parseFloat(a.LedgerAccount_number) -
              parseFloat(b.LedgerAccount_number)
          )
        );
      }
      if (value === "tx") {
        setChosenSort("tx");
        setTransactions(
          transactions.reverse(
            (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate)
          )
        );
      }
      if (value === "post") {
        setChosenSort("post");
        setTransactions(
          transactions.reverse(
            (a, b) => new Date(a.postedDate) - new Date(b.postedDate)
          )
        );
      }
      if (value === "description") {
        setChosenSort("description");
        transactions.forEach(function (x) {
          if (x.description === null) {
            x.description = "";
            return x;
          }
        });
        setTransactions(
          transactions.reverse((a, b) =>
            a.description.localeCompare(b.description)
          )
        );
      }
      if (value === "debit") {
        setChosenSort("debit");
        setTransactions(
          transactions.reverse(
            (a, b) => parseFloat(a.debit) - parseFloat(b.debit)
          )
        );
      }
      if (value === "credit") {
        setChosenSort("credit");
        setTransactions(
          transactions.reverse(
            (a, b) => parseFloat(a.credit) - parseFloat(b.credit)
          )
        );
      } else return;
    }
  };

  const Sorting2 = (value) => {
    if (!direction) {
      setDirection(true);
      if (value === "id") {
        setChosenSort2("id");
        setPayments(
          payments.sort((a, b) => parseFloat(a._id) - parseFloat(b._id))
        );
      }
      if (value === "type") {
        setChosenSort2("type");
        setPayments(payments.sort((a, b) => a.type.localeCompare(b.type)));
      }
      if (value === "status") {
        setChosenSort2("status");
        setPayments(payments.sort((a, b) => a.status.localeCompare(b.status)));
      }
      if (value === "location") {
        setChosenSort2("location");
        setPayments(
          payments.sort((a, b) =>
            a.Location.name.localeCompare(b.Location.name)
          )
        );
      }
      if (value === "bill") {
        setChosenSort2("bill");
        setPayments(
          payments.sort((a, b) => parseFloat(a.BillId) - parseFloat(b.BillId))
        );
      }
      if (value === "credit") {
        setChosenSort2("credit");
        payments.forEach(function (x) {
          if (x.VendorCredit === null) {
            x.VendorCredit = 0;
            return x;
          }
        });
        setPayments(
          payments.sort(
            (a, b) => parseFloat(a.VendorCredit) - parseFloat(b.VendorCredit)
          )
        );
      }
      if (value === "ledger") {
        setChosenSort2("ledger");
        setPayments(
          payments.sort(
            (a, b) =>
              parseFloat(a.LedgerAccount.number) -
              parseFloat(b.LedgerAccount.number)
          )
        );
      }
      if (value === "payment") {
        setChosenSort2("payment");
        setPayments(
          payments.sort(
            (a, b) => new Date(a.paymentDate) - new Date(b.paymentDate)
          )
        );
      }
      if (value === "posted") {
        setChosenSort2("posted");
        setPayments(
          payments.sort(
            (a, b) => new Date(a.postedDate) - new Date(b.postedDate)
          )
        );
      }
      if (value === "memo") {
        setChosenSort2("memo");
        payments.forEach(function (x) {
          if (x.memo === null) {
            x.memo = "";
            return x;
          }
        });
        setPayments(payments.sort((a, b) => a.memo.localeCompare(b.memo)));
      }
      if (value === "check") {
        setChosenSort2("check");
        payments.forEach(function (x) {
          if (x.checkNumber === null) {
            x.checkNumber = 0;
            return x;
          }
        });
        setPayments(
          payments.sort(
            (a, b) => parseFloat(a.checkNumber) - parseFloat(b.checkNumber)
          )
        );
      }
      if (value === "amount") {
        setChosenSort2("amount");
        setPayments(
          payments.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount))
        );
      } else return;
    }
    if (direction) {
      setDirection(false);
      if (value === "id") {
        setChosenSort2("id");
        setPayments(
          payments.reverse((a, b) => parseFloat(a._id) - parseFloat(b._id))
        );
      }
      if (value === "type") {
        setChosenSort2("type");
        setPayments(payments.reverse((a, b) => a.type.localeCompare(b.type)));
      }
      if (value === "status") {
        setChosenSort2("status");
        setPayments(
          payments.reverse((a, b) => a.status.localeCompare(b.status))
        );
      }
      if (value === "location") {
        setChosenSort2("location");
        setPayments(
          payments.reverse((a, b) =>
            a.Location.name.localeCompare(b.Location.name)
          )
        );
      }
      if (value === "bill") {
        setChosenSort2("bill");
        setPayments(
          payments.reverse(
            (a, b) => parseFloat(a.BillId) - parseFloat(b.BillId)
          )
        );
      }
      if (value === "credit") {
        setChosenSort2("credit");
        payments.forEach(function (x) {
          if (x.VendorCredit === null) {
            x.VendorCredit = 0;
            return x;
          }
        });
        setPayments(
          payments.reverse(
            (a, b) => parseFloat(a.VendorCredit) - parseFloat(b.VendorCredit)
          )
        );
      }
      if (value === "ledger") {
        setChosenSort2("ledger");
        setPayments(
          payments.reverse(
            (a, b) =>
              parseFloat(a.LedgerAccount.number) -
              parseFloat(b.LedgerAccount.number)
          )
        );
      }
      if (value === "payment") {
        setChosenSort2("payment");
        setPayments(
          payments.reverse(
            (a, b) => new Date(a.paymentDate) - new Date(b.paymentDate)
          )
        );
      }
      if (value === "posted") {
        setChosenSort2("posted");
        setPayments(
          payments.reverse(
            (a, b) => new Date(a.postedDate) - new Date(b.postedDate)
          )
        );
      }
      if (value === "memo") {
        setChosenSort2("memo");
        payments.forEach(function (x) {
          if (x.memo === null) {
            x.memo = "";
            return x;
          }
        });
        setPayments(payments.reverse((a, b) => a.memo.localeCompare(b.memo)));
      }
      if (value === "check") {
        setChosenSort2("check");
        payments.forEach(function (x) {
          if (x.checkNumber === null) {
            x.checkNumber = 0;
            return x;
          }
        });
        setPayments(
          payments.reverse(
            (a, b) => parseFloat(a.checkNumber) - parseFloat(b.checkNumber)
          )
        );
      }
      if (value === "amount") {
        setChosenSort2("amount");
        setPayments(
          payments.reverse(
            (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
          )
        );
      } else return;
    }
  };
  // Sets payment status based on option selected. Runs through 3 choices.
  const sendPaymentChange = () => {
    if (paymentOption === "approve") {
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Approve Payment to Bill",
        });
        paymentClient
          .mutate({
            mutation: approveSLPaymentToBillMutation,
            variables: {
              input: {
                BillId: paymentData._id,
                location: props.location.selectedPrimary.node.id,
              },
            },
          })
          .then((response) => {
            toastSuccessMsg("Payment Approved");
            gatherPayments();
            props.getLedger("skip");
            setPaymentOption("");
            setPaymentData("");
            setSecondOpen(false);
          })
          .catch((error) => {
            toastFailMsg("Unable to approve payment:" + error);
          });
      } catch (e) {
        alert(e);
      }
    }
    if (paymentOption === "delete") {
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Delete Payment to Bill",
        });
        paymentClient
          .mutate({
            mutation: deleteSLPaymentToBillMutation,
            variables: {
              input: {
                BillId: paymentData._id,
                location: props.location.selectedPrimary.node.id,
              },
            },
          })
          .then((response) => {
            toastSuccessMsg("Payment Deleted");
            gatherPayments();
            props.getLedger("skip");
            setPaymentOption("");
            setPaymentData("");
            setSecondOpen(false);
          })
          .catch((error) => {
            toastFailMsg("Unable to approve payment:" + error);
          });
      } catch (e) {
        alert(e);
      }
    }
    if (paymentOption === "refund") {
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Void Payment to Bill",
        });
        paymentClient
          .mutate({
            mutation: voidSLPaymentToBillMutation,
            variables: {
              accounting: {
                BillId: paymentData._id,
                location: props.location.selectedPrimary.node.id,
                reasoning:notes,
                type:paymentOption,
                amount:paymentData.amount * 100,
                paymentId:paymentData.paymentId
              },
            },
          })
          .then((response) => {
            toastSuccessMsg("Payment Voided");
            gatherPayments();
            props.getLedger("skip");
            setPaymentOption("");
            setPaymentData("");
            setSecondOpen(false);
          })
          .catch((error) => {
            toastFailMsg("Unable to approve payment:" + error);
          });
      } catch (e) {
        alert(e);
      }
    }
  };
  // Gets the payments on the bill
  const getPayments = () => {
    if (prior.includes(props.bill._id)) {
      setChosenTrans(false);
      setChosenTSearch(false);
      setChosenPayments(false);
      setChosenPSearch(false);
      return;
    } else {
      gatherPayments();
    }
  };
  // Query Payments on bill
  const gatherPayments = () => {
    setProgress(25);
    try {
      paymentClient
        .query({
          query: props.bills
            ? slLocationBillPayments
            : slLocationInvoicePayments,
          variables: props.bills
            ? {
              location: props.location.selectedPrimary.node.id,
              BillId: props.bill._id,
            }
            : {
              location: props.location.selectedPrimary.node.id,
              InvoiceId: props.bill._id,
            },
        })
        .then((res) => {
          const thePayments = props.bills
            ? res.data.slLocationBillPayments.data
            : res.data.slLocationInvoicePayments.data;
          setPayments(thePayments);
          setPaymentsBackup(thePayments);
          setChosenTrans(false);
          setChosenTSearch(false);
          setChosenPayments(false);
          setChosenPSearch(false);
          getTransactions();
        })
        .catch((error) => {
          getTransactions();
        });
    } catch (e) {
      alert(e);
    }
  };
  // Transaction Options
  const transactionOptions = [
    { key: "tnumber", text: "Number", value: "tnumber" },
    { key: "tstatus", text: "Status", value: "tstatus" },
    { key: "tledger", text: "Ledger", value: "tledger" },
    { key: "tlocation", text: "Location", value: "tlocation" },
    { key: "taccount", text: "Account", value: "taccount" },
    { key: "tsubtype", text: "Subtype", value: "tsubtype" },
    { key: "ttx", text: "TX Date", value: "ttx" },
    { key: "tpost", text: "Post Date", value: "tpost" },
    { key: "tdescription", text: "Description", value: "tdescription" },
    { key: "tdebit", text: "Debit", value: "tdebit" },
    { key: "tcredit", text: "Credit", value: "tcredit" },
  ];
  // Payment options
  const paymentOptions = [
    { key: "Id", text: "Id", value: "Id" },
    { key: "Type", text: "Type", value: "Type" },
    { key: "Status", text: "Status", value: "Status" },
    { key: "Location", text: "Location", value: "Location" },
    { key: "Bill", text: "Bill", value: "Bill" },
    { key: "Credit", text: "Credit", value: "Credit" },
    { key: "Ledger", text: "Ledger", value: "Ledger" },
    { key: "Payment Date", text: "Payment Date", value: "Payment Date" },
    { key: "Posted Date", text: "Posted Date", value: "Posted Date" },
    { key: "Memo", text: "Memo", value: "memo" },
    // { key: "Check", text: "Check", value: "Check" },
    { key: "Amount", text: "Amount", value: "Amount" },
  ];
  // Query to get individual bill information which contains more than group query
  const getindividualBill = () => {
    setProgress(75);
    try {
      paymentClient
        .query({
          query: props.bills ? BillAccounting : InvoiceInfoAccounting,
          variables: props.bills
            ? {
              location: props.location.selectedPrimary.node.id,
              BillId: props.bill._id,
            }
            : {
              location: props.location.selectedPrimary.node.id,
              id: props.bill._id,
            },
        })
        .then((res) => {
          if (props.bills) {
            const theBill = res.data.slLocationBills;
            setPriorCodes([...prior, theBill._id]);
            setProgress(90);
            setSingleBill(theBill);
          }
          if (props.invoice) {
            if (res.data) {
              const data = res.data.slLocationInvoice;
              /* eslint-disable */
              const Check = data.payments.forEach((payment) => {
                const check = data.payments.filter(
                  (pay) => pay.externalCheckBankId === payment._id
                );
                if (check.length > 0) {
                  return (payment.status = "refund");
                }
              });
              /* eslint-enable */
              setSingleBill(data);
            }
          }
        })
        .catch((error) => {
          setProgress(90);
          setSingleBill({});
        });
    } catch (e) {
      alert(e);
    }
  };
  // Gets the transactions occurring on bill
  const getTransactions = () => {
    setProgress(50);
    try {
      paymentClient
        .query({
          query: props.bills
            ? slLocationTransactions
            : slLocationInvoiceTransactions,
          variables: props.bills
            ? {
              location: props.location.selectedPrimary.node.id,
              BillId: props.bill._id,
            }
            : {
              location: props.location.selectedPrimary.node.id,
              InvoiceId: props.bill._id,
            },
        })
        .then((res) => {
          const theTransactions = res.data.slLocationTransactions.data;
          setTransactions(theTransactions);
          setTransactionsBackup(theTransactions);
          getindividualBill();
        })
        .catch((error) => {
          getindividualBill();
        });
    } catch (e) {
      alert(e);
    }
  };

  const panes = [
    {
      menuItem: "Details",
      render: () =>
        props.bills ? (
          <>
            <Grid columns={2}>
              <Grid.Column>
                <Table compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Vendor</Table.Cell>
                      <Table.Cell>
                        #{bill.Vendor && bill.Vendor.id} -{" "}
                        {bill.Vendor && bill.Vendor.name}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Vendor Email</Table.Cell>
                      <Table.Cell>
                        {bill.Vendor && bill.Vendor.email}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Status</Table.Cell>
                      <Table.Cell style={{ textTransform: "Capitalize" }}>
                        {bill.status}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Approved On</Table.Cell>
                      <Table.Cell>{bill.approved_on}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Approved By</Table.Cell>
                      <Table.Cell>{bill.approved_name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Approved Email</Table.Cell>
                      <Table.Cell>{bill.approved_email}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Amount</Table.Cell>
                      <Table.Cell>{bill.amount}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Amount Due</Table.Cell>
                      <Table.Cell>${bill.dueAmount}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Invoice Date</Table.Cell>
                      <Table.Cell>{bill.invoiceDate}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Posting Date</Table.Cell>
                      <Table.Cell>{bill.postingDate}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Due Date</Table.Cell>
                      <Table.Cell>{bill.dueDate}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Location</Table.Cell>
                      <Table.Cell>
                        {bill.Location && bill.Location.name}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>IC Location</Table.Cell>
                      <Table.Cell>{bill.ICLocation}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>AP Account</Table.Cell>
                      <Table.Cell>
                        {bill?.APAccount && bill?.APAccount?.number} -{" "}
                        {bill?.APAccount && bill?.APAccount?.name}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Purchase Order</Table.Cell>
                      <Table.Cell>
                        # {bill?.PurchaseOrder?.number}
                        <br />
                        ID: {bill?.PurchaseOrder?._id}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>External Id</Table.Cell>
                      <Table.Cell>{bill?.externalId}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>

            <Table padded="very" compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="2">Line #</Table.HeaderCell>
                  <Table.HeaderCell width="5">Description</Table.HeaderCell>
                  <Table.HeaderCell width="5">Ledger Account</Table.HeaderCell>
                  <Table.HeaderCell width="1">Unit Amount</Table.HeaderCell>
                  <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                  <Table.HeaderCell width="1">Tax</Table.HeaderCell>
                  <Table.HeaderCell width="1">Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
            <div style={{ maxHeight: "300px", overflowY: "overlay" }}>
              <Table padded="very" compact>
                <Table.Body>
                  {bill.billLineItems &&
                    bill.billLineItems.map((item) => {
                      return (
                        <Table.Row>
                          <Table.Cell width="2">{item._id}</Table.Cell>
                          <Table.Cell width="5">{item.description}</Table.Cell>
                          <Table.Cell width="5">
                            {item?.LedgerAccount?.number} -{" "}
                            {item?.LedgerAccount?.name}
                          </Table.Cell>
                          <Table.Cell width="1">{item.subtotal}</Table.Cell>
                          <Table.Cell width="1">{item.quantity}</Table.Cell>
                          <Table.Cell width="1">{item.taxAmount}</Table.Cell>
                          <Table.Cell width="1">{item.total}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </div>

            <Grid columns={2}>
              <Grid.Column></Grid.Column>
              <Grid.Column>
                <Table padded="very" compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{"Subtotal"}</Table.Cell>
                      <Table.Cell textAlign="right">
                        ${props.bill.dueAmount}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{"Sales Tax"}</Table.Cell>
                      <Table.Cell textAlign="right">
                        ${props.bill.tax ? props.bill.tax : 0}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Total</Table.Cell>
                      <Table.Cell textAlign="right">
                        ${props.bill.dueAmount}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>
            <Segment.Group>
              <Segment>Notes</Segment>
              <Segment.Group>
                <Segment>{props.bill.notes}</Segment>
              </Segment.Group>
            </Segment.Group>
            {/*   */}
          </>
        ) : (
          <ViewInvoiceDetails invoice={bill} />
        ),
    },
    {
      menuItem: "Payments",
      render: () => (
        <div>
          Filter in column{" "}
          <Select
            placeholder="Select your column"
            options={paymentOptions}
            onChange={setPaymentsFilter}
          />{" "}
          for{" "}
          <Input
            className="filterInputBill"
            style={{ height: "14px" }}
            placeholder="Search..."
            onChange={setPaymentsFilterSearch}
          />
          <Button
            style={{ marginLeft: "10px" }}
            compact
            disabled={!chosenPayments || !chosenPSearch}
            onClick={startFilterP}
          >
            Filter
          </Button>
          <Button onClick={() => setPayments(paymentsBackup)} compact>
            Reset
          </Button>
          <Table padded="very" compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className="hoverableHeader"
                  style={{
                    background: `${chosenSort2 === "id" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("id")}
                  width="1"
                >
                  ID
                  {chosenSort2 === "id" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  style={{
                    background: `${chosenSort2 === "type" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("type")}
                  width="1"
                >
                  Type
                  {chosenSort2 === "type" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  style={{
                    background: `${chosenSort2 === "status" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("status")}
                  width="1"
                >
                  Status
                  {chosenSort2 === "status" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  style={{
                    background: `${chosenSort2 === "location" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("location")}
                  width="2"
                >
                  Location
                  {chosenSort2 === "location" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort2 === "bill" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("bill")}
                >
                  Bill
                  {chosenSort2 === "bill" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort2 === "credit" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("credit")}
                >
                  Vendor Credit
                  {chosenSort2 === "credit" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>

                <Table.HeaderCell
                  className="hoverableHeader"
                  style={{
                    background: `${chosenSort2 === "ledger" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("ledger")}
                  width="2"
                >
                  Ledger Account
                  {chosenSort2 === "ledger" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort2 === "payment" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("payment")}
                >
                  Payment Date
                  {chosenSort2 === "payment" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort2 === "posted" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("posted")}
                >
                  Posted Date
                  {chosenSort2 === "posted" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="2"
                  style={{
                    background: `${chosenSort2 === "memo" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("memo")}
                >
                  Memo
                  {chosenSort2 === "memo" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                {/* <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort2 === "check" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("check")}
                >
                  Check Number
                  {chosenSort2 === "check" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell> */}
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort2 === "amount" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting2("amount")}
                >
                  Amount
                  {chosenSort2 === "amount" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell width="1">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          <div style={{ maxHeight: "300px", overflowY: "overlay" }}>
            <Table padded="very" compact className="paymentsPane">
              <Table.Body>
                {payments.map((payment) => {
                  return (
                    <Table.Row>
                      <Table.Cell width="1">{payment._id}</Table.Cell>
                      <Table.Cell
                        width="1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {payment.type}
                      </Table.Cell>
                      <Table.Cell
                        width="1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {payment.status}
                      </Table.Cell>
                      <Table.Cell width="2">{payment.Location.name}</Table.Cell>
                      <Table.Cell width="1">{payment.BillId}</Table.Cell>
                      <Table.Cell width="1">{payment.VendorCredit}</Table.Cell>
                      <Table.Cell width="2">
                        {payment.LedgerAccount && payment.LedgerAccount.number} -{" "}
                        {payment.LedgerAccount && payment.LedgerAccount.name}
                      </Table.Cell>
                      <Table.Cell width="1">{payment.paymentDate}</Table.Cell>
                      <Table.Cell width="1">{payment.postedDate}</Table.Cell>
                      <Table.Cell width="2">{payment.memo}</Table.Cell>
                      {/* <Table.Cell width="1">{payment.checkNumber}</Table.Cell> */}
                      <Table.Cell width="1">{payment.amount}</Table.Cell>
                      <Table.Cell width="1">
                        <Dropdown>
                          <Dropdown.Menu>
                            {payment.status !== "approved" &&
                              payment.status !== "voided" && (
                                <Dropdown.Item
                                  onClick={() =>
                                    setSecondOpenModal("approve", payment)
                                  }
                                  text="Approve"
                                />
                              )}
                            {payment.status !== "created" &&
                              payment.status !== "voided" && (
                                <Dropdown.Item
                                  onClick={() =>
                                    setSecondOpenModal("refund", payment)
                                  }
                                  text="Refund"
                                />
                              )}
                            {payment.status !== "approved" && (
                              <Dropdown.Item
                                onClick={() =>
                                  setSecondOpenModal("delete", payment)
                                }
                                text="Delete"
                              />
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </div>
      ),
    },
    {
      menuItem: "Transactions",
      render: () => (
        <>
          Filter in column{" "}
          <Select
            placeholder="Select your column"
            options={transactionOptions}
            onChange={setTransactionFilter}
          />{" "}
          for{" "}
          <Input
            className="filterInputBill"
            style={{ height: "14px" }}
            placeholder="Search..."
            onChange={setTransactionFilterSearch}
          />
          <Button
            style={{ marginLeft: "10px" }}
            compact
            disabled={!chosenTrans || !chosenTSearch}
            onClick={startFilter}
          >
            Filter
          </Button>
          <Button onClick={() => setTransactions(transactionsBackup)} compact>
            Reset
          </Button>
          <Table padded="very" compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "number" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("number")}
                >
                  Number
                  {chosenSort === "number" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "status" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("status")}
                >
                  Status
                  {chosenSort === "status" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "ledger" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("ledger")}
                >
                  Ledger
                  {chosenSort === "ledger" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "location" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("location")}
                >
                  Location
                  {chosenSort === "location" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "account" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("account")}
                >
                  Account
                  {chosenSort === "account" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="3"
                  style={{
                    background: `${chosenSort === "subtype" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("subtype")}
                >
                  Subtype
                  {chosenSort === "subtype" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "tx" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("tx")}
                >
                  TX Date
                  {chosenSort === "tx" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "post" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("post")}
                >
                  Post Date
                  {chosenSort === "post" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="4"
                  style={{
                    background: `${chosenSort === "description" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("description")}
                >
                  Description
                  {chosenSort === "description" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "debit" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("debit")}
                >
                  Debit
                  {chosenSort === "debit" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="hoverableHeader"
                  width="1"
                  style={{
                    background: `${chosenSort === "credit" ? "#c4bbce" : "#f9fafc"
                      }`,
                  }}
                  onClick={() => Sorting("credit")}
                >
                  Credit
                  {chosenSort === "credit" &&
                    (direction === true ? (
                      <span>&#x2191;</span>
                    ) : (
                      <span>&#x2193;</span>
                    ))}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          <div style={{ maxHeight: "300px", overflowY: "overlay" }}>
            <Table padded="very" compact>
              <Table.Body>
                {transactions.map((transaction) => {
                  return (
                    <Table.Row>
                      <Table.Cell width="1">
                        {transaction.Journal.number}
                      </Table.Cell>
                      <Table.Cell
                        width="1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {transaction.Journal.status}
                      </Table.Cell>
                      <Table.Cell width="1">
                        {transaction.Journal.sourceLedger}
                      </Table.Cell>
                      <Table.Cell width="1">
                        {transaction.Location_id}
                      </Table.Cell>
                      <Table.Cell width="1">
                        {transaction.LedgerAccount_number}
                      </Table.Cell>
                      <Table.Cell width="3">
                        {transaction.LedgerAccount_subtype}
                      </Table.Cell>
                      <Table.Cell width="1">
                        {moment(transaction.transactionDate).format(
                          "MM/DD/YYYY"
                        )}
                      </Table.Cell>
                      <Table.Cell width="1">
                        {moment(transaction.transactionDate).format(
                          "MM/DD/YYYY"
                        )}
                      </Table.Cell>
                      <Table.Cell width="4">
                        {transaction.description}
                      </Table.Cell>
                      <Table.Cell width="1">${transaction.debit}</Table.Cell>
                      <Table.Cell width="1">${transaction.credit}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </>
      ),
    },
  ];

  /* eslint-disable */
  return (
    <Modal
      className="semanticModal paybillsmodaloverview overflow-y-visible"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <a onClick={() => getPayments()} className="paybillsmodal">
          {props.bills ? props.bill._id : props.bill.number}
        </a>
      }
    >
      <Modal.Header>
        {props.bills ? "Bill" : "Invoice"} #
        {props.bills ? props.bill.APAccountId : props.bill.number}{" "}
      </Modal.Header>
      <Modal.Content className="billscontent">
        {bill ? (
          <Tab panes={panes} />
        ) : (
          <Segment textAlign="center" placeholder className="segmentLoading">
            <Image centered src="/assets/img/logo-color-2.svg" size="medium" />
            <Progress
              Progress
              value={String(progress)}
              total="100"
              indicating
            ></Progress>
            <Header>
              {progress === 5 && "Gathering Fields"}
              {progress === 25 && `Getting ${props.bills ? "Bill" : "Invoice"} Payments`}
              {progress === 50 && "Retrieving Transactions"}
              {progress === 75 && `Fetching Full ${props.bills ? "Bill" : "Invoice"} Details`}
              {progress === 90 && "Rendering"}
            </Header>
          </Segment>
        )}
      </Modal.Content>
      <Modal.Actions>
        {props.bills ? (
          <Button
            floated="left"
            disabled={
              !bill ||
              bill.status !== "approved" ||
              payments.filter((item) => item.status !== "voided" || loading)
                .length !== 0
            }
            onClick={() => voidBill()}
          >
            {loading ? "Processing..." : "Void"}
          </Button>
        ) : (
          <Button
            floated="left"
            disabled={!bill || bill?.invoice?.status !== "issued" || loading}
            onClick={() => voidBill()}
          >
            {loading ? "Processing..." : "Void"}
          </Button>
        )}
        <Button color="black" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
      <Modal
        className="semanticModal  overflow-y-visible"
        onClose={() => setSecondOpen(false)}
        open={secondOpen}
        size="small"
      >
        {paymentOption === "approve" && (
          <Modal.Header>
            <span style={{ color: "green" }}>Approve</span>
          </Modal.Header>
        )}
        {paymentOption === "refund" && (
          <Modal.Header>
            <span style={{ color: "red" }}>Refund</span>
          </Modal.Header>
        )}
        {paymentOption === "delete" && (
          <Modal.Header>
            <span style={{ color: "red" }}>Delete</span>
          </Modal.Header>
        )}
        <Modal.Content>
          {paymentOption === "approve" && (
            <p style={{ color: "black" }}>
              Please confirm that you wish to approve this payment for bill.
            </p>
          )}
          {paymentOption === "refund" && (
            <p style={{ color: "black" }}>
              Please confirm that you wish to refund this payment for bill.
            </p>
          )}
          {paymentOption === "delete" && (
            <p style={{ color: "black" }}>
              Please confirm that you wish to delete this payment for bill.
            </p>
          )}
          <Table padded="very" compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Location</Table.HeaderCell>
                <Table.HeaderCell>Bill</Table.HeaderCell>
                <Table.HeaderCell>Ledger</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {paymentData && (
                <Table.Row>
                  <Table.Cell>{paymentData._id}</Table.Cell>
                  <Table.Cell>
                    {paymentData.Location && paymentData.Location.name}
                  </Table.Cell>
                  <Table.Cell>{paymentData.BillId}</Table.Cell>
                  <Table.Cell>
                    {paymentData.LedgerAccount&&paymentData.LedgerAccount.number} -{" "}
                    {paymentData.LedgerAccount&&paymentData.LedgerAccount.name}
                  </Table.Cell>
                  <Table.Cell>{paymentData.amount}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <TextArea placeholder='Tell us more' onChange={(e) => setNotes(e.target.value)} />
        </Modal.Content>
        
        <Modal.Actions>
          <Button content="Submit" onClick={() => sendPaymentChange()} />
        </Modal.Actions>
      </Modal>
    </Modal>
  );
}

export default BillDetailsModal;
