import React from "react";
import { Header, Modal, Button } from "semantic-ui-react";
import { get, startCase } from "lodash";
import { UNIT_AVAILABLITY_STATUS, ROUTES } from "../../../utils";
import { Link } from "react-router-dom";
import { pluralize } from "../../../utils/common";

const UnitInfoModal = ({ isOpen, onClose, unit, getAmenities, currentLocationId }) => {
  const unitStatus = React.useMemo(() => {
    let text = startCase(unit.node.status).replace(" ", "");
    if (unit.node.status === "off_the_market") text = "OffTheMarket";
    if (unit.node.status === "Signed") text = "Created";
    return UNIT_AVAILABLITY_STATUS.find((u) => u.value === text) || {};
  }, [unit.node.status]);

  return (
    <Modal
      className="semanticModal custom-modal amenitie-modal"
      onClose={onClose}
      closeIcon
      open={isOpen}
      size="mini"
    >
      <Modal.Header>
        <Header>Detail
          <Link
            to={{
              pathname: ROUTES.editPropertyDetails + `?locationId=${currentLocationId}`,
            }}
          >
            <Button
              compact
              style={{
                paddingTop: "1px",
                paddingBottom: "1px",
                float: 'right',
                left: "15.75px",
                color: "white",
                backgroundImage:
                  "linear-gradient(to left, #7127c4, #4c158a)",
              }}
            >
              Edit
            </Button>
          </Link>
        </Header>
      </Modal.Header>
      <Modal.Content>
        {unit.node.unitImg && (
          <div className="img-box">
            <img src={unit.node.unitImg} alt="renter-bg" />
          </div>
        )}
        <div className="aminites-header-block">
          <div>
            <p className="unit-name">{`Unit ${unit.node.number}`}</p>
            <div className="small-text">
              <span>{`${Number(unit.node.bedrooms)} ${pluralize(
                unit.node.bedrooms,
                "Bed"
              )}`}</span>{" "}
              <div className="dot" />
              <span>{`${Number(unit.node.bathrooms)} ${pluralize(
                unit.node.bathrooms,
                "Bath"
              )}`}</span>{" "}
              <div className="dot" />{" "}
              <span>{`${Number(unit.node.sqft)} sq. ft.`}</span>
            </div>
          </div>
          <p className="bold-text">{`$${unit.node.price.toLocaleString()} /month`}</p>
        </div>
        <h5>Amenities</h5>
        <p className="ameniti-text">{getAmenities(unit.node.amenities)}</p>
        <div className="floor-item-row">
          <div className="row-item">
            <div className="head">Status</div>
            <div className="text">{unitStatus.text || "NA"}</div>
          </div>
          <div className="row-item">
            <div className="head">Floor Number</div>
            <div className="text">{unit.node.level}</div>
          </div>
          <div className="row-item">
            <div className="head">Floor Plan</div>
            <div className="text">{get(unit, "node.unitType.type", "NA")}</div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default UnitInfoModal;
