import gql from "graphql-tag";
import { propertyPlace, propertyLocation } from "../utils/propertiesKeys";

export const myPropertiesGql = gql`
query Properties($filter: Filters!){
  properties(filter: $filter) {
    edges {
      node {
          ${propertyLocation}
          ${propertyPlace}
          dateListed
          ndbId
        }
      }
    }
  }
`;

export const legalGql = gql`
query LegalEntity($locationId: String!){
  legalEntity(locationId: $locationId) 
  }
`;

export const CreateLegal = gql`
mutation createWepayLegalEntity($merchant: CreateWePayLegalEntityInput!) {
  createWepayLegalEntity(merchant: $merchant) {
    response
  }
}
`;

export const CreateProperty = gql`
  mutation CreateProperty($input: CreatePropertyInput!) {
    createProperty(input: $input) {
      property {
        ndbId
      }
    }
  }
`;

export const UpdatePrimaryLocation = gql`
  mutation UpdatePrimaryLocation($input: UpdatePrimaryLocationInput!) {
    updatePrimaryLocation(input: $input) {
      response
    }
  }
`;

export const CreatePrimaryLocation = gql`
  mutation CreatePrimaryLocation($input: CreatePrimaryLocationInput!) {
    createPrimaryLocation(input: $input) {
      response
    }
  }
`;
