import React, { Component } from "react";
import "../../assets/fomantic/dist/semantic.css";
import { getClient } from "../../store/auth";
import { Grid, Card } from "semantic-ui-react";
import { PERSON_URL } from "../../utils";
import { updatePersonGql } from "../../store/person/person";
import Profile from "../../containers/Profile/Profile";
import PhotoEditModal from "../../components/Modals/PhotoEditModal";
import "./Profile.scss";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";

const personMainClient = getClient(PERSON_URL);
// Sidebar which will display the users
// Location, Current Loyalty Points, Balance(Assuming this would represent payments that need being addressed?), and Badges(This space can be utilized for other ideas)
class ProfileBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnToProfile: false,
    };
  }

  handlePhotoSubmit = () => {
    this.setState({ returnToProfile: true });
  };

  render() {
    const {
      user,
    } = this.props;

    const forgotPassword = () => {
      mixpanel.track("Renter Account Action", {
        sub: "Password Reset from Profile",
      });
      try {
        personMainClient
          .mutate({
            mutation: updatePersonGql,
            variables: {
              input: {
                passwordReset: true,
              },
            },
          })
          .then((response) => {
            toastSuccessMsg("Success! Please check your email.");
          })
          .catch((e) => {
            toastFailMsg("Something went wrong, please contact website admin.");
          });
      } catch (e) {
        console.log(e);
      }
    };
    return !this.state.returnToProfile ? (
      <div className="profile-wrap">
        <div className="top-header">
          <h5>Profile</h5>
          <span>Here’s your leasera profile</span>
        </div>
        <Card fluid>
          <Grid
            stackable
            celled="internally"
            columns={2}
            className="profile-detail"
          >
            <Grid.Column className="left-data">
              <PhotoEditModal
                handlePhotoSubmit={this.handlePhotoSubmit}
                fileType={this.props.fileType && this.props.fileType.ext}
                filePath={this.props.file && this.props.file}
              />
              <div className="user-detail">
                <h6>
                  {user && user.firstName} {user && user.lastName}
                </h6>
                <span>{user && user.email}</span>
              </div>
            </Grid.Column>
            <Grid.Column className="right-data">
              <Link
                label=""
                className="edit-profile"
                onClick={this.props.editBool}
              >
                Edit Profile
              </Link>
            </Grid.Column>
          </Grid>
          <Grid celled="internally" columns={1} className="about-detail">
            <Grid.Column>
              <h6>About Me</h6>
              <p>{user && user.bio}</p>
            </Grid.Column>
          </Grid>
          <Grid celled="internally" columns={3} className="contact-detail">
            <Grid.Column>
              <label>Cell Number</label>
              <span>
                {user &&
                  user.phoneMobile &&
                  user.phoneMobile
                    .toString()
                    .replace(/\d{3,4}?(?=...)/g, "$& ")}
              </span>
            </Grid.Column>
            <Grid.Column>
              <label>Home Phone</label>
              <span>
                {user &&
                  user.phoneHome &&
                  user.phoneHome.toString().replace(/\d{3,4}?(?=...)/g, "$& ")}
              </span>
            </Grid.Column>
          </Grid>
        </Card>
        <Card fluid className="change-password">
          <Grid celled="internally" columns={2}>
            <Grid.Column className="left-data">
              <div className="lock-icon">
                <img
                  src="/assets/img/dashboard/icons-password.svg"
                  alt="lock-icon"
                ></img>
              </div>
              <div className="heading-txt">
                <h6>Change Password</h6>
                <p>Update your secret combination of letters and numbers.</p>
              </div>
            </Grid.Column>
            <Grid.Column className="right-data">
              <img
                src="/assets/img/dashboard/icons-arrow-down.svg"
                alt="arrow"
                onClick={() => forgotPassword()}
              ></img>
            </Grid.Column>
          </Grid>
        </Card>
      </div>
    ) : (
      <Profile />
    );
  }
}

export default ProfileBar;
