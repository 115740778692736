import moment from "moment";
import React, { useMemo, useState, useEffect } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";
import { MONTHS, AFFORDABLE_HOUSING } from "./constants";
import { getClient } from "../init-apollo-googleFn";
import housingProgramByPropertyGql from "../store/person/housingProgramByPropertyGql";

const affordableHousing = getClient(AFFORDABLE_HOUSING);

export const formatDateTo12Hours = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let meridiem = hours >= 12 ? "PM" : "AM";
  let strHour = hours % 12;
  let strMinute = minutes < 10 ? `0${minutes}` : minutes;
  return `${strHour}:${strMinute} ${meridiem}`;
};

export const amountFormatter = (price) =>
  `$${price.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}`;

export const daysDifference = (sD, eD) =>
  Math.floor((Date.parse(eD) - Date.parse(sD)) / 86400000);

export const formatDate = (date) => {
  const currentDateTime = moment(date);
  return `${currentDateTime.getDate()} ${
    MONTHS[currentDateTime.getMonth()]
  } ${currentDateTime.getFullYear()}`;
};

export const streetAdd = (location) =>
  `${location.streetOne ? location.streetOne : "Unknown street"} ${
    location.streetTwo ? location.streetTwo : ""
  }, ${location.city ? location.city : "Unknown city"}`;

// If shrink == false: 555-555-5555 else if shrink == true: 5555555555
export const telephoneFormatter = (phoneNum, shrink = false) => {
  if (phoneNum === "" || phoneNum === undefined || phoneNum == null) return;

  let parsed = "";
  for (let i = 0; i < phoneNum.length; i++) {
    if (!isNaN(parseInt(phoneNum[i]))) {
      parsed += phoneNum[i];
    }
  }
  if (shrink) {
    return parsed;
  }

  return `(${parsed.substr(0, 3)}) ${parsed.substr(3, 3)}-${parsed.substr(6)}`;
};

export const parseGraphQLErrors = (error) => {
  if (!error) return;
  if (!error.toString().includes("GraphQL error:")) return error;

  return error.toString().replace("GraphQL error:", "");
};

export const multipleColumnsSearch = (inputValue, data) => {
  const filteredData = data.filter(
    (value) =>
      value.node.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
      value.node.lastName.toLowerCase().includes(inputValue.toLowerCase())
  );
  return filteredData;
};

export const dataFilterByColumn = (inputValue, data, type, key) => {
  const filteredData = data.filter((value) =>
    key
      ? value.node[type] &&
        value.node[type][key].toLowerCase().includes(inputValue.toLowerCase())
      : value.node[type] &&
        value.node[type].toLowerCase().includes(inputValue.toLowerCase())
  );
  return filteredData;
};

export const handlePlacesSelect = (
  address,
  parentKey,
  index,
  addressChange,
  setAddressFields
) => {
  geocodeByAddress(address)
    .then((results) => {
      const AddressResults = results && results[0].address_components;
      setAddressFields(AddressResults);
      addressChange(
        "streetLine1",
        `${AddressResults[0].long_name} ${AddressResults[1].long_name}`,
        index,
        parentKey,
        "address"
      );
      return getLatLng(results[0]);
    })
    .then((latLng) => {
      latLng.address = address;
    })
    .catch((error) => console.error("Error", error));
};

export const handleArrayPlacesSelect = (
  address,
  parentKey,
  index,
  nestedIndex,
  addressChange,
  setAddressFields
) => {
  geocodeByAddress(address)
    .then((results) => {
      const AddressResults = results && results[0].address_components;
      setAddressFields(AddressResults);
      addressChange(
        "streetLine1",
        `${AddressResults[0].long_name} ${AddressResults[1].long_name}`,
        index,
        nestedIndex,
        parentKey,
        "address"
      );
      return getLatLng(results[0]);
    })
    .then((latLng) => {
      latLng.address = address;
    })
    .catch((error) => console.error("Error", error));
};

export const disablePastDt = (current) => {
  const yesterday = moment().subtract(1, "day");
  return current.isAfter(yesterday);
};

export const disableFutureDt = (current) => {
  const yesterday = moment().subtract(1, "day");
  return current.isBefore(yesterday);
};

export const ageValidation = (current) => {
  const age = moment().subtract(18, "year");
  return current.isBefore(age);
};

export const toastFailMsg = (msg) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
export const toastSuccessMsg = (msg) =>
  toast.success(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const affordHousingApi = async (propertyCustomId) => {
  const data = [];
  await affordableHousing
    .query({
      query: housingProgramByPropertyGql,
      variables: { location: propertyCustomId },
    })
    .then((res) => {
      res.data.housingPrograms.edges.map((ele) => {
        data.push(
          ele && {
            key: ele.node.name,
            text: ele.node.name,
            value: {
              name: ele.node.name,
              id: ele.node.code,
              field: "affordableHousingProgram",
            },
          }
        );
        return null;
      });
    });
  return data;
};

export const getYears = (count = 20) => {
  const year = new Date().getFullYear();
  return Array.from(
    { length: count },
    (v, i) => year - count + i + 1
  ).reverse();
};

export const getYearOptions = () =>
  getYears(122).map((item) => ({ key: item, text: item, value: item }));

export const parsePhotosUrl = (photos) => {
  if (typeof photos === "string") {
    try {
      return parsePhotosUrl(JSON.parse(photos));
    } catch (e) {
      return photos;
    }
  }
  return photos;
};

export const formatErrorMessage = (errors) => {
  if (errors && Array.isArray(errors)) {
    return errors.map((item) => ({ field: item.field, message: item.msg }));
  }
  return errors;
};

export const computeAmounts = (amount) =>{
  
  const convertToNumber = parseFloat(amount)
  if(convertToNumber < 0){
    return <span>$ ({Math.abs(Number.parseFloat(amount)).toLocaleString('en')})</span>
  }
  if(convertToNumber >= 0){
    return <span>$ {Math.abs(Number.parseFloat(amount)).toLocaleString('en')}</span>
  }
  else return <span>$ 0</span>
};

export const pluralize = (val, word, plural = `${word}s`) =>
  [1, -1].includes(Number(val)) ? word : plural;

// Property utils
export const parseArrayString = (arr) => {
  try {
    if (typeof arr === "string") {
      return JSON.parse(arr);
    }
    return arr || [];
  } catch (error) {
    return [];
  }
};

export const parseAddresses = (addresses) => {
  let str = "";
  const addressComponents = parseArrayString(addresses);
  const {
    street = "",
    streetOne = "",
    streetTwo = "",
    city = "",
    state = "",
    country = "",
    zip = "",
  } = (Array.isArray(addressComponents)
    ? addressComponents[0]
    : addressComponents) || {};
  str = [street, streetOne, streetTwo, city, state, country, zip]
    .filter((x) => x)
    .join(", ");
  const stringFix = str.replaceAll(", undefined", "");
  return stringFix;
};

export const parseAmenities = (amenities) =>
  amenities ? parseArrayString(amenities).join(", ") : "";

export const parseDepositRules = (depositRules) =>
  depositRules ? parseArrayString(depositRules)[0] || {} : {};

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };