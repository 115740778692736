import React, { Component } from "react";
import { Icon, Form, Button, Modal, Header, TextArea } from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { toast } from "react-toastify";
import toaster from "../../utils/toaster";

class EditComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      id: "",
      modalOpen: false,
    };
  }
  componentDidMount() {
    this.setter();
  }
  setter = () => {
    this.setState({
      comment: this.props.comment.node.message,
      id: this.props.comment.node.id,
    });
  };
  handleClose = () => {
    this.setter();
    this.setState({ modalOpen: false });
  };

  success = () =>
    toast.success("Email Sent!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => {});
  };
  validateAndSubmit = (e) => {
    const { comment, id } = this.state;
    const Obj = { newComment: comment, existingId: id };
    e.preventDefault();
    this.props.updateComment(Obj);
    this.handleClose();
  };

  render() {
    return (
      <>
        <Modal
          className="semanticModal"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size="mini"
          trigger={<Icon name="pencil" title="Edit" className="editable" />}
        >
          <Modal.Header>
            <Header textAlign="center">Edit Comment</Header>
          </Modal.Header>
          <Modal.Content>
            <Form width="equal">
              <TextArea
                style={{ height: "300px", overflowY: "auto" }}
                value={this.state.comment}
                name="comment"
                maxLength="1500"
                onChange={this.handleChange}
              />
            </Form>
          <div>Remaining Characters: {this.state.comment.length}/1500</div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              className="modal-close-button"
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              positive
              className="modal-save-button"
              content="Submit"
              onClick={(e) => this.validateAndSubmit(e)}
            />
          </Modal.Actions>
        </Modal>
        {toaster()}
      </>
    );
  }
}
export default withApollo(EditComment);
