import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./ChatRoomList.scss";

import { SET_CHAT_LOG } from "../../../store/redux/chat";
import { getClient } from "../../../store/auth";
import { CHAT_URL } from "../../../utils";
import {
  getChatRoomLogsManagerQuery,
  subscribeChatMembersToChatroomGql,
} from "../../../store/chat/chat";

import { PageLoader } from "../../../components/Loader/PageLoader";
import ListOfRooms from "./ChatRoomComponents/ListOfRooms";
import { messaging } from "../../Auth/Firebase/index";

const chatApi = getClient(CHAT_URL);

const ChatRoomList = ({ mode, selectedPrimary }) => {
  const dispatch = useDispatch();
  const { chatRoomId } = useParams();

  const [loading, setLoading] = React.useState(false);

  const getChatLogs = async () => {
    try {
      const chatLogsQuery = await chatApi.query({
        query: getChatRoomLogsManagerQuery,
        variables: { chatRoomId },
      });

      const logs = chatLogsQuery.data.getChatRoomLogsManager.edges.map(
        ({ node }) => {
          const { body, chatRoomId, media, timeStamp, senderId, messageLabel } =
            node;
          return {
            senderId,
            chatRoomId,
            body,
            messageLabel,
            timeStamp,
            media: typeof media !== "string" ? [] : JSON.parse(media),
          };
        }
      );


      dispatch({
        type: SET_CHAT_LOG,
        payload: {
          [chatRoomId]: logs,
        },
      });
    } catch (error) {
      console.log("error [chatLogs]", error);
    }
  };

  const subscribeChatRoom = async () => {
    try {
      await chatApi.mutate({
        mutation: subscribeChatMembersToChatroomGql,
        variables: { chat_room_id: chatRoomId },
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (chatRoomId !== undefined) getChatLogs();
    if (chatRoomId !== undefined) subscribeChatRoom();

    let timer = null;

    if (Notification.permission !== "granted" || messaging === null) {
      timer = setInterval(() => {
        if (chatRoomId !== undefined) getChatLogs(chatRoomId);
      }, 3000);
    }

    return () => {
      if (timer !== null) clearInterval(timer);
    };
  }, [chatRoomId]);

  if (loading) return <PageLoader />;

  return (
    <div className="chat-room-list ">
      <ListOfRooms {...{ mode, selectedPrimary }} />
    </div>
  );
};

export default ChatRoomList;
