import React, { useState } from "react";
import { Checkbox, Loader } from "semantic-ui-react";
import { decodeBase64ID } from "../../../utils";
import { pluralize } from "../../../utils/common";

const UnitListItem = ({ unit, unitImg, onClick, getStatus, setStatus }) => {
  const [loading, setLoading] = useState(false);

  const onChange = async () => {
    setLoading(true);
    await setStatus(decodeBase64ID(unit.id), !getStatus);
    setLoading(false);
  };

  return (
    <div className="card-row">
      <div className="block img-box d-inline-flex" onClick={onClick}>
        <img src={unitImg || "assets/img/renter-bg.jpg"} alt="unit-img" />
      </div>
      <div className="block" onClick={onClick}>
        <div className="card-titile">{`Unit No: ${unit.number}`}</div>
      </div>
      <div className="block card-aminites" onClick={onClick}>
        <span>{`${Number(unit.bedrooms)} ${pluralize(
          unit.bedrooms,
          "Bed"
        )}`}</span>{" "}
        <div className="dot" />
        <span>{`${Number(unit.bathrooms)} ${pluralize(
          unit.bathrooms,
          "Bath"
        )}`}</span>{" "}
        <div className="dot" /> <span>{`${Number(unit.sqft)} sq. ft.`}</span>
      </div>

      <div className="zillow-switch-container">
        <div className="zillow-switch-label">Zillow : </div>
        <div className="zillow-switch-content">
          {loading ? (
            <Loader active inline />
          ) : (
            <Checkbox toggle checked={getStatus} onChange={onChange} />
          )}
        </div>
      </div>

      <div className="block" onClick={onClick}>
        <div className="black-strip">
          <span>{`$${unit.price.toLocaleString()} /month`}</span>
        </div>
      </div>
    </div>
  );
};

export default UnitListItem;
