import React, { useState } from "react";
import { Button, Modal, Icon, Header, Form } from "semantic-ui-react";
import { getClient } from "../../init-apollo-googleFn";
import "./moveLeadModal.scss";
import {
  TaxCreateMutation,
  TaxUpdateMutation,
} from "../../store/person/accounting";
import { ACCOUNTING } from "../../utils/constants";

const chargeClient = getClient(ACCOUNTING);

export default function TaxCreationAccounting(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoader] = useState(false);
  const [name, nameCode] = useState(false);
  const [description, descCode] = useState(false);
  const [subType, inputSub] = useState(false);
  const [amount, amountCode] = useState(false);

  const createCharge = (e) => {
    setLoader(true);
    e.preventDefault();
    if (props.props) {
      try {
        chargeClient
          .mutate({
            mutation: TaxUpdateMutation,
            variables: {
              input: {
                location: props.propertyId,
                code: name,
                description: description,
                rate: amount,
                id: subType.toString(),
              },
            },
          })
          .then((response) => {
            props.success();
            setLoader(false);
            props.updateInvoice(response);
            setOpen(false);
          });
      } catch (e) {
        alert(e);
      }
    } else {
      try {
        chargeClient
          .mutate({
            mutation: TaxCreateMutation,
            variables: {
              input: {
                location: props.propertyId,
                code: name,
                description: description,
                rate: amount,
              },
            },
          })
          .then((response) => {
            props.success();
            setLoader(false);
            props.dataPush(response);
            setOpen(false);
          });
      } catch (e) {
        alert(e);
      }
    }
  };

  const editCheck = () => {
    nameCode("");
    descCode("");
    inputSub("");
    amountCode("");
    if (props.props) {
      nameCode(props.props.code);
      descCode(props.props.description);
      inputSub(props.props._id);
      amountCode(props.props.rate);
    }
  };

  return (
    <Modal
      className="semanticModal modal-ui modal-radius overflow-y-visible"
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button onClick={editCheck} icon>
          <Icon name="add circle" />
        </Button>
      }
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky"
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          {props.props ? "Update Tax Code" : "Create Tax Code"}
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Form color="blue">
            <Form.Field required>
              <label>Code</label>
              <input
                placeholder="Insert Code"
                value={name ? name : ""}
                onChange={(e) => nameCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Desciption</label>
              <input
                placeholder="Insert Description"
                value={description ? description : ""}
                onChange={(e) => descCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Rate</label>
              <input
                placeholder="Insert Rate"
                style={{ width: "93%" }}
                type="number"
                value={amount ? amount : ""}
                onChange={(e) => amountCode(e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        {loading ? (
          <Button loading>Loading</Button>
        ) : (
          <>
            {/* eslint-disable */}
            <Button
              className="modal-save-button"
              content={props.props ? "Update" : "Create"}
              disabled={
                (name && !name.trim()) ||
                (description && !description.trim()) ||
                (amount && !amount.trim()) ||
                !amount ||
                !description ||
                !name
              }
              onClick={createCharge}
              positive
            />
            {/* eslint-enable */}
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
}
