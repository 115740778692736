import React, {
  useCallback, useEffect, useState,
} from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import {
  Button, Select, Input, Form, Dropdown,
} from 'semantic-ui-react';
import userIcon from '../../assets/images/user-icon.svg';
import { getClient } from '../../init-apollo-googleFn';
import {
  DATE_FORMAT,
  LEASE_MANAGER,
  LEASE_TIME_FORMAT,
  LOCATIONAPI,
  UNIT_TYPE_API,
  UNITAPI,
  LEASE_FREQUENCY,
  ACCOUNTING,
  CHARGE_CODE,
  BLUEMOON_API,
  ROUTES,
  DOCUSIGN
} from '../../utils';
import {
  disablePastDt, toastSuccessMsg, toastFailMsg, parseGraphQLErrors,
} from '../../utils/common';
import {
  renewLease, proRate, locationPolicies, lgavailable, updateLease, CreateEnvelop
} from '../../store/person/leases';
import { unitType, units, statusFilteredUnits } from '../../store/person/properties';
import {
  ChargeAccounting,
} from '../../store/person/accounting';
import { bluemoonPolicies } from '../../store/bluemoon/bluemoon';
import AddUnitModal from './AddUnitModal';
// images
import backIcon from '../../assets/images/back-arrow.svg';
import LeaseRenewalSummaryModal from './LeaseRenewalSummary';

function LeaseRenewal({ location, selectedPrimary, history }) {
  const policyDataOptions = [];
  const customDataOptions = [];
  const standardDataOptions = [];
  const leaseManager = getClient(LEASE_MANAGER);
  const locationClient = getClient(LOCATIONAPI);
  const unitTypeManager = getClient(UNIT_TYPE_API);
  const leaseDOCU = getClient(DOCUSIGN);
  const unitManager = getClient(UNITAPI);
  const chargeClient = getClient(ACCOUNTING);
  const chargeCodeClient = getClient(CHARGE_CODE);
  const bluemoonApi = getClient(BLUEMOON_API);
  const [leaseDetail, setLeaseDetail] = useState(location.state || JSON.parse(localStorage.getItem('tenantData')));
  const [guarantee, setGuarantee] = useState(leaseDetail.guarantee);
  const [firstLastInvoice, setFirstLastInvoice] = useState(leaseDetail.firstLastInvoice);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dsFormsList, setDSFormsList] = useState([]);
  const [unitTypeData, setUnitTypeData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [unitNumberText, setUnitNumberText] = useState(leaseDetail.unit && leaseDetail.unit.number);
  const [frequency, setFrequency] = useState(leaseDetail.leaseFrequency);
  const [charges, setCharges] = useState([]);
  const [chargeOptions, setChargeOptions] = useState([]);
  const [unitChargeText, setUnitChargeText] = useState(leaseDetail.unitChargeCode && leaseDetail.unitChargeCode.name);
  const [unitChargeAmount, setUnitChargeAmount] = useState(leaseDetail.unitAmount);
  const [unitChargeCode, setUnitChargeCode] = useState(leaseDetail.unitChargeCode && leaseDetail.unitChargeCode.id);
  const [proRateEnabled, setProRateEnabled] = useState(false);
  const [petChargeText, setPetChargeText] = useState(leaseDetail.petChargeCode && leaseDetail.petChargeCode.name);
  const [petChargeAmount, setPetChargeAmount] = useState(leaseDetail.petAmount);
  const [vehicleChargeAmount, setVehicleChargeAmount] = useState(leaseDetail.vehicleAmount);
  const [unitProRata, setUnitProRata] = useState('');
  const [unitQuantity, setUnitQuantity] = useState(leaseDetail.unitChargeCode && leaseDetail.unitChargeCode.quantity);
  const [petChargeCode, setPetChargeCode] = useState(leaseDetail.petChargeCode && leaseDetail.petChargeCode.id);
  const [petProRata, setPetProRata] = useState('');
  const [petQuantity, setPetQuantity] = useState(leaseDetail.petQuantity);
  const [vehicleChargeCode, setVehicleChargeCode] = useState(leaseDetail.vehicleChargeCode && leaseDetail.vehicleChargeCode.id);
  const [vehicleChargeText, setVehicleChargeText] = useState(leaseDetail.vehicleChargeCode && leaseDetail.vehicleChargeCode.name);
  const [vehicleProRata, setVehicleProRata] = useState('');
  const [vehicleQuantity, setVehicleQuantity] = useState(leaseDetail.vehicleQuantity);
  const [depositChargeText, setDepositChargeText] = useState(leaseDetail.depositChargeCode && leaseDetail.depositChargeCode.name);
  const [depositChargeCode, setDepositChargeCode] = useState(leaseDetail.depositChargeCode && leaseDetail.depositChargeCode.id);
  const [fixedDepositAmount, setFixedDepositAmount] = useState(leaseDetail.deposit);
  const [refundableAmount, setRefundableAmount] = useState(leaseDetail.nonRefundable);
  const [extraCharges, setExtraCharges] = useState([]);
  const [billDate, setBillDate] = useState(1);
  const [billOnDate, setBillOnDate] = useState(leaseDetail.billOnDate);
  const [arr, setArr] = useState([]);
  const [vacantUnit, setVacantUnitData] = useState([]);
  const [isDocuSign, setIsDocusign] = useState(selectedPrimary.node.esginType === 'docusign');
  const [isBluemoon, setIsBluemoon] = useState(selectedPrimary.node.esginType === 'bluemoon');
  const [docusignPolicies, setDocusignPolicies] = useState([]);
  const [docusignOptions, setDocusignOptions] = useState([]);
  const [lgAvail, setLgAvail] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [depositRequired, setDepositRequired] = useState(false);
  const [customPolicies, setCustomPolicices] = useState([]);
  const [standardPolicies, setStandardPolicies] = useState([]);
  const [bmCustomForms, setBmCustomForms] = useState([]);
  const [bmStandardForms, setBmStandardForms] = useState([]);
  const [standardOptions, setStandardOptions] = useState([]);
  const [customOptions, setCustomOptions] = useState([]);

  const goToBackPage = () => {
    if (location.status === 'edit') {
      return history.push(ROUTES.lease);
    }
    return history.push({
      pathname: `/tenantDetail/tenantId=${leaseDetail.id}/applicationId=${leaseDetail.applicationId}`,
    });
  };

  const renderTopHeader = () => (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="page-header mb-5">
            <div className="d-flex align-items-center">
              <span className="page-back" onClick={() => goToBackPage()}>
                <img src={backIcon} alt="icon" />
              </span>
              <div className="page-header-title pl-0">{location.status === 'edit' ? 'Edit Lease' : 'Renew Lease'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="renew-lease-header">
            <div className="card-profile-header d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="resident-profile-icon">
                  <img src={userIcon} alt="icon" />
                </div>
                <div>
                  <div className="profile-title">
                    {`${leaseDetail.person.firstName} ${leaseDetail.person.lastName}`}
                  </div>
                  <div className="profile-subtitle">
                    {' '}
                    <span>
                      <a href={`mailto:${leaseDetail.person.email}`}>
                        {leaseDetail.person.email}
                      </a>
                    </span>
                    {' '}
                    <span className="dot-separator" />
                    <span>
                      <a href={
                        `tel:${leaseDetail.person.phoneMobile}`
                      }
                      >
                        {leaseDetail.person.phoneMobile}
                        .
                      </a>
                    </span>
                  </div>
                  <div className="profile-subtitle">
                    <span>
                      Lease Start Date:
                      {moment(leaseDetail.startDate).format(DATE_FORMAT)}
                    </span>
                    <span className="dot-separator" />
                    <span>
                      Lease End Date:
                      {moment(leaseDetail.endDate).format(DATE_FORMAT)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );

  const handleDateChange = (event, field) => {
    const dateRes = moment(event).format(LEASE_TIME_FORMAT);
    if (field === 'startDate') {
      setStartDate(dateRes);
    } else {
      setEndDate(dateRes);
    }
  };

  const onChangeExtraCharge = (e, { value }, index) => {
    const updatedExtraCharges = [...extraCharges];
    updatedExtraCharges[index] = value;
    setExtraCharges(updatedExtraCharges);
  };

  const onChangeExtraChargeAmount = (value, key, index) => {
    const updatedExtraCharges = [...extraCharges];
    updatedExtraCharges[index][key] = value;
    setExtraCharges(updatedExtraCharges);
    if (key === 'amountDefault') {
      setProRateEnabled(false);
    }
  };

  const lgAvailFunc = () => {
    leaseManager
      .query({
        query: lgavailable,
        variables: {
          applicationId: leaseDetail.applicationId,
        },
      })
      .then((response) => {
        if (response.data.isLgAvailable === true) {
          setLgAvail(true);
        } else setLgAvail(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getUnitData = useCallback(() => {
    unitManager
      .query({
        query: statusFilteredUnits,
        variables: {
          locationId: selectedPrimary.node.id,
          status: 'vacant',
        },
      })
      .then((res) => {
        const data = res.data.units.edges;
        setVacantUnitData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const prepareDocuSignOptions = () => {
    policyDataOptions.length = 0;
    const docusignOptionsData = docusignPolicies.length
      && docusignPolicies.map((policy) => ({
        key: policy.node.name,
        text: policy.node.name,
        value: policy.node.id,
      }));
    const arr = leaseDetail.extLeaseInfo && leaseDetail.extLeaseInfo.ds_forms;
    Array.prototype.push.apply(policyDataOptions, arr);
    setDocusignOptions(docusignOptionsData);
    setDSFormsList(arr);
  };

  const fetchDocuSignPolicies = () => {
    locationClient
      .query({
        query: locationPolicies,
        variables: {
          locationId: selectedPrimary.node.id,
        },
      })
      .then((response) => {
        const data = response.data.locationPolicies.edges;
        setDocusignPolicies(data);
      });
  };

  useEffect(() => {
    prepareDocuSignOptions();
  }, [docusignPolicies]);

  const prepareStandardOptions = () => {
    const standardOptionsData = standardPolicies
      && standardPolicies.length
      && standardPolicies.map((policy, index) => ({
        key: index,
        text: policy.label,
        value: policy.name,
      }));
    setStandardOptions(standardOptionsData);
  };

  const prepareCustomOptions = () => {
    const customOptionsData = customPolicies
      && customPolicies.map((policy, index) => ({
        key: index,
        text: policy.label,
        value: policy.name,
      }));
    setCustomOptions(customOptionsData);
  };

  const fetchBluemoonPolicies = async () => {
    customDataOptions.length = 0;
    standardDataOptions.length = 0;
    await bluemoonApi
      .query({
        query: bluemoonPolicies,
        variables: {
          locationId: selectedPrimary.node.id,
        },
      })
      .then(async (res) => {
        const result = res.data.bluemoonForms && res.data.bluemoonForms.edges;
        setCustomPolicices(result && result[0].node.custom);
        setStandardPolicies(result && result[0].node.standard);
        const options = leaseDetail.extLeaseInfo && leaseDetail.extLeaseInfo;
        if (options) {
          Array.prototype.push.apply(customDataOptions, options.custom_forms);
          Array.prototype.push.apply(
            standardDataOptions,
            options.standard_forms,
          );
          setBmStandardForms(options.standard_forms);
          setBmCustomForms(options.custom_forms);
        }
      });
  };

  useEffect(() => {
    prepareStandardOptions();
    prepareCustomOptions();
  }, [customPolicies, standardPolicies])

  const getUnitTypeData = () => {
    unitTypeManager
      .query({
        query: unitType,
        variables: {
          locationId: selectedPrimary.node.id,
        },
      })
      .then((res) => {
        if (res.data) {
          const data = [];
          res.data.unitType.edges.map((ele, index) => {
            data.push(
              ele && {
                key: index,
                text: ele.node.type,
                value: {
                  name: ele.node.type,
                  id: ele.node.id,
                  field: 'unitType',
                },
              },
            );
            return null;
          });
          setUnitTypeData(data);
        }
        isDocuSign && fetchDocuSignPolicies();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchBluemoonPolicies();
  }, [unitTypeData]);

  const fetchUnitNumber = async (e, { value }, id) => {
    await unitManager
      .query({
        query: units,
        variables: {
          locationId: selectedPrimary.node.id,
          unitTypesId: !id ? value.id : id.id,
        },
      })
      .then((res) => {
        const data = [];
        res.data.units.edges.map((ele) => {
          data.push(
            ele && {
              key: ele.node.number,
              text: ele.node.number,
              value: {
                name: ele.node.number,
                id: ele.node.id,
                field: 'unitNumber',
                deposit: ele.node.deposit,
                refund: ele.node.nonRefundable,
                price: ele.node.price,
              },
            },
          );
          return null;
        });
        setUnitsData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectUnit = (e, { value }) => {
    setUnitNumberText(value.name);
    setUnitNumberText(value.name);
    setUnitChargeAmount(value.price);
    setProRateEnabled(false);
  };

  const prepareChargeOptions = async () => {
    const chargeOptions = charges
      && charges.map((charge, index) => ({
        key: index,
        text: charge.node.name,
        value: charge.node,
      }));
    setChargeOptions(chargeOptions);
  };

  useEffect(() => {
    prepareChargeOptions();
  }, [charges]);

  const getLedger = () => {
    getUnitTypeData();
    chargeClient
      .query({
        query: ChargeAccounting,
        variables: {
          location: selectedPrimary.node.id,
        },
      })
      .then(async (res) => {
        if (res.data) {
          setCharges(res.data.chargeCodes.edges);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const totalCharges = () => {
    const allCharges = [
      unitChargeAmount * Number(unitQuantity),
      petChargeAmount * Number(petQuantity),
      vehicleChargeAmount * Number(vehicleQuantity),
      ...extraCharges.map(
        (charge) => Number(charge.amountDefault) * Number(charge.quantity),
      ),
    ];
    return allCharges.reduce((total, amount) => {
      // eslint-disable-next-line no-param-reassign
      total += Number(amount);
      return total;
    }, 0);
  };

  const selectFrequency = (e) => {
    setFrequency(e);

  };

  const selectFreq = (e, { value }) => {
    selectFrequency(value);
    setProRateEnabled(false);
  };

  const selectUnitChargeCode = (e, { value }) => {
    setUnitChargeCode(value.id);
    setUnitChargeText(value.name);
    setUnitChargeAmount(value.amountDefault);
    setProRateEnabled(false);
  };

  const onChangeChargeAmount = (e, key) => {
    setProRateEnabled(false);
    switch (key) {
      case 'unitChargeAmount':
        setUnitChargeAmount(e.target.value);
        break;
      case 'petChargeAmount':
        setPetChargeAmount(e.target.value);
        break;
      case 'vehicleChargeAmount':
        setVehicleChargeAmount(e.target.value);
        break;
      default:
        break;
    }
  };

  const selectPetChargeCode = (e, { value }) => {
    setPetChargeCode(value.id);
    setPetChargeText(value.name);
    setPetChargeAmount(value.amountDefault);
    setProRateEnabled(false);
  };

  const selectVehicleChargeCode = (e, { value }) => {
    setVehicleChargeCode(value.id);
    setVehicleChargeText(value.name);
    setVehicleChargeAmount(value.amountDefault);
    setProRateEnabled(false);
  };

  const selectDepositChargeCode = (e, { value }) => {
    setDepositChargeText(value.name);
    setDepositChargeCode(value.id);
  };

  const setDateChange = (value) => {
    if (value && value.toString().length === 1) {
      return `0${value}`;
    }
    return value;
  };

  const newBillDate = () => {
    let newDate = '';
    if (startDate) {
      const temp = startDate.split('T');
      const temp1 = temp[0].split('-');
      if (temp1[1] === '12') {
        newDate = `${Number(temp1[0]) + 1}-01-${setDateChange(billDate)}T${temp[1]
          }`;
      } else {
        newDate = `${temp1[0]}-${setDateChange(
          Number(temp1[1]) + 1,
        )}-${setDateChange(billDate)}T${temp[1]}`;
      }
      setBillOnDate(newDate);
    }
  };

  const proRateMutation = () => {
    const itemsToReturn = [
      {
        inputDate: moment(startDate).format('YYYY-MM-DD'),
        billOnDate: billDate,
        monthlyRent: unitChargeAmount,
      },
      {
        inputDate: moment(startDate).format('YYYY-MM-DD'),
        billOnDate: billDate,
        monthlyRent: petChargeAmount,
      },
      {
        inputDate: moment(startDate).format('YYYY-MM-DD'),
        billOnDate: billDate,
        monthlyRent: vehicleChargeAmount,
      },
    ];
    if (extraCharges.length) {
      extraCharges.forEach((charge, index) => {
        itemsToReturn.push({
          id: index,
          inputDate: moment(startDate).format('YYYY-MM-DD'),
          billOnDate: billDate,
          monthlyRent: charge.amountDefault,
        });
      });
    }
    try {
      leaseManager
        .mutate({
          mutation: proRate,
          variables: {
            input: {
              items: itemsToReturn,
              leaseFrequency: frequency,
            },
          },
        })
        .then((res) => {
          const data = res.data.proRata.results;
          newBillDate();
          setUnitProRata(data[0].finalAmount);
          setPetProRata(data[1].finalAmount);
          setVehicleProRata(data[2].finalAmount);
          if (data.length > 3) {
            data
              .filter((value, index) => index >= 3)
              .map((value, index) => {
                onChangeExtraChargeAmount(
                  value.finalAmount,
                  'proRata',
                  index,
                );
                return null;
              });
          }
        });
    } catch (e) {
      alert(e);
    }
  };

  const updateCheckboxOption = (value, field) => {
    if (field === 'LG') {
      setGuarantee(value);
    } else if (field === 'FLM') {
      setFirstLastInvoice(value);
    } else if (field === 'DP') {
      setDepositRequired(value);
    } else {
      setProRateEnabled(value);
      value && proRateMutation();
    }
  };

  const handleNewCharges = (response) => {
    const { chargeCode } = response.data.createChargeCode;
    setExtraCharges((prev) => [...prev, chargeCode]);
  };

  const showPolicies = (ele) => (
    <div className="white-block policy-wrap mb-4">
      <h3 className="m-t-4">{ele.node.name}</h3>
      <div className="policy-col">
        <p>
          <i className="fa fa-file" aria-hidden="true" />
          <a
            href={ele.node.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
            {' '}
            {ele.node.name}
          </a>
        </p>
      </div>
    </div>
  );

  const setDocuData = (e, { value }) => {
    setDSFormsList(value);
  };

  const setCustomData = (e, { value }) => {
    setBmCustomForms(value);
  };

  const setStandardData = (e, { value }) => {
    setBmStandardForms(value);
  };

  const renderLeaseDetails = () => (
    <div className="row">
      <div className="col-md-12">
        <div className="theme-card card">
          <div className="card-header">
            <div className="card-header-title">Lease Details</div>
          </div>
          <div className="card-body">
            <div className="card-profile-body lease-renew-body">
              <div className="row">
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Start Date</div>
                    <Datetime
                      timeFormat={false}
                      isValidDate={disablePastDt}
                      minDate={moment().toDate()}
                      closeOnSelect
                      inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                      onChange={(e) => handleDateChange(e, 'startDate')}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">
                      End Date
                    </div>
                    <Datetime
                      timeFormat={false}
                      isValidDate={disablePastDt}
                      minDate={moment().toDate()}
                      closeOnSelect
                      inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                      onChange={(e) => handleDateChange(e, 'endDate')}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">
                      Bill Day
                    </div>
                    <div className="profile-label-value">
                      <Input
                        search
                        disabled
                        required
                        value={frequency !== 'Monthly' ? '1 & 15' : '1'}
                        selectOnBlur={false}
                        className="height-38"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Floor Plan</div>
                    <div className="profile-label-value">
                      <Select
                        placeholder="Floor Plan"
                        options={unitTypeData && unitTypeData}
                        selectOnBlur={false}
                        search
                        selection
                        text={leaseDetail.unit && leaseDetail.unit.unitType.type}
                        value={leaseDetail.unit && leaseDetail.unit.unitType.type}
                        onChange={fetchUnitNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Unit Number</div>
                    <div className="profile-label-value">
                      <Select
                        placeholder="Unit Number"
                        selection
                        selectOnBlur={false}
                        search
                        options={unitsData && unitsData}
                        text={unitNumberText}
                        value={unitNumberText}
                        onChange={selectUnit}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Frequency</div>
                    <div className="profile-label-value">
                      <Select
                        search
                        selection
                        options={LEASE_FREQUENCY}
                        text={frequency}
                        selectOnBlur={false}
                        onChange={selectFreq}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Unit Charge Code</div>
                    <div className="profile-label-value">
                      <Select
                        selection
                        selectOnBlur={false}
                        search
                        options={chargeOptions}
                        text={
                          unitChargeText ? `${unitChargeText} - $${unitChargeAmount}`
                            : ''
                        }
                        onChange={selectUnitChargeCode}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Unit Amount</div>
                    <div className="profile-label-value">
                      <Input
                        search
                        required
                        type="number"
                        value={unitChargeAmount}
                        selectOnBlur={false}
                        onChange={(e) => onChangeChargeAmount(e, 'unitChargeAmount')}
                        className="height-38"
                      />
                    </div>
                  </div>
                </div>
                {proRateEnabled && (
                  <div className="col">
                    <div className="profile-basic">
                      <div className="profile-label">Unit Pro Rata</div>
                      <div className="profile-label-value">
                        <Input
                          type="number"
                          value={unitProRata}
                          selectOnBlur={false}
                          onChange={(e) => setUnitProRata(e.target.value)}
                          className="height-38"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Unit Quantity</div>
                    <div className="profile-label-value">
                      <Input
                        search
                        disabled
                        required
                        type="number"
                        defaultValue={unitQuantity}
                        selectOnBlur={false}
                        onChange={(e) => setUnitQuantity(e.target.value)}
                        className="height-38"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Pet Charge Code</div>
                    <div className="profile-label-value">
                      <Select
                        selection
                        search
                        options={chargeOptions}
                        text={
                          petChargeText
                            ? `${petChargeText} - ` + `$${petChargeAmount}`
                            : ''
                        }
                        selectOnBlur={false}
                        onChange={selectPetChargeCode}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Pet Amount</div>
                    <div className="profile-label-value">
                      <Input
                        search
                        required
                        type="number"
                        value={petChargeAmount}
                        selectOnBlur={false}
                        onChange={(e) => onChangeChargeAmount(e, 'petChargeAmount')}
                        className="height-38"
                      />
                    </div>
                  </div>
                </div>
                {proRateEnabled && (
                  <div className="col">
                    <div className="profile-basic">
                      <div className="profile-label">Pet Pro Rata</div>
                      <div className="profile-label-value">
                        <Input
                          type="number"
                          value={petProRata}
                          selectOnBlur={false}
                          onChange={(e) => setPetProRata(e.target.value)}
                          className="height-38"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Pet Quantity</div>
                    <div className="profile-label-value">
                      <Input
                        search
                        type="number"
                        defaultValue={petQuantity}
                        selectOnBlur={false}
                        onChange={(e) => setPetQuantity(e.target.value)}
                        className="height-38"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Vehicle Charge Code</div>
                    <div className="profile-label-value">
                      <Select
                        selection
                        search
                        options={chargeOptions}
                        text={
                          vehicleChargeText
                            ? `${vehicleChargeText} - $${vehicleChargeAmount}`
                            : ''
                        }
                        selectOnBlur={false}
                        onChange={selectVehicleChargeCode}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Vehicle Amount</div>
                    <div className="profile-label-value">
                      <Input
                        search
                        type="number"
                        value={vehicleChargeAmount}
                        selectOnBlur={false}
                        onChange={(e) => onChangeChargeAmount(e, 'vehicleChargeAmount')}
                        className="height-38"
                      />
                    </div>
                  </div>
                </div>
                {proRateEnabled && (
                  <div className="col">
                    <div className="profile-basic">
                      <div className="profile-label">Vehicle Pro Rata</div>
                      <div className="profile-label-value">
                        <Input
                          required
                          type="number"
                          value={vehicleProRata}
                          selectOnBlur={false}
                          onChange={(e) => setVehicleProRata(e.target.value)}
                          className="height-38"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col">
                  <div className="profile-basic">
                    <div className="profile-label">Vehicle Quantity</div>
                    <div className="profile-label-value">
                      <Input
                        search
                        type="number"
                        defaultValue={vehicleQuantity}
                        selectOnBlur={false}
                        onChange={(e) => setVehicleQuantity(e.target.value)}
                        className="height-38"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {depositRequired && (
                <div className="row">
                  <div className="col">
                    <div className="profile-basic">
                      <div className="profile-label">Deposit Charge Code</div>
                      <div className="profile-label-value">
                        <Select
                          selection
                          search
                          options={chargeOptions}
                          text={depositChargeText ? `${depositChargeText}` : ' '}
                          selectOnBlur={false}
                          onChange={selectDepositChargeCode}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="profile-basic">
                      <div className="profile-label">Deposit Amount</div>
                      <div className="profile-label-value">
                        <Input
                          search
                          type="number"
                          defaultValue={Number(0)}
                          value={fixedDepositAmount}
                          selectOnBlur={false}
                          onChange={(e) => setFixedDepositAmount(e.target.value)}
                          className="height-38"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="profile-basic">
                      <div className="profile-label">Non Refundable Amount</div>
                      <div className="profile-label-value">
                        <Input
                          search
                          type="number"
                          defaultValue={Number(0)}
                          value={refundableAmount}
                          selectOnBlur={false}
                          onChange={(e) => setRefundableAmount(e.target.value)}
                          className="height-38"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {extraCharges.map((charge, index) => (
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      widths="equal"
                      className="unit-info-form-first-row"
                      key={charge.id}
                    >
                      <Form.Select
                        label={`Extra Charge ${index + 1} Code`}
                        selection
                        search
                        options={chargeOptions}
                        text={
                          `Extra Charge ${index + 1} - $${charge.amountDefault}`
                          || ''
                        }
                        selectOnBlur={false}
                        onChange={(...args) => onChangeExtraCharge(...args, index)}
                      />
                      <Form.Input
                        label={`Extra Charge ${index + 1} Amount`}
                        search
                        type="number"
                        defaultValue={charge.amountDefault}
                        selectOnBlur={false}
                        onChange={(e) => onChangeExtraChargeAmount(
                          e.target.value,
                          'amountDefault',
                          index,
                        )}
                        className="height-38"
                      />
                      {proRateEnabled && (
                        <Form.Input
                          label="Extra Charge Pro Rata"
                          required
                          type="number"
                          value={charge.proRata}
                          selectOnBlur={false}
                          onChange={(e) => onChangeExtraChargeAmount(
                            e.target.value,
                            'proRata',
                            index,
                          )}
                          className="height-38"
                        />
                      )}
                      <Form.Input
                        label={`Extra Charge ${index + 1} Quantity`}
                        search
                        type="number"
                        defaultValue={charge.quantity}
                        selectOnBlur={false}
                        onChange={(e) => onChangeExtraChargeAmount(
                          e.target.value,
                          'quantity',
                          index,
                        )}
                        className="height-38"
                      />
                    </Form.Group>
                  </div>
                </div>
              ))}
              {isBluemoon && (
                <Form.Group>
                  <Form.Field>
                    <label>Custom Policy</label>
                    <Dropdown
                      placeholder="Custom Policy"
                      search
                      multiple
                      selection
                      defaultValue={customDataOptions}
                      options={customOptions}
                      onChange={setCustomData}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Standard Policy</label>
                    <Dropdown
                      placeholder="Standard Policy"
                      search
                      multiple
                      selection
                      defaultValue={standardDataOptions}
                      options={standardOptions}
                      onChange={setStandardData}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              {isDocuSign && (
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <Form.Field>
                        <label className="d-block mb-2">Policy Documents</label>
                        <Dropdown
                          placeholder="Location Policy"
                          search
                          multiple
                          selection
                          defaultValue={policyDataOptions}
                          options={docusignOptions}
                          onChange={setDocuData}
                        />
                      </Form.Field>
                    </Form.Group>
                  </div>
                </div>
              )}
              <div className="row renew-lease-row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between mt-5">
                    <div className="radio-container d-flex">
                      <label className="custom-checkbox mr-3">
                        <div>Enable Pro Rate</div>
                        <input
                          type="checkbox"
                          checked={proRateEnabled}
                          onChange={() => updateCheckboxOption(!proRateEnabled, 'PR')}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const prepareBillDateArr = () => {
    const tempArr = Array.from(Array(32), (_, i) => i);
    tempArr.shift();
    return tempArr;
  };

  useEffect(() => {
    getUnitTypeData();
    getLedger();
    setArr(prepareBillDateArr);
    lgAvailFunc();
  }, []);

  const renewLeaseApi = () => {

    let dataToSend = {
      leaseId: leaseDetail.id,
      unit: leaseDetail.unit.id,
      unitChargeCode,
      petChargeCode,
      vehicleChargeCode,
      petQuantity: Number(petQuantity),
      vehicleQuantity: Number(vehicleQuantity),
      unitAmount: Number(unitChargeAmount),
      petAmount: Number(petChargeAmount),
      vehicleAmount: Number(vehicleChargeAmount),
      startDate,
      endDate,
      leaseFrequency: frequency,
      guarantee,
      firstLastInvoice,
      applicationId: leaseDetail.applicationId,
      leaseType: 'esign',
      unitProRata: Number(unitProRata),
      petProRata: Number(petProRata),
      vehicleProRata: Number(vehicleProRata),
      extraCharges,
      depositRequired,
    };

    if (proRateEnabled) {
      dataToSend = { ...dataToSend, billOnDate };
    }

    if (isDocuSign) {
      dataToSend = { ...dataToSend, dsFormsList };
    }

    if (isBluemoon) {
      dataToSend = { ...dataToSend, bmCustomForms, bmStandardForms };
    }

    if (depositRequired) {
      dataToSend = {
        ...dataToSend,
        deposit: Number(fixedDepositAmount),
        nonRefundable: Number(refundableAmount),
        depositChargeCode,
      };
    }

    leaseManager
      .mutate({
        mutation: location.status === 'edit' ? updateLease : renewLease,
        variables: {
          input: {
            lease: dataToSend,
          },
        },
      })
      .then(() => {
        if (location.status === 'edit') {
          toastSuccessMsg('Lease Renewal edited successfully');
        } else {
          SetRecip()
          toastSuccessMsg('Lease Renewal sent successfully');
        }
        history.push({
          pathname: '/lease',
        });
      })
      .catch((error) => toastFailMsg(parseGraphQLErrors(error)));
  };

  const SetRecip = (item) => {
    leaseDOCU
      .mutate({
        mutation: CreateEnvelop,
        variables: {
          input: {
            leaseId: leaseDetail.id
          },
        },
      })
      .then(() => {

      })
      .catch(() => {
      });
  };

  const checkForValidation = (button) => {
    if (
      startDate === undefined
      || !unitChargeAmount
      || !petChargeAmount
      || !vehicleChargeAmount
      || endDate === undefined
      || unitChargeText === ''
      || unitQuantity === ''
      || vehicleQuantity === ''
      || petQuantity === ''
    ) {
      toastFailMsg('Fill all the required details');
    } else if (
      isDocuSign
      && dsFormsList === undefined
    ) {
      toastFailMsg('Select the location policies');
    } else if (
      isBluemoon &&
      bmStandardForms.length === 0 &&
      bmCustomForms.length === 0
    ) {
      toastFailMsg('Select the custom or standard policies');
    } else if (button === 'renew') {
      renewLeaseApi();
    } else if (button === 'next') {
      setOpenSummary(!openSummary);
    }
    return null;
  };

  return (
    <>
      {leaseDetail
        && (
          <>
            {renderTopHeader()}
            {renderLeaseDetails()}
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between flex-wrap mb-3">
                  {docusignPolicies.map((ele) => showPolicies(ele))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-data-wrap">
                  <div className="form-group floating-label">
                    <label>Amount charged per month</label>
                    <p className="form-control">{`${totalCharges().toLocaleString()} charged ${frequency}`}</p>
                  </div>
                </div>
              </div>
            </div>


            {/* New Unit Row */}
            <div className="row">
              <div className="col-md-12">
                <div className="theme-card card bordered mt-5">
                  <div className="card-header unit-header">
                    <AddUnitModal callApi={getUnitData} vacantUnit={vacantUnit} />
                  </div>
                </div>
              </div>
            </div>


          </>
        )}
      <div className="row">
        <div className="col-md-12 add-property-container">
          <div className="btn-block justify-content-end">
            <Button className="btn cancel-btn mr-3">Cancel</Button>
            <Button
              className="btn next-btn btn-gradient"
              onClick={() => checkForValidation('next')}
            >
              Review Lease Offer
            </Button>
            {openSummary
              && (
                <LeaseRenewalSummaryModal
                  changedAmount={totalCharges()}
                  originalAmount={leaseDetail.amount}
                  locationData={selectedPrimary.node}
                  unitNumberText={unitNumberText}
                  firstName={leaseDetail.person.firstName}
                  lastName={leaseDetail.person.lastName}
                  startDate={startDate}
                  endDate={endDate}
                  previousStartDate={leaseDetail.startDate}
                  previousEndDate={leaseDetail.endDate}
                  floorPlan={leaseDetail.unit && leaseDetail.unit.unitType.type}
                  previousFrequency={leaseDetail.leaseFrequency}
                  frequency={frequency}
                  fetchRenewLeaseApi={() => checkForValidation('renew')}
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaseRenewal;
