import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import chatReducer from "./chat";
import personReducer from "./person";

const reducers = combineReducers({
  chat: chatReducer,
  person: personReducer,
});

const store = createStore(reducers, composeWithDevTools());

export default store;
