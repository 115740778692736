import React, { Component } from "react";
import { withApollo } from "react-apollo";
import get from "lodash/get";
import "./FileManager.scss";
import { PageLoader } from "../../components/Loader/PageLoader";
import { allDocumentsGql } from "../../store/person/fileManager";
import { DOCUMENTS_URL, DOCUMENTS_GET_URL } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import { parseGraphQLErrors } from "../../utils/common";
import {
  Grid,
  Header,
  Segment,
  Accordion,
  Icon,
  Modal,
  Button,
} from "semantic-ui-react";
import getAuthToken from "../../store/auth/authUtility";
import axios from "axios";
import FileViewer from "react-file-viewer";
import FileType from "file-type/browser";
import mixpanel from "mixpanel-browser";
import Tour from 'reactour'
import { fileManagerSteps } from "../../utils/tourSteps";
const newClient = getClient(DOCUMENTS_URL);

class FileManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      folders: [],
      documents: [],
      subfolders: [],
      subSubFolders: [],
      stateId: [],
      selectedFolder: "",
      previousFolder: "",
      isTourOpen:false,
      newFolder: "",
      fileName: "",
      recipient: "",
      activeDocuments: "",
      message: "",
      moveFile: false,
      where: "",
      fileUploaded: "",
      messageResponse: "",
      file: null,
      modalOpen: false,
      download: false,
      isError: false,
      loading: true,
      fetching: true,
      path: "",
    };
  }
  componentDidMount = async () => {
    await this.getFilesAndSubfolders();

    mixpanel.track("Manager Page Load", {
      sub: "File Manager",
    });
  };

  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.getFilesAndSubfolders("folders", "", "");
      });
    }
    if (this.props.selectedPrimary.node) {
      this.setState({ primary: nodeData }, () => {
        this.getFilesAndSubfolders("folders", "", "");
      });
    }
  }

  folderToAdd(folderName, where) {
    this.setState({ selectedFolder: folderName, where: where });
  }

  onChange = (e) => {
    const target = e.target;
    const name = target.id;
    const value = target.value;

    this.setState({
      [name]: value,
    });
  };

  filterArr(arr, path) {
    return arr.filter(function (ele) {
      return ele.filePath !== path;
    });
  }

  fileExtension = (filename) => {
    return (
      filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
      filename
    );
  };

  downloadFile(url, fileName) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = url;
    a.target = "_blank";
    a.setAttribute("download", fileName);
    a.click();
    document.body.removeChild(a);
  }

  getFilesAndSubfolders = (folder, name, id) => {
    const location = this.props.selectedPrimary;
    this.setState({ loading: true });
    try {
      newClient
        .query({
          query: allDocumentsGql,
          variables: {
            location: location.node.id,
          },
        })
        .then((response) => {
          if (!response.errors) {
            const data = get(response, "data.documents.Leases");
            this.setState({ folders: data, loading: false }, () => {});
          }
        })
        .catch((error) => {
          this.setState({
            messageResponse: `Oops! There was a problem fetching files: ${parseGraphQLErrors(
              error
            )}`,
            isError: true,
          });
          this.setState({ fetching: false });
        });
    } catch (e) {
      this.setState({ fetching: false });
      console.log(e);
    }
  };

  moveFile = (e, path, name) => {
    this.setState({ path, moveFile: true, fileName: name });
  };

  download = async (path) => {
    const location = this.props.selectedPrimary;
    let url = new URL(DOCUMENTS_GET_URL);
    url.searchParams.append("path", path);
    url.searchParams.append("location_id", location.node.id);
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "blob", // important
    }).then(function (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      if(response.data.type.includes("/pdf")){
        link.setAttribute("download", "file.pdf");
        link.click();
      }
      if(response.data.type.includes("/doc")){
        link.setAttribute("download", "file.doc");
        link.click();
      }
      else  {
        link.setAttribute("download", "file.png");
        link.click();
      }
    });
  };

  view = async (path) => {
    const location = this.props.selectedPrimary;
    let url = new URL(DOCUMENTS_GET_URL);
    url.searchParams.append("path", path);
    url.searchParams.append("location_id", location.node.id);
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "blob", // important
    }).then(async (response) => {
      const blob = new Blob([response.data]);
      const blobFile = window.URL.createObjectURL(new Blob([response.data]));
      const fileType = await FileType.fromBlob(blob);
      this.setState({ file: blobFile, fileType: fileType });
    });
  };

  sendFile = (e) => {
    e.preventDefault();
  };

  parseFileName = (fullname) => {
    let output = "";
    for (let i = fullname.length - 1; i >= 0; i--) {
      if (fullname[i] !== "/") output = fullname[i] + output;
      else break;
    }
    return output;
  };

  closeMessage = () => {
    this.setState({ messageResponse: "" });
  };

  folderSelect = (folder, name, id) => {
    const { documents } = this.state;
    const activeDocuments = [];
    documents.forEach((document) => {
      if (document.path.includes(folder)) {
        activeDocuments.push(document);
      }
    });
    this.setState({ activeDocuments: activeDocuments });
  };

  showImages = (files) => {
    this.setState({ activeDocuments: files });
  };

  render() {
    const { folders, activeDocuments } = this.state;

    const Level1Content = (
      <div>
        {folders.Maintenance &&
          Object.entries(folders.Maintenance).map(([value, i]) => {
            const inf = [
              Object.entries(i).map(([name, files]) => {
                return (
                  <p
                    className="file-manager-item-para"
                    onClick={() => this.showImages(files)}
                  >
                    <span> - {name}</span>
                  </p>
                );
              }),
            ];
            const level1Panels = [
              { key: "panel-1a", title: value, content: inf },
            ];
            return (
              <div key={value} className="report">
                <Accordion.Accordion info={i} panels={level1Panels} />
              </div>
            );
          })}
      </div>
    );

    const Level2Content = (
      <div>
        {folders.Units &&
          Object.entries(folders.Units).map(([value, i]) => {
            console.log(value, i)
            return (
              <div key={value} className="report">
                <p>{value}</p>
                {Object.entries(i).map(([name, files]) => {
                  console.log(name, files)
                  if(name== "Signed"){
                    return (
                      <p onClick={() => this.showImages(files)}> - {files[Object.keys(files)[0]]}</p>
                    );
                  }
                })}
              </div>
            );
          })}
      </div>
    );
    const Level3Content = (
      <div>
        {folders.Other &&
          Object.entries(folders.Other).map(([value, i]) => {
            return (
              <div key={value} className="report">
                <p>{value}</p>
                {Object.entries(i).map(([name, files]) => {
                  return (
                    <p onClick={() => this.showImages(files)}> - {name}</p>
                  );
                })}
              </div>
            );
          })}
      </div>
    );

    const rootPanels = [
      // {
      //   key: "panel-1",
      //   title: "Maintenance",
      //   content: { content: Level1Content },
      // },
      { key: "panel-2", title: "Leases", content: { content: Level2Content } },
      // { key: "panel-3", title: "Other", content: { content: Level3Content } },
    ];

    return (
      this.state.loading ? (
        <PageLoader text />
      ) : (
      <>
        <Header className="fileManager-first-step" as="h2">File Manager <Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} /></Header>

        <Grid columns="2">
          <Grid.Column className="fileManager-second-step">
            <Segment>
              {folders && (
                <Accordion defaultActiveIndex={0} panels={rootPanels} styled />
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column className="fileManager-third-step">
            <Segment>
              {activeDocuments &&
                Object.entries(activeDocuments).map(
                  ([test, activeDocument]) => {
                    return (
                      <>
                        <div className="col-3 mb-3" key={Math.random()}>
                          <div className="card w-60 file-card">
                            <div className="card-body p-0">
                              <div>
                                <img
                                  className="file-icon"
                                  src={`assets/icons/pptIcon.png`}
                                  alt="file icon"
                                  width="40"
                                />
                                <div className="data-range dropdown">
                                  {" "}
                                  <a
                                    href="javascipt:void(0);"
                                    className="dropdown-toggle file-dropdown-toggle"
                                    id="navbar-dropdown-sales-overview-header-button"
                                    data-toggle="dropdown"
                                    download
                                    data-flip="false"
                                    title={activeDocument}
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></a>{" "}
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="navbar-dropdown-sales-overview-header-button"
                                  >
                                    <span
                                      className="dropdown-item"
                                      onClick={() => this.view(activeDocument)}
                                    >
                                      View
                                    </span>

                                    <a
                                      type="file"
                                      id="file"
                                      ref="fileUploader"
                                      download
                                      className="dropdown-item"
                                      onClick={() =>
                                        this.download(activeDocument)
                                      }
                                    >
                                      Download
                                    </a>
                                  </div>
                                  <div className="card-title overflow-hidden text-truncate file-name">
                                    {activeDocument}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
            </Segment>
            {this.state.file && (
              <Modal
                className="semanticModal file-viewer-modal"
                open={this.state.file}
              >
                <Modal.Content>
                  <FileViewer
                    fileType={this.state.fileType.ext}
                    filePath={this.state.file}
                    onError={this.onError}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    onClick={() => this.setState({ file: null })}
                    positive
                  >
                    Ok
                  </Button>
                </Modal.Actions>
              </Modal>
            )}
          </Grid.Column>
        </Grid>
        <Tour
          steps={fileManagerSteps}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
          className="helper"
          rounded={5}
          startAt={0}
        />
      </>
    ));
  }
}

export default withApollo(FileManager);
