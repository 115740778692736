import React, { Component } from "react";
import {
  Form,
  Button,
  Modal,
  TextArea,
  Header,
  Select,
} from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { INVOICE_ACCOUNTING_PAYMENT, ACCOUNTING } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import Datetime from "react-datetime";
import { LEASE_TIME_FORMAT } from "../../utils";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { paySlInvoice } from "../../store/person/accounting";
import {
  approveSLBillMutation,
  LedgerAccounting,
  paySLBillMutation,
  deleteSLBillMutation,
  voidSLBillMutation,
  DeleteInvoiceMutation,
  VoidInvoiceMutation,
  IssueInvoiceMutation,
} from "../../store/person/accounting";
import "react-toastify/dist/ReactToastify.css";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
const paymentClient = getClient(INVOICE_ACCOUNTING_PAYMENT);
const accountingClient = getClient(ACCOUNTING);
class PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      ledgerState: [],
      usedLedger: "",
      postingDate: "",
      paymentDate: "",
      amount: "",
      loading: false,
      memo: "",
    };
  }
  // Handles the closing of the modal
  handleClose = () =>
    this.setState({
      modalOpen: false,
    });

  // Approves the bill
  activate = (item) => {
    this.setState({ loading: true });
    try {
      if (this.props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Issue Bill",
        });
      }
      if (!this.props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Issue Invoice",
        });
      }
      paymentClient
        .mutate({
          mutation: this.props.bills
            ? approveSLBillMutation
            : IssueInvoiceMutation,
          variables: this.props.bills
            ? {
              input: {
                BillId: this.props.bill._id,
                location: this.props.location.selectedPrimary.node.id,
              },
            }
            : {
              input: {
                id: this.props.bill._id,
                location: this.props.location.selectedPrimary.node.id,
              },
            },
        })
        .then((response) => {
          toastSuccessMsg("Action Approved");
          this.props.bills
            ? this.props.getLedger()
            : this.props.location.getLedger();
          this.setState({ loading: false });
          this.handleClose();
        })
        .catch((error) => {
          this.setState({ loading: false });
          toastFailMsg("This is unable to be approved:" + error);
        });
    } catch (e) {
      this.setState({ loading: false });
      alert(e);
    }
  };

  // Voids the bill
  void = (item) => {
    this.setState({ loading: true });
    try {
      if (this.props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Void Bill",
        });
      }
      if (!this.props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Void Invoice",
        });
      }
      paymentClient
        .mutate({
          mutation: this.props.bills ? voidSLBillMutation : VoidInvoiceMutation,
          variables: this.props.bills
            ? {
              input: {
                BillId: this.props.bill._id,
                location: this.props.location.selectedPrimary.node.id,
              },
            }
            : {
              input: {
                location: this.props.location.selectedPrimary.node.id,
                id: this.props.bill._id,
              },
            },
        })
        .then((response) => {
          this.setState({ loading: false });
          if (
            response.data.voidSlBill.response ===
            'b\'{"error":"Bill not found"}\''
          ) {
            toastFailMsg(
              "In order to void bill please void all payments within the bill first."
            );
          } else if (
            response.data.voidSlBill.response ===
            'b\'{"error":"Cannot void, linked payments"}\''
          ) {
            toastFailMsg(
              "In order to void bill please void all linked payments within the bill first."
            );
          } else if (
            response.data.voidSlBill.response !==
            'b\'{"error":"Cannot void, linked payments"}\'' ||
            response.data.voidSlBill.response !==
            'b\'{"error":"Bill not found"}\''
          ) {
            toastSuccessMsg("Bill Voided");
            this.props.location.getLedger();
            this.handleClose();
          } else {
            return;
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          toastFailMsg("This is unable to be voided:" + error);
        });
    } catch (e) {
      this.setState({ loading: false });
      alert(e);
    }
  };

  // Deletes the bill
  delete = (item) => {
    this.setState({ loading: true });
    try {
      if (this.props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Delete Bill",
        });
      }
      if (!this.props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Delete Invoice",
        });
      }
      paymentClient
        .mutate({
          mutation: this.props.bills
            ? deleteSLBillMutation
            : DeleteInvoiceMutation,
          variables: this.props.bills
            ? {
              input: {
                BillId: this.props.bill._id,
                location: this.props.location.selectedPrimary.node.id,
              },
            }
            : {
              input: {
                location: this.props.location.selectedPrimary.node.id,
                id: this.props.bill._id,
              },
            },
        })
        .then((response) => {
          toastSuccessMsg("Successfully Deleted");
          this.props.getLedger();
          this.setState({ loading: false });
          this.handleClose();
        })
        .catch((error) => {
          this.setState({ loading: false });
          toastFailMsg("This is unable to be deleted:" + error);
        });
    } catch (e) {
      this.setState({ loading: false });
      alert(e);
    }
  };

  // Clears the existing state and gets the available Ledgers
  getLedgerAccount = () => {
    this.setState({
      paymentDate: "",
      memo: "",
      postingDate: "",
      amount: this.props.bill.amount?this.props.bill.amount:"",
      usedLedger: "",
    });
    console.log(this.props)
    if (this.state.ledgerState.length === 0) {
      try {
        accountingClient
          .query({
            query: LedgerAccounting,
            variables: {
              location: this.props.location.selectedPrimary.node.id,
              offset: 0,
              limit: 1000,
            },
          })
          .then((res) => {
            const ledger = res.data.slLocationLedgerAccounts;
            const LedgerArray = ledger.data.map((ledger) => ({
              key: ledger.number,
              text: ledger.name,
              value: ledger._id,
            }));
            this.setState({
              ledgerState: LedgerArray,
            });
            this.setState({
              usedLedger: LedgerArray[0],
            });
          })
          .catch((error) => {
            toastFailMsg("Unable to gather Ledgers");
          });
      } catch (e) {
        alert(e);
      }
    }
    else {
      this.setState({
        usedLedger: this.state.ledgerState[0],
      });
    }
  };

  // Pays the bill
  Payment = () => {
    this.setState({ loading: true });
    try {
      if (this.props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Pay Bill",
        });
      }
      if (!this.props.bills) {
        mixpanel.track("Manager Accounting Action", {
          sub: "Pay Invoice",
        });
      }
      paymentClient
        .mutate({
          mutation: this.props.bills ? paySLBillMutation : paySlInvoice,
          variables: this.props.bills
            ? {
              input: {
                location: this.props.location.selectedPrimary.node.id,
                BillId: this.props.bill._id,
                type: "manual",
                amount: this.state.amount,
                billAmount: this.props.bill.amount,
                currency: this.props.bill.currency,
                dueAmount: this.props.bill.dueAmount,
                number: this.props.bill.invoiceNumber,
                paymentDate: moment(this.state.paymentDate).format(
                  "YYYY-MM-DD"
                ),
                postedDate: moment(this.state.postingDate).format(
                  "YYYY-MM-DD"
                ),
                memo: this.state.memo,
                LedgerAccountId: this.state.usedLedger,
                vendor:
                  this.props.bill.Vendor.id +
                  " - " +
                  this.props.bill.Vendor.name,
                LocationId: this.props.bill.LocationId,
              },
            }
            : {
              input: {
                AgentId: this.props.bill.AgentId,
                InvoiceId: this.props.bill._id,
                location: this.props.location.selectedPrimary.node.id,
                amount: this.state.amount,
                paymentDate: moment(this.state.paymentDate).format(
                  "YYYY-MM-DD"
                ),
                LedgerAccountId: this.state.usedLedger,
                LocationId: this.props.bill.LocationId,
              },
            },
        })
        .then((response) => {
          toastSuccessMsg("Action Success");
          this.props.bills
            ? this.props.getLedger()
            : this.props.updateInvoice();
          this.setState({ loading: false });
          this.handleClose();
        })
        .catch((error) => {
          this.setState({ loading: false });
          toastFailMsg("This is unable to be paid:" + error);
        });
    } catch (e) {
      this.setState({ loading: false });
      alert(e);
    }
  };

  // Sets the date for payment and posting
  setDate = (e, field) => {
    const dateRes = moment(e).format(LEASE_TIME_FORMAT);
    if (field === "paymentDate") {
      this.setState({
        paymentDate: dateRes,
      });
    }
    if (field === "postingDate") {
      this.setState({
        postingDate: dateRes,
      });
    }
  };

  // Sets primary ledger
  changeLedger = (e, { value }) => {
    this.setState({
      usedLedger: value,
    });
  };


  // Sets the amount on the bill
  amountSet = (e) => {
    this.setState({
      amount: e,
    });
  };

  // Sets the memo on the bill
  memoSet = (e) => {
    this.setState({
      memo: e,
    });
  };
  validation = (currentDate) => {
    return currentDate.isBefore(moment());
  };

  // Dropdown handler
  handleChange = (e, { value }) =>
    this.setState({
      value,
    });

  render() {
    return (
      <>
        <Modal
          className="semanticModal add-lead-modal"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size={this.props.bill.status === "created" ? "tiny" : "small"}
          trigger={
            <Button
              compact
              className="ml-3 button-hover-universal"
              id="getLedger"
              style={{ background: "#5e6267", color: "white" }}
              size="tiny"
              icon="edit"
              onClick={() => this.getLedgerAccount()}
            >
            </Button>
          }
        >
          <Modal.Header textalign="center" className="modal-header-bg">
            <Header
              as="h2"
              style={{ color: "white" }}
              className="modal-heading-custom"
              textAlign="left"
            >
              {this.props.bills ? "Bill" : "Invoice"} Action
            </Header>
          </Modal.Header>
          {this.props.bill.status === "created" ? (
            <>
              <Header as="p" textAlign="center">
                This {this.props.bills ? "bill" : "invoice"} must be{" "}
                {this.props.bills ? "approved" : "issued"} before you can take
                further action.
              </Header>
              <Button.Group floated="right" className="billactiongroup">
                {this.props.bills ? (
                  <Button
                    className="billactionapprove"
                    disabled={this.state.loading}
                    onClick={() => this.activate()}
                  >
                    {this.state.loading ? "Processing..." : "Approve"}
                  </Button>
                ) : (
                  <Button
                    className="billactionapprove"
                    disabled={this.state.loading}
                    onClick={() => this.activate()}
                  >
                    {this.state.loading ? "Processing..." : "Issue"}
                  </Button>
                )}
                <Button.Group floated="left" className="billactiongroup">
                  <Button
                    className="billactiondelete"
                    disabled={this.state.loading}
                    onClick={() => this.delete()}
                  >
                    Delete
                  </Button>
                </Button.Group>
              </Button.Group>
            </>
          ) : (
            <>
              <Modal.Content>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field required>
                      <label>Payment Date</label>
                      <Datetime
                        timeFormat={false}
                        className="bills-times"
                        style={{ width: "50%" }}
                        isValidDate={this.validation}
                        closeOnSelect
                        inputProps={{
                          readOnly: true,
                          placeholder: "MM/DD/YYYY",
                        }}
                        onChange={(e) => this.setDate(e, "paymentDate")}
                      />
                    </Form.Field>
                    {this.props.bills && (
                      <Form.Field required>
                        <label>Posting Date</label>
                        <Datetime
                          timeFormat={false}
                          className="bills-times"
                          minDate={moment().toDate()}
                          closeOnSelect
                          inputProps={{
                            readOnly: true,
                            placeholder: "MM/DD/YYYY",
                          }}
                          onChange={(e) => this.setDate(e, "postingDate")}
                        />
                      </Form.Field>
                    )}
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field className="bills-times" required>
                      <label>Amount</label>
                      <input
                        type="number"
                        placeholder="Amount"
                        value={this.state.amount}
                        onChange={(e) => this.amountSet(e.target.value)}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      className="select-ledgers"
                      control={Select}
                      label="Ledger"
                      options={this.state.ledgerState}
                      placeholder={this.state.usedLedger.text}
                      value={this.state.usedLedger.value}
                      onChange={this.changeLedger}
                    />
                  </Form.Group>

                  <Form.Field
                    style={{ minHeight: 130 }}
                    control={TextArea}
                    label="Memo"
                    placeholder="Memo"
                    onChange={(e) => this.memoSet(e.target.value)}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button.Group floated="right" className="billactiongroup">
                  <Button
                    className="billactionapprove"
                    disabled={
                      this.state.amount === "" ||
                      this.state.usedLedger === "" ||
                      this.state.loading ||
                      (this.props.bills && this.state.postingDate === "") ||
                      this.state.paymentDate === ""
                    }
                    onClick={() => this.Payment()}
                  >
                    Submit
                  </Button>
                </Button.Group>
              </Modal.Actions>
            </>
          )}
        </Modal>
      </>
    );
  }
}
export default withApollo(PaymentModal);
