import gql from "graphql-tag";

export const unreadPersonNotificationsGql = gql`
  query UnreadPersonNotifications {
    personNotifications(read: false) {
      edges {
        node {
          ndbId
          message
          created
          read
        }
      }
    }
  }
`;

export const allPersonNotificationsGql = gql`
  query AllPersonNotifications {
    personNotifications {
      edges {
        node {
          ndbId
          message
          created
          read
        }
      }
    }
  }
`;

export const updatePersonNotificationsGql = gql`
  mutation UpdatePersonNotifications($input: UpdatePersonNotificationInput!) {
    updatePersonNotification(input: $input) {
      response
    }
  }
`;

export const maintenanceMetricsGql = gql`
  query maintenanceMetrics($location: String!) {
    maintenanceMetrics(location: $location)
  }
`;
export const leadMetricsGql = gql`
  query leadMetrics($location: String!) {
    leadMetrics(location: $location)
  }
`;
export const applicationMetricsGql = gql`
  query applicationMetrics($location: String!) {
    applicationMetrics(location: $location)
  }
`;

export const sendNotificationGql = gql`
  mutation sendNotification(
    $title: String!
    $body: String!
    $locationId: String
    $image: String
    $url: String
    $platform: Platforms
  ) {
    sendNotification(
      title: $title
      body: $body
      image: $image
      url: $url
      locationId: $locationId
      platform: $platform
    )
  }
`;
