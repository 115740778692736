import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import get from "lodash/get";
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import "./Settings.scss";
import { PageLoader } from "../../components/Loader/PageLoader";
import TourCreation from "../../components/Modals/TourCreationModal";
import BucketExpansion from "../../components/Modals/BucketCreationModal";
import { Header, Card, Segment, Icon } from "semantic-ui-react";
import { Link, NavLink, withRouter, matchPath } from "react-router-dom";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      primaryLocations: [],
      loading: false,
      isTourOpen: false,
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      allApps: [],
      isFirstRender: true,
    };
  }

  componentDidMount() {
    qpDatatables();
    qpTooltipPopover();
    mixpanel.track("Manager Page Load", {
      sub: "Settings",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {});
    }
  }
  setLocation() {
    this.setState({ primary: this.props.selectedPrimary }, () => {});
  }

  componentWillUnmount() {
    this.setState({
      isFirstRender: true,
    });
  }

  setTitle = (data) => {
    if (data) {
      const prime = get(data, "data.primaryLocations.edges", null);
      this.setState({
        primaryLocations: prime,
        allApps: prime,
        isFirstRender: false,
      });
    }
  };

  handleChange(e) {
    let currentList = [];
    let newList = [];
    // If the search bar isn't empty
    if (e.target.value !== "") {
      // Assign the original list to currentList
      currentList = this.state.allApps;
      newList = currentList.filter((item) => {
        const lc = item.node.property.location.name.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      newList = this.state.allApps;
    }
    this.setState({
      primaryLocations: newList,
    });
  }

  redirectToLeases(id) {
    this.props.history.push(`/leases/${id}`);
  }

  redirectToProperty() {
    this.props.history.push({
      pathname:
        "/manageLocation/location=" + this.props.selectedPrimary.node.customId,
    });
  }

  render() {
    const { loading, primary } = this.state;
    return (
      <>
        {!this.props.selectedPrimary.node ? (
          <PageLoader text />
        ) : (
          <>
            <Header className="settings-first-step">
              Property Settings for {primary.name}{" "}
              <Icon
                size="small"
                circular
                name="lightbulb"
                onClick={() => this.setState({ isTourOpen: true })}
              />
            </Header>
            <Link to="/settings/tourManagement">
              <div className="settings-outerCard">
                <div className="settings-cardText">
                  <span className="settings-cardTitle">Tour Management</span>
                  <br></br>
                  <span className="settings-cardDescription">
                    View/manage your tour types
                  </span>
                  <img
                    className="settings-cardArrow"
                    src="/assets/img/right-arrow.svg"
                    alt="arrow"
                  ></img>
                </div>
              </div>
            </Link>
            {/* <TourCreation primary={primary} /> */}
            {/* <BucketExpansion primary={primary} /> */}
            {/* </Card.Group> */}
          </>
        )}
      </>
    );
  }
}

export default withApollo(Settings);