import React from "react";
import { Label, Button } from "semantic-ui-react"
import moment from "moment";
import mixpanel from "mixpanel-browser";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import { leaseDocuments } from "../../store/person/leases";
import { leaseSign } from "../../store/bluemoon/bluemoon";
import { getClient } from "../../store/auth";
import {
  ROUTES,
  BLUEMOON_API,
  LEASE_MANAGER,
} from "../../utils/constants";
const leaseManager = getClient(LEASE_MANAGER);
const bluemoonApi = getClient(BLUEMOON_API);



//* Start Leases Table
const SetSignType = (sign) => {
  if (sign.type) {
    if (sign.type === "bluemoon") {
      return (
        <Label
          size="mini"
          style={{
            background: "#A2CDCD",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Bluemoon
        </Label>
      );
    }
    if (sign.type === "docusign") {
      return (
        <Label
          size="mini"
          style={{
            background: "#A2CDCD",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          DocuSign
        </Label>
      );
    } else {
      return (
        <Label
          size="mini"
          style={{
            background: "grey",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          None
        </Label>
      );
    }
  } else {
    return "";
  }
};

const SetSign = (sign) => {
  if (sign) {
    if (sign.status === "initial") {
      return (
        <Label
          size="mini"
          style={{
            background: "#A2CDCD",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Initial
        </Label>
      );
    }
    if (sign.status === "signed") {
      return (
        <Label
          size="mini"
          style={{
            background: "#A2CDCD",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Signed
        </Label>
      );
    }
    if (sign.status === "in_progress") {
      return (
        <Label
          size="mini"
          style={{
            background: "#A2CDCD",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          In Progress
        </Label>
      );
    }
    if (sign.status === "completed") {
      return (
        <Label
          size="mini"
          style={{
            background: "#C6D57E",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Complete
        </Label>
      );
    }
    if (sign.status === "expired") {
      return (
        <Label
          size="mini"
          style={{
            background: "#D57E7E",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Expired
        </Label>
      );
    }
    if (sign.status === "delivered") {
      return (
        <Label
          size="mini"
          style={{
            background: "#A2CDCD",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Delivered
        </Label>
      );
    }
    if (sign.status === "declined") {
      return (
        <Label
          size="mini"
          style={{
            background: "#D57E7E",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Declined
        </Label>
      );
    }
    if (sign.status === "completed") {
      return (
        <Label
          size="mini"
          style={{
            background: "#C6D57E",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Complete
        </Label>
      );
    }
    if (sign.status === "partial") {
      return (
        <Label
          size="mini"
          style={{
            background: "#FFE1AF",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Partial
        </Label>
      );
    } else {
      return (
        <Label
          size="mini"
          style={{
            background: "grey",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          Not Started
        </Label>
      );
    }
  } else {
    return (
      <Label
        size="mini"
        style={{
          background: "grey",
          color: "white",
          width: "100%",
          textAlign: "center",
        }}
      >
        Not Started
      </Label>
    );
  }
};

const signLease = (item) => {
  mixpanel.track("Manager Lease Action", {
    sub: "Send Email to Sign Lease",
  });
  leaseManager
    .mutate({
      mutation: leaseDocuments,
      variables: {
        input: {
          lease: {
            leaseId: item.node.id,
          },
        },
      },
    })
    .then(() => {
      toastSuccessMsg("Email has been sent, the lease signing has begun.");
    })
    .catch(() => {
      toastFailMsg("Failed to sign.");
    });
};

const signHere = (item) => {
  leaseManager
    .mutate({
      mutation: leaseDocuments,
      variables: {
        input: {
          lease: {
            leaseId: item.node.id,
          },
        },
      },
    })
    .then(() => {
      toastSuccessMsg("Email has been sent, the lease signing has begun.");
    })
    .catch(() => {
      toastFailMsg("Failed to sign.");
    });
};

const finalizeLease = (item) => {
  mixpanel.track("Manager Lease Action", {
    sub: "Finalize Lease",
  });
  bluemoonApi
    .mutate({
      mutation: leaseSign,
      variables: {
        input: {
          leaseId: item.node.id,
        },
      },
    })
    .then(() => {
      toastSuccessMsg("Lease Signing Finalized.");
    })
    .catch(() => {
      toastFailMsg("Failed to sign.");
    });
};

const FormatSign = (item) => {
  if (
    item.node.lgCompleted === true &&
    item.node.insuranceCompleted === true
  ) {
    if (item.node.extLeaseInfo) {
      if (!item.node.extLeaseInfo.status) {
        return (
          <Button
            positive
            onClick={() => signLease(item)}
            style={{
              fontSize: ".8rem",
              padding: "5px 10px",
              textTransform: "capitalize",
              height: "auto",
            }}
          >
            Begin Signing
          </Button>
        );
      }
      if (item.node.extLeaseInfo.status) {
        if (item.node.extLeaseInfo.status === "signed") {
          return (
            <Button
              style={{
                fontSize: ".8rem",
                padding: "5px 10px",
                textTransform: "capitalize",
                height: "auto",
              }}
              positive
              onClick={() => finalizeLease(item)}
            >
              Finalize Lease
            </Button>
          );
        }
        if (item.node.extLeaseInfo.status === "partial") {
          return (
            <Button
              positive
              onClick={() => signHere(item)}
              style={{
                fontSize: ".8rem",
                padding: "5px 10px",
                textTransform: "capitalize",
                height: "auto",
              }}
            >
              Sign Here
            </Button>
          );
        }
        if (
          item.node.extLeaseInfo.status === "initial" ||
          item.node.extLeaseInfo.status === "expired" ||
          item.node.extLeaseInfo.status === "sent"
        ) {
          return (
            <Button
              positive
              onClick={() => signLease(item)}
              style={{
                fontSize: ".8rem",
                padding: "5px 10px",
                textTransform: "capitalize",
                height: "auto",
              }}
            >
              Begin Signing
            </Button>
          );
        } else {
          return "";
        }
      }
    } else return "";
  }
}

export const FormatLeases = (lease, viewDetails, props) => {
  return () => lease.map((obj) => {
    const { status, person, amount, leaseFrequency, startDate, endDate, extLeaseInfo, id } = obj.node;
    const { number } = obj.node.unit;
    const uiStatus = `${status[0].toUpperCase()}${status.slice(1, status.length)}`;
    const firstName = person.firstName;
    const lastName = person.lastName;
    const uiAmount = amount.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    const uiStartDate = moment(startDate).format('MM/DD/YYYY');
    const uiEndDate = moment(endDate).format('MM/DD/YYYY');
    const uiSignStatus = SetSign(extLeaseInfo);
    const uiSignType = extLeaseInfo ? SetSignType(extLeaseInfo) : "";
    const uiSelect = "[]"
    const view = <Button
      className="dark-button"
      icon="eye"
      size="mini"
      onClick={() => viewDetails(obj)}
    />
    const edit = <Button
      size="mini"
      icon="edit"
      className="dark-button"
      disabled={status !== "created" && status !== 'renewal'}
      onClick={() =>
        props.history.push(ROUTES.editLease.replace(":leaseId", id))
      }
    />
    const uiSign = FormatSign(obj);
    return { uiSelect, uiStatus, firstName, lastName, uiAmount, leaseFrequency, number, uiStartDate, uiEndDate, uiSignStatus, uiSignType, view, edit, uiSign };
  })
}

