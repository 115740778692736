import gql from "graphql-tag";

  export const AddGoogleToken = gql`
  mutation addGoogleToken($input: AddGoogleTokenInput!) {
    addGoogleToken(package: $input) {
      response
    }
  }
`;

export const GetIntegrations = gql`
  query getIntegrations($personId: String!) {
    getIntegrations(personId: $personId) {
      edges {
        node {
        id
        personId
        locationId
        accessToken
        refreshToken
        createdAt
        updatedAt
        }
      }
    }
  }
`;

export const DeleteGoogleToken = gql`
mutation deleteGoogleToken($input: DeleteGoogleTokenInput!) {
    deleteGoogleToken(package: $input) {
    response
  }
}
`;