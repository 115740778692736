import React, { useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import { Button, Header, Icon, Label, Menu, Table } from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { toast } from "react-toastify";
import { FinancialReport } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import Datetime from "react-datetime";
import _ from "lodash";
import { toastSuccessMsg,toastFailMsg } from "../../utils/common";
import { Loader } from "../../components/Loader/Loader";
import StripedBalanceTable from "../../components/Tables/NewBalanceTable";
import { ACCOUNTING, ACCOUNTING_REPORTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";

const ledgerClient = getClient(ACCOUNTING);
const balanceClient = getClient(ACCOUNTING_REPORTING);

const ProfitLoss = (props) => {
  const [primary, setPrimary] = useState(
    props.selectedPrimary ? props.selectedPrimary.node : ""
  );
  const [loading, setLoading] = useState(false);
  const [totalBalance, setTotalBalance] = useState({});
  const [ledgerCache, setLedgerCache] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [singleLedger, setSingleLedger] = useState([]);
  const [ledgerCacheStable, setLedgerCacheStable] = useState({});
  const [loadingCompute, setLoadingCompute] = useState(false);
  const [offset, setOffset] = useState(0);
  const [view, setView] = useState(false);

  useEffect(() => {
    mixpanel.track("Manager Page Load", {
      sub: "Ledger",
    });
  }, []);

  useEffect(() => {
    getBalance();
  }, [props.selectedPrimary]);

  const getBalance = () => {
    setLoading(true)
    balanceClient
      .query({
        query: FinancialReport,
        variables: {
          startDate: startDate,
          endDate: endDate,
          slLocationId: props?.selectedPrimary?.node?.slLocationId,
        },
      })
      .then((res) => {
        const FinanceValues = res.data.financialReport;
        sortObjectsByType(FinanceValues);
        toastSuccessMsg("Generated")
      })
      .catch((error) => {
        toastFailMsg("Something went wrong")
      }).finally(() => {
        setLoading(false);
      });
  };
  const sortObjectsByType = (objects) => {
    const sortedArrays = {};
    for (const object of objects) {
      const objectType = object.type;
      if (objectType !== "Revenue" && objectType !== "Expense") {
        continue;
      }
      if (!sortedArrays[objectType]) {
        sortedArrays[objectType] = [];
      }
      sortedArrays[objectType].push(object);
    }
    return setLedgerCacheStable(sortedArrays);
  };

  const handleStartClick = (date) => {
    const startDate = moment(date).format("YYYY-MM-DD");
    setStartDate(startDate);
  };

  const handleEndClick = (date) => {
    const endDate = moment(date).format("YYYY-MM-DD");
    setEndDate(endDate);
  };

  const computeAmounts = (amount) => {
    const convertToNumber = parseFloat(amount);
    const absAmount = Math.abs(convertToNumber).toLocaleString("en");
    const formattedAmount = `$ ${absAmount}`;
    return convertToNumber < 0 ? (
      <span>(${formattedAmount})</span>
    ) : (
      <span>{formattedAmount}</span>
    );
  };

  return (
    <>
      <main className="main-content balance-sheet-layout" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title pl-0">Profit/Loss</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <div className="ml-3">
                  <Header textAlign="center">Start Date</Header>
                  <Datetime
                    inputProps={{ placeholder: "Select Start Date" }}
                    defaultValue={moment().startOf("month")}
                    timeFormat={false}
                    onChange={handleStartClick}
                  />
                </div>
                <div className="ml-3">
                  <Header textAlign="center">End Date</Header>
                  <Datetime
                    inputProps={{ placeholder: "Select End Date" }}
                    defaultValue={moment().endOf("month")}
                    timeFormat={false}
                    onChange={handleEndClick}
                  />
                </div>
              </div>
              <div className="d-flex align-items-end mt-3 mt-md-0">
                <Button
                  disabled={loadingCompute}
                  compact
                  onClick={() => getBalance()}
                  className="ml-0 ml-md-3 button-hover-universal"
                >
                  {loadingCompute ? (
                    <span>
                      Processing...
                      <Button
                        style={{ paddingBottom: "0px" }}
                        compact
                        loading
                      ></Button>
                    </span>
                  ) : (
                    <span>Generate</span>
                  )}
                </Button>
                <Button
                  onClick={() => window.print()}
                  compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <>
            <div className="balance-sheet-simplified">
              <Table compact="very" celled>
                <Table.Header></Table.Header>

                <Table.Body>
                  {Object.entries(ledgerCacheStable).map(
                    ([header, objects]) => (
                      <React.Fragment key={header}>
                        <Table.Row>
                          <Table.HeaderCell colSpan="4">
                            {header}
                          </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                          <Table.HeaderCell>Id</Table.HeaderCell>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Subtype</Table.HeaderCell>
                          <Table.HeaderCell>Balance</Table.HeaderCell>
                        </Table.Row>
                        {objects.map((object) => (
                          <Table.Row key={object._id}>
                            <Table.Cell>{object._id}</Table.Cell>
                            <Table.Cell>{object.name}</Table.Cell>
                            <Table.Cell>{object.subtype}</Table.Cell>
                            <Table.Cell>
                              $
                              {object.balance < 0
                                ? `(${-object.balance})`
                                : object.balance > 0
                                ? `${object.balance}`
                                : 0}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </Table.Body>
              </Table>
            </div>
          </>
        )}
      </main>
    </>
  );
};

export default withApollo(ProfitLoss);
