import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { trialGql, LedgerAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import { Button, Grid, Dropdown, Header } from "semantic-ui-react";
import { Table } from "../../components/Tables/TrialData";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import "react-datetime/css/react-datetime.css";
import { Loader } from "../../components/Loader/Loader";
import { TypeNameMetaFieldDef } from "graphql";
import moment from "moment";
const invoiceClient = getClient(ACCOUNTING);

class TrialReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      trialsCache: [],
      columnLimit: [],
      dateType: "Monthly",
      numType: "1",
      reportType: "posted",
    };
  }

  componentDidMount() {
    mixpanel.track("Manager Page Load", {
      sub: "Trial Report",
    });
  }

  getLedgerMatch = (trialsCache) => {
    invoiceClient
      .query({
        query: LedgerAccounting,
        variables: {
          location: this.props.selectedPrimary.node.id,
          offset: 0,
          limit: 1000,
        },
      })
      .then((res) => {
        const arrayConv = [];
        const ledgerArr = res.data.slLocationLedgerAccounts.data
        Object.entries(trialsCache).forEach((file) => {
          ledgerArr.map(ledger => {
            if (file[0] == ledger._id) {
              const newObj = {
                name: ledger.number,
                data: file[1],
              };
              arrayConv.push(newObj);
            }
          })
        });
        this.setState({ trialsCache: arrayConv });
        this.setState({
          loading: false,
        });

      })
      .catch((error) => {

      });
  };

  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState(
        {
          primary: nodeData,
        },
        () => {
          this.setState({
            selectedMonthEvents: {
              ...this.state.selectedMonthEvents,
              montEvents: [],
            },
          });
        }
      );
    }
  }

  getLedger = () => {
    this.getColumnLimit();
    try {
      this.setState({
        loading: true,
      });
      invoiceClient
        .query({
          query: trialGql,
          variables: {
            location: this.props.selectedPrimary.node.id,
            dateType: this.state.dateType,
            numType: Number(this.state.numType),
            journalStatus: this.state.reportType,
          },
        })
        .then((res) => {

          const trialsCache = res.data.slLocationTrialBalance;
          this.getColumnLimit(trialsCache);
          this.getLedgerMatch(trialsCache)

        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
        });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  };
  getColumnLimit = (Cache) => {
    const newArr = [
      `data[${moment().subtract(11, "months").format("M")}]`,
      `data[${moment().subtract(10, "months").format("M")}]`,
      `data[${moment().subtract(9, "months").format("M")}]`,
      `data[${moment().subtract(8, "months").format("M")}]`,
      `data[${moment().subtract(7, "months").format("M")}]`,
      `data[${moment().subtract(6, "months").format("M")}]`,
      `data[${moment().subtract(5, "months").format("M")}]`,
      `data[${moment().subtract(4, "months").format("M")}]`,
      `data[${moment().subtract(3, "months").format("M")}]`,
      `data[${moment().subtract(2, "months").format("M")}]`,
      `data[${moment().subtract(1, "months").format("M")}]`,
      `data[${moment().format("M")}]`,
    ]
    const convertObj = Cache && Object.values(Cache)[0]
    var result = convertObj && Object.keys(convertObj).map((key) => Number(key));
    const finalResult = result && result.map(item => {
      return (
        `data[${item}]`
      )
    })
    const filterFinal = finalResult && newArr.filter(val => !finalResult.includes(val));
    this.setState({ columnLimit: filterFinal });
  };
  dataPush = (response) => {
    this.setState((prevState) => ({
      financialsCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.financialsCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { financialsCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        financialsCache: financialsCache.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      financialsCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({
        financialsCache: array,
      });
    }
  };

  handleDateClick = (event, { value }) => {
    this.setState({ dateType: value });
  };
  handleNumberClick = (event, { value }) => {
    this.setState({ numType: value });
  };

  handleReportChange = (event, { value }) => {
    this.setState({ reportType: value });
  };

  render() {
    const { loading, numType, dateType, reportType } = this.state;
    const financials = [
      {
        key: "Posted",
        text: "Posted",
        value: "posted",
      },
      {
        key: "Draft",
        text: "Draft",
        value: "draft",
      },
    ];
    const financialsType = [
      {
        key: "Monthly",
        text: "Monthly",
        value: "Monthly",
      },
    ];
    const financialsNumber = [
      {
        key: "1",
        text: "1",
        value: "1",
      },
      {
        key: "2",
        text: "2",
        value: "2",
      },
      {
        key: "3",
        text: "3",
        value: "3",
      },
      {
        key: "4",
        text: "4",
        value: "4",
      },
      {
        key: "5",
        text: "5",
        value: "5",
      },
      {
        key: "6",
        text: "6",
        value: "6",
      },
      {
        key: "7",
        text: "7",
        value: "7",
      },
      {
        key: "8",
        text: "8",
        value: "8",
      },
      {
        key: "9",
        text: "9",
        value: "9",
      },
      {
        key: "10",
        text: "10",
        value: "10",
      },
      {
        key: "11",
        text: "11",
        value: "11",
      },
      {
        key: "12",
        text: "12",
        value: "12",
      },
    ];

    return loading ? (
      <Loader text inTable />
    ) : (
      <main className="main-content" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header flex-wrap">
              <div className="d-flex align-items-left">
                <div className="mt-3">
                  <div className="page-header-title pl-0">Trial Balance</div>
                </div>
              </div>
              <div className="d-flex align-items-end flex-wrap">
                <div className="rdt ml-3 mt-3">
                  <Header textAlign="center">Journal Status</Header>
                  <Dropdown
                    style={{ marginTop: "5px" }}
                    selection
                    defaultValue={"posted"}
                    selectOnBlur={false}
                    options={financials}
                    onChange={this.handleReportChange}
                  />
                </div>
                <div className="rdt ml-3 mt-3">
                  <Header textAlign="center">Date Type</Header>
                  <Dropdown
                    style={{ marginTop: "5px" }}
                    selection
                    placeholder={this.state.dateType}
                    defaultValue={this.state.dateType}
                    selectOnBlur={false}
                    options={financialsType}
                    onChange={this.handleDateClick}
                  />
                </div>
                <div className="rdt ml-3 mt-3">
                  <Header textAlign="center">{this.state.dateType === "Monthly" && "# Months"}
                    {this.state.dateType === "Yearly" && "# Years"}</Header>
                  <Dropdown
                    style={{ marginTop: "5px" }}
                    selection
                    placeholder={this.state.numType}
                    defaultValue={this.state.numType}
                    selectOnBlur={false}
                    options={financialsNumber}
                    onChange={this.handleNumberClick}
                  />
                </div>
              </div>
              <div className="d-flex align-items-end mt-3">
                <Button
                  id="getLedger"
                  disabled={!dateType || !numType || !reportType}
                  compact
                  className="ml-3 button-hover-universal"
                  onClick={this.getLedger}
                >
                  <span>Get Report</span>
                </Button>
                <Button
                  className="ml-4 noPrint noPrint-button"
                  compact
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                  onClick={() => window.print()}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <br></br>
          <Table
            columnLimit={this.state.columnLimit}
            lastPage={this.state.lastPage}
            monthYear={this.state.dateType}
            trialsCache={this.state.trialsCache}
          />
        </div>
      </main>
    );
  }
}

export default withApollo(TrialReport);
