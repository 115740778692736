import React, { Component } from "react";
import { Icon, Form, Button, Modal, Header } from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { inviteMaintenanceWorker } from "../../store/person/maintenance";
import {
  VendorsAccounting,
} from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import mixpanel from "mixpanel-browser";
import { ACCOUNTING, MAINTENANCE_URL } from "../../utils/constants";

const maintenanceClient = getClient(MAINTENANCE_URL);
const accountingClient = getClient(ACCOUNTING);

class MInviteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalOpen: false,
      vendors: [],
      vendor: null,
      secondOpen: false,
      setIssue: null
    };
  }
  handleClose = () =>
    this.setState({ modalOpen: false, email: "", lastName: "", firstName: "", vendor: null, secondOpen: false });

  success = () =>
    toast.success("Email Sent!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  handleChange = (name, e) => {
    const { value } = e.target;
    this.setState({
      [name]: name == "vendor" ? e : value,
    });
  };
  getVendors = () => {
    accountingClient
      .query({
        query: VendorsAccounting,
        variables: {
          location: this.props.primary.id,
        },
      })
      .then((res) => {
        if (res.data) {
          const array = res.data.slLocationVendors.data;
          this.setState({ vendors: array })
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  validateAndSubmit = (e) => {
    e.preventDefault();
    this.handleInviteSubmit(this.state);
  };
  // Sends the Maintenance Worker Invite
  handleInviteSubmit = (over) => {
    this.setState({ loading: true });
    const { firstName, lastName, email, vendor } = this.state;
    try {
      mixpanel.track("Manager Maintenance Action", {
        sub: "Invite Maintenance Tech",
      });
      maintenanceClient
        .mutate({
          mutation: inviteMaintenanceWorker,
          variables: {
            input: {
              firstName: firstName,
              lastName: lastName,
              vendorId: vendor,
              email: email,
              locationId: this.props.primary.id,
              overwrite: over
            },
          },
        })
        .then((response) => {
          toastSuccessMsg(response.data.inviteMaintenanceWorker.response);
          this.setState({ loading: false });
          this.handleClose();
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
          toastFailMsg(error && error.toString().split(/error: (.+)/)[1]);
          if (error && error.toString().split(/error: (.+)/)[1].includes('Already Exists')) {
            this.setState({ secondOpen: true })
            this.setState({ setIssue: error.toString().split(/error: (.+)/)[1] })
          }
        });
    } catch (e) { }
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <Modal
          className="semanticModal modal-radius"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size="tiny"
          trigger={
            <Button
              onClick={() => this.getVendors()}
              style={{ background: "#343c49", color: "#F2FCFB" }}
              compact
              icon
              labelPosition="left"
            >
              <Icon name="mail" style={{ paddingTop: "15px" }} />
              Maintenance Invite
            </Button>
          }
        >
          <Modal.Header textalign="left" className="modal-header-bg">
            <Header textalign="left" className="modal-heading-custom">
              Send Invite
            </Header>
          </Modal.Header>
          <Modal.Content className="maintenance-invite-modal">
            <Form >
              <Form.Select
                onChange={(e, { value }) => this.setState({ vendor: value })}
                options={
                  this.state.vendors &&
                  this.state.vendors.map((vend) => ({
                    key: vend._id,
                    text: vend.name,
                    value: vend._id,
                  }))
                }
                placeholder='Vendor'
              />
              <Form.Input
                placeholder="First Name"
                name="firstName"
                onChange={(event) => {
                  this.handleChange("firstName", event);
                }}
                className="height-38"
              />
              <Form.Input
                placeholder="Last Name"
                name="lastName"
                onChange={(event) => {
                  this.handleChange("lastName", event);
                }}
                className="height-38"
              />
              <Form.Input
                placeholder="Email"
                name="email"
                onChange={(event) => {
                  this.handleChange("email", event);
                }}
                className="height-38"
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="modal-close-button"
              positive
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            {loading ? (
              <Button style={{ maxHeight: '36px' }} className="modal-save-button" disabled positive loading>
                Loading
              </Button>
            ) : (
              <>
                {/* eslint-disable */}
                <Button
                  type="button"
                  style={{ maxHeight: '36px' }}
                  className="modal-save-button"
                  positive
                  disabled={
                    !this.state.firstName ||
                    !this.state.lastName ||
                    !this.state.email ||
                    (this.state.email && !this.state.email.trim()) ||
                    (this.state.firstName && !this.state.firstName.trim()) ||
                    (this.state.lastName && !this.state.lastName.trim())
                  }
                  content="Submit"
                  onClick={() => this.handleInviteSubmit(false)}
                >
                  Submit
                </Button>
                {/* eslint-enable */}
              </>
            )}
          </Modal.Actions>
          <Modal
            className="semanticModal"
            onClose={() => this.setState({ secondOpen: false })}
            open={this.state.secondOpen}
            size='small'
          >
            <Modal.Header>Confirm</Modal.Header>
            <Modal.Content>
              <p>{this.state.setIssue}</p>
              <br></br>
              <p>Do you wish to overwrite the existing lead?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                disabled={loading}
                loading={loading}
                icon='check'
                content='Confirm'
                onClick={() => this.handleInviteSubmit(true)}
              />
            </Modal.Actions>
          </Modal>
        </Modal>
      </>
    );
  }
}
export default withApollo(MInviteModal);
