import gql from "graphql-tag";

const housingProgramByPropertyGql = gql`
  query housingPrograms($location: String!) {
    housingPrograms(location: $location) {
      edges {
        node {
          id
          code
          name
        }
      }
    }
  }
`;

export default housingProgramByPropertyGql;
