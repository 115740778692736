import React, { useState } from "react";
import { Button, Modal, Input } from 'semantic-ui-react'
import { LEASE_MANAGER } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import {
  InviteRenter
} from "../../store/person/applications";
const managerClient = getClient(LEASE_MANAGER);

function InviteResident(props) {
  const [open, setOpen] = React.useState(false)
  const [inviteArray, inviteArrayFunc] = useState([
    {
      "email": null,
    },
  ]);

  const sendInvite = () => {
    const finalArray = inviteArray.map(function (obj) {
      return obj.email;
    });
    managerClient
      .mutate({
        mutation: InviteRenter,
        variables: {
          input: {
            invitation: {
              emails: finalArray,
              location: props.location.id
            }
          }
        }
      })
      .then((res) => {
        toastSuccessMsg("Invites sent to renters!")
        setOpen(false)
      })
      .catch((error) => {
        toastFailMsg("Something went wrong")
      });
  }

  const AddRow = () => {
    const newObj = {
      "email": null,
    }
    inviteArrayFunc([...inviteArray, newObj])
  }
  const RemoveRow = () => {
    if (inviteArray.length > 1) {
      const Arr = inviteArray.slice(0, inviteArray.length - 1);
      inviteArrayFunc(Arr)
    }
    else return
  }

  const validationTest = () => {
    if (inviteArray.every(e => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.email)))) {
      return false
    }
    else {
      return true
    }
  }

  return (
    <Modal
      className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={<Button 
        onClick={() => inviteArrayFunc([
          {
            "email": null,
          },
        ])}
        className="btn-gradient btn-med residents-fourth-step"
      >
        Invite Resident
      </Button>}
    >
      <Modal.Header>Invite Resident</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {
            inviteArray.map((ledger, index) => {
              return (
                <Input style={{ padding: '5px' }} icon={{ name: `${(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ledger.email) ? "check circle outline" : "x")}` }} fluid placeholder='Email' onChange={(e) => inviteArrayFunc(Object.values({ ...inviteArray, [index]: { ...inviteArray[index], email: e.target.value } }))} />
              )
            })
          }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={inviteArray.length == 1} color='black' onClick={() => RemoveRow()}>
          Remove
        </Button>
        <Button disabled={inviteArray.length == 5} color='black' onClick={() => AddRow()}>
          Add
        </Button>
        <Button
          disabled={validationTest()}
          content="Send All"
          labelPosition='right'
          icon='checkmark'
          onClick={() => sendInvite()}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default InviteResident