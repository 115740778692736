import React from "react";
import moment from "moment";
import { Button } from "semantic-ui-react";
import { DATE_FORMAT } from "../../../utils";

function LeaseHistory({ leaseHistoryData, history }) {
  const navigateToLeaseDetailPage = (leaseId) => {
    history.push({
      pathname: `/lease/details/${leaseId}`,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div className="card-header">
              <div className="card-header-title">Application</div>
            </div>
            <div className="card-body pb-2 lease-history-table">
              <div className="payment-table-header">
                <div className="table-header-label-application">Start Date</div>
                <div className="table-header-label-application">End Date</div>
                <div className="table-header-label-application">Status</div>
                <div className="table-header-label-application">Unit Number</div>
                <div className="table-header-label-application">Amount</div>
                <div className="table-header-label-application">Action</div>
              </div>
              <div className="payment-table-body">
                {/* div to be repeated */}
                {leaseHistoryData &&
                  leaseHistoryData.map((ele) => (
                    <>
                      <div className="payment-table-row">
                        <div className="table-col">
                          {moment(ele.node.startDate).format(DATE_FORMAT)}
                        </div>
                        <div className="table-col">
                          {moment(ele.node.endDate).format(DATE_FORMAT)}
                        </div>
                        <div className="table-col">{ele.node.status}</div>
                        <div className="table-col">{ele.node.unit.number}</div>
                        <div className="table-col">{ele.node.amount}</div>
                        <div className="table-col">
                        <Button
                          icon="eye"
                          size="mini"
                          onClick={() => navigateToLeaseDetailPage(ele.node.id)}
                        />
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaseHistory;
