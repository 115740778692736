import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import "../../containers/Accounting/Accounting.scss";

export default function ReconcileTable({
  columns,
  data,
  defaultColumn,
  filterTypes,
  lastPage,
  loading,
  paginate,
  onPaginate,
  update,
  sortDesc,
  sortId,
  totalItems,
}) {
  const {
    endRow,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    pageCount: controlledPageCount,
    prepareRow,
    previousPage,
    page,
    state: { pageIndex },
  } = useTable(
    {
      autoResetPage: false,
      columns,
      filterTypes,
      data,
      defaultColumn,
      initialState: {
        pageSize: 30,
        sortBy: [
          {
            id: sortId,
            desc: sortDesc,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // creates iterable array for mapping ui buttons.
  /* eslint-disable */
  const handleGoToPreviousPage = () => {
    onPaginate(paginate.start - 30);
    setTimeout(() => {
      update();
    }, 200)
  };

  const handleGoToNextPage = () => {
    onPaginate(paginate.start + 30);
    setTimeout(() => {
      update();
    }, 200)
  };

  // Render the UI for your table\
  return (
    <>
      <table
        className="lead-table-list"
        style={{
          width: "100%",
          flex: "0 0 100%",
          position: "relative",
        }}
        {...getTableProps()}
      >
        <thead>
          <tr style={{ minHeight: "800px" }}>
            {loading ? (
              <td colSpan="10000">Loading...</td>
            ) : (
              <td colSpan="10000">
                {page.length !== 0 &&
                  `Showing ${endRow <= controlledPageCount ? endRow : controlledPageCount
                  } of ${controlledPageCount}
                results`}
              </td>
            )}
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <br />
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length === 0 ? (
            <td colSpan={columns.length} style={{ textAlign: "center" }}>
              No Results Found
            </td>
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {totalItems > 30 && (
        <div className="pagination">
          <span className="pagination-index">
            Page{" "}
            <strong>
              {(30 + paginate.start) / 30} of {lastPage}
            </strong>{" "}
          </span>
          {((30 + paginate.start) / 30) > 1 && (
            <button
              className="pagination-nav arrow-left"
              onClick={() => handleGoToPreviousPage()}
              disabled={((30 + paginate.start) / 30) === 1}
            >
              {"<"}
            </button>
          )}
          {((30 + paginate.start) / 30) < lastPage && (
            <button
              className="pagination-nav arrow-right"
              onClick={() => handleGoToNextPage()}
              disabled={((30 + paginate.start) / 30) === lastPage}
            >
              {">"}
            </button>
          )}
        </div>
      )}
    </>
  );
}
