import React from 'react'
import { Button, Header, Image, Modal , Confirm} from 'semantic-ui-react'
import {
    LEASE_MANAGER,
  } from "../../utils/constants";
  import { actionLocalLease } from "../../store/bluemoon/bluemoon";
  import { getClient } from "../../store/auth";
  import { toastFailMsg, toastSuccessMsg } from "../../utils/common";

const leaseManager = getClient(LEASE_MANAGER);
function FinalLeaseModal(props) {
  const [open, setOpen] = React.useState(false)
  const [decline, onDecline] = React.useState(false);
  const [confirm, onConfirm] = React.useState(false);

  const actionLease= (item) => {
    leaseManager
      .mutate({
        mutation: actionLocalLease,
        variables: {
          input: {
            leaseId: props.item.id,
            notify:item=="decline"?false:true,
            status:item
          }
        },
      })
      .then(() => {
        toastSuccessMsg("Lease Signing Finalized.");
        props.getLease()
      })
      .catch(() => {
        toastFailMsg("Failed to sign.");
      });
  };
  return (
    <Modal
    className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={                  <Button
        style={{
          fontSize: ".8rem",
          padding: "5px 10px",
          textTransform: "capitalize",
          height: "auto",
        }}
        positive
        // onClick={() => finalizeLeaseLocal(item.node)}
      >
        Finalize Lease
      </Button>}
    >
      <Modal.Header>Lease</Modal.Header>
      <Modal.Content >
        <Modal.Description>
          <Header>Lease</Header>
          <p>
            Everyone has approved of the lease and it is awaiting your signature. Please press accept to finalize this lease.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
      <Button color="black" floated="left" onClick={() => setOpen(false)}>
          Close
        </Button>

        <Button color="black" onClick={() => onDecline(true)}>
          Decline
        </Button>
        <Button color="green" onClick={() => onConfirm(true)}>
          Accept
        </Button>
      </Modal.Actions>
      <Confirm
        className="semanticModal"
        open={decline}
        content="By selecting Decline you are choosing to reject the offer. The Property Manager will be able to review your declination and review further options."
        onCancel={() => onDecline(false)}
        onConfirm={() => actionLease("decline")}
      />
      <Confirm
        className="semanticModal"
        content="By selecting Accept you are agreeing to all the terms within the outlined Lease.  Once all parties confirm on this lease the Property Manager will be able to move forward."
        open={confirm}
        onCancel={() => onConfirm(false)}
        onConfirm={() => actionLease("accept")}
      />
    </Modal>
  )
}

export default FinalLeaseModal