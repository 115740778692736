import React, { Component } from "react";
import { Form, Header, Button, Modal, Dropdown, Grid } from "semantic-ui-react";
import {
  leadCommunication,
  applicationCommunication,
} from "../../store/person/leads";
import { fetchEmailTemplates } from "../../store/person/properties";
import { withApollo } from "react-apollo";
import { getClient } from "../../init-apollo-googleFn";
import { LEADS_COMMUNICATION_URL, LOCATION_UTILS } from "../../utils/constants";
import TextEditor from "../TextEditor/textEditor";
import mixpanel from "mixpanel-browser";
const leadCommunicationClient = getClient(LEADS_COMMUNICATION_URL);

const LeadsQuery = getClient(LOCATION_UTILS);
class EmailLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      loading: false,
      modalOpen: false,
      templates: "",
      selectedTemplate: "",
    };
  }

  fetchInfo = () => {
    const location = this.props.info
      ? this.props.info.location
      : this.props.location;
    if (this.state.templates !== "") {
      return;
    }
    try {
      LeadsQuery.query({
        query: fetchEmailTemplates,
        variables: {
          locationId: location,
          templateType: this.props.info ? "lead" : "application",
        },
      }).then((res) => {
        const templates =
          res.data.locationUtils &&
          res.data.locationUtils.edges.length &&
          res.data.locationUtils.edges[0].node.emailTemplate;
        this.setState({ templates: templates });
      });
    } catch (e) {}
  };
  handleClose = () => {
    this.setState({ modalOpen: false, selectedTemplate: "" });
  };

  contactLead = (e) => {
    this.setState({ submitted: true, loading: true });
    const { selectedTemplate, note } = this.state;
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Contact Lead Through Email Template",
      });
      leadCommunicationClient
        .mutate({
          mutation: leadCommunication,
          variables: {
            input: {
              email: {
                templateId: selectedTemplate.selectedTemplate.template_id,
                leadId: this.props.info.id,
                subject: selectedTemplate.selectedTemplate.subject,
                message: note,
              },
            },
          },
        })
        .then((response) => {
          this.setState({ loading: false });
          this.handleClose();
          this.props.successSent();
        });
    } catch (e) {
      alert(e);
      this.setState({ loading: false });
      this.handleClose();
    }
  };

  contactApplication = (e) => {
    this.setState({ submitted: true, loading: true });
    const { selectedTemplate, note } = this.state;
    try {
      mixpanel.track("Manager Application Action", {
        sub: "Contact Applicant Through Email Template",
      });
      leadCommunicationClient
        .mutate({
          mutation: applicationCommunication,
          variables: {
            input: {
              email: {
                templateId: selectedTemplate.selectedTemplate.template_id,
                applicationId: this.props.appId,
                subject: selectedTemplate.selectedTemplate.subject,
                message: note,
              },
            },
          },
        })
        .then((response) => {
          this.setState({ loading: false });
          this.handleClose();
          this.props.successSent();
        });
    } catch (e) {
      alert(e);
      this.setState({ loading: false });
      this.handleClose();
    }
  };

  handleChange = (e) => {
    this.setState({ note: e });
  };

  handleSourceChange = (event, { value, key }) => {
    this.setState({
      selectedTemplate: {
        ...this.state.selectedTemplate,
        selectedTemplate: value,
      },
      note: value.message,
    });
  };
  validateAndSubmit = (e) => {
    e.preventDefault();
    this.props.info ? this.contactLead() : this.contactApplication();
  };
  createMarkup = (value) => {
    return { __html: value };
  };
  render() {
    const { selectedTemplate } = this.state;
    return (
      <>
        <Modal
          className="semanticModal modal-radius"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size="small"
          trigger={
            <Button
              onClick={this.fetchInfo}
              className="dark-button"
              size="large"
              floated="right"
            >
              {this.props.info ? "Email Lead" : "Email Applicant"}
            </Button>
          }
        >
          <Header textAlign="left" className="modal-header-bg">
            <Header className="modal-heading-custom" textAlign="left">
              {this.props.info
                ? "Send Email to Lead"
                : "Send Email to Applicant"}
            </Header>
          </Header>

          <Modal.Content>
            <Grid columns="2">
              <Grid.Column>
                <Header className="from-text">
                  From: {this.props.pmContact && this.props.pmContact.email}
                </Header>
                <Header className="to-text">
                  To:{" "}
                  {this.props.info
                    ? this.props.info.email
                    : this.props.appEmail}{" "}
                </Header>
              </Grid.Column>
              <Grid.Column>
                {this.state.templates && (
                  <Dropdown
                    className="w-100"
                    placeholder="Select Template"
                    openOnFocus={false}
                    selection
                    onChange={this.handleSourceChange}
                    options={this.state.templates.map((val) => ({
                      key: val.template_id,
                      text: val.type,
                      value: val,
                      content: val.type,
                    }))}
                  />
                )}
              </Grid.Column>
            </Grid>

            <Form>
              {selectedTemplate.selectedTemplate && (
                <>
                  <Form.Field>
                    <Header textAlign="left" className="email-header-spacing">
                      {selectedTemplate.selectedTemplate.subject}
                    </Header>
                  </Form.Field>
                  <TextEditor
                    template={selectedTemplate.selectedTemplate.message}
                    id="mytextarea"
                    handleEditorChange={this.handleChange}
                  />
                </>
              )}
            </Form>
          </Modal.Content>

          <Modal.Actions>
            <Button
              className="modal-close-button"
              positive
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            {this.state.loading ? (
              <Button className="modal-save-button" positive disabled loading>
                Loading
              </Button>
            ) : (
              <Button
                type="button"
                content="Submit"
                className="modal-save-button"
                positive
                disabled={selectedTemplate === ""}
                onClick={(e) => this.validateAndSubmit(e)}
              >
                Send
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
export default withApollo(EmailLead);
