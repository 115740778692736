/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Icon,
  Popup,
  Dropdown,
  Form,
  Button,
  Message,
  Header,
  Label,
} from "semantic-ui-react";
import { camelCase } from "lodash";
import * as WepayUtils from "../../../utils/wepay";
import axios from "axios";
import { getClient } from "../../../init-apollo-googleFn";
import {
  LOCATIONAPI,
  WEPAY_URL,
  BLUEMOON_API,
  LOCATIONDOC,
  PERSON_WEPAY,
} from "../../../utils/constants";
import {
  bluemoonInfo,
  bluemoonProperties,
} from "../../../store/bluemoon/bluemoon";
import { CreateLegal } from "../../../store/properties/properties";
import { getAuthToken } from "../../../store/auth/authUtility";
import { ConvenienceFeesWepay } from "../../../store/person/accounting";

import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../../utils/common";
import AddPayoutMethods from "./AddPayoutMethods";

const bluemoonApi = getClient(BLUEMOON_API);
const paymentApi = getClient(PERSON_WEPAY);
const wePayClient = getClient(WEPAY_URL);

const prepareDepositMutation = ({
  depositRequired,
  depositAmount,
  depositNonrefundableAmount,
}) => {
  if (depositRequired === "Yes") {
    return `depositAmount: ${depositAmount},
            depositRequired: true,
            depositRefundable: true,
            depositNonrefundableAmount: ${depositNonrefundableAmount}`;
  }
  return `depositRequired: false,
          depositRefundable: false`;
};



const PaymentForm = ({
  depositRules,
  details,
  locationId,
  user,
  fetchLocationDetails,
  wepay1stStep,
  handleSubmitting,
  changeFormStep,
  onNextStep,
  onSave,
  propertyData,
  organizationId,
}) => {
  const { watch, setValue, getValues, } =
    useForm({ mode: "onChange", defaultValues: { depositRequired: "Yes" } });

  const [addedDocumentsPath, setAddedDocumentsPaths] = React.useState({});
  const [bluemoonData, setBluemoonData] = React.useState({});
  const [fileLease, setFileLease] = React.useState(false);
  const [fileApp, setFileApp] = React.useState(false);
  const [toPayments, toggleToPayments] = React.useState(false);
  const [filePayment, setFilePayment] = React.useState(false);
  const [loads, setLoads] = React.useState(false);
  const [payMethods, setMethods] = React.useState([]);
  const [accountPayoutMethodId, setAccountPayoutMethodId] = React.useState("");
  const [paymentMethods, setPaymentMethods] = React.useState(null);
  const [accountPayoutFrequency, setAccountPayoutFrequency] =
    React.useState("daily");
  const [fileCancel, setFileCancel] = React.useState(false);
  const [wePayFees, setWePayFees] = useState([]);
  const [companyType, setCompanyType] = useState(false);
  const [merchant, setMerchant] = useState(null);
  const [checkPrivacyPolicy, setPrivacyPolicy] = useState(false);
  const [checkTermsOfService, setCheckTermsOfService] = useState(false);
  const [SSN, setSSN] = useState("");

  const [filePet, setFilePet] = React.useState(false);
  const [toggleSwitch, setToggleSwitch] = React.useState(
    (details.esginType || propertyData.esginType) === "bluemoon"
  );
  const [bluemoonPropertiesData, setBluemoonProperties] = React.useState([]);
  const [bmLocationId, setBmLocationId] = React.useState(
    details.bmLocationId || propertyData.esginType || ""
  );
  const [bluemoonName, setBluemoonName] = React.useState([]);

  React.useEffect(() => {
    fetchWePayPrice()
    if(details.wepaySetup ==true) toggleToPayments(true);
    if(details.wepaySetup ==false) toggleToPayments(false);
  }, []);

  const fetchWePayPrice = () => {
    wePayClient
      .query({
        query: ConvenienceFeesWepay,
      })
      .then((response) => {
        const convFees = response.data.convenienceFeesWepay && response.data.convenienceFeesWepay
        setWePayFees(convFees)
      })
      .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
      .finally();
  };

  const fetchCredentialDetail = async () => {
    await bluemoonApi
      .query({
        query: bluemoonInfo,
      })
      .then((res) => {
        const data = res.data.bluemoonInfo.edges[0].node;
        localStorage.setItem("bluemoonData", JSON.stringify(data));
        setBluemoonData(data);
      })
      .catch((error) => {
        toastFailMsg(parseGraphQLErrors(error));
      });
  };

  const fetchBluemoonProperties = async () => {
    await bluemoonApi
      .query({
        query: bluemoonProperties,
      })
      .then((res) => {
        const data = res.data.bluemoonProperties.edges;
        const finalData = [];
        setBluemoonName(data[0].node.name);
        setBmLocationId(data[0].node.id);
        data.map((ele) => {
          if (
            ele.node.id === (details.bmLocationId || propertyData.bmLocationId)
          ) {
            setBluemoonName(ele.node.name);
          }
          finalData.push({
            key: ele.node.id,
            text: ele.node.name,
            value: ele.node.name,
          });
          return null;
        });
        setBluemoonProperties(finalData);
      })
      .catch((error) => {
        toastFailMsg(parseGraphQLErrors(error));
      });
  };



  const {
    esginType,
  } = watch();

  const initializeEditForm = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      const fieldName = camelCase(key);
      switch (fieldName) {
        case "depositRequired":
        case "leaseGuarantee":
        case "flMonthRequired":
          setValue(fieldName, value ? "Yes" : "No");
          break;
        case "hoa":
          setValue("HOA", value ? "Yes" : "No");
          if (value) setValue("hoaChargeCode", Number(data.hoa_charge_code));
          break;
        case "hoaChargeCode":
          setValue(fieldName, Number(value));
          break;
        case "esginType":
        case "petPolicyDocPath":
        case "paymentPolicyDocPath":
        case "cancellationPolicyDocPath":
        case "hoaPolicyDocPath":
        case "distributionNoticeDocPath":
        case "applicationDocPath":
        case "leaseDocPath":
          setAddedDocumentsPaths((prev) => ({ ...prev, [fieldName]: value }));
          break;
        default:
          setValue(fieldName, value);
          break;
      }
    });
  };

  /* eslint-disable */
  React.useEffect(() => {
    if (propertyData && Object.keys(propertyData).length !== 0) {
      initializeEditForm(propertyData);
    } else if (depositRules) initializeEditForm(depositRules);
  }, [depositRules, propertyData]);

  React.useEffect(() => {
    if (!bluemoonData.id) fetchCredentialDetail();
  }, []);

  React.useEffect(() => {
    if (addedDocumentsPath.leaseDocPath) {
      GetDocusign("LEASE_POLICY");
    }
  }, [addedDocumentsPath.leaseDocPath]);
  React.useEffect(() => {
    if (addedDocumentsPath.applicationDocPath) {
      GetDocusign("APPLICATION_POLICY");
    }
  }, [addedDocumentsPath.applicationDocPath]);
  React.useEffect(() => {
    if (addedDocumentsPath.petPolicyDocPath) {
      GetDocusign("PET_POLICY");
    }
  }, [addedDocumentsPath.petPolicyDocPath]);
  React.useEffect(() => {
    if (addedDocumentsPath.paymentPolicyDocPath) {
      GetDocusign("PAYMENT_POLICY");
    }
  }, [addedDocumentsPath.paymentPolicyDocPath]);
  React.useEffect(() => {
    if (addedDocumentsPath.cancellationPolicyDocPath) {
      GetDocusign("CANCELLATION_POLICY");
    }
  }, [addedDocumentsPath.cancellationPolicyDocPath]);

  const fetchPaymentMethods = async () => {
    try {
      const res = await WepayUtils.getPaymentMethods({ locationId });
      setPaymentMethods(res);
    } catch (error) { }
  };

  React.useEffect(() => {
    fetchPaymentMethods();
  }, []);
  /* eslint-enable */

  React.useEffect(() => {
    fetchBluemoonProperties();
  }, [toggleSwitch]);
  /* eslint-enable */


  const companyTypes = [
    {
      key: 'sole_proprietor',
      text: 'Sole Proprietor',
      value: 'sole_proprietor',
    },
    {
      key: 'limited_liability_company',
      text: 'Limited Liability Company',
      value: 'limited_liability_company',
    },
    {
      key: 'corporation',
      text: 'Corporation',
      value: 'corporation',
      disabled: true
    },
  ]


  const GetDocusign = (e) => {
    const url = new URL(LOCATIONDOC);
    url.searchParams.append("doc_type", e);
    url.searchParams.append("location_id", locationId);
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
    })
      .then((response) => {
        const fileGet = response.data.pages&&response.data.pages[0];
        if (e == "LEASE_POLICY") {
          setFileLease(fileGet);
        }
        if (e == "APPLICATION_POLICY") {
          setFileApp(fileGet);
        }
        if (e == "PAYMENT_POLICY") {
          setFilePayment(fileGet);
        }
        if (e == "CANCEL_POLICY") {
          setFileCancel(fileGet);
        }
        if (e == "PET_POLICY") {
          setFilePet(fileGet);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const WePayMerchant = async () => {
    const valuea = await WepayUtils.createWepayLegalEntity({
      organizationId: organizationId,
      companyType: companyType.value,
      locationId: locationId,
      termsService: checkTermsOfService,
      privacyPolicy: checkPrivacyPolicy,
      orgRegistrationId: SSN,
    });
    if (valuea.data.createWepayLegalEntity.response == "200") {
      toggleToPayments(true)
      setTimeout(() => {
        fetchLocationDetails()
      }, 3000);
    }
  }
  const checkFormInputs = () => {
    if (loads) return true
    if (payMethods && payMethods.length == 0) {
      return true
    }
    else return false
  }



  return (
    <>
      <Message>
        <Header textAlign="center">Payment Information</Header>
        <p>
          Link your banking information so you get paid out sooner. This will also unlock our accounting services to better streamline your business.
        </p>
      </Message>
      <div className="form-section m-t-3">
        <Grid>

          {(merchant === null && !details.wepaySetup) && !toPayments && (
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="white-block">
                <Grid>
                  <Grid.Column mobile={5} tablet={5} computer={5}>
                    {/* terms of service */}
                    <div className="radio-container">
                      <label className="custom-checkbox">
                        <a
                          href="https://go.wepay.com/terms-of-service-us/"
                          target="_blank"
                          style={{ color: 'blue' }}
                        >
                          Terms of Service
                        </a>{" "}
                        for Wepay
                        <input
                          type="checkbox"
                          value={checkTermsOfService}
                          checked={checkTermsOfService}
                          onChange={() =>
                            setCheckTermsOfService(!checkTermsOfService)
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={5} tablet={5} computer={5}>
                    {/* privacy policy */}
                    <div className="radio-container">
                      <label className="custom-checkbox">
                        <a
                          href="https://go.wepay.com/privacy-policy/"
                          target="_blank"
                          style={{ color: 'blue' }}
                        >
                          Privacy Policy
                        </a>{" "}
                        for Wepay
                        <input
                          type="checkbox"
                          value={checkPrivacyPolicy}
                          checked={checkPrivacyPolicy}
                          onChange={() =>
                            setPrivacyPolicy(!checkPrivacyPolicy)
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={6} tablet={6} computer={6}>
                    <div className="form-group">
                      <div className="has-float-label">
                        <input
                          className="form-control"
                          id="orgRegistrationId"
                          name="orgRegistrationId"
                          type="number"
                          placeholder="SSN"
                          autoComplete="off"
                          value={SSN}
                          onChange={(e) => setSSN(e.target.value)}
                          required
                        />
                        <label htmlFor="orgRegistrationId">SSN{" "}<Popup content='Wepay requires users social security number in order to transmit rent payments.' trigger={<Icon name='question circle' />} /></label>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid>
                <div>
                  <Dropdown
                    placeholder='Company Type'
                    onChange={(e, { value }) => setCompanyType({ value })}
                    selection
                    options={companyTypes}
                  />
                </div>
                {/* </Grid.Row> */}
                <div>
                  <Button floated={'right'} onClick={() => WePayMerchant()}>Submit Check</Button>
                  <br></br>
                  <Header as="h4">WePay Fees</Header>
                  {wePayFees&&wePayFees.map(item => {
                    return (
                      <>
                        <Label as='a' color='blue' >
                          Type
                          <Label.Detail> {item.type}</Label.Detail>
                        </Label>
                        <Label as='a' color='blue' >
                          Charge Type
                          <Label.Detail> {item.chargeType}</Label.Detail>
                        </Label>
                        <Label as='a' color='blue' >
                          Amount
                          <Label.Detail> %{item.convenienceAmount}</Label.Detail>
                        </Label>
                        <br></br>
                      </>
                    )
                  })}
                </div>
              </div>
            </Grid.Column>
          )}

          {/* <div className="create-account-form">
            <div className="white-block">
              <div className="header">Create Payout Account</div>
              <div style={{ height: 50 }}></div>
              <Form>
                <Form.Group widths="equal">
                  <Form.Select
                    label="Payout Method"
                    value={accountPayoutMethodId}
                    onChange={(event, { value }) => {
                      setAccountPayoutMethodId(value);
                    }}
                    options={paymentMethods
                      ?.filter((m) => m.payoutMethod)
                      ?.map((method, i) => {
                        return {
                          key: `payout-method-${i}`,
                          text: `${method.name} - ******${method.lastFour}`,
                          value: method.heartlandToken,
                        };
                      })}
                    placeholder="Payout Method"
                  />

                  <Form.Select
                    label="Payout Frequency"
                    value={accountPayoutFrequency}
                    onChange={(event, { value }) => {
                      setAccountPayoutFrequency(value);
                    }}
                    options={[
                      { key: "d", text: "Daily", value: "daily" },
                      { key: "w", text: "Weekly", value: "weekly" },
                    ]}
                    placeholder="Payout Frequency"
                  />
                </Form.Group>
              </Form>
            </div>
          </div> */}
          {toPayments && (
            <>
              <AddPayoutMethods
                setMethods={setMethods}
                locationId={locationId}
                organizationId={organizationId}
              />
            </>
          )}
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <div className="btn-block">
              <div>
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={() => changeFormStep(0)}
                >
                  Back
                </button>
              </div>
              <div>
                <button type="submit" onClick={() => changeFormStep(0)} disabled={checkFormInputs()} className="btn next-btn">
                  Complete
                </button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
};

export default PaymentForm;
