import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { ChargeAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import ChargeCodeModal from "../../components/Modals/ChargeCodeModal";
import { ACCOUNTING } from "../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "semantic-ui-react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const chargeClient = getClient(ACCOUNTING);

class Charge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      tableData: [],
      old: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Charge",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }
  stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = [
      "Ledger",
      "Name",
      "Description",
      "Type",
      "Rate",

    ];

    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Charge Codes", 14, 15);
    console.log(this.state.tableData);
    // Convert data to array of arrays
    const data = this.state.tableData.map((item) => [
      item?.link?.props?.children,
      item.name,
      item.description,
      item.uiType,
      item.uiAmount,
    ]);

    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });

    // Output PDF
    doc.save("ChargeCodes.pdf");
  };

  success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  getLedger = () => {
    this.setState({ loading: true });
    try {
      chargeClient
        .query({
          query: ChargeAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
          },
        })
        .then((res) => {
          if (res.data) {
            const dataToPass = get(res, "data.chargeCodes.edges", []).map(
              (obj, i) => {
                const { extLedgerId, desc, type, amountDefault } = obj.node;
                const link = (
                  <Link to={`/accounting/reconcile/${extLedgerId}`}>
                    {extLedgerId}
                  </Link>
                );
                const description = desc ? desc : "";
                const uiType = type ? type : "";
                const uiAmount =
                  "$" +
                  amountDefault
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                const chargeCodeModal = (
                  <ChargeCodeModal
                    success={this.success}
                    propertyId={this.props.selectedPrimary.node.id}
                    updateInvoice={this.getLedger}
                    props={obj}
                  />
                );
                return (({ name }) => ({
                  link,
                  name,
                  description,
                  uiType,
                  uiAmount,
                  chargeCodeModal,
                }))(obj.node);
              }
            );
            this.setState({
              tableData: dataToPass,
              old: get(res, "data.chargeCodes.edges", []),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    const node = { node: response.data.createChargeCode.chargeCode };
    this.setState((prevState) => ({
      tableData: [node, ...prevState.tableData],
    }));
  };

  updateInvoice = (update, chargeId) => {
    const { tableData } = this.state;
    const array = [];
    if (update === "Delete") {
      this.setState({
        tableData: tableData.filter((charge) => charge.node.id !== chargeId),
      });
    } else {
      tableData.forEach((charge) => {
        if (charge.node.id === update.data.updateChargeCode.chargeCode.id) {
          charge = { node: update.data.updateChargeCode.chargeCode };
          array.push(charge);
        } else {
          array.push(charge);
        }
      });
      this.setState({ tableData: array });
    }
  };

  render() {
    const { loading } = this.state;
    const mainHeader = [
      "Ledger",
      "Name",
      "Description",
      "Type",
      "Rate",
      "Edit",
    ];
    const success = () =>
      toast.success("Success!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    // !: Error handling.
    // TODO: Style
    return loading ? (
      <Loader text inTable />
    ) : (
      <>
        <main className="main-content" role="main">
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="page-header">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="page-header-title">Charge Codes</div>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <ChargeCodeModal
                    updateInvoice={this.getLedger}
                    success={success}
                    dataPush={this.dataPush}
                    propertyId={
                      this.props.selectedPrimary.node &&
                      this.props.selectedPrimary.node.id
                    }
                  />
                  <Button
                    onClick={() => this.stateDataToPDF()}
                    compact
                    className="ml-4 noPrint noPrint-button"
                    style={{
                      backgroundImage:
                        "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                    }}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <GeneralAccountingTable
            mainCellData={this.state.tableData}
            mainHeader={mainHeader}
            propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
            updateInvoice={this.getLedger}
          />
        </main>
      </>
    );
  }
}

export default withApollo(Charge);
