export const propertyPlace = `place {
    photos {
        url
    }
    exFromOcc
    bathrooms
    tax
    holdingUnit
    hearingAcc
    visualAcc
    cleaningDeposit
    size
    unitType
    securityDeposit
    leaOriginFee
    mobilityAcc
    updated
    description
    maxOccupancy
    price
    bedrooms
    onlineMrkt
    created
    petsPermitted
    available
    carbonMonoxTest
    smokeDetectTest
  }`;

export const propertyLocation = `location {
    city
    state
    streetOne
    streetTwo
    postcode
    unitNum
    lat
    lng
    yearBuilt,
    floor
  }`;

export const addressKey = `address {
  current
  street
  city
  state
  postcode
  type
}`;

export const locationAddressKey = `address {
  streetOne
  streetTwo
  city
  state
  postcode
  yearBuilt
  lat
  lng
}`;

export const appliancesKey = `appliances {
  washerMachine,
  dailyReception,
  restaurant,
  freeWifi,
  parking,
  nonSmokingRooms,
  airportShuttle,
  ac,
  balcony,
  heating,
  pool,
  hotTub,
  gym,
  petsAllowed,
  dishWasher,
  microwave,
  terrace
}`;
