import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { LedgerAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "semantic-ui-react";

import { toast } from "react-toastify";
import LedgerCreationAccounting from "../../components/Modals/LedgerCreationAccounting";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ledgerClient = getClient(ACCOUNTING);

class Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      ledgerCache: [],
      offset: 0,
      view: false,
      tableData: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Ledger",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }
  stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = [
      "Id",
      "Number",
      "Name",
      "Type",
      "Sub",
      "Description"
    ];

    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Chart of Accounts", 14, 15);
    console.log(this.state.tableData);
    // Convert data to array of arrays
    const data = this.state.tableData.map((item) => [
      item?.link?.props?.children,
      item.number,
      item.name,
      item.type,
      item.subtype,
      item.desc
    ]);

    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });

    // Output PDF
    doc.save("ChartOfAccounts.pdf");
  };

  getLedger = () => {
    try {
      this.setState({ loading: true });
      ledgerClient
        .query({
          query: LedgerAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            offset: this.state.offset,
            limit: 30,
          },
        })
        .then((res) => {
          if (res.data) {
            const dataToPass = get(res, "data.slLocationLedgerAccounts.data", null).map((ledgerObj) => {
              const { description, _id } = ledgerObj;
              const desc = description ? description : "";
              const actions = <LedgerCreationAccounting
                updateLedger={this.props.updateLedger}
                propertyId={this.props.propertyId}
                props={ledgerObj}
                user={this.props.user}
                edit={true}
              />;
              const link = <Link to={`/accounting/reconcile/${_id}`}>{_id}</Link>;
              return (({ number, name, type, subtype }) =>
                ({ "link": link, number, name, type, subtype, desc, actions }))(ledgerObj)
            });
            this.setState({
              tableData: dataToPass,
              ledgerCache: get(res, "data.slLocationLedgerAccounts.data", null),
              totalItems: get(
                res,
                "data.slLocationLedgerAccounts.totalItems"
              ),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    const node = response.data.createSlLedgerAccount.slLedgerAccount;
    this.setState((prevState) => ({
      tableData: [...prevState.tableData, node],
    }));
  };



  updateLedger = (update, ledgerId) => {
    const { tableData } = this.state;
    const array = [];
    if (update === "Delete") {
      this.setState({
        tableData: tableData.filter((ledger) => ledger._id !== ledgerId),
      });
    } else {
      tableData.forEach((ledger) => {
        if (
          ledger._id === update.data.updateSlLedgerAccount.slLedgerAccount._id
        ) {
          ledger = update.data.updateSlLedgerAccount.slLedgerAccount;
          array.push(ledger);
        } else {
          array.push(ledger);
        }
      });
      this.setState({ tableData: array });
    }
  };

  handleOffset = (x) => {
    this.setState({ offset: x });
  };

  render() {
    const { loading } = this.state;
    const mainHeader = ["Id", "Number", "Name", "Type", "Sub-Type", "Description", "Actions"];
    const success = () =>
      toast.success("Success!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    const failure = () =>
      toast.error("Something went wrong!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    return (
      <>
        <main className="main-content" role="main">
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="page-header">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="page-header-title">Chart of Accounts</div>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  {
                    <LedgerCreationAccounting
                      dataPush={this.dataPush}
                      updateLedger={this.updateLedger}
                      ledgerCache={this.state.ledgerCache && this.state.ledgerCache}
                      propertyData={
                        this.props.selectedPrimary && this.props.selectedPrimary
                      }
                      success={success}
                      user={this.props.user}
                      failure={failure}
                      propertyId={
                        this.props.selectedPrimary.node &&
                        this.props.selectedPrimary.node.id
                      }
                    />
                  }
                  <Button
                    onClick={() => this.stateDataToPDF()}
                    compact
                    className="ml-4 noPrint noPrint-button"
                    style={{
                      backgroundImage:
                        "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                    }}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <Loader text inTable />
          ) : (
            <>
              <GeneralAccountingTable
                dataPush={this.dataPush}
                getLedger={this.getLedger}
                hideAdd={this.hideAdd}
                mainCellData={this.state.tableData}
                mainHeader={mainHeader}
                propertyData={
                  this.props.selectedPrimary && this.props.selectedPrimary
                }
                propertyId={
                  this.props.selectedPrimary.node &&
                  this.props.selectedPrimary.node.id
                }
                totalItems={this.state.totalItems && this.state.totalItems}
                user={this.props.user}
                updateLedger={this.updateLedger}
              />
            </>
          )}
        </main>
      </>
    );
  }
}

export default withApollo(Ledger);
