import React, { useState, useEffect} from "react";
import Sortable from "sortablejs/modular/sortable.complete.esm.js";

const PropertyImages = ({
  title = "Upload photos of your property",
  images = [],
  setImages,
  tag,
  setDeletedImages,
  isMultiple = true,
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(images.map((image, index) => ({ ...image, id: index })));
  }, [images]);

  const handleImageChange = (event) => {
    const { files } = event.target;
    const filesData = Array.from(files).map((file) => {
      file.previewUrl = URL.createObjectURL(file);
      return file;
    });
    setImages((prevImages) => [...prevImages, ...filesData]);
  };

  const handleImageDelete = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      if (tag === "unit") {
        const deletedImage = updatedImages[index];
        if (deletedImage && deletedImage.previewUrl && !deletedImage.previewUrl.includes("blob")) {
          setDeletedImages((prevDeletedImages) => [...prevDeletedImages, deletedImage.previewUrl]);
        }
      }
      URL.revokeObjectURL(updatedImages[index].previewUrl);
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  useEffect(() => {
    if (list.length === 0) return;

    const listElement = document.getElementById("sortable-list");
    const sortable = Sortable.create(listElement, {
      onEnd: () => {
        const newList = Array.from(listElement.children).map((child) => {
          const id = Number(child.getAttribute("data-id"));
          const image = list.find((item) => item.id === id);
          return { ...image };
        });
        setList(newList);
        setImages(newList);
      },
      animation: 150,
    });

    return () => {
      sortable.destroy();
    };
  }, [list, setImages]);

  if (images.length === 0) {
    return <div className="upload-photo-container">
    <input
      type="file"
      name="property-images"
      accept="image/*"
      multiple={isMultiple}
      onChange={handleImageChange}
    />
    <div className="inner-section">
      <img src="assets/img/img-default.svg" alt="img-default" />
      <div className="normal-text">{title}</div>
      <div className="hint">You can upload images in .jpg, .png format.</div>
    </div>
  </div>;
  }

  return (
    <>
      <div className="upload-photo-container">
        <input
          type="file"
          name="property-images"
          accept="image/*"
          multiple={isMultiple}
          onChange={handleImageChange}
        />
        <div className="inner-section">
          <img src="assets/img/img-default.svg" alt="img-default" />
          <div className="normal-text">{title}</div>
          <div className="hint">You can upload images in .jpg, .png format.</div>
        </div>
      </div>

      <div className="preview-box" id="sortable-list">
        {list.map((image, index) => (
          <div key={image.id} data-id={image.id} className="img-box" style={{ border: `3px solid ${index === 0 ? "rgba(128, 0, 128, 0.5)" : "transparent"}` }}>
            <img key={image.id} src={image.previewUrl} alt={`property-image-${index}`} />
            <div className="overlay-box" />
            <span className="white-delete-icon cursor-pointer" onClick={() => handleImageDelete(index)}>
              <img src="assets/img/white-delete.svg" alt="delete-btn" />
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default PropertyImages;