import React, { Component } from "react";
import { withApollo } from "react-apollo";
import ProfileBar from "../../components/Profile/ProfileBar";
import EditProfile from "./EditProfile";
import Bio from "../../components/Profile/Bio/Bio";
import "./Profile.scss";
import {
  PERSON_URL,
  PERSON_URL_IMG,
} from "../../utils/constants";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { getAuthToken } from "../../store/auth/authUtility";
import "../../assets/fomantic/dist/semantic.css";
import axios from "axios";
import FileType from "file-type/browser";
import { personGql } from "../../store/person/person";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      openApp: false,
      editAccount: false,
      loading: false,
      file: "",
      fileType: "",
      name: "",
      type: "",
      message: "",
      isError: false,
      photo: {
        url: "assets/img/default_avatar.png",
      },
      accountInfo: {
        email: "",
        phoneHome: "",
        phoneMobile: "",
        addresses: [
          {
            street: "Tell us where you are!",
            state: "",
            city: "",
            postcode: "",
          },
        ],
        references: "Add a reference!",
      },
      bioInfo: {
        personPets: "None",
        employment: "What do you do?",
        bio: "Tell us about yourself!",
        dateOfBirth: "",
      },
      payments: null,
      auto: false,
      customAmount: "",
      selectedPayment: null,
      backgroundChecks: null,
    };
  }

  async componentDidMount() {
    if (!this.state.user) await this.getPersonInfo();
    else this.setUserData();
    this.getPersonPhoto();
  }

  setUserData = () => {
    const {
      email,
      phoneHome,
      phoneMobile,
      employment,
      bio,
      dateOfBirth,
      references,
      addresses,
      photoUrl,
    } = this.state.user;

    this.setState({
      photo: {
        url: photoUrl || "assets/img/default_avatar.png",
      },
      accountInfo: {
        email: email || "",
        phoneHome: phoneHome || "",
        phoneMobile: phoneMobile || "",
        addresses: addresses || [
          {
            street: "Tell us where you are!",
            state: "",
            city: "",
            postcode: "",
          },
        ],
        references: references || "Add a reference!",
      },
      bioInfo: {
        personPets: "None",
        employment: employment || "What do you do?",
        bio: bio || "Tell us about yourself!",
        dateOfBirth: dateOfBirth || "",
      },
    });
  };

  getPersonInfo = () => {
    const newClient = getClient(PERSON_URL);
    try {
      newClient
        .query({
          query: personGql,
        })
        .then((res) => {
          this.setState({
            user: res.data.person.edges[0].node,
          });
          this.setUserData();
          this.getPersonPhoto();
          return res;
        })
        .catch((e) => {
          console.log(e);
          return;
        });
    } catch (e) {
      return;
    }
  };

  getPersonPhoto = () => {
    const { user } = this.state;
    let url = new URL(PERSON_URL_IMG);
    if (user) {
      url.searchParams.append("path", user.photoPath
      );
      axios({
        method: "get",
        url: url.href,
        headers: {
          authorization: getAuthToken(),
        },
        responseType: "blob", // important
      }).then(async (response) => {
        const blob = new Blob([response.data]);
        const blobFile = window.URL.createObjectURL(new Blob([response.data]));
        const fileType = await FileType.fromBlob(blob);
        this.setState({ file: blobFile, fileType: fileType });
      });
    }
  };

  setSelectedPayment = (selectedPayment) => {
    this.setState({ selectedPayment });
  };

  openApplication = () => {
    const element = document.getElementById("application-tab");
    element.click();
    this.setState({ openApp: true });
  };

  toggleApp = () => {
    this.setState({ openApp: false });
  };

  handleAddCreditCardSubmit = (e) => {
    const config = require("../../config");
    e.preventDefault();
    const { nameOnCard, cardNum, cvv, expMonth, expYear, zip } = e.target;
    const n = cardNum.value.replace(/\s+/g, "");
    const lastFour = n.slice(n.length - 4);

    // Request single user token from heartland
    fetch(
      `https://cert.api2.heartlandportico.com/Hps.Exchange.PosGateway.Hpf.v1/` +
      `api/token?token_type=supt&object=token&_method=post&api_key=${config.heartland_api}` + //.env doesn't seem to want to work? We need webpack?
      `&card%5Bnumber%5D=${n}` +
      `&card%5Bexp_month%5D=${expMonth.value}` +
      `&card%5Bexp_year%5D=${expYear.value}` +
      `&card%5Bcvc%5D=${cvv.value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        if (data) {
          const responseJson = JSON.parse(data);
          // make sure response is good
          if (responseJson.error) {
            this.setMessage(responseJson.error.message, true);
            return;
          }
          // send single use token to our payment mutation
          // to create a multiuse token
          this.createPersonPayment({
            creditCard: {
              token: responseJson.token_value,
              lastFour: lastFour,
              ccHolderName: nameOnCard.value,
              ccHolderZip: zip.value,
              expYear: expYear.value,
              expMonth: expMonth.value,
            },
          });
        }
      })
      .then(() => {
        // Clear form fields
        nameOnCard.value = "";
        cardNum.value = "";
        cvv.value = "";
        expMonth.value = "";
        expYear.value = "";
        zip.value = "";
      });
  };

  handleChange = (e) => {
    const custom = e.target.value;
    this.setState({ inputAmount: custom });
  };

  setMessage = (message, isError = false) => {
    this.setState({ message, isError });
  };

  // this is passed down to <Bio> --> <BackgroundCheckInfo>
  saveBackgroundChecksToState = (bgChecks) => {
    this.setState({ backgroundChecks: bgChecks });
  };

  closeMessage = () => {
    this.setState({ message: "" });
  };

  toggleBlur = () => {
    const blurDiv = document.getElementById("blur");
    const noClicksDiv = document.getElementById("prevent-clicks");

    if (blurDiv) {
      blurDiv.className = blurDiv.className === "page-blur" ? "" : "page-blur";
    }

    if (noClicksDiv) {
      noClicksDiv.className =
        noClicksDiv.className === "prevent-clicks" ? "" : "prevent-clicks";
    }
  };

  editBool = () => {
    this.setState({ editAccount: true });
  };
  editBoolBack = () => {
    this.setState({ editAccount: false });
  };

  render() {
    const {
      bioInfo,
      file,
      fileType,
      accountInfo: { addresses },
      backgroundChecks,
      user,
      editAccount,
    } = this.state;

    return (
      <>
        {user && (
          <>
            {editAccount ? (
              <EditProfile
                file={file}
                fileType={fileType}
                user={user}
                editBoolBack={this.editBoolBack}
              />
            ) : (
              <>
                <ProfileBar
                  handlePhotoEditModal={this.handlePhotoEditModal}
                  editBool={this.editBool}
                  getPersonPhoto={this.getPersonPhoto}
                  address={addresses}
                  file={file}
                  fileType={fileType}
                  user={user}
                />
                <Bio
                  user={user}
                  bioInfo={bioInfo}
                  history={this.props.history}
                  saveBackgroundChecksToState={this.saveBackgroundChecksToState}
                  openApplication={this.openApplication}
                  backgroundChecks={backgroundChecks}
                  oldClient={this.props.oldClient}
                  setMessage={this.setMessage}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default withApollo(Profile);
