import React, { Component } from "react";
import { withApollo } from "react-apollo";
import "../Properties.scss";
import { Container } from "semantic-ui-react";
import { get, isEqual } from "lodash";
import AddPropertyStepper from "./AddPropertyStepper";
import PropertyInformation from "./PropertyInfoForm";
import PaymentForm from "./PaymentInformation";
import {
  getPropertyInfoAndPolicies,
  getUnitsInfo,
} from "../../../store/person/properties";
import UnitInfoForm from "./UnitInfoForm";
import PaymentAndPoliciesForm from "./PaymentAndPoliciesForm";
import CompanyInfoForm from "./CompanyInfoForm";
import ReviewPropertyDetails from "./ReviewPropertyDetails";
import CancelDeleteModal from "./CancelDeleteModal";
import {
  LOCATION_UTILS,
  ON_BOARDING_STEPS,
  LOCATIONAPI,
  UNITAPI,
} from "../../../utils";
import { createAmenities } from "../../../store/person/properties";
import { parseGraphQLErrors, toastFailMsg } from "../../../utils/common";
import { getClient } from "../../../init-apollo-googleFn";
import withPropertyDetails from "./withPropertyDetails";
import Marketing from "./Marketing";
import mixpanel from "mixpanel-browser";
import * as WepayUtils from "../../../utils/wepay";

const locationUtilsClient = getClient(LOCATION_UTILS);
const locationClient = getClient(LOCATIONAPI);
const unitClient = getClient(UNITAPI);

const FORM_STEPS = {
  propertyInfo: 1,
  unitInfo: 2,
  payments: 3,
  companyInfo: 4,
  marketing: 5,
  wepay: 6,
  review: 7,
};

class PropertiesComponent extends Component {
  constructor(props) {
    super(props);
    const { pathname, search } = props.location;
    const params = new URLSearchParams(search);
    const locationId =
      props.match.params.locationId || params.get("locationId");
    const isEditing = pathname.includes("edit");

    this.state = {
      loading: false,
      savedData: false,
      activeFormStep: 0,
      propertyTypes: [],
      petTypes: [],
      amenityTypes: [],
      isSubmittingForm: false,
      isCancelling: false,
      locationId,
      isEditing,
      unitData: {},
      propertyData: {},
      marketingData: {},
      accountCapabilities: null,
      onUpdate: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const updatedState = state;
    if (props.propertyTypes) updatedState.propertyTypes = props.propertyTypes;
    if (props.petTypes) updatedState.petTypes = props.petTypes;
    if (props.amenityTypes) updatedState.amenityTypes = props.amenityTypes;
    if (state.isEditing) {
      if (state.activeFormStep === FORM_STEPS.companyInfo) {
        updatedState.isSubmittingForm = !props.dataLoaded.orgDetails;
      } else if (state.activeFormStep === FORM_STEPS.unitInfo) {
        updatedState.isSubmittingForm = !props.dataLoaded.units;
      } else if (state.activeFormStep === FORM_STEPS.unitInfo) {
        updatedState.isSubmittingForm = !props.dataLoaded.details;
      }
    }
    return updatedState;
  }

  componentDidUpdate(prevProps) {
    const { details, refetched } = this.props;
    if (
      !refetched &&
      !isEqual(prevProps.details, details) &&
      get(details, "onBoardingStep")
    ) {
      const activeStep = ON_BOARDING_STEPS.indexOf(details.onBoardingStep);
      this.setState({ activeFormStep: 0 });
      // Temp Fix
    }
  }

  changeFormStep = (step) => {
    this.setState({ activeFormStep: step });
  };
  compileData = (data) => {
    this.setState({ savedData: data });
  };

  checkWepayAccountVerification = async () => {
    if (this.state.locationId) {
      try {
        const accountCapabilities = await WepayUtils.getAccountCapibilities(
          this.state.locationId
        );
        this.setState({
          accountCapabilities: accountCapabilities,
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  componentDidMount() {
    mixpanel.track("Manager Page Load", {
      sub: "Add property",
    });
  }

  onNextStep = (step) => {
    let { activeFormStep } = this.state;
    this.props.refetchDetails();
    if (step) {
      Object.values(FORM_STEPS).includes(step) &&
        step <= activeFormStep &&
        this.setState({ activeFormStep: step });
    } else if (activeFormStep !== FORM_STEPS.review) {
      activeFormStep += 0;
      this.setState({ activeFormStep });
    }
  };

  handleSubmitting = (isSubmitting) => {
    this.setState({ isSubmittingForm: isSubmitting });
  };

  onLocationCreate = async (locationId) => {
    this.props.history.replace({ search: `locationId=${locationId}` });
    this.setState({ locationId });
  };

  wepay1stStep = async (
    organizationId,
    locationId,
    termsService,
    privacyPolicy,
    ssn,
    type
  ) => {
    //TODO: check legal entity exist and create if not\
    try {
      const merchant = await WepayUtils.getMerchant(locationId);
      const isEntityExists = merchant === null ? false : "id" in merchant;
      if (!isEntityExists) {
        await WepayUtils.createWepayLegalEntity({
          organizationId: this.state.savedData.organizationId,
          companyType: type.value,
          locationId,
          termsService,
          privacyPolicy,
          orgRegistrationId: ssn,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onAddNewAmenities = (newAmenityName) => {
    const { locationId } = this.state;
    const input = {
      name: newAmenityName,
      isDefault: false,
    };
    if (locationId) input.locationId = locationId;
    return new Promise((resolve, reject) => {
      locationUtilsClient
        .query({
          query: createAmenities,
          variables: { input },
        })
        .then((response) => {
          const newAmenityObj = get(
            response,
            "data.createAmenities.amenitiesObj"
          );
          if (newAmenityObj) {
            this.props.refetchDetails("amenities");
            resolve(newAmenityObj);
          }
        })
        .catch((error) => {
          toastFailMsg(parseGraphQLErrors(error));
          reject({});
        });
    });
  };
  fetchLocationDetails = async () => {
    const queryString = window.location.href;
    const propId = new URLSearchParams(queryString);
    const product = propId.get("locationId");
    locationClient
      .query({
        query: getPropertyInfoAndPolicies,
        variables: { locationId: product },
      })
      .then((response) => {
        const locationData = get(response, "data.location.edges[0].node");
        if (locationData) {
          this.compileData(locationData);
        }
      })
      .catch((error) => {});
  };

  fetchLocationInfoAfterUpdate = async () => {
    const queryString = window.location.href;
    const propId = new URLSearchParams(queryString);
    const product = propId.get("locationId");
    locationClient
      .query({
        query: getPropertyInfoAndPolicies,
        variables: { locationId: product },
      })
      .then((response) => {
        const locationData = get(response, "data.location.edges[0].node");
        if (locationData) {
          this.setState({ onUpdate: locationData });
        }
      })
      .catch((error) => {});
  };

  onCancelClick = () => this.setState({ isCancelling: true });

  onCancelResponse = (response) => {
    if (response) this.onGoBack();
    else this.setState({ isCancelling: false });
  };

  onGoBack = () => this.props.history.goBack();

  onUnitSave = (data) => this.setState({ unitData: data });

  onPropertySave = (data) => this.setState({ propertyData: data });

  onMarketingSave = (data) => this.setState({ marketingData: data });

  renderForm = () => {
    const { details, units, orgDetails, refetchDetails, chargeCodes } =
      this.props;
    const {
      activeFormStep,
      propertyTypes,
      petTypes,
      amenityTypes,
      locationId,
      savedData,
      isEditing,
      unitData,
      propertyData,
      marketingData,
    } = this.state;
    switch (activeFormStep) {
      case FORM_STEPS.propertyInfo: {
        const params = new URLSearchParams(this.props.location.search);
        return (
          <PropertyInformation
            {...{
              propertyTypes,
              petTypes,
              changeFormStep: this.changeFormStep,
              amenityTypes,
              details,
              propertyData,
              savedData,
              isEditing,
              locationId,
              wepay1stStep: this.wepay1stStep,
              selectedPropertyType: params.get("propertyType"),
              onNextStep: this.onNextStep,
              handleSubmitting: this.handleSubmitting,
              onAddNewAmenities: this.onAddNewAmenities,
              onLocationCreate: this.onLocationCreate,
              onCancelClick: this.onCancelClick,
              refetchDetails,
            }}
          />
        );
      }
      case FORM_STEPS.unitInfo:
        return (
          <UnitInfoForm
            {...{
              amenityTypes,
              locationId,
              units,
              changeFormStep: this.changeFormStep,
              isEditing,
              details: details,
              onNextStep: this.onNextStep,
              handleSubmitting: this.handleSubmitting,
              onAddNewAmenities: this.onAddNewAmenities,
              onCancelClick: this.onCancelClick,
              refetchDetails,
              onSave: this.onUnitSave,
              unitData: this.state.unitData,
            }}
          />
        );
      case FORM_STEPS.payments:
        return (
          <PaymentAndPoliciesForm
            user={this.props.user}
            {...{
              locationId,
              isEditing,
              history: this.props.history,
              details: details,
              changeFormStep: this.changeFormStep,
              depositRules: {
                ...details.depositRules,
                hoaPolicyDocPath: details.hoaPolicyDocPath,
                distributionNoticeDocPath: details.distributionNoticeDocPath,
                cancellationPolicyDocPath: details.cancellationPolicyDocPath,
                paymentPolicyDocPath: details.paymentPolicyDocPath,
                petPolicyDocPath: details.petPolicyDocPath,
                applicationDocPath: details.applicationDocPath,
                leaseDocPath: details.leaseDocPath,
              },
              chargeCodes,
              onNextStep: this.onNextStep,
              handleSubmitting: this.handleSubmitting,
              onCancelClick: this.onCancelClick,
              onSave: this.onPropertySave,
              refetchDetails,
              propertyData,
              organizationId: this.props.organizationId,
            }}
          />
        );
      case FORM_STEPS.wepay:
        return (
          <PaymentForm
            user={this.props.user}
            {...{
              locationId,
              isEditing,
              wepay1stStep: this.wepay1stStep,
              history: this.props.history,
              fetchLocationDetails: this.fetchLocationDetails,
              details: details,
              changeFormStep: this.changeFormStep,
              depositRules: {
                ...details.depositRules,
                hoaPolicyDocPath: details.hoaPolicyDocPath,
                distributionNoticeDocPath: details.distributionNoticeDocPath,
                cancellationPolicyDocPath: details.cancellationPolicyDocPath,
                paymentPolicyDocPath: details.paymentPolicyDocPath,
                petPolicyDocPath: details.petPolicyDocPath,
                applicationDocPath: details.applicationDocPath,
                leaseDocPath: details.leaseDocPath,
              },
              chargeCodes,
              onNextStep: this.onNextStep,
              handleSubmitting: this.handleSubmitting,
              onCancelClick: this.onCancelClick,
              onSave: this.onPropertySave,
              refetchDetails,
              propertyData,
              organizationId: savedData.organizationId,
            }}
          />
        );
      case FORM_STEPS.companyInfo:
        return (
          <CompanyInfoForm
            {...{
              locationId,
              isEditing,
              orgDetails,
              changeFormStep: this.changeFormStep,
              onNextStep: this.onNextStep,
              handleSubmitting: this.handleSubmitting,
              onCancelClick: this.onCancelClick,
              refetchDetails,
            }}
          />
        );
      case FORM_STEPS.marketing:
        return (
          <Marketing
            {...{
              ...this.props,
              details,
              propertyTypes,
              petTypes,
              amenityTypes,
              changeFormStep: this.changeFormStep,
              locationId,
              isEditing,
              orgDetails,
              onNextStep: this.onNextStep,
              handleSubmitting: this.handleSubmitting,
              onCancelClick: this.onCancelClick,
              refetchDetails,
              onSave: this.onMarketingSave,
              marketingData,
            }}
          />
        );
      case FORM_STEPS.review:
        return (
          <ReviewPropertyDetails
            {...{
              ...this.props,
              propertyTypes,
              petTypes,
              amenityTypes,
              changeFormStep: this.changeFormStep,
              locationId,
              isEditing,
              onNextStep: this.onNextStep,
              handleSubmitting: this.handleSubmitting,
              onCancelClick: this.onCancelClick,
              refetchDetails,
              onPublish: this.props.callLocationApi,
            }}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { activeFormStep, isSubmittingForm, isEditing, isCancelling } =
      this.state;
    return (
      <Container className="add-property-container">
        {isCancelling && (
          <CancelDeleteModal
            isOpen={isCancelling}
            onClose={() => this.setState({ isCancelling: false })}
            onResponse={this.onCancelResponse}
            isDeleting={false}
            title="Cancel Process"
            yesBtnText="Yes, Cancel Process"
          >
            <p>
              Are you sure you want to cancel the process of adding a{" "}
              <strong>property</strong>?
            </p>
          </CancelDeleteModal>
        )}
        {activeFormStep == 0 ? (
          <AddPropertyStepper
            compileData={this.compileData}
            title={isEditing ? "Edit Property" : "Add Property"}
            user={this.props.user}
            activeFormStep={activeFormStep}
            orgDetails={this.props.orgDetails}
            savedData={this.state.savedData}
            isSubmitting={isSubmittingForm}
            changeFormStep={this.changeFormStep}
            callLocationApi={this.props.callLocationApi}
            onStepClick={this.onNextStep}
            onGoBack={this.onGoBack}
            accountCapabilities={this.state.accountCapabilities}
          />
        ) : (
          this.renderForm()
        )}
      </Container>
    );
  }
}

export default withPropertyDetails(withApollo(PropertiesComponent));
