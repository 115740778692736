import React from "react";
import { Button, Modal, Header } from "semantic-ui-react";
import { NUMERIC_REGEX } from "../../../utils/constants";
import "../Properties.scss";

const TotalUnitModal = ({ isOpen, totalUnits, onClose, onSubmit }) => {
  const [units, setUnits] = React.useState(totalUnits);

  const handleChange = (event) => {
    const { value } = event.target;
    if (!value || NUMERIC_REGEX.test(value)) setUnits(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(units);
  };

  return (
    <Modal
      className="semanticModal custom-modal"
      closeIcon
      onClose={onClose}
      open={isOpen}
      size="mini"
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <Header>Total Number of Units</Header>
        <p>How many units do you have in this property?</p>
      </Modal.Header>
      <Modal.Content>
        <form width="equal" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="has-float-label">
              <input
                value={units}
                name="comment"
                type="text"
                placeholder="Total number of units"
                onChange={handleChange}
                className="form-control"
              />
              <label htmlFor="comment">Total number of units</label>
            </div>
          </div>
          <Button
            type="submit"
            positive
            className="modal-save-button"
            content="Submit"
          />
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default TotalUnitModal;
