import React, { useMemo, useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import axios from "axios";
import { toast } from "react-toastify";
import AccountingTable from "./AccountingTable";
import { ACCOUNTING, INVOICE_ACCOUNTING } from "../../utils/constants";
import { multipleColumnsSearch } from "../../utils/common";
import NewBillsModal from "../Modals/NewBillsModal";
import BillDetailsModal from "../Modals/BillDetailsModal";
import PaymentModal from "../Modals/PaymentModal";
import { getClient } from "../../init-apollo-googleFn";
import getAuthToken from "../../store/auth/authUtility";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
import { InvoiceInfoAccounting } from "../../store/person/accounting";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
const invoiceClient = getClient(ACCOUNTING);

export function Table(props) {
  const [filterInput, setFilterInput] = useState("");

  const [toggled, viewChange] = useState(false);
  const [viewInvoiceData, viewDataInvoice] = useState("");
  const [viewInvoicesData, viewDataInvoices] = useState("");
  const locationId = props.propertyId && props.propertyId;
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(
      value,
      props.invoiceCache && props.invoiceCache
    );
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }
  const DateFormat = ({ values }) => {
    return moment(values).format("l");
  };

  const StatusFormat = ({ values }) => {
    if (values === "partiallyPaid") return "Partially Paid";
    if (values === "paid") return "Paid";
    if (values === "created") return "Created";
    if (values === "approved") return "Approved";
    if (values === "voided") return "Voided";
    else return "";
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Number",
        accessor: (item) => (
          <BillDetailsModal
            bill={item}
            user={props.user}
            location={props}
            invoice
            updateInvoice={props.updateInvoice}
          />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (value ? <span style={{ textTransform: 'capitalize' }}>{value}</span> : ""),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) => value && "$ " + Number.parseFloat(value).toLocaleString('en'),
      },
      {
        Header: "Amount Remaining",
        accessor: "amountPayable",
        Cell: ({ cell: { value } }) => value && "$ " + Number.parseFloat(value).toLocaleString('en'),
      },
      {
        Header: "Invoice",
        accessor: "invoiceDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : null),
      },
      {
        Header: "Posted",
        accessor: "postedDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : null),
      },
      {
        Header: "Due",
        accessor: "dueDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : null),
      },
      {
        Header: "Actions",
        accessor: (item) => (
          <>

            {item.status !== "voided" && item.status !== "paid" && (
              <PaymentModal
                location={props}
                updateInvoice={props.updateInvoice}
                success={success}
                bills={false}
                bill={item}
                user={props.user}
              />
            )}
            <Button size="tiny" className="ml-3 button-hover-universal" compact icon onClick={download} props={item} ><Icon name="file" /></Button>
          </>
        ),
      },
    ],
    []
  );

  const columnsBills = useMemo(
    () => [
      {
        Header: "ID",
        accessor: (item) => (
          <BillDetailsModal
            bill={item}
            user={props.user}
            location={props}
            bills
          />
        ),
      },
      {
        Header: "AP ID",
        accessor: "APAccountId",
      },
      {
        Header: "Due",
        accessor: "dueDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ""),
      },
      {
        Header: "Invoice",
        accessor: "invoiceDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ""),
      },
      {
        Header: "Posting",
        accessor: "postingDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ""),
      },
      {
        Header: "Number",
        accessor: "invoiceNumber",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) =>
          value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: "Amount Due",
        accessor: "dueAmount",
        Cell: ({ cell: { value } }) =>
          value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (value ? <StatusFormat values={value} /> : ""),
      },
      {
        Header: "Vendor",
        accessor: "Vendor.name",
      },
      {
        Header: "Action",
        accessor: (item) =>
          item.status !== "paid" &&
          item.status !== "voided" && (
            <PaymentModal
              bill={item}
              user={props.user}
              location={props}
              bills={true}
            />
          ),
      },
      {
        Header: "Edit",
        accessor: (item) =>
          item.status == "created" && (
            <NewBillsModal bill={item} user={props.user} location={props} />
          ),
      },
    ],
    []
  );

  /* eslint-disable */
  const columnsVendors = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Account Number",
        accessor: "accNumber",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Terms",
        accessor: "terms",
      },
      {
        Header: "Attachment",
        accessor: "invoiceNumber",
      },
      {
        Header: "Actions",
        accessor: "status",
      },
    ],
    []
  );
  /* eslint-enable */

  const viewInvoice = (e, props) => {
    viewDataInvoices(props);
    if (toggled) {
      viewChange(false);
    }
    if (!toggled) {
      getInvoice(props);
    }
  };

  const getInvoice = (props) => {
    try {
      invoiceClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: locationId,
            id: props.props._id,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data.slLocationInvoice;
            /* eslint-disable */
            const Check = data.payments.forEach((payment) => {
              const check = data.payments.filter(
                (pay) => pay.externalCheckBankId === payment._id
              );
              if (check.length > 0) {
                return (payment.status = "refund");
              }
            });
            /* eslint-enable */
            viewDataInvoice(
              res.data.slLocationInvoice && res.data.slLocationInvoice
            );
            viewChange(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) { }
  };

  const download = (e, props) => {
    const url = new URL(INVOICE_ACCOUNTING);
    url.searchParams.append("invoice_id", props.props._id);
    url.searchParams.append("action", "invoice");
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "blob", // important
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();


  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  /* eslint-disable */
  useEffect(() => {
    const result = props.totalItems / 30;
    setLastPage(Math.ceil(result));
    setData(props.invoiceCache && props.invoiceCache);
  }, [props.invoiceCache && props.invoiceCache]);
  /* eslint-enable */

  return (
    <>
      {!toggled ? (
        <>
          <br></br>
          <main className="main-content" role="main">
            <div className="">
              <div className="">
                <AccountingTable
                  columns={
                    (!props.bills && !props.vendors && columns) ||
                    (props.bills && columnsBills) ||
                    (props.vendors && columnsVendors)
                  }
                  data={data}
                  defaultColumn={defaultColumn}
                  getLedger={props.getLedger}
                  offset={props.offset}
                  onOffset={props.handleOffset}
                  lastPage={lastPage}
                  onChange={handleFilterChange}
                  pageCount={lastPage}
                  sortDesc={true}
                  sortId={
                    (!props.bills && !props.vendors && "postedDate") ||
                    (props.bills && "postingDate") ||
                    (props.vendors && "id")
                  }
                  totalItems={props.totalItems}
                  value={filterInput}
                />
              </div>
            </div>
          </main>
        </>
      ) : (
        <ViewInvoiceDetails
          property={props.property}
          invoice={viewInvoiceData}
          invoices={viewInvoicesData}
          ledgerView={viewInvoice}
        />
      )}
    </>
  );
}
