import gql from "graphql-tag";

export const fusebitData = gql`
  query fusebitConfiguration {
    fusebitConfiguration {
      edges {
        node {
          id
          baseUrl
          accountId 
          install
          serviceLogoUrl
          returnUrl
          templateManagerUrl
          subscriptionId
          boundaryId
          functionId
          templateName
        }
      }
    }
  }
`;

export const createBackgroundCheckGql = gql`
  mutation tenantAlertRequest($input: TenantAlertRequestInput!) {
    tenantAlertRequest(input: $input) {
      response
    }
  }
`;