import React, { useMemo, useEffect } from "react";
import ReconcileTable from "./TrialTable";
import moment from "moment";

export function Table(props) {
  const Status = ({ values }) => {
    const numberize = Number(values);
    if (Math.sign(numberize) === 1) {
      return <p style={{ color: "black" }}>${Math.abs(Number.parseFloat(numberize)).toLocaleString("en")}</p>;
    }
    if (numberize === 0||isNaN(numberize)) {
      return "--";
    } else {
      return <p style={{ color: "black" }}>$({Math.abs(Number.parseFloat(numberize)).toLocaleString("en")})</p>;
    }
  };

  const defaultColumn = React.useMemo(() => ({ Filter: "" }), []);
  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Number",
        accessor: "name",
        Cell: ({ cell: { value } }) => (value && value.substring(0,value.length-2)+"-"+value.substring(value.length-2)),
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(11, "months").format("MM/YYYY")
            : moment().subtract(11, "years").format("YYYY"),
            accessor: `data[${moment().subtract(11, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(10, "months").format("MM/YYYY")
            : moment().subtract(10, "years").format("YYYY"),
            accessor: `data[${moment().subtract(10, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(9, "months").format("MM/YYYY")
            : moment().subtract(9, "years").format("YYYY"),
            accessor: `data[${moment().subtract(9, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",

      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(8, "months").format("MM/YYYY")
            : moment().subtract(8, "years").format("YYYY"),
            accessor: `data[${moment().subtract(8, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(7, "months").format("MM/YYYY")
            : moment().subtract(7, "years").format("YYYY"),
            accessor: `data[${moment().subtract(7, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(6, "months").format("MM/YYYY")
            : moment().subtract(6, "years").format("YYYY"),
            accessor: `data[${moment().subtract(6, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(5, "months").format("MM/YYYY")
            : moment().subtract(5, "years").format("YYYY"),
            accessor: `data[${moment().subtract(5, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(4, "months").format("MM/YYYY")
            : moment().subtract(4, "years").format("YYYY"),
            accessor: `data[${moment().subtract(4, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(3, "months").format("MM/YYYY")
            : moment().subtract(3, "years").format("YYYY"),
            accessor: `data[${moment().subtract(3, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(2, "months").format("MM/YYYY")
            : moment().subtract(2, "years").format("YYYY"),
            accessor: `data[${moment().subtract(2, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().subtract(1, "months").format("MM/YYYY")
            : moment().subtract(1, "years").format("YYYY"),
        accessor: `data[${moment().subtract(1, "months").format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
      {
        Header:
          props.monthYear === "Monthly"
            ? moment().format("MM/YYYY")
            : moment().format("YYYY"),
        accessor: `data[${moment().format("M")}]`,
        Cell: ({ cell: { value } }) =>
          value ? <Status values={value} /> : "--",
      },
    ],
    []
  );
  /* eslint-enable */

  const { trialsCache, totalItems, ReconcileAccount, columnLimit } = props;
  return (
    <>
      {trialsCache && (
        <ReconcileTable
          columns={columns}
          columnLimit={columnLimit}
          data={trialsCache}
          defaultColumn={defaultColumn}
          sortDesc={true}
          sortId={"name"}
          totalItems={totalItems}
          update={ReconcileAccount}
        />
      )}
    </>
  );
}