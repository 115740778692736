import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { TaxAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { Table } from "../../components/Tables/TaxData";
import { ACCOUNTING } from "../../utils/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TaxCreationAccounting from "../../components/Modals/TaxCreationAccounting";

const taxClient = getClient(ACCOUNTING);

class Tax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      taxCache: [],
      offset: 0
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Tax",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    try {
      this.setState({ loading: true });
      taxClient
        .query({
          query: TaxAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            offset: this.state.offset,
            limit: 30,
          },
        })
        .then((res) => {
          if (res.data) {
            this.setState({
              taxCache: get(res, "data.slLocationTaxCodes.data", []),
              totalItems: get(res, "data.slLocationTaxCodes.totalItems"),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
    }
  };

  dataPush = (response) => {
    this.setState({ loading: true });
    const node = response.data.createSlTaxCode.slTaxCode;
    this.setState((prevState) => ({
      taxCache: [node, ...prevState.taxCache],
      loading: false,
    }));
  };

  updateInvoice = (update, taxId) => {
    const { taxCache } = this.state;
    const array = [];
    if (update === "Delete") {
      this.setState({
        taxCache: taxCache.filter((tax) => tax._id !== taxId),
      });
    } else {
      taxCache.forEach((tax) => {
        if (tax._id === update.data.updateSlTaxCode.slTaxCode._id) {
          tax = update.data.updateSlTaxCode.slTaxCode;
          array.push(tax);
        } else {
          array.push(tax);
        }
      });
      this.setState({ taxCache: array });
    }
  };

  handleOffset = (x) => {
    this.setState({ offset: x });
  }

  render() {
    const { loading } = this.state;
    const success = () =>
      toast.success("Success!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    return (
      <>
        <TaxCreationAccounting
          success={success}
          dataPush={this.dataPush}
          updateInvoice={this.updateInvoice}
          propertyId={
            this.props.selectedPrimary.node &&
            this.props.selectedPrimary.node.id
          }
          propertyData={
            this.props.selectedPrimary && this.props.selectedPrimary
          }
        />
        {loading ? (
          <Loader text inTable />
        ) : (
          <Table
            totalItems={this.state.totalItems && this.state.totalItems}
            updateInvoice={this.updateInvoice}
            offset={this.state.offset}
            handleOffset={this.handleOffset}
            getLedger={this.getLedger}
            taxCache={this.state.taxCache && this.state.taxCache}
            propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
          />
        )}
      </>
    );
  }
}

export default withApollo(Tax);
