/* eslint-disable */
// @flow
import Cookie from "js-cookie";
import config from "../../config";
import { getAuthToken, getClient, logoutAction } from ".";
import {
  signInWithEmailAndPassword,
  getIdTokenResult,
  getIdToken,
  onAuthStateChanged,
} from "firebase/auth";
import mixpanel from "mixpanel-browser";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import {
  getEmitter,
  TOKEN,
  PERSON_URL,
  RELOAD,
  LOGIN,
  SESSION_TIMEOUT_THRESHOLD,
  USER_EVENT,
  USER_ROLE_EVENT,
  RENTER,
  MANAGER,
  SERVICEPROFESSIONAL,
  USER_LOC,
  LOCATIONAPI,
  EXPIRE_TIME,
  NOTIFICATION,
} from "../../utils";
import { personGql } from "../person/person";
import { auth, messaging, createToken } from "../../containers/Auth/Firebase";

let sessionTimeout = null;
export const registerAuthToken = (token) => {
  Cookie.set(TOKEN, token, {
    domain: config.domain,
  });
};

// export const refreshToken = () => {
//   auth.currentUser.getIdToken(true)
// }

export const loginAction = ({ email, password }, client) =>
  signInWithEmailAndPassword(auth, email, password)
    .then(async () => auth.currentUser)
    .catch((e) => {
      console.log("User e-mail id or password is incorrect.");
    });

const setSessionTimeout = (duration, client) => {
  clearTimeout(sessionTimeout);
  sessionTimeout = setTimeout(
    refreshToken,
    (duration - SESSION_TIMEOUT_THRESHOLD) * 1000,
    { client }
  );
};

const getToken = async (setTokenFound) => {
  const personMainClient = getClient(PERSON_URL);
  const currentToken = await createToken();
  try {
    await personMainClient.mutate({
      mutation: FCMAttachDevice,
      variables: {
        input: {
          device: {
            deviceType: "WEB",
            deviceToken: currentToken,
          },
        },
      },
    });
    const eventEmit = getEmitter();
    eventEmit.emit(CURRENT_TOKEN, currentToken);
  } catch (e) {}
};

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const refreshToken = ({ client }) => {
  const session = getAuthToken();
  if (!session) {
    return Promise.reject();
  }
  onRequestSuccess(client);
};

export const refreshToken2 = () => {
  getIdToken(auth.currentUser, true);
};

export const handleMangerLogin = (client) => {
  return getIdTokenResult(auth.currentUser)
    .then(async (idTokenResult) => {
      if (
        idTokenResult.claims.manager &&
        idTokenResult.claims.email_verified == true
      ) {
        
        onRequestSuccess(client);
        return true;
      }
      if (
        idTokenResult.claims.manager &&
        idTokenResult.claims.email_verified == false
      ) {
        toast.warning("Please make sure to verify your email.", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return "Verify";
      } else return false;
    })
    .catch(() => {
      return false;
    });
};

export const onRequestSuccess = async (client, from = LOGIN) => {
  const time = "3600";
  if (from === LOGIN || (from === RELOAD && getAuthToken())) {
    let authFlag = true;
    if (auth.currentUser) {
      getIdTokenResult(auth.currentUser)
        .then(async (idTokenResult) => {

          if (
            idTokenResult.claims.manager ||
            idTokenResult.claims.serviceProfessional
          ) {
            onAuthStateChanged(auth, async (user) => {
              if (authFlag) {
                authFlag = false;
                if (user) {
                  const token = await getIdToken(auth.currentUser, true);
                  mixpanel.identify(user.email);
                  mixpanel.track("Renter Log In Success", {
                    Email: user.email,
                    sub: "Log in Success",
                  });
                  mixpanel.people.set({
                    Email: user.email,
                    Name: user.displayName,
                  });
                  mixpanel.register({
                    Email: user.email,
                    Name: user.displayName,

                  });
                  registerAuthToken(token);
                  getUserRoles();
                  client.resetStore();
                  const eventEmit = getEmitter();

                  // expire time is current time  plus 55 minutes more
                  Cookie.set(
                    EXPIRE_TIME,
                    new Date().getTime() + 55 * 60 * 1000,
                    {
                      domain: config.domain,
                    }
                  );
                  setSessionTimeout(time, client);

                  if (from === LOGIN) {
                    const personMainClient = getClient(PERSON_URL);
                    try {
                      personMainClient
                        .query({
                          query: personGql,
                        })
                        .then((res) => {
                          eventEmit.emit(USER_EVENT, { data: res.data.person });
                          getToken();
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    } catch (e) {
                      return;
                    }
                  }
                }
              }
            });
          }
        })
        .catch(() => {
          console.log("id token catch");
          return false;
        });
    } else {
      console.log("Currrent user unavailable.");
      // logoutAction(client).then(() => setTimeout(() => {
      //   window.location.href = '/'
      // }, 1000))
    }
  }
};

export const getUserRoles = async () => {
  auth.currentUser
    .getIdTokenResult(true)
    .then((idTokenResult) => {
      if (idTokenResult.claims) {
        const eventEmit = getEmitter();
        Cookie.set(RENTER, idTokenResult.claims.renter, {
          domain: config.domain,
        });
        Cookie.set(MANAGER, idTokenResult.claims.manager, {
          domain: config.domain,
        });
        
        Cookie.set(
          SERVICEPROFESSIONAL,
          idTokenResult.claims.serviceProfessional,
          {
            domain: config.domain,
          }
        );
        Cookie.set("claims", idTokenResult.claims.organization_id);
        Cookie.set("locations", idTokenResult.claims.locations);
        eventEmit.emit(USER_ROLE_EVENT);
        console.log(idTokenResult.claims)
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export default { loginAction, onRequestSuccess };
