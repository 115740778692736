import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { NavLink } from "react-router-dom";
import {
  Button,
  Header,
  Form,
  Checkbox,
  Dropdown,
  Select,
  Input,
  Breadcrumb,
} from "semantic-ui-react";
import { pmSingleApplicationGql } from "../../store/person/applications";
import moment from "moment";
import Datetime from "react-datetime";
import { get } from "lodash";
import { PageLoader } from "../../components/Loader/PageLoader";
import ChargeCodeModal from "../../components/Modals/ChargeCodeModal";
import { unitType, units } from "../../store/person/properties";
import { bluemoonPolicies } from "../../store/bluemoon/bluemoon";
import {
  ChargeAccounting,
  DefaultChargeCodes,
} from "../../store/person/accounting";
import { getClient } from "../../store/auth";
import {
  getLeaseClaim,
  unitAvailabilityCheck,
  proRate,
  createLease,
  locationPolicies,
  lgavailable,
} from "../../store/person/leases";
import {
  APPLICATION_MANAGER,
  LEASE_MANAGER,
  BLUEMOON_API,
  LEASE_TIME_FORMAT,
  UNITAPI,
  LEASE_FREQUENCY,
  UNIT_TYPE_API,
  ACCOUNTING,
  CHARGE_CODE,
  LOCATIONAPI,
} from "../../utils";
import {
  toastFailMsg,
  disablePastDt,
  toastSuccessMsg,
} from "../../utils/common";
import mixpanel from "mixpanel-browser";
const leaseManager = getClient(LEASE_MANAGER);
const bluemoonApi = getClient(BLUEMOON_API);
const unitManager = getClient(UNITAPI);
const unitTypeManager = getClient(UNIT_TYPE_API);
const chargeClient = getClient(ACCOUNTING);
const chargeCodeClient = getClient(CHARGE_CODE);
const applicationQuery = getClient(APPLICATION_MANAGER);
const locationClient = getClient(LOCATIONAPI);
const requestOptions = [
  { key: "T", text: "Approve", value: "accept" },
  { key: "G", text: "Deny", value: "reject" },
];
const customDataOptions = [];
const standardDataOptions = [];
const policyDataOptions = [];

class CreateLease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showSignButton: true,
      requestStatus: false,
      rejectReason: false,
      startDate: "",
      endDate: "",
      moveInDate: moment().startOf("day").format().slice(0, -6),
      proRateEnabled: false,
      arr: [],
      billDate: 1,
      unitTypeData: [],
      unitTypeText: "",
      locationId: "",
      unitsData: [],
      unitNumberText: "",
      unitChargeAmount: "",
      unit: "",
      frequency: "Monthly",
      charges: false,
      unitChargeText: "",
      unitChargeCode: "",
      petChargeAmount: "",
      vehicleChargeAmount: "",
      unitProRata: "",
      unitQuantity: 1,
      petChargeText: "",
      petProRata: "",
      petQuantity: 1,
      vehicleChargeText: "",
      vehicleChargeCode: "",
      vehicleProRata: "",
      vehicleQuantity: 1,
      depositChargeText: "",
      depositChargeCode: "",
      fixedDepositAmount: "",
      refundableAmount: "",
      extraCharges: [],
      leaseGuarantee: false,
      firstLastInvoice: false,
      billOnDate: "",
      frequencyAmount: "",
      isBluemoon: false,
      lgavail: false,
      isDocuSign: false,
      customPolicies: [],
      standardPolicies: [],
      bmCustomForms: [],
      bmStandardForms: [],
      standardOptions: [],
      customOptions: [],
      chargeOptions: [],
      signProcess: "esign",
      docusignPolicies: [],
      docusignOptions: [],
      dsFormsList: [],
    };
  }

  componentDidMount() {
    const { application, location, isBluemoon, isDocuSign } =
      this.props.location;
    const { id } =
      this.props.selectedPrimary && this.props.selectedPrimary.node;
    const locationId = id;

    if (application && location) {
      this.setState({
        application: application,
        moveInDate: moment(application.targetMoveInDate).format(
          LEASE_TIME_FORMAT
        ),
        unitChargeAmount:
          application.unitNumber && application.unitNumber.price,
        fixedDepositAmount:
          location.depositRules && location.depositRules.length
            ? location.depositRules[0].deposit_amount
            : application.unitNumber
              ? application.unitNumber.deposit
              : 0,
        refundableAmount:
          location.depositRules && location.depositRules.length
            ? location.depositRules[0].deposit_nonrefundable_amount
            : application.unitNumber
              ? application.unitNumber.nonRefundable
              : Number(0),
        unitTypeText: application.unitType && application.unitType.type,
        unitNumberText: application.unitNumber && application.unitNumber.number,
        unit: application.unitNumber && application.unitNumber.id,
        leaseGuarantee: false,
        firstLastInvoice:
          location.depositRules && location.depositRules.length
            ? location.depositRules[0].fl_month_required
            : false,
        locationId: locationId,
        isBluemoon: isBluemoon,
        isDocuSign: isDocuSign,
      });
      mixpanel.track("Manager Lease Action", {
        sub: "Create Lease Page Application Prefill",
      });
      this.lgAvail(application);
    } else {
      this.fetchApplication();
    }
    this.getLedger();
    this.setState({ arr: this.prepareBillDateArr() });
    mixpanel.track("Manager Lease Action", {
      sub: "Create Lease Page Rendered",
    });
  }

  fetchApplication = async () => {
    this.setState({ loading: true });
    const params = this.props.match.params;
    try {
      mixpanel.track("Manager Lease Action", {
        sub: "Create Lease Page Application Query",
      });
      await applicationQuery
        .query({
          query: pmSingleApplicationGql,
          variables: {
            applicationId: params.id,
          },
        })
        .then((res) => {
          const application =
            res.data.application &&
            res.data.application.edges[0] &&
            res.data.application.edges[0].node;
          const location = this.props.selectedPrimary.node;
          application &&
            this.setState({
              application: application,
              startDate: moment(application.targetMoveInDate).format(
                LEASE_TIME_FORMAT
              ),
              unitChargeAmount:
                application.unitNumber && application.unitNumber.price,
              fixedDepositAmount:
                location.depositRules && location.depositRules.length
                  ? location.depositRules[0].deposit_amount
                  : application.unitNumber
                    ? application.unitNumber.deposit
                    : 0,
              refundableAmount:
                location.depositRules && location.depositRules.length
                  ? location.depositRules[0].deposit_nonrefundable_amount
                  : application.unitNumber
                    ? application.unitNumber.nonRefundable
                    : 0,
              unitTypeText: application.unitType && application.unitType.type,
              unitNumberText:
                application.unitNumber && application.unitNumber.number,
              unit: application.unitNumber && application.unitNumber.id,
              leaseGuarantee: false,
              firstLastInvoice:
                location.depositRules && location.depositRules.length
                  ? location.depositRules[0].fl_month_required
                  : false,
              locationId: params.location,
              isBluemoon:
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.esginType === "bluemoon",
              isDocuSign:
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.esginType === "docusign",
            });
          this.lgAvail(application);
          this.getLedger();
          this.setState({ arr: this.prepareBillDateArr() });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  prepareBillDateArr = () => {
    const arr = Array.from(Array(32), (_, i) => i);
    arr.shift();
    return arr;
  };

  prepareChargeOptions = (item) => {
    const { charges } = this.state;
    const chargeOptions =
    item && item.map((charge) => ({
        key: charge.node.id,
        text: charge.node.name,
        value: charge.node,
      }));
    this.setState({ chargeOptions: chargeOptions });
  };

  prepareCustomOptions = () => {
    const { customPolicies } = this.state;
    const customOptions =
      customPolicies &&
      customPolicies.map((policy) => ({
        key: policy.label,
        text: policy.label,
        value: policy.name,
      }));
    this.setState({ customOptions: customOptions });
  };

  prepareStandardOptions = () => {
    const { standardPolicies } = this.state;
    const standardOptions =
      standardPolicies &&
      standardPolicies.length &&
      standardPolicies.map((policy) => ({
        key: policy.label,
        text: policy.label,
        value: policy.name,
      }));
    this.setState({ standardOptions: standardOptions });
  };

  fetchBluemoonPolicies = async () => {
    const { application } = this.props.location.createLease
      ? this.props.location
      : this.state;
    const { id } =
      this.props.selectedPrimary && this.props.selectedPrimary.node;
    customDataOptions.length = 0;
    standardDataOptions.length = 0;
    this.setState({ isLoading: true });
    await bluemoonApi
      .query({
        query: bluemoonPolicies,
        variables: {
          locationId: id,
        },
      })
      .then((res) => {
        const result = res.data.bluemoonForms && res.data.bluemoonForms.edges;
        this.setState({ customPolicies: result && result[0].node.custom });
        this.setState({ standardPolicies: result && result[0].node.standard });
        const options = application.node && application.node.extLeaseInfo;
        if (options) {
          Array.prototype.push.apply(customDataOptions, options.custom_forms);
          Array.prototype.push.apply(
            standardDataOptions,
            options.standard_forms
          );
          this.setState({ bmCustomForms: options.custom_forms });
          this.setState({ bmStandardForms: options.standard_forms });
        }
        this.setState({ isLoading: false });
        this.prepareStandardOptions();
        this.prepareCustomOptions();
      });
  };

  prepareDocuSignOptions = (data) => {
    const { docusignPolicies } = this.state;
    policyDataOptions.length = 0;
    const docusignOptions =
    data.length !== 0&&
    data.map((policy) => ({
        key: policy.node.name,
        text: policy.node.name,
        value: policy.node.id,
      }));
    const arr =
    data.length &&
      data.map((policy) => policy.node.id);
      console.log(docusignOptions, arr)
    Array.prototype.push.apply(policyDataOptions, arr);
    this.setState({ docusignOptions: docusignOptions, dsFormsList: arr });
  };

  fetchDocuSignPolicies = () => {
    const { locationId } = this.state;
    locationClient
      .query({
        query: locationPolicies,
        variables: { locationId },
      })
      .then((response) => {
        const data = response.data.locationPolicies.edges;
        this.setState({ docusignPolicies: data });
        this.prepareDocuSignOptions(data);
      })
      .catch((err) => {
        // toastFailMsg("Error", err);
      });
  };
  lgAvail = (item) => {
    leaseManager
      .query({
        query: lgavailable,
        variables: {
          applicationId: item.id,
        },
      })
      .then((response) => {
        if (response.data.isLgAvailable === true) {
          this.setState({ lgavail: true });
        } else this.setState({ lgavail: false });
      })
      .catch((err) => { });
  };

  getDefaultChargeCodes = () => {
    const { application } = this.props.location.createLease
      ? this.props.location
      : this.state;
    const { id } =
      this.props.selectedPrimary && this.props.selectedPrimary.node;
    chargeCodeClient
      .query({
        query: DefaultChargeCodes,
        variables: {
          locationId: id,
        },
      })
      .then((res) => {
        if (res.data) {
          console.log(res.data)
          const data =
            res.data.defaultChargeCodes.edges &&
            res.data.defaultChargeCodes.edges[0];
          const {
            unitChargeCode,
            petChargeCode,
            vehicleChargeCode,
            depositChargeCode,
          } = data.node;
          if (unitChargeCode) {
            this.setState({
              unitChargeText: unitChargeCode.name,
              unitChargeCode: unitChargeCode.id,
              unitChargeAmount: application.unitNumber
                ? application.unitNumber.price
                : unitChargeCode.amountDefault,
              unitQuantity: unitChargeCode.quantity,
            });
          }
          if (petChargeCode) {
            this.setState({
              petChargeText: petChargeCode.name,
              petChargeCode: petChargeCode.id,
              petChargeAmount: petChargeCode.amountDefault,
              petQuantity: petChargeCode.quantity,
            });
          }
          if (vehicleChargeCode) {
            this.setState({
              vehicleChargeText: vehicleChargeCode.name,
              vehicleChargeCode: vehicleChargeCode.id,
              vehicleChargeAmount: vehicleChargeCode.amountDefault,
              vehicleQuantity: vehicleChargeCode.quantity,
            });
          }
          if (depositChargeCode) {
            console.log(depositChargeCode)
            this.setState({
              depositChargeText: depositChargeCode.name,
              depositChargeCode: depositChargeCode.id,
              fixedDepositAmount: depositChargeCode.amountDefault,
              refundableAmount: depositChargeCode.amountDefault,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getUnitTypeData = () => {
    const { isBluemoon, isDocuSign } = this.props.location.createLease
      ? this.props.location
      : this.state;
    const { id } =
      this.props.selectedPrimary && this.props.selectedPrimary.node;
    unitTypeManager
      .query({
        query: unitType,
        variables: {
          locationId: id,
        },
      })
      .then((res) => {
        if (res.data) {
          const data = [];
          res.data.unitType.edges.map((ele) => {
            data.push(
              ele && {
                key: ele.node.id,
                text: ele.node.type,
                value: {
                  name: ele.node.type,
                  id: ele.node.id,
                  field: "unitType",
                },
              }
            );
            return null;
          });
          this.setState({ unitTypeData: data });
        }
        this.getDefaultChargeCodes();
        isBluemoon && this.fetchBluemoonPolicies();
        isDocuSign && this.fetchDocuSignPolicies();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getLedger = () => {
    this.getUnitTypeData();
    this.setState({ isLoading: true });
    const { id } =
      this.props.selectedPrimary && this.props.selectedPrimary.node;
    const { application } = this.state;
    /* eslint-disable */
    if (application && (application.unitType || application.node)) {
      this.fetchUnitNumber(
        "event",
        "value",
        application.unitType ||
        (application.node.unit && application.node.unit.unitType),
        "update"
      );
    }
    /* eslint-enable */
    chargeClient
      .query({
        query: ChargeAccounting,
        variables: {
          location: id,
        },
      })
      .then((res) => {
        if (res.data) {
          console.log(res.data)
          this.setState({
            charges: get(res, "data.chargeCodes.edges"),
            isLoading: false,
          });
          this.prepareChargeOptions(get(res, "data.chargeCodes.edges"));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  selectFrequency = (e) => {
    this.setState({ frequency: e });
    switch (e) {
      case "Weekly":
        this.setState({ frequencyAmount: 0.5 });
        break;
      case "Monthly":
        this.setState({ frequencyAmount: 1 });
        break;
      case "Yearly":
        this.setState({ frequencyAmount: 12 });
        break;
      default:
        break;
    }
  };

  prepareExtraCharges = () =>
   this.state.charges.map((ch) => ({
        amountDefault: ch.amount,
        id: ch.charge_code_id,
        quantity: ch.quantity,
        proRata: ch.pro_rata,
      }))
   ;

  onChangeExtraCharge = (e, { value }, index) => {
    const { extraCharges } = this.state;
    const updatedExtraCharges = [...extraCharges];
    updatedExtraCharges[index] = value;
    this.setState({ extraCharges: updatedExtraCharges });
  };

  onChangeExtraChargeAmount = (value, key, index) => {
    const { extraCharges } = this.state;
    const updatedExtraCharges = [...extraCharges];
    updatedExtraCharges[index][key] = value;
    this.setState({ extraCharges: updatedExtraCharges });
    if (key === "amountDefault") {
      this.setState({ proRateEnabled: false });
    }
  };

  leaseClaim = () => {
    const { leaseId } = this.state;
    this.setState({ isLoading: true });
    leaseManager
      .query({
        query: getLeaseClaim,
        variables: { leaseId },
      })
      .then((response) => {
        if (response.errors) {
          toastFailMsg(response.errors[0].message);
        } else if (response.data.leaseClaim) {
          this.setState({ leaseClaimed: true });
        }
      })
      .catch(() => {
        toastFailMsg("Failed to claim");
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  setDate = (e, field) => {
    const dateRes = moment(e).format(LEASE_TIME_FORMAT);
    if (field === "startDate") {
      this.setState({ startDate: dateRes, proRateEnabled: false });
    }
    if (field === "moveInDate") {
      this.setState({ moveInDate: dateRes, proRateEnabled: false });
    } else {
      this.setState({ endDate: dateRes });
    }
  };

  getRequest = (event, data) => this.setState({ requestStatus: data.value });

  setRejectReason = (value) => this.setState({ rejectReason: value });

  changeBillDate = (e) => {
    this.setState({
      billDate: Number(e.target.textContent),
      proRateEnabled: false,
    });
    this.newBillDate();
  };

  fetchUnitNumber = async (e, { value }, id, isUpdate) => {
    await unitManager
      .query({
        query: units,
        variables: {
          locationId: this.props.selectedPrimary.node.id,
          unitTypesId: !id ? value.id : id.id,
        },
      })
      .then((res) => {
        const data = [];
        res.data.units.edges.map((ele) => {
          data.push(
            ele && {
              key: ele.node.id,
              text: ele.node.number,
              value: {
                name: ele.node.number,
                id: ele.node.id,
                field: "unitNumber",
                deposit: ele.node.deposit,
                refund: ele.node.nonRefundable,
                price: ele.node.price,
              },
            }
          );
          return null;
        });
        console.log(data)
        this.setState({ unitsData: data });
        if (isUpdate !== "update") {
          this.setState({
            unitNumberText: "",
            unitAmount: "",
            proRateEnabled: false,
          });
        }
        this.setState({ unitTypeText: !id ? value.name : id.type });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  selectUnit = (e, { value }) => {
    this.setState({
      unitNumberText: value.name,
      proRateEnabled: false,
      unit: value.id,
      unitChargeAmount: value.price,
    });

  };

  selectUnitChargeCode = (e, { value }) => {
    this.setState({
      unitChargeCode: value.id,
      unitChargeText: value.name,
      unitChargeAmount: value.amountDefault,
      proRateEnabled: false,
    });
  };

  selectPetChargeCode = (e, { value }) => {
    this.setState({
      petChargeCode: value.id,
      petChargeText: value.name,
      petChargeAmount: value.amountDefault,
      proRateEnabled: false,
    });
  };

  selectVehicleChargeCode = (e, { value }) => {
    this.setState({
      vehicleChargeCode: value.id,
      vehicleChargeText: value.name,
      vehicleChargeAmount: value.amountDefault,
      proRateEnabled: false,
    });
  };

  selectDepositChargeCode = (e, { value }) => {
    console.log(value)
    this.setState({
      depositChargeCode: value.id,
      depositChargeText: value.name,
      fixedDepositAmount: value.amountDefault,
      refundableAmount: value.amountDefault,
    });
  };

  setDateChange = (value) => {
    if (value && value.toString().length === 1) {
      return `0${value}`;
    }
    return value;
  };

  newBillDate = () => {
    const { startDate, billDate } = this.state;
    let newDate = "";
    if (startDate) {
      const temp = startDate.split("T");
      const temp1 = temp[0].split("-");
      if (temp1[1] === "12") {
        newDate = `${Number(temp1[0]) + 1}-01-${this.setDateChange(billDate)}T${temp[1]
          }`;
      } else {
        newDate = `${temp1[0]}-${this.setDateChange(
          Number(temp1[1]) + 1
        )}-${this.setDateChange(billDate)}T${temp[1]}`;
      }
      this.setState({ billOnDate: newDate });
    }
  };

  proRateMutation = () => {
    const {
      startDate,
      billDate,
      unitChargeAmount,
      petChargeAmount,
      vehicleChargeAmount,
      extraCharges,
    } = this.state;
    const itemsToReturn = [
      {
        inputDate: moment(startDate).format("YYYY-MM-DD"),
        billOnDate: billDate,
        monthlyRent: unitChargeAmount,
      },
      {
        inputDate: moment(startDate).format("YYYY-MM-DD"),
        billOnDate: billDate,
        monthlyRent: petChargeAmount,
      },
      {
        inputDate: moment(startDate).format("YYYY-MM-DD"),
        billOnDate: billDate,
        monthlyRent: vehicleChargeAmount,
      },
    ];
    if (extraCharges.length) {
      extraCharges.forEach((charge, index) => {
        itemsToReturn.push({
          id: index,
          inputDate: moment(startDate).format("YYYY-MM-DD"),
          billOnDate: billDate,
          monthlyRent: charge.amountDefault,
        });
      });
    }
    try {
      mixpanel.track("Manager Lease Action", {
        sub: "ProRate Set",
      });
      leaseManager
        .mutate({
          mutation: proRate,
          variables: {
            input: {
              items: itemsToReturn,
              leaseFrequency: this.state.frequency,
            },
          },
        })
        .then((res) => {
          const data = res.data.proRata.results;
          this.newBillDate();
          this.setState({
            unitProRata: data[0].finalAmount,
            petProRata: data[1].finalAmount,
            vehicleProRata: data[2].finalAmount,
          });
          if (data.length > 3) {
            data
              .filter((value, index) => index >= 3)
              .map((value, index) => {
                this.onChangeExtraChargeAmount(
                  value.finalAmount,
                  "proRata",
                  index
                );
                return null;
              });
          }
        });
    } catch (e) {
      alert(e);
    }
  };

  updateCheckboxOption = (value, field) => {
    const {
      startDate,
      billDate,
      unitChargeAmount,
      petChargeAmount,
      vehicleChargeAmount,
    } = this.state;
    if (field === "LG") {
      this.setState({ leaseGuarantee: value });
    } else if (field === "FLM") {
      this.setState({ firstLastInvoice: value });
    } else if (
      typeof startDate === "string" &&
      billDate &&
      unitChargeAmount &&
      petChargeAmount &&
      vehicleChargeAmount
    ) {
      this.setState({ proRateEnabled: value });
      value && this.proRateMutation();
    } else {
      if (typeof startDate !== "string") {
        toastFailMsg("Fill Start Date for Pro Rate");
      } else if (!billDate) {
        toastFailMsg("Fill Bill Date for Pro Rate");
      } else if (!unitChargeAmount) {
        toastFailMsg("Fill Unit Charge Amount for Pro Rate");
      } else if (!petChargeAmount) {
        toastFailMsg("Fill Pet Charge Amount for Pro Rate");
      }
      if (!vehicleChargeAmount) {
        toastFailMsg("Fill Vehicle Charge Amount Date for Pro Rate");
      }
    }
  };

  onChangeChargeAmount = (e, key) => {
    this.setState({ proRateEnabled: false });
    switch (key) {
      case "unitChargeAmount":
        this.setState({ unitChargeAmount: e.target.value });
        break;
      case "petChargeAmount":
        this.setState({ petChargeAmount: e.target.value });
        break;
      case "vehicleChargeAmount":
        this.setState({ vehicleChargeAmount: e.target.value });
        break;
      default:
        break;
    }
  };

  setCustomData = (e, { value }) => {
    this.setState({ bmCustomForms: value });
  };

  selectFreq = (e, { value }) => {
    this.selectFrequency(value);
    this.setState({ proRateEnabled: false });
  };

  setStandardData = (e, { value }) => {
    this.setState({ bmStandardForms: value });
  };

  setDocuData = (e, { value }) => {
    this.setState({ dsFormsList: value });
  };

  totalCharges = () => {
    const {
      unitQuantity,
      petQuantity,
      vehicleQuantity,
      unitChargeAmount,
      petChargeAmount,
      vehicleChargeAmount,
      extraCharges,
    } = this.state;
    const allCharges = [
      unitChargeAmount * Number(unitQuantity),
      petChargeAmount * Number(petQuantity),
      vehicleChargeAmount * Number(vehicleQuantity),
      ...extraCharges.map(
        (charge) => Number(charge.amountDefault) * Number(charge.quantity)
      ),
    ];
    return allCharges.reduce((total, amount) => {
      // eslint-disable-next-line no-param-reassign
      total += Number(amount);
      return total;
    }, 0);
  };

  createLease = async () => {
    this.setState({ isLoading: true });
    const {
      unit,
      unitChargeCode,
      petChargeCode,
      vehicleChargeCode,
      depositChargeCode,
      unitChargeAmount,
      petChargeAmount,
      vehicleChargeAmount,
      petQuantity,
      vehicleQuantity,
      moveInDate,
      fixedDepositAmount,
      refundableAmount,
      startDate,
      endDate,
      extraCharges,
      frequency,
      leaseGuarantee,
      firstLastInvoice,
      proRateEnabled,
      unitProRata,
      petProRata,
      vehicleProRata,
      billOnDate,
      isBluemoon,
      isDocuSign,
      locationId,
      bmCustomForms,
      bmStandardForms,
      dsFormsList,
      signProcess,
    } = this.state;
    const { application } = this.props.location;
    let dataToSend = {
      unit,
      unitChargeCode,
      petChargeCode,
      vehicleChargeCode,
      depositChargeCode,
      petQuantity,
      vehicleQuantity,
      unitAmount: Number(unitChargeAmount),
      petAmount: Number(petChargeAmount),
      vehicleAmount: Number(vehicleChargeAmount),
      deposit: Number(fixedDepositAmount),
      nonRefundable: Number(refundableAmount),
      startDate: moment(startDate).startOf("day").format().slice(0, -6),
      moveInDate: moment(moveInDate).startOf("day").format().slice(0, -6),
      endDate: moment(endDate).startOf("day").format().slice(0, -6),
      leaseFrequency: frequency,
      guarantee: leaseGuarantee,
      firstLastInvoice,
      applicationId: application.id,
      leaseType: signProcess,
    };
    if (proRateEnabled) {
      dataToSend = {
        ...dataToSend,
        unitProRata,
        petProRata,
        vehicleProRata,
        billOnDate,
      };
    }
    if (isBluemoon && signProcess === "esign") {
      dataToSend = { ...dataToSend, bmCustomForms, bmStandardForms };
    }
    if (isDocuSign && signProcess === "esign") {
      dataToSend = { ...dataToSend, dsFormsList };
    }
    if (petChargeCode !== "" && petChargeCode !== null) {
      dataToSend = { ...dataToSend, petChargeCode };
    }
    if (vehicleChargeCode !== "" && vehicleChargeCode !== null) {
      dataToSend = { ...dataToSend, vehicleChargeCode };
    }
    if (
      proRateEnabled &&
      unitProRata &&
      petProRata &&
      vehicleProRata &&
      billOnDate
    ) {
      dataToSend = {
        ...dataToSend,
        isProRata: proRateEnabled,
        unitProRata,
        vehicleProRata,
        petProRata,
        billOnDate,
      };
    }
    if (
      bmCustomForms &&
      bmStandardForms &&
      bmCustomForms.length &&
      bmStandardForms.length
    ) {
      dataToSend = { ...dataToSend, bmCustomForms, bmStandardForms };
    }
    dataToSend.extraCharges = extraCharges
      ? extraCharges.map((charge) => ({
        chargeCodeId: charge.id,
        amount: charge.amountDefault,
        quantity: charge.quantity,
        proRata: charge.proRata,
      }))
      : [];
    try {
      mixpanel.track("Manager Lease Action", {
        sub: "Create Lease",
      });
      await leaseManager
        .mutate({
          mutation: createLease,
          variables: {
            input: {
              lease: dataToSend,
            },
          },
        })
        .then((res) => {
          this.setState({ isLoading: false }, () => {
            this.props.history.push({
              pathname: "/lease",
            });
          });
          toastSuccessMsg("Lease created successfully");
        });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
      alert(e);
    }
  };

  checkForUnitAvailability = async () => {
    this.setState({ isLoading: true });
    const { unit, startDate, endDate } = this.state;
    let value = true;
    await leaseManager
      .query({
        query: unitAvailabilityCheck,
        variables: {
          unitId: unit,
          startDate,
          endDate,
        },
      })
      .then((res) => {
        value = res.data.isUnitAvailable;
        this.setState({ isLoading: false });
      });
    if (!value) {
      toastFailMsg(
        "Selected unit is already occupied for the selected dates. Choose different dates."
      );
    } else {
      this.createLease();
    }
    return value;
  };

  checkForLease = () => {
    const {
      unitTypeText,
      unitNumberText,
      frequency,
      startDate,
      refundableAmount,
      depositChargeText,
      endDate,
      unitChargeText,
      unitQuantity,
      vehicleQuantity,
      petQuantity,
      isBluemoon,
      isDocuSign,
      dsFormsList,
      fixedDepositAmount,
      bmStandardForms,
      bmCustomForms,
      signProcess,
    } = this.state;
    if (
      unitTypeText === "" ||
      unitNumberText === "" ||
      frequency === "" ||
      startDate === "" ||
      endDate === "" ||
      unitChargeText === "" ||
      !unitQuantity ||
      !vehicleQuantity ||
      !petQuantity
    ) {
      toastFailMsg("Fill all the required details");
    } else if (
      isBluemoon &&
      signProcess === "esign" &&
      !bmStandardForms.length &&
      !bmCustomForms.length
    ) {
      toastFailMsg("Select the custom or standard policies");
    } else {
      this.checkForUnitAvailability();
    }
  };

  render() {
    const {
      isLoading,
      requestStatus,
      rejectReason,
      arr,
      billDate,
      startDate,
      endDate,
      unitTypeData,
      unitTypeText,
      unitsData,
      moveInDate,
      unitNumberText,
      frequency,
      unitChargeText,
      unitChargeAmount,
      proRateEnabled,
      unitProRata,
      unitQuantity,
      chargeOptions,
      petChargeText,
      docusignOptions,
      petChargeAmount,
      petProRata,
      petQuantity,
      vehicleChargeText,
      vehicleChargeAmount,
      vehicleProRata,
      vehicleQuantity,
      depositChargeText,
      signProcess,
      lgavail,
      fixedDepositAmount,
      refundableAmount,
      extraCharges,
      leaseGuarantee,
      firstLastInvoice,
      isBluemoon,
      customOptions,
      standardOptions,
      isDocuSign,
    } = this.state;
    const { application, location } = this.props.location;
    const {node} = this?.props?.selectedPrimary
    const Bread = () => (
      <Breadcrumb className="custom-breadcrumb">
        <Breadcrumb.Section link>
          <NavLink exact to="/lease">
            Lease
          </NavLink>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section link>
          <NavLink
            exact
            to={`/viewApplication/location=${location && location.customId
              }/id=${application && application.id}`}
          >
            {application &&
              application.primaryApplicant &&
              application.primaryApplicant.lastName}
          </NavLink>
        </Breadcrumb.Section>
      </Breadcrumb>
    );
    if (isLoading) return <PageLoader text />;
    return (
      <>
        <Bread />
        <Header className="comments-head">Create Lease</Header>
        {this.props.isRequest && (
          <>
            <p>Hello</p>
            <Select
              label="Request Approval"
              placeholder="Request Approval"
              selectOnBlur={false}
              options={requestOptions}
              selection
              text={requestStatus}
              value={requestStatus}
              onChange={this.getRequest}
              className="required"
            />
          </>
        )}
        {requestStatus === "reject" && (
          <Input
            label="Reason"
            required
            value={rejectReason}
            selectOnBlur={false}
            onChange={(e) => this.setRejectReason(e.target.value)}
            className="height-38"
          />
        )}
        {requestStatus === "accept" && (
          <Form className="unit-info-form mt-2">
            <Form.Group widths="equal">
              <div className="move-date required field">
                <label className="move-date-label">Start Date</label>
                <Datetime
                  timeFormat={false}
                  isValidDate={disablePastDt}
                  minDate={moment().toDate()}
                  closeOnSelect
                  inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                  onChange={(e) => this.setDate(e, "startDate")}
                />
              </div>
              <div className="move-date required field">
                <label className="move-date-label">End Date</label>
                <Datetime
                  timeFormat={false}
                  isValidDate={disablePastDt}
                  minDate={moment().toDate()}
                  closeOnSelect
                  inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                  defaultValue={moment(endDate)}
                  onChange={(e) => this.setDate(e, "endDate")}
                />
              </div>
              <div className="move-date required field">
                <label className="move-date-label">Move Date</label>
                <Datetime
                  timeFormat={false}
                  isValidDate={disablePastDt}
                  minDate={moment().toDate()}
                  closeOnSelect
                  inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                  defaultValue={moment(
                    this.props.location.application &&
                    this.props.location.application.targetMoveInDate
                  )}
                  onChange={(e) => this.setDate(e, "endDate")}
                />
              </div>
              <Form.Select
                label="Bill Day"
                placeholder="Bill Day"
                selectOnBlur={false}
                search
                selection
                disabled
                text={this.state.frequency == "Monthly" ? "1" : "1 & 15"}
                value={this.state.frequency == "Monthly" ? "1" : "1 & 15"}
                className="required"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              <Form.Select
                label="Floor Plan"
                placeholder="Floor Plan"
                selectOnBlur={false}
                search
                options={unitTypeData && unitTypeData}
                selection
                text={unitTypeText}
                value={unitTypeText}
                onChange={this.fetchUnitNumber}
                className="required"
              />
              <Form.Select
                label="Unit Number"
                placeholder="Unit Number"
                selection
                selectOnBlur={false}
                search
                options={unitsData}
                text={unitNumberText}
                value={unitNumberText}
                onChange={this.selectUnit}
                className="required"
              />
              <Form.Select
                label="Frequency"
                search
                selection
                options={LEASE_FREQUENCY}
                text={frequency}
                selectOnBlur={false}
                onChange={this.selectFreq}
                className="required"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              <Form.Select
                label="Unit Charge Code"
                selection
                selectOnBlur={false}
                search
                options={chargeOptions}
                text={
                  unitChargeText && `${unitChargeText} - $${unitChargeAmount}`
                }
                onChange={this.selectUnitChargeCode}
                className="required"
              />
              <Form.Input
                label="Unit Amount Per Period"
                search
                required
                type="number"
                value={unitChargeAmount}
                selectOnBlur={false}
                onChange={(e) =>
                  this.onChangeChargeAmount(e, "unitChargeAmount")
                }
                className="height-38"
              />
              {proRateEnabled && (
                <Form.Input
                  label="Unit Pro Rata"
                  required
                  type="number"
                  value={unitProRata}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ unitProRata: e.target.value })
                  }
                  className="height-38"
                />
              )}
              <Form.Input
                label="Unit Quantity"
                search
                disabled
                required
                type="number"
                defaultValue={unitQuantity}
                selectOnBlur={false}
                onChange={(e) =>
                  this.setState({ unitQuantity: e.target.value })
                }
                className="height-38"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              {/* eslint-disable */}
              <Form.Select
                label="Pet Charge Code"
                selection
                search
                required
                options={chargeOptions}
                text={
                  petChargeText
                    ? `${petChargeText} - ` + `$${petChargeAmount}`
                    : ""
                }
                selectOnBlur={false}
                onChange={this.selectPetChargeCode}
              />
              {/* eslint-enable */}
              <Form.Input
                label="Pet Amount Per Period"
                search
                required
                type="number"
                value={petChargeAmount}
                selectOnBlur={false}
                onChange={(e) =>
                  this.onChangeChargeAmount(e, "petChargeAmount")
                }
                className="height-38"
              />
              {proRateEnabled && (
                <Form.Input
                  label="Pet Pro Rata"
                  required
                  type="number"
                  value={petProRata}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ petProRata: e.target.value })
                  }
                  className="height-38"
                />
              )}
              <Form.Input
                label="Pet Quantity"
                search
                required
                type="number"
                defaultValue={petQuantity}
                selectOnBlur={false}
                onChange={(e) => this.setState({ petQuantity: e.target.value })}
                className="height-38"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              <Form.Select
                label="Vehicle Charge Code"
                selection
                search
                required
                options={chargeOptions}
                text={
                  vehicleChargeText
                    ? `${vehicleChargeText} - $${vehicleChargeAmount}`
                    : ""
                }
                selectOnBlur={false}
                onChange={this.selectVehicleChargeCode}
              />
              <Form.Input
                label="Vehicle Amount Per Period"
                search
                required
                type="number"
                value={vehicleChargeAmount}
                selectOnBlur={false}
                onChange={(e) =>
                  this.onChangeChargeAmount(e, "vehicleChargeAmount")
                }
                className="height-38"
              />
              {proRateEnabled && (
                <Form.Input
                  label="Vehicle Pro Rata"
                  required
                  type="number"
                  value={vehicleProRata}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ vehicleProRata: e.target.value })
                  }
                  className="height-38"
                />
              )}
              <Form.Input
                label="Vehicle Quantity"
                search
                required
                type="number"
                defaultValue={vehicleQuantity}
                selectOnBlur={false}
                onChange={(e) =>
                  this.setState({ vehicleQuantity: e.target.value })
                }
                className="height-38"
              />
            </Form.Group>
            <Form.Group widths="equal" className="unit-info-form-first-row">
              <Form.Select
                label="Fixed Deposit"
                selection
                search
                required
                options={chargeOptions}
                text={depositChargeText ? `${depositChargeText}` : " "}
                selectOnBlur={false}
                onChange={this.selectDepositChargeCode}
              />
              <Form.Input
                label="Deposit Amount"
                search
                required
                type="number"
                value={fixedDepositAmount}
                selectOnBlur={false}
                onChange={(e) =>
                  this.setState({ fixedDepositAmount: e.target.value })
                }
                className="height-38"
              />
              <Form.Input
                label="Non Refundable Amount"
                search
                required
                type="number"
                value={refundableAmount}
                selectOnBlur={false}
                onChange={(e) =>
                  this.setState({ refundableAmount: e.target.value })
                }
                className="height-38"
              />
            </Form.Group>
            {extraCharges.map((charge, index) => (
              <Form.Group
                widths="equal"
                className="unit-info-form-first-row"
                key={charge.id}
              >
                <Form.Select
                  label={`Extra Charge ${index + 1} Code`}
                  selection
                  search
                  options={chargeOptions}
                  text={
                    `Extra Charge ${index + 1} - $${charge.amountDefault}` || ""
                  }
                  selectOnBlur={false}
                  onChange={(...args) =>
                    this.onChangeExtraCharge(...args, index)
                  }
                />
                <Form.Input
                  label={`Extra Charge ${index + 1} Amount`}
                  search
                  type="number"
                  defaultValue={charge.amountDefault}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.onChangeExtraChargeAmount(
                      e.target.value,
                      "amountDefault",
                      index
                    )
                  }
                  className="height-38"
                />
                {proRateEnabled && (
                  <Form.Input
                    label="Extra Charge Pro Rata"
                    required
                    type="number"
                    value={charge.proRata}
                    selectOnBlur={false}
                    onChange={(e) =>
                      this.onChangeExtraChargeAmount(
                        e.target.value,
                        "proRata",
                        index
                      )
                    }
                    className="height-38"
                  />
                )}
                <Form.Input
                  label={`Extra Charge ${index + 1} Quantity`}
                  search
                  type="number"
                  defaultValue={charge.quantity}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.onChangeExtraChargeAmount(
                      e.target.value,
                      "quantity",
                      index
                    )
                  }
                  className="height-38"
                />
              </Form.Group>
            ))}
            <Form.Group>
              <Checkbox
                label="Allow Lease Guarantee"
                checked={leaseGuarantee}
                onChange={() =>
                  this.updateCheckboxOption(!leaseGuarantee, "LG")
                }
              />
              <Checkbox
                label="Include First and Last Month"
                checked={firstLastInvoice}
                onChange={() =>
                  this.updateCheckboxOption(!firstLastInvoice, "FLM")
                }
              />
              <Checkbox
                label="Enable Pro Rate"
                checked={proRateEnabled}
                onChange={() =>
                  this.updateCheckboxOption(!proRateEnabled, "PR")
                }
              />
            </Form.Group>
          </Form>
        )}
        {!this.props.isRequest && (
          <>
            <Form className="unit-info-form mt-2">
              <Form.Group widths="equal">
                <div className="move-date required field">
                  <label className="move-date-label">Start Date</label>
                  <Datetime
                    timeFormat={false}
                    isValidDate={disablePastDt}
                    minDate={moment().toDate()}
                    closeOnSelect
                    inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                    onChange={(e) => this.setDate(e, "startDate")}
                  />
                </div>
                <div className="move-date required field">
                  <label className="move-date-label">End Date</label>
                  <Datetime
                    timeFormat={false}
                    isValidDate={disablePastDt}
                    minDate={moment().toDate()}
                    closeOnSelect
                    inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                    defaultValue={moment(endDate)}
                    onChange={(e) => this.setDate(e, "endDate")}
                  />
                </div>
                <div className="move-date required field">
                  <label className="move-date-label">Move Date</label>
                  <Datetime
                    timeFormat={false}
                    isValidDate={disablePastDt}
                    minDate={moment().toDate()}
                    closeOnSelect
                    inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                    defaultValue={moment(
                      this.props.location.application &&
                      this.props.location.application.targetMoveInDate
                    )}
                    onChange={(e) => this.setDate(e, "moveInDate")}
                  />
                </div>
                <Form.Select
                  label="Bill Day"
                  placeholder="Bill Day"
                  selectOnBlur={false}
                  search
                  disabled
                  selection
                  text={this.state.frequency == "Monthly" ? "1" : "1 & 15"}
                  value={this.state.frequency == "Monthly" ? "1" : "1 & 15"}
                  className="required"
                />
              </Form.Group>
              <Form.Group widths="equal" className="unit-info-form-first-row">
                <Form.Select
                  label="Floor Plan"
                  placeholder="Floor Plan"
                  selectOnBlur={false}
                  search
                  options={unitTypeData && unitTypeData}
                  selection
                  text={unitTypeText}
                  value={unitTypeText}
                  onChange={this.fetchUnitNumber}
                  className="required"
                />
                <Form.Select
                  label="Unit Number"
                  placeholder="Unit Number"
                  selection
                  selectOnBlur={false}
                  search
                  options={unitsData && unitsData}
                  text={unitNumberText}
                  value={unitNumberText}
                  onChange={this.selectUnit}
                  className="required"
                />
                <Form.Select
                  label="Frequency"
                  search
                  selection
                  options={LEASE_FREQUENCY}
                  text={frequency}
                  selectOnBlur={false}
                  onChange={this.selectFreq}
                  className="required"
                />
              </Form.Group>
              <Form.Group widths="equal" className="unit-info-form-first-row">
                <Form.Select
                  label="Unit Charge Code"
                  selection
                  selectOnBlur={false}
                  search
                  options={chargeOptions && chargeOptions}
                  text={
                    unitChargeText
                      ? `${unitChargeText} - $${unitChargeAmount}`
                      : ""
                  }
                  onChange={this.selectUnitChargeCode}
                  className="required"
                />
                <Form.Input
                  label="Unit Amount Per Period"
                  search
                  required
                  type="number"
                  value={unitChargeAmount}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.onChangeChargeAmount(e, "unitChargeAmount")
                  }
                  className="height-38"
                />
                {proRateEnabled && (
                  <Form.Input
                    label="Unit Pro Rata"
                    required
                    type="number"
                    value={unitProRata}
                    selectOnBlur={false}
                    onChange={(e) =>
                      this.setState({ unitProRata: e.target.value })
                    }
                    className="height-38"
                  />
                )}
                <Form.Input
                  label="Unit Quantity"
                  search
                  disabled
                  required
                  type="number"
                  defaultValue={unitQuantity}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ unitQuantity: e.target.value })
                  }
                  className="height-38"
                />
              </Form.Group>
              <Form.Group widths="equal" className="unit-info-form-first-row">
                {/* eslint-disable */}
                <Form.Select
                  label="Pet Charge Code"
                  selection
                  search
                  required
                  options={chargeOptions}
                  text={
                    petChargeText
                      ? `${petChargeText} - ` + `$${petChargeAmount}`
                      : ""
                  }
                  selectOnBlur={false}
                  onChange={this.selectPetChargeCode}
                />
                {/* eslint-enable */}
                <Form.Input
                  label="Pet Amount Per Period"
                  search
                  required
                  type="number"
                  value={petChargeAmount}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.onChangeChargeAmount(e, "petChargeAmount")
                  }
                  className="height-38"
                />
                {proRateEnabled && (
                  <Form.Input
                    label="Pet Pro Rata"
                    required
                    type="number"
                    value={petProRata}
                    selectOnBlur={false}
                    onChange={(e) =>
                      this.setState({ petProRata: e.target.value })
                    }
                    className="height-38"
                  />
                )}
                <Form.Input
                  label="Pet Quantity"
                  search
                  required
                  type="number"
                  defaultValue={petQuantity}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ petQuantity: e.target.value })
                  }
                  className="height-38"
                />
              </Form.Group>
              <Form.Group widths="equal" className="unit-info-form-first-row">
                <Form.Select
                  label="Vehicle Charge Code"
                  selection
                  search
                  required
                  options={chargeOptions}
                  text={
                    vehicleChargeText
                      ? `${vehicleChargeText} - $${vehicleChargeAmount}`
                      : ""
                  }
                  selectOnBlur={false}
                  onChange={this.selectVehicleChargeCode}
                />
                <Form.Input
                  label="Vehicle Amount Per Period"
                  search
                  required
                  type="number"
                  value={vehicleChargeAmount}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.onChangeChargeAmount(e, "vehicleChargeAmount")
                  }
                  className="height-38"
                />
                {proRateEnabled && (
                  <Form.Input
                    label="Vehicle Pro Rata"
                    required
                    type="number"
                    value={vehicleProRata}
                    selectOnBlur={false}
                    onChange={(e) =>
                      this.setState({ vehicleProRata: e.target.value })
                    }
                    className="height-38"
                  />
                )}
                <Form.Input
                  label="Vehicle Quantity"
                  search
                  required
                  type="number"
                  defaultValue={vehicleQuantity}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ vehicleQuantity: e.target.value })
                  }
                  className="height-38"
                />
              </Form.Group>
              <Form.Group widths="equal" className="unit-info-form-first-row">
                <Form.Select
                  label="Fixed Deposit"
                  selection
                  search
                  required
                  options={chargeOptions}
                  text={depositChargeText ? `${depositChargeText}` : " "}
                  selectOnBlur={false}
                  onChange={this.selectDepositChargeCode}
                />
                <Form.Input
                  label="Deposit Amount"
                  search
                  required
                  type="number"
                  // defaultValue={Number(0)}
                  value={fixedDepositAmount}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ fixedDepositAmount: e.target.value })
                  }
                  className="height-38"
                />
                <Form.Input
                  label="Non Refundable Amount"
                  search
                  required
                  type="number"
                  // defaultValue={Number(0)}
                  value={refundableAmount}
                  selectOnBlur={false}
                  onChange={(e) =>
                    this.setState({ refundableAmount: e.target.value })
                  }
                  className="height-38"
                />
              </Form.Group>
              {extraCharges.map((charge, index) => (
                <Form.Group
                  widths="equal"
                  className="unit-info-form-first-row"
                  key={charge.id}
                >
                  <Form.Select
                    label={`Extra Charge ${index + 1} Code`}
                    selection
                    search
                    options={chargeOptions}
                    text={
                      `Extra Charge ${index + 1} - $${charge.amountDefault}` ||
                      ""
                    }
                    selectOnBlur={false}
                    onChange={(...args) =>
                      this.onChangeExtraCharge(...args, index)
                    }
                  />
                  <Form.Input
                    label={`Extra Charge ${index + 1} Amount`}
                    search
                    type="number"
                    defaultValue={charge.amountDefault}
                    selectOnBlur={false}
                    onChange={(e) =>
                      this.onChangeExtraChargeAmount(
                        e.target.value,
                        "amountDefault",
                        index
                      )
                    }
                    className="height-38"
                  />
                  {proRateEnabled && (
                    <Form.Input
                      label="Extra Charge Pro Rata"
                      required
                      type="number"
                      value={charge.proRata}
                      selectOnBlur={false}
                      onChange={(e) =>
                        this.onChangeExtraChargeAmount(
                          e.target.value,
                          "proRata",
                          index
                        )
                      }
                      className="height-38"
                    />
                  )}
                  <Form.Input
                    label={`Extra Charge ${index + 1} Quantity`}
                    search
                    type="number"
                    defaultValue={charge.quantity}
                    selectOnBlur={false}
                    onChange={(e) =>
                      this.onChangeExtraChargeAmount(
                        e.target.value,
                        "quantity",
                        index
                      )
                    }
                    className="height-38"
                  />
                </Form.Group>
              ))}
              {isBluemoon && signProcess === "esign" && (
                <Form.Group>
                  <Form.Field style={{ width: "100%" }}>
                    <label>Custom Policy</label>
                    <Dropdown
                      placeholder="Custom Policy"
                      search
                      multiple
                      selection
                      defaultValue={customDataOptions}
                      options={customOptions}
                      onChange={this.setCustomData}
                    />
                  </Form.Field>
                  <Form.Field style={{ width: "100%" }}>
                    <label>Standard Policy</label>
                    <Dropdown
                      placeholder="Standard Policy"
                      search
                      multiple
                      selection
                      defaultValue={standardDataOptions}
                      options={standardOptions}
                      onChange={this.setStandardData}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              {isDocuSign && signProcess === "esign" && (
                <Form.Group>
                  <Form.Field>
                    <label>Policy Documents</label>
                    <Dropdown
                      placeholder="Location Policy"
                      search
                      multiple
                      selection
                      defaultValue={policyDataOptions}
                      options={docusignOptions}
                      onChange={this.setDocuData}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              <Form.Group>
                {lgavail && (
                  <Checkbox
                    label="Allow Lease Guarantee"
                    checked={leaseGuarantee}
                    onChange={() =>
                      this.updateCheckboxOption(!leaseGuarantee, "LG")
                    }
                  />
                )}
                <Checkbox
                  label="Include First and Last Month"
                  checked={firstLastInvoice}
                  onChange={() =>
                    this.updateCheckboxOption(!firstLastInvoice, "FLM")
                  }
                />
                <Checkbox
                  label="Enable Pro Rate"
                  checked={proRateEnabled}
                  onChange={() =>
                    this.updateCheckboxOption(!proRateEnabled, "PR")
                  }
                />
              </Form.Group>
            </Form>
          </>
        )}
        <ChargeCodeModal
          updateInvoice={() => { }}
          success={() => { }}
          dataPush={this.getLedger}
          propertyId={this.state.locationId}
          isCustomCharge
        />

        <p className="mt-3">
          Total Amount: $
          {`${this.totalCharges().toLocaleString()} charged ${frequency}`}
        </p>
        <Button onClick={() => this.props.history.push("/lease")}>
          CANCEL
        </Button>
        <Button
          positive
          disabled={
            !frequency ||
            !startDate ||
            !endDate ||
            !moveInDate ||
            !unitTypeText ||
            !unitNumberText
          }
          onClick={this.checkForLease}
        >
          CREATE LEASE
        </Button>
      </>
    );
  }
}

export default withApollo(CreateLease);