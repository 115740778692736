import gql from "graphql-tag";
import {
  propertyPlace,
  propertyLocation,
  addressKey,
} from "../utils/propertiesKeys";

export const UpdateAppStatus = gql`
  mutation UpdateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      response
    }
  }
`;

export const applicationGql = gql`
query Application($id: String!) {
  application(id: $id) {
    edges {
      node {
        id
        ndbId
        status
        property {
          ${propertyLocation}
          ${propertyPlace}
        }
        lessee
        lease {
          startDate
          endDate
        }
        application {
          firstName
          lastName
          email
          phoneMobile
          phoneHome
          photo {
            url
          }
          employment {
            employer
            ${addressKey}
            employerPhone
            salary
          }
          residencies {
            name
            startDate
            endDate
            ${addressKey}
          }
          references {
            name
            phone
            email
          }

        }
      }
    }
  }
}
`;

export const applicationActionsGql = gql`
  mutation ManagerApplicationAction($input: ManagerApplicationActionInput!) {
    managerApplicationAction(input: $input) {
      response
    }
  }
`;

export const pmApplicationsGql = gql`
  query applications($locationId: String!) {
    applications(locationId: $locationId) {
      edges {
        node {
          id
          orderNumber
          isLeaseCreated
          targetMoveInDate
          status
          unitType {
            id
            type
          }
          unitNumber {
            id
            number
            deposit
            nonRefundable
          }
          adults
          children
          primaryApplicant {
            firstName
            lastName
          }
          created
          updated
        }
      }
    }
  }
`;
export const existingApplications = gql`
  query applications($locationId: String!, $status: String!) {
    applications(locationId: $locationId, status: $status) {
      edges {
        node {
          id
          orderNumber
          targetMoveInDate
          isLeaseCreated
          status
          unitType {
            id
            type
          }
          unitNumber {
            id
            number
            deposit
            nonRefundable
          }
          adults
          children
          primaryApplicant {
            firstName
            lastName
          }
          created
          updated
        }
      }
    }
  }
`;

export const pmSingleApplicationGql = gql`
  query application($applicationId: String!) {
    application(applicationId: $applicationId) {
      edges {
        node {
          locationId
          id
          decisionSummary {
            taApplicationNote
            taApplicationDecision
            taApplicationTimestamp
            taApplicationScore
            isBackgroundExpired
          }
          isLeaseCreated
          targetMoveInDate
          orderNumber
          status
          leasingAgent {
            id
            firstName
            lastName
          }
          unitType {
            id
            type
            bedrooms
            bathrooms
          }
          unitNumber {
            id
            number
            level
            price
            deposit
            nonRefundable
          }
          adults
          children
          fileUrls
          affordableHousingProgram {
            id
            code
            name
          }
          petsNumber
          pets {
            id
            name
            petType
            breed
            gender
            age
            service
            color
            weight
          }
          vehicles {
            id
            make
            model
            color
            year
            licensePlate
            state
          }
          liquidFilledFurniture
          smoke
          primaryApplicant {
            id
            firstName
            idNum
            middleName
            lastName
            email
            phoneMobile
            dateOfBirth
            maritalStatus
            drivingLicence {
              number
              id
              idType
              issuingState
            }
            employmentHistory {
              id
              title
              employerName
              monthlyIncome
              startDate
              endDate
              isCurrentlyWorking
              supervisorName
              supervisorEmail
              supervisorPhone
              address {
                streetLine1
                city
                state
                postcode
              }
            }
            rentalHistory {
              id
              landlordName
              landlordEmail
              landlordPhone
              monthlyRent
              startDate
              endDate
              isCurrentlyResiding
              address {
                streetLine2
                streetLine1
                city
                state
                postcode
              }
            }
            otherIncomes {
              id
              source
              monthlyIncome
            }
            emergencyContact {
              id
              name
              email
              relationship
              mobilePhone
              workPhone
              homePhone
              address {
                streetLine1
                city
                state
                postcode
              }
            }
            backgroundChecks
          }
          coApplicants {
            id
            firstName
            middleName
            lastName
            email
            dateOfBirth
            maritalStatus
            phoneMobile
            drivingLicence {
              id
              number
              idType
              issuingState
            }
            employmentHistory {
              id
              title
              employerName
              monthlyIncome
              startDate
              endDate
              isCurrentlyWorking
              supervisorName
              supervisorEmail
              supervisorPhone
              address {
                streetLine1
                city
                state
                postcode
              }
            }
            rentalHistory {
              id
              landlordName
              landlordEmail
              landlordPhone
              monthlyRent
              isCurrentlyResiding
              startDate
              endDate
              address {
                streetLine2
                streetLine1
                city
                state
                postcode
              }
            }
            otherIncomes {
              id
              source
              monthlyIncome
            }
            isFinanciallyResponsible
          }
          occupants {
            firstName
            middleName
            lastName
            dateOfBirth
          }
          created
          updated
        }
      }
    }
  }
`;

export const createApplication = gql`
  mutation createApplication($input: CreateApplicationTypeInput!) {
    createApplication(input: $input) {
      response
    }
  }
`;

export const updateApplication = gql`
  mutation updateApplication($input: UpdateApplicationTypeInput!) {
    updateApplication(input: $input) {
      response
    }
  }
`;

export const updateBulkEdit = gql`
  mutation bulkUpdateApplication($input: BulkUpdateApplicationTypeInput!) {
    bulkUpdateApplication(input: $input) {
      response
    }
  }
`;

export const applicationHistory = gql`
  query comments($applicationId: String!) {
    comments(applicationId: $applicationId) {
      edges {
        node {
          id
          subject
          message
          commentType
          person {
            id
            firstName
            lastName
          }
          created
        }
      }
    }
  }
`;

export const createComment = gql`
  mutation CreateComment($input: CreateApplicationCommentInput!) {
    createComment(input: $input) {
      response
      comment {
        id
      }
    }
  }
`;

export const updateComment = gql`
  mutation UpdateComment($input: UpdateApplicationCommentInput!) {
    updateComment(input: $input) {
      response
      comment {
        id
      }
    }
  }
`;

export const deleteComment = gql`
  mutation DeleteComment($input: DeleteApplicationCommentInput!) {
    deleteComment(input: $input) {
      response
    }
  }
`;

export const decisionSummary = gql`
  query decisionSummary($applicationId: String!) {
    decisionSummary(applicationId: $applicationId)
  }
`;

export const tenantAlertCheck = gql`
  query decisionSummaryCheck($email: String!) {
    decisionSummaryCheck(email: $email) {
      edges {
        node {
          isBackgroundExpired
        }
      }
    }
  }
`;

export const BGCCheck = gql`
  query taApplication($email: String!) {
    taApplication(email: $email)
  }
`;

export const allApplicantDecisionSummary = gql`
  query allApplicantDecisionSummary($applicationId: String!) {
    allApplicantDecisionSummary(applicationId: $applicationId) {
      edges {
        node {
          firstName
          lastName
          email
          isPrimaryApplicant
          taApplicationNote
          taApplicationStatus
          taApplicationScore
          isBackgroundExpired
          taApplicationTimestamp
        }
      }
    }
  }
`;

export const InviteRenter = gql`
  mutation inviteRenter($input: InviteRenterInput!) {
    inviteRenter(input: $input) {
      responses
    }
  }
`;


