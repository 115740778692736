import React from "react";

const MessageTypeIcon = ({ type }) => {
  let icon = null;
  if (type === "in-app") {
    icon = <img src="/assets/img/icon-in-app-chat.png" alt="in app message" />;
  }
  if (type === "text") {
    icon = <img src="/assets/img/icon-sms-chat.png" alt="text message" />;
  }
  if (type === "email") {
    icon = <img src="/assets/img/icon-email-chat.png" alt="email" />;
  }

  return <div className="icon-wrapper">{icon}</div>;
};

export default MessageTypeIcon;
