import React, { Component } from "react";
import {
  Icon,
  Form,
  Button,
  Modal,
  Header,
  Radio,
  Grid,
  Feed,
  Tab,
  Checkbox,
  Dropdown,
  Card,
} from "semantic-ui-react";
import { withApollo } from "react-apollo";
import {
  createTourType,
  TourTypes,
  deleteTourType,
} from "../../store/person/leads";
import { LEADS_TOUR_TYPE } from "../../utils/constants";
import _ from "lodash";
import { getClient } from "../../init-apollo-googleFn";
import { toast } from "react-toastify";
import moment from "moment";

import "react-toastify/dist/ReactToastify.css";

const tourTypeAPI = getClient(LEADS_TOUR_TYPE);
class TourCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      primary: this.props.primary,
      code: "",
      duration: "10",
      link: "",
      submitted: false,
      selfBooking: false,
      modalOpen: false,
      tourFeed: [],
      value: "in-person",
    };
  }

  componentDidMount = () => {
    this.gatherTours();
  };
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => { });
    }
  }

  handleClose = () =>
    this.setState({
      modalOpen: false,
      value: "in-person",
      name: "",
      code: "",
      link: "",
      duration: "10",
    });
  // This will create a new Tour Type for the PM
  handleTourSubmit = () => {
    this.setState({ submitted: true });
    const { name, code, value, link, selfBooking, duration } = this.state;
    if (value === "in-person") {
      try {
        tourTypeAPI
          .mutate({
            mutation: createTourType,
            variables: {
              input: {
                tourType: {
                  location: this.props.primary.id,
                  code: code,
                  duration: duration,
                  name: name,
                  tourType: value,
                  selfBooking: selfBooking,
                },
              },
            },
          })
          .then((response) => {
            this.setState({
              code: "",
              name: "",
              link: "",
              duration: "10",
              selfBooking: false,
              submitted: false,
            });
            this.handleClose();
            this.gatherTours();
            this.success("Tour Type created successfully");
          });
      } catch (e) {
        alert(e);
        console.log(e);
      }
    } else {
      try {
        tourTypeAPI
          .mutate({
            mutation: createTourType,
            variables: {
              input: {
                tourType: {
                  location: this.props.primary.id,
                  code: code,
                  name: name,
                  tourType: value,
                  selfBooking: selfBooking,
                  duration: duration,
                  connectionInfo: link,
                },
              },
            },
          })
          .then((response) => {
            this.setState({
              code: "",
              name: "",
              link: "",
              duration: "10",
              selfBooking: false,
              submitted: false,
            });
            this.handleClose();
            this.gatherTours();
            this.success("Tour Type created successfully");
          });
      } catch (e) {
        alert(e);
        this.setState({
          code: "",
          name: "",
          link: "",
          duration: "10",
          selfBooking: false,
          submitted: false,
        });
        this.handleClose();
        console.log(e);
      }
    }
  };

  handlePermission = (e, { checked }) => {
    this.toggle();
  };
  toggle = () =>
    this.setState(({ selfBooking }) => ({ selfBooking: !selfBooking }));

  // This will gather all tours by the currently selected location
  gatherTours = () => {
    if (this.props.primary) {
      const { id } = this.props.primary;
      this.setState({ tourFeed: [] });
      try {
        tourTypeAPI
          .query({
            query: TourTypes,
            variables: {
              location: id,
            },
          })
          .then((res) => {
            const feed = res.data.tourTypes.edges;
            this.setState({ tourFeed: feed });
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  success = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  removeEvent(i) {
    const { tourFeed } = this.state;
    const Array = tourFeed;
    const index = i;
    try {
      tourTypeAPI
        .mutate({
          mutation: deleteTourType,
          variables: {
            input: {
              tourType: {
                id: index.node.id,
              },
            },
          },
        })
        .then((response) => {
          /* eslint-disable */
          Array.map((item) => {
            if (index === item) {
              const value = Array.indexOf(item);
              if (value > -1) {
                Array.splice(value, 1);
              }
              return Array;
            }
          });
          /* eslint-enable */
          this.success("Tour Type deleted successfully");
          this.setState({
            tourFeed: Array,
          });
        });
    } catch (e) {
      alert(e);
      console.log(e);
    }
  }

  handleVirtual = (e, { value }) => {
    this.setState(
      { value, code: "", name: "", link: "", duration: "10" },
      () => { }
    );
    this.handleDropdownChange("duration", "10");
  };

  handleChange = (name, e) => {
    const { value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => { }
    );
  };
  validateAndSubmit = (e) => {
    e.preventDefault();
    this.handleTourSubmit();
  };
  handleDropdownChange = (field, value, test) => {
    this.setState({ duration: value });
  };
  getDuration = (number) =>
    _.times(number, (index) => ({
      key: index,
      text: `${(index + 1) * 10}`,
      value: index,
    }));

  inviteForm = (getDuration) => (
    <Form width="equal" style={{ overflowY: "visible" }}>
      <Form.Field>
        <Radio
          name="radioGroup"
          defaultChecked
          value="in-person"
          checked={this.state.value === "in-person"}
          onChange={this.handleVirtual}
          label="In Person"
        />
      </Form.Field>
      <Form.Field>
        <Radio
          name="radioGroup"
          value="virtual"
          checked={this.state.value === "virtual"}
          onChange={this.handleVirtual}
          label="Virtual"
        />
      </Form.Field>
      <Form.Input
        placeholder="Code"
        name="code"
        value={this.state.code}
        onChange={(event) => {
          this.handleChange("code", event);
        }}
      />
      <Form.Input
        placeholder="Name"
        name="name"
        value={this.state.name}
        onChange={(event) => {
          this.handleChange("name", event);
        }}
      />

      <Dropdown
        style={{ overflowY: "visible" }}
        fluid
        text={this.state.duration}
        upward
        name="duration"
        scrolling
        options={this.getDuration(6)}
        onChange={(e) =>
          this.handleDropdownChange("duration", e.target.textContent)
        }
      />
      <br />
      {this.state.value === "virtual" && (
        <Form.Input
          placeholder="Link"
          value={this.state.link}
          name="link"
          onChange={(event) => {
            this.handleChange("link", event);
          }}
        />
      )}
      <br />
      <Checkbox toggle label="Self Booking" onChange={this.handlePermission} />
    </Form>
  );
  render() {
    const { tourFeed } = this.state;

    /* eslint-disable */
    const panes = [
      {
        menuItem: "In-Person",
        render: () => (
          <Tab.Pane>
            <Feed
              size="small"
              style={{ maxHeight: "250px", overflowY: "auto" }}
            >
              {tourFeed &&
                tourFeed.map((tour) => {
                  if (
                    tour.node.connectionInfo === null ||
                    !tour.connectionInfo == null
                  ) {
                    return (
                      <Feed.Event>
                        <Feed.Content>
                          <Feed.Summary>
                            <Feed.User>
                              {tour.name || tour.node.name}{" "}
                              {tour.status || tour.node.status === "cancel" ? (
                                <p style={{ color: "red" }}>Canceled</p>
                              ) : (
                                ""
                              )}
                            </Feed.User>
                            <Feed.Date>
                              {moment(tour.date || tour.node.date).format(
                                "MM/DD/YYYY"
                              )}{" "}
                              at {tour.time || tour.node.time}
                            </Feed.Date>
                          </Feed.Summary>
                        </Feed.Content>
                        <Feed.Label
                          className="py-0 text-danger"
                          onClick={() => this.removeEvent(tour)}
                          icon="x"
                        />
                      </Feed.Event>
                    );
                  }
                })}
            </Feed>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Virtual",
        render: () => (
          <Tab.Pane>
            <Feed
              size="small"
              style={{ maxHeight: "250px", overflowY: "auto" }}
            >
              {/* eslint-disable */}
              {tourFeed &&
                tourFeed.map((tour) => {
                  /* eslint-enable */
                  if (tour.node.connectionInfo || tour.connectionInfo) {
                    const prependHttp = require("prepend-http");
                    return (
                      <Feed.Event>
                        <Feed.Content>
                          <Feed.Summary>
                            <Feed.User>
                              {tour.name || tour.node.name}{" "}
                              {tour.status || tour.node.status === "cancel" ? (
                                <p style={{ color: "red" }}>Canceled</p>
                              ) : (
                                ""
                              )}
                            </Feed.User>
                            <Feed.Date>
                              {moment(tour.date || tour.node.date).format(
                                "MM/DD/YYYY"
                              )}{" "}
                              at {tour.time || tour.node.time}
                            </Feed.Date>
                          </Feed.Summary>
                          <Feed.Meta>
                            <Feed.Like>
                              <Icon name="linkify" />
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={prependHttp(tour.node.connectionInfo)}
                              >
                                {tour.connectionInfo ||
                                  tour.node.connectionInfo}
                              </a>
                            </Feed.Like>
                          </Feed.Meta>
                        </Feed.Content>
                        <Feed.Label
                          className="text-danger"
                          onClick={() => this.removeEvent(tour)}
                          icon="x"
                        />
                      </Feed.Event>
                    );
                  }
                })}
            </Feed>
          </Tab.Pane>
        ),
      },
    ];
    /* eslint-enable */

    const TabExampleBasic = () => <Tab panes={panes} />;

    return (
      <>
        <Modal
          className="semanticModal"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size="small"
          trigger={
            <Card className="settings-second-step" onClick={this.gatherTours} style={{ border: "2px solid" }}>
              <Card.Content>
                <Card.Header textAlign="center">Tour Management</Card.Header>
                <Card.Meta textAlign="center">View/Manage Tour Types</Card.Meta>
              </Card.Content>
            </Card>
          }
        >
          <Modal.Header style={{ background: "#343c49", color: "#F2FCFB" }}>
            <Header
              style={{ background: "#343c49", color: "#F2FCFB" }}
              textAlign="center"
            >
              Manage Tours
            </Header>
          </Modal.Header>
          <Modal.Header>
            {" "}
            <Header
              style={{ background: "#343c49", color: "#F2FCFB" }}
              textAlign="center"
            >
              {this.props.primary ? this.props.primary.customId : ""}
            </Header>
          </Modal.Header>
          <Modal.Content>
            <Grid columns="equal">
              <Grid.Column>
                <Header textAlign="center">New Tour Type</Header>
                <this.inviteForm />
              </Grid.Column>
              <Grid.Column>
                <Header textAlign="center">Existing Tours Types</Header>

                <TabExampleBasic />
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ background: "#343c49", color: "#F2FCFB" }}
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            {this.state.submitted ? (
              <Button
                style={{ background: "#4C158A", color: "#F2FCFB" }}
                disabled
                loading
              >
                Loading
              </Button>
            ) : (
              <Button
                style={{ background: "#4C158A", color: "#F2FCFB" }}
                disabled={
                  this.state.value === "in-person"
                    ? !this.state.code.trim().length ||
                    !this.state.name.trim().length ||
                    !this.state.duration.trim().length
                    : !this.state.code.trim().length ||
                    !this.state.name.trim().length ||
                    !this.state.duration.trim().length ||
                    !this.state.link.trim().length
                }
                type="button"
                content="Submit"
                onClick={(e) => this.validateAndSubmit(e)}
              >
                Submit
              </Button>
            )}
          </Modal.Actions>
        </Modal>

      </>
    );
  }
}
export default withApollo(TourCreation);
