import React from "react";

const CoApplicant = ({ applicant }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="profile-label-row">
            <div>
              <span className="profile-label-title">Co Applicant</span>
              <span className="profile-label-subtitle">
                {[
                  applicant.firstName,
                  applicant.middleName,
                  applicant.lastName,
                ].join(" ")}
              </span>
            </div>
            <div className="profile-label-line"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Goverment ID Type</div>
            <div className="profile-label-value">
              {applicant.drivingLicence && applicant.drivingLicence.idType}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Drivers License/ID Number</div>
            <div className="profile-label-value">
              {applicant.drivingLicence && applicant.drivingLicence.number}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Issuing State</div>
            <div className="profile-label-value">
              {applicant.drivingLicence &&
                applicant.drivingLicence.issuingState}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Date of Birth</div>
            <div className="profile-label-value">
              {applicant.drivingLicence && applicant.dateOfBirth}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Marital Status</div>
            <div className="profile-label-value">
              {applicant.maritalStatus === "" ? "N/A" : applicant.maritalStatus}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Email</div>
            <div className="profile-label-value">
              {applicant.email === "" ? "N/A" : applicant.email}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="profile-basic">
            <div className="profile-label">Mobile Phone</div>
            <div className="profile-label-value">
              {applicant.phoneMobile === "" ? "N/A" : applicant.phoneMobile}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoApplicant;
