import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { InvoiceAccounting, InvoiceInfoAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { ACCOUNTING, INVOICE_ACCOUNTING } from "../../utils/constants";
import axios from "axios";
import getAuthToken from "../../store/auth/authUtility";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import moment from 'moment';
import { Button, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import InvoicesModal from "../../components/Modals/InvoicesModal";
import VoidInvoiceModal from "../../components/Modals/VoidInvoiceModal";
import ViewInvoiceDetails from "../../components/Accounting/ViewInvoiceDetails";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const invoiceClient = getClient(ACCOUNTING);

const Credits = ({ selectedPrimary, user }) => {
  const [primary, setPrimary] = useState(selectedPrimary ? selectedPrimary.node : "");
  const [loading, setLoading] = useState(false);
  const [invoiceCache, setInvoiceCache] = useState([]);
  const [offset, setOffset] = useState(0);
  const [old, setOld] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [viewInvoiceData, setViewInvoiceData] = useState("");
  const [viewInvoicesData, setViewInvoicesData] = useState("");

  useEffect(() => {
    getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Credits",
    });
  }, []);

  useEffect(() => {
    const nodeData = selectedPrimary ? selectedPrimary.node : "";
    if (nodeData !== primary) {
      setPrimary(nodeData);
      setOffset(0);
      setInvoiceCache([]);
      setToggled(false);
      setViewInvoiceData("");
      setViewInvoicesData("");
      getLedger();
    }
  }, [selectedPrimary]);

  const download = (item) => {
    const url = new URL(INVOICE_ACCOUNTING);
    url.searchParams.append("invoice_id", item._id);
    url.searchParams.append("action", "invoice");
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "blob", // important
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = [
      "Number",
      "Status",
      "Amount",
      "Invoice",
      "Post",
    ];

    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Credits", 14, 15);
    // Convert data to array of arrays
    const data = invoiceCache.map((item) => [
      item.number,
      item.uiStatus,
      item.uiAmount,
      item.uiInvoiceDate,
      item.uiPostedDate,
    ]);

    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });

    // Output PDF
    doc.save("Credits.pdf");
  };

  const viewInvoice = (item) => {
    setViewInvoicesData(item);
    if (toggled) {
      setToggled(false);
    }
    if (!toggled) {
      getInvoice(item);
    }
  };

  const StatusFormat = (values) => {
    if (values === "partiallyPaid") return "Partially Paid";
    if (values === "paid") return "Paid";
    if (values === "issued") return "Issued";
    if (values === "created") return "Created";
    if (values === "approved") return "Approved";
    if (values === "voided") return "Voided";
    else return "";
  };

  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const Actions = (item) => {
    return (
      <>
        {item.status === "created" && (
          <InvoicesModal
            propertyId={selectedPrimary.node.id}
            success={success}
            user={user}
            props={item}
          />
        )}
        {item.status === "approved" && (
          <InvoicesModal
            propertyId={selectedPrimary.node.id}
            success={success}
            user={user}
            props={item}
          />
        )}
        {item.status !== "voided" && (
          <VoidInvoiceModal
            propertyId={selectedPrimary.node.id}
            updateInvoice={updateInvoice}
            success={success}
            user={user}
            props={item}
          />
        )}
        <Button icon compact className="button-hover-universal"
          onClick={() => download(item)}
        >
          <Icon name='file' />
        </Button>
        <Button
          compact
          icon='world'
          className="button-hover-universal"
          onClick={() => viewInvoice(item)}
          property={selectedPrimary}
          props={item}
        />
      </>
    )
  };

  const getLedger = (data) => {
    try {
      setLoading(true);
      invoiceClient
        .query({
          query: InvoiceAccounting,
          variables: {
            location: selectedPrimary.node.id,
            credits: true,
            offset: offset,
            limit: 30,
          },
        })
        .then((res) => {
          if (res.data) {
            const dataToPass = get(res, "data.slLocationInvoices.data", []).map((obj, i) => {
              const { amount, invoiceDate, number, postedDate, status } = obj;
              const uiAmount = (Number(amount) < 0 ? `$(${Math.abs(parseFloat(amount).toFixed(2)).toLocaleString("en")})` : `$${Math.abs(parseFloat(amount).toFixed(2)).toLocaleString("en")}`);
              const uiInvoiceDate = moment(invoiceDate).format("l");
              const uiPostedDate = moment(postedDate).format("l");
              const uiStatus = StatusFormat(status);
              const actions = Actions(obj);
              return { number, uiStatus, uiAmount, uiInvoiceDate, uiPostedDate, actions };
            });
            setInvoiceCache(dataToPass);
            setOld(get(res, "data.slLocationInvoices.data", []));
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const getInvoice = (item) => {
    try {
      invoiceClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: selectedPrimary.node.id,
            id: item._id,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data.slLocationInvoice;
            const Check = data.payments.map((payment) => {
              const check = data.payments.filter(
                (pay) => pay.externalCheckBankId === payment._id
              );
              if (check.length > 0) {
                return (payment.status = "refund");
              }
            });
            setViewInvoiceData(res.data.slLocationInvoice);
            setToggled(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) { }
  };

  const dataPush = (response) => {
    setInvoiceCache(prevState => ([
      response.data.createSlInvoice.slInvoice,
      ...prevState.invoiceCache,
    ]));
  };

  const updateInvoice = (status, invoiceId) => {
    const array = [];
    if (status === "Delete") {
      setInvoiceCache(invoiceCache.filter(
        (invoice) => invoice._id !== invoiceId
      ));
    } else {
      invoiceCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      setInvoiceCache(array);
    }
  };

  const handleOffset = (x) => {
    setOffset(x);
  };

  useEffect(() => {
    getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Credits",
    });
  }, []);

  useEffect(() => {
    const nodeData = selectedPrimary ? selectedPrimary.node : "";
    if (nodeData !== primary) {
      setPrimary(nodeData);
      getLedger();
    }
  }, [selectedPrimary]);

  const mainHeader = ["Number", "Status", "Amount", "Invoice", "Posted", "Actions"];
  const fail = () =>
    toast.error("No agent with this ID exists in our system", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const successToast = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <>
      <main className="main-content" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title">Credits
                    {toggled &&
                      <Button style={{ marginLeft: '12px' }} onClick={() => viewInvoice()}>Back</Button>
                    }
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <InvoicesModal
                  dataPush={dataPush}
                  fail={fail}
                  user={user}
                  success={successToast}
                  propertyId={
                    selectedPrimary && selectedPrimary.node && selectedPrimary.node.id
                  }
                />
                <Button
                  onClick={() => stateDataToPDF()}
                  compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          !toggled ?
            <>
              <GeneralAccountingTable
                mainHeader={mainHeader}
                mainCellData={invoiceCache}
              />
            </>
            :
            <ViewInvoiceDetails
              property={selectedPrimary && selectedPrimary.node && selectedPrimary.node}
              invoiceData={viewInvoiceData}
              getInvoice={getInvoice}
              updateInvoice={updateInvoice}
            />
        )}
        
      </main>
    </>
  );
};

export default withApollo(Credits);