import gql from "graphql-tag";

export const allDocumentsGql = gql`
  query documents($location: String!) {
    documents(location: $location)
  }
`;

export const DeleteFile = gql`
  mutation DeleteFile($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      response
    }
  }
`;
export const MoveFile = gql`
  mutation MoveFile($input: MoveFileInput!) {
    moveFile(input: $input) {
      response
    }
  }
`;
export const DownloadFile = gql`
  mutation downloadFile($input: DownloadFileInput!) {
    downloadFile(input: $input) {
      response
    }
  }
`;
