import React, { Component } from "react";
import getAuthToken from "../../store/auth/authUtility";
import { withApollo } from "react-apollo";
import {
  CATEGORIES_VALUES,
  MAINTENANCE_URL,
  UNITAPI,
} from "../../utils/constants";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import {
  Icon,
  Checkbox,
  Form,
  Label,
  TextArea,
  Header,
  Image,
  Button,
  Select,
  Popup,
  Modal,
  Message,
} from "semantic-ui-react";
import { primaryUnits } from "../../store/person/properties";
import { getClient } from "../../init-apollo-googleFn";

const QueryUnits = getClient(UNITAPI);

class MaintenanceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: {},
      keys: "",
      photos: [],
      errors: {
        unitFormInput: false,
        categoryFormInput: false,
        priorityFormInput: false,
        primaryLocationId: false,
      },
      preview: [],
      descriptionFormInput: "",
      primaryLocationId: "",
      modalOpen: false,
      confirmedCheck: false,
      unitFormInput: "",
      finalCheck: false,
      selLease: "",
      categoryFormInput: "",
      priorityFormInput: "Medium",
      enterPermission: false,
      accessNotesFormInput: "",
      formSubmit: false,
      fire: false,
      submitting: false,
      triggered: false,
      message: "",
      isError: false,
      loading: false,
      propertyLoader: false,
    };
    this.modalClose = React.createRef();
    this.photoUpload = React.createRef();
  }

  componentDidMount() {
    this.resetState();
    this.getUnits();
  }

  getUnits = async () => {
    this.setState({ loading: true });
    try {
      await QueryUnits.query({
        query: primaryUnits,
        variables: {
          locationId: this.props.primary.id,
        },
      }).then((res) => {
        const units = res.data.units.edges;
        let unitsArray = [];
        units &&
          units.forEach((node) => {
            unitsArray.push({
              key: node.node.id,
              value: node.node.number,
              text: node.node.number,
            });
          });
        this.setState({ units: unitsArray });
        this.setState({ loading: false });
      });
    } catch (e) {}
  };

  closeMessage = () => {
    this.setState({ message: "" });
  };

  setMessage = (message, isError = false) => {
    this.setState({ message, isError });
  };

  fileUpload = (e) => {
    const { count } = this.state;
    this.setState({ count: count + 1 });
    return Array.from(e.target.files).forEach((file) => {
      const { photos } = this.state;
      photos.push(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        this.setState({
          preview: [...this.state.preview, reader.result],
        });
      };
      e.target.value = ""; // prevents error where if you delete photo you can't reupload
    });
  };
  getRequestObj = () => {
    const {
      unitFormInput,
      descriptionFormInput,
      photos,
      priorityFormInput,
      categoryFormInput,
      accessNotesFormInput,
      enterPermission,
    } = this.state;
    let reqObj = {
      unit: unitFormInput,
      location: this.props.primary.id,
      priority: priorityFormInput,
      category: categoryFormInput.toLowerCase(),
      role: "manager",
      description: descriptionFormInput,
      permissionToEnter: enterPermission,
      accessNotes: accessNotesFormInput,
    };
    if (photos) reqObj["files"] = photos;
    if (accessNotesFormInput) reqObj["accessNotes"] = accessNotesFormInput;
    return reqObj;
  };

  submitMaintenanaceReq = async () => {
    const { categoryFormInput, unitFormInput } = this.state;

    if (categoryFormInput === "" || null || unitFormInput === "") {
      const fieldType = [
        "unitFormInput",
        "priorityFormInput",
        "categoryFormInput",
      ];
      let tempErrorsObj = {
        ...this.state.errors,
      };
      fieldType.map((type) => {
        if (this.state[type] === "") {
          tempErrorsObj = { ...tempErrorsObj, [type]: true };
        }
        return null;
      });
      this.setState({ errors: tempErrorsObj });
      return toastFailMsg("Please complete the form!");
    } else {
      this.handleClose();
      this.setState({ formSubmit: true });
      this.setState({ submitting: true });
      this.setState({ triggered: false });
      const reqObj = this.getRequestObj();
      const formData = new FormData();

      if (this.state.photos.length > 0) {
        this.state.photos.forEach((photo) => {
          formData.append("", photo);
        });
      }
      formData.append(
        "query",
        `mutation {
        createMaintenanceRequest(input: {
          maintenance: {
            requestedByDesc: "${reqObj.description.replace(
              /(?:\r\n|\r|\n)/g,
              " "
            )}", 
            unit:"${reqObj.unit}",
            location: "${reqObj.location}", 
            priority: "${reqObj.priority}", 
            category:${reqObj.category}, 
            accessNotes: "${reqObj.accessNotes.replace(
              /(?:\r\n|\r|\n)/g,
              " "
            )}",
            permissionToEnter: ${reqObj.permissionToEnter},
            role: "${reqObj.role}"
          }
        }){
          response
        }}`
      );

      if (formData) {
        this.setState({ uploading: true });
        try {
          await fetch(MAINTENANCE_URL, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              Authorization: getAuthToken(),
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: formData, // body data type must match "Content-Type" header
          })
            .then((response) => {
              this.setMessage(`Success!`, false);
              this.resetState();
              this.props.getMaintenanceRequests();
              toastSuccessMsg("Maintenance Request Submitted");
            })
            .catch((error) => {
              toastFailMsg(error);
              this.setState({ loading: false });
              this.setState({ submitting: false });
            });
        } catch (e) {
          toastFailMsg(e);
          this.setState({ loading: false });
          this.setState({ submitting: false });
          console.log(e);
        }
      }
    }
  };

  resetState = () => {
    this.setState(
      {
        properties: [],
        photos: [],
        count: 0,
        preview: [],
        description: "",
        priorityFormInput: "Medium",
        categoryFormInput: "",
        propertyId: "",
        finalCheck: false,
        selLease: "",
        enterPermission: false,
        accessNotes: "",
        pin: "",
        fire: false,
        errors: {
          unitFormInput: false,
          categoryFormInput: false,
          priorityFormInput: false,
          primaryLocationId: false,
        },
        formSubmit: false,
        message: "",
        submitting: false,
        triggered: false,
        isError: false,
        propertyLoader: false,
      },
      () => {}
    );
  };

  removePhoto = (index) => {
    const preview = this.state.preview;
    const photos = this.state.photos;
    preview.splice(index, 1);
    photos.splice(index, 1);
    this.setState({ preview, photos });
  };

  handlePrimaryChange = (e, { name, value }) => {
    this.setState(
      { [name]: value, errors: { ...this.state.errors, [name]: false } },
      () => {
        this.getUnits();
      }
    );
  };
  handleClose = () => {
    this.resetState();
    this.setState({ modalOpen: false });
  };
  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
      errors: { ...this.state.errors, [name]: false },
    });
  };
  handlePermission = (e, { checked }) => {
    this.toggle();
  };
  handlePermission = (e, { checked }) => {
    this.toggle2();
  };
  toggle = () =>
    this.setState(({ enterPermission }) => ({
      enterPermission: !enterPermission,
    }));
  toggle2 = () =>
    this.setState(({ confirmedCheck }) => ({
      confirmedCheck: !confirmedCheck,
    }));

  fireToggle = () => {
    if (this.state.fire === false) {
      this.setState({ fire: true, priorityFormInput: "Urgent" });
    } else {
      this.setState({ fire: false, priorityFormInput: "Medium" });
    }
  };

  handleChangeDescription = (e, { name, value }) => {
    const triggerWords = [
      "fire",
      "water",
      "flood",
      "smoke",
      "soak",
      "flame",
      "leak",
      "faucet",
      "pipe",
      "plumbing",
      "toilet",
      "sink",
    ];
    const wordCount = {};
    let multipleTrigger = false;
    let finalCheck = false;

    for (let i = 0; i < triggerWords.length; i++) {
      const word = triggerWords[i];
      if (value.toLowerCase().includes(word) && this.state.triggered !== true) {
        this.setState({
          fire: true,
          priorityFormInput: "Urgent",
          triggered: true,
        });
      }

      const wordOccurrences =
        value.toLowerCase().split(word.toLowerCase()).length - 1;
      if (wordOccurrences >= 2) {
        multipleTrigger = true;
        break;
      }

      if (value.toLowerCase().includes(word)) {
        wordCount[word] = true;
      }
    }

    if (Object.keys(wordCount).length >= 2 || multipleTrigger) {
      finalCheck = true;
    }

    this.setState({
      descriptionFormInput: value,
      finalCheck: finalCheck,
    });
    if (finalCheck == false) {
      this.setState({ confirmedCheck: false });
    }
  };

  requestForm = () => {
    return (
      <Form>
        <Form.Input
          placeholder={this.state.loading ? "Gathering Units... " : "Unit"}
          name="unitFormInput"
          required
          id="unitFormInput"
          control={Select}
          onInput={this.state.errors === false}
          error={this.state.errors.unitFormInput === true}
          options={this.state.units}
          onFocus
          onChange={this.handleChange}
        />
        <Checkbox
          toggle
          checked={this.state.fire === true}
          label="Is this fire or water related?"
          name="fireToggle"
          onChange={this.fireToggle}
        />
        <Form.Input
          placeholder="Category"
          name="categoryFormInput"
          id="categoryFormInput"
          required
          error={this.state.errors.categoryFormInput === true}
          control={Select}
          options={CATEGORIES_VALUES.map((location, index) => {
            const value = location.replace(/_/g, " ");
            return {
              key: location,
              value: CATEGORIES_VALUES[index],
              text: value.replace(/\w\S*/g, function (txt) {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              }),
            };
          })}
          onChange={this.handleChange}
        />
        <TextArea
          required
          name="descriptionFormInput"
          error={this.state.errors === true}
          placeholder="Description"
          style={{ minHeight: 100 }}
          onChange={this.handleChangeDescription}
        />
        <Form.Input
          placeholder="Photos"
          name="photos"
          type="file"
          ref={this.photoUpload}
          onChange={(e) => this.fileUpload(e)}
        />
        <Image.Group size="tiny">
          {this.state.photos &&
            this.state.preview.map((photo, index) => {
              return (
                <Image size="small" src={photo}>
                  <Label
                    size="mini"
                    floating
                    onClick={() => this.removePhoto(index)}
                  >
                    <Icon name="cancel" />
                  </Label>
                  <Image src={photo} />
                </Image>
              );
            })}
        </Image.Group>
        <Checkbox
          toggle
          label="Permission to Enter"
          name="enterPermission"
          onChange={this.handlePermission}
        />
        {this.state.enterPermission === true && (
          <>
            <TextArea
              name="accessNotesFormInput"
              placeholder="Access Notes"
              style={{ minHeight: 100 }}
              onChange={this.handleChange}
            />
          </>
        )}
      </Form>
    );
  };

  render() {
    return (
      // <>
      <Modal
        className="semanticModal"
        onClose={() => this.handleClose()}
        onOpen={() => this.setState({ modalOpen: true })}
        open={this.state.modalOpen}
        size="tiny"
        trigger={
          <Button
            style={{ marginLeft: "45px" }}
            compact
            icon
            labelPosition="left"
          >
            <Icon name="plus" style={{ paddingTop: "15px" }} />
            Make Maintenance Request
          </Button>
        }
      >
        <Modal.Header
          style={{
            background: "#343c49",
            color: "#F2FCFB",
            position: "sticky",
            top: "0",
          }}
        >
          <Header
            style={{
              background: "#343c49",
              color: "#F2FCFB",
              position: "sticky",
              top: "0",
            }}
            textAlign="center"
          >
            Maintenance Request
          </Header>
        </Modal.Header>
        <Message attached="bottom" warning>
          If you smell gas or see standing water please call 911 immediately and
          then follow your emergency protocols!
        </Message>
        <Modal.Content>
          {this.props.primary && (
            <Header textAlign="center">{this.props.primary.name}</Header>
          )}
          <this.requestForm />
        </Modal.Content>
        <Modal.Actions className="semanticModal-request-footer">
          {this.state.finalCheck && !this.state.fire && (
            <>
              <div style={{ marginRight: "25px", float:'left' }}>
                <Checkbox
                  onChange={this.handlePermission}
                  label="This is not a water or fire emergency."
                />
                <Popup
                  trigger={<Icon size="small" name="question circle outline" />}
                  content="We noticed some urgency in your description. Please confirm that this is not a fire or water emergency."
                  size="tiny"
                />
              </div>
            </>
          )}
          <Button.Group>
            <Button
              className="semanticModal-request-close"
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              disabled={
                !this.state.unitFormInput ||
                (this.state.finalCheck &&
                  !this.state.fire &&
                  !this.state.confirmedCheck) ||
                this.state.submitting ||
                !this.state.categoryFormInput ||
                !this.state.descriptionFormInput ||
                (this.state.descriptionFormInput &&
                  !this.state.descriptionFormInput.trim())
              }
              className="semanticModal-request-submit"
              content="Submit"
              onClick={() => this.submitMaintenanaceReq()}
            >
              {this.state.submitting ? "Submitting..." : "Submit"}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withApollo(MaintenanceModal);
