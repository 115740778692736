import React from "react";
import { Button, Header, Modal } from "semantic-ui-react";

const CancelDeleteModal = ({
  children,
  isOpen,
  onClose,
  onResponse,
  title = "",
  yesBtnText = "Yes",
}) => (
  <Modal
    className="semanticModal custom-modal amenitie-modal"
    onClose={onClose}
    closeIcon
    open={isOpen}
    size="mini"
    closeOnEscape={false}
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      <Header>{title}</Header>
    </Modal.Header>
    <Modal.Content>
      {children}
      <div className="d-flex justify-content-around mt-4">
        <Button
          className="btn cancel-btn bg-grey w-25"
          onClick={(e) => {
            e.stopPropagation();
            onResponse(false);
          }}
        >
          No
        </Button>
        <Button
          className="btn btn-red"
          negative
          onClick={(e) => {
            e.stopPropagation();
            onResponse(true);
          }}
        >
          {yesBtnText}
        </Button>
      </div>
    </Modal.Content>
  </Modal>
);

export default CancelDeleteModal;
