import React from "react";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";

export default function ReportsData(props) {
  /* eslint-disable */
  React.useEffect(() => {
    mixpanel.track("Manager Page Load", {
      ndbId: props.user.ndbId,
      sub: "Reports Data",
    });
  }, []);
  /* eslint-enable */

  return (
    <>
      <main className="main-content" role="main">
        <div className="roll-container">
          <div className="row mb-4">
            <div className="col-md-12">
              <span className="heading heading-md">Reports</span>
              <h6>Here’s the overview of your reports</h6>
            </div>
          </div>
          <div className="reports-list-row">
            <Link exact to="/balanceSheet" className="reports-list-item">
              <div className="report-type">
                Balance Sheet
              </div>
            </Link>
            <div className="reports-list-item">
              <div className="report-type">Income Statement</div>
            </div>
            <div className="reports-list-item">
              <div className="report-type">Trial Balance</div>
            </div>
            <div className="reports-list-item">
              <div className="report-type">Box Score</div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
