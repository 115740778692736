/* eslint-disable */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import { Checkbox, Button, Header } from "semantic-ui-react";
import LeadsTable from "./LeadsTable";
import { LEAD_CREATED_FORMAT } from "../../utils/constants";
import { multipleColumnsSearch, dataFilterByColumn } from "../../utils/common";
import "./LeadsExtra.scss";

const SetDateFormat = ({ date }) => (
  <Moment format={LEAD_CREATED_FORMAT}>{date}</Moment>
);

const CapitalizeFirstLetter = ({ value }) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export default function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const [multiFilterData, setMultiFilterData] = useState([]);
  const [tempFilteredData, setTempFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [sourceFilter, setSourceFilter] = React.useState([]);
  const [currentPageIndex, setPageIndex] = useState(0);
  const { leadsData, isWaitlist, multipleHandling, view } = props;

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData =
      sourceFilter.length || statusFilter.length || filterInput
        ? multipleColumnsSearch(value, multiFilterData)
        : multipleColumnsSearch(value, leadsData);
    setData(filteredData);
    setFilterInput(value);
    setPageCount(filteredData.length);
    if (value === "") {
      const startRow = 30 * currentPageIndex;
      const endRow = startRow + 30;
      setData(leadsData.slice(startRow, endRow));
      setMultiFilterData(tempFilteredData);
    }
    setFilterApplied(true);
    value === ""
      ? setMultiFilterData(tempFilteredData)
      : setMultiFilterData(filteredData);
  };

  const filterByColumn = (
    filteredData,
    filter,
    column,
    arrayToFilter,
    filteredArray
  ) => {
    filter[0].value.forEach((ele) => {
      filteredData = dataFilterByColumn(ele || "", arrayToFilter, column);
      filteredData.forEach((ele) => {
        filteredArray.push(ele);
        return null;
      });
    });
    setData(filteredArray);
    setMultiFilterData(filteredArray);
    setTempFilteredData(filteredArray);
  };

  const updateFilterByPreviousValue = () => {
    const filteredData = [];
    const sourceFilterData = [];
    const statusFilterData = [];
    let isStatusFilter = false;
    if (statusFilter.length) {
      isStatusFilter = true;
      filterByColumn(
        filteredData,
        statusFilter,
        "status",
        leadsData,
        statusFilterData
      );
    }
    if (sourceFilter.length) {
      filterByColumn(
        filteredData,
        sourceFilter,
        "source",
        isStatusFilter ? statusFilterData : leadsData,
        sourceFilterData
      );
    }
  };

  const handleMultipleFiltering = () => {
    if (statusFilter.length || sourceFilter.length) {
      setFilterApplied(true);
      updateFilterByPreviousValue();
    } else {
      setData(props.leadsData);
      setMultiFilterData([]);
      setTempFilteredData([]);
    }
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const nameHeaderColumns = [
    {
      Header: "First",
      accessor: "node.firstName",
    },
    {
      Header: "Last",
      accessor: (item) => (
        <Header
          content={item.node.lastName}
          as="h4"
          className="last-name-accessor"
          onClick={() => view(item)}
        />
      ),
    },
  ];

  /* eslint-disable */
  const columns = useMemo(
    () =>
      !isWaitlist
        ? [
            {
              Header: "Select",
              accessor: (item) => (
                <Checkbox
                  onChange={(e) => multipleHandling(item, e)}
                  checked={props.selectedLeads.includes(item.node.id)}
                />
              ),
              disableSortBy: true,
            },
            {
              Header: "Name",
              columns: nameHeaderColumns,
            },
            {
              Header: "Details",
              columns: [
                {
                  Header: "Status",
                  accessor: "node.status",
                  Cell: ({ cell: { value } }) =>
                    value ? <CapitalizeFirstLetter value={value} /> : "",
                  filter: "includes",
                },
                {
                  Header: "Created On",
                  accessor: "node.created",
                  Cell: ({ cell: { value } }) => <SetDateFormat date={value} />,
                },
                {
                  Header: "Source",
                  accessor: "node.source",
                  filter: "includes",
                },
                {
                  Header: "Assigned To",
                  accessor: "node.assign.lastName",
                },
                {
                  Header: "Type",
                  accessor: "node.preference.unitType.type",
                },
              ],
            },
            {
              Header: "Actions",
              columns: [
                {
                  Header: "View",
                  accessor: (item) => (
                    <Button icon="eye" size="mini" onClick={() => view(item)} />
                  ),
                  disableSortBy: true,
                },
              ],
            },
          ]
        : [
            {
              Header: "Select",
              accessor: (item) => (
                <Checkbox onChange={(e) => multipleHandling(item, e)} />
              ),
              disableSortBy: true,
            },
            {
              Header: "Name",
              columns: nameHeaderColumns,
            },
            {
              Header: "Details",
              columns: [
                {
                  Header: "Status",
                  accessor: "node.status",
                  Cell: ({ cell: { value } }) =>
                    value ? <CapitalizeFirstLetter value={value} /> : "",
                },
                {
                  Header: "Waitlisted On",
                  accessor: "node.waitlistedOn",
                  Cell: ({ cell: { value } }) => <SetDateFormat date={value} />,
                },
                {
                  Header: "Affordable",
                  accessor: "node.affordable",
                  // Filter: SelectColumnFilter,
                  // filter: 'includes',
                },
                {
                  Header: "Assigned To",
                  accessor: "node.assign.lastName",
                },
                {
                  Header: "Type",
                  accessor: "node.preference.unitType.type",
                },
              ],
            },
            {
              Header: "Actions",
              columns: [
                {
                  Header: "View",
                  accessor: (item) => (
                    <Button icon="eye" size="mini" onClick={() => view(item)} />
                  ),
                  disableSortBy: true,
                },
              ],
            },
          ],
    [props, nameHeaderColumns]
  );
  /* eslint-enable */

  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [isWaitlistPropsChanged, setWaitlistPropsChange] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);

  const calculateLastPage = ({ pageSize, controlledPageCount }) => {
    const result = controlledPageCount / pageSize;
    setLastPage(Math.ceil(result));
  };

  /* eslint-disable */
  const fetchData = useCallback(
    ({ pageSize, pageIndex, filteredValue }) => {
      setPageIndex(pageIndex);
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      setWaitlistPropsChange(false);
      setFilterApplied(false);
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          const startRow = pageSize * pageIndex;
          const endRow = leadsData.length;
          if (sourceFilter.length || statusFilter.length || filteredValue) {
            setData(multiFilterData.slice(startRow, endRow));
            setPageCount(multiFilterData.length);
          } else {
            setData(leadsData.slice(startRow, endRow));
            setPageCount(leadsData.length);
          }
          setStartRowIndex(startRow);
          setEndRowIndex(endRow);
          setLoading(false);
        }
      }, 2000);
    },
    [multiFilterData, leadsData]
  );
  /* eslint-enable */

  useEffect(() => {
    setData(leadsData);
  }, [leadsData]);

  useEffect(() => {
    setWaitlistPropsChange(true);
  }, [isWaitlist]);

  return (
    <div className="table-container ">
      <LeadsTable
        filteredValue={filterInput}
        startRow={startRowIndex}
        defaultColumn={defaultColumn}
        endRow={endRowIndex}
        onChange={handleFilterChange}
        columns={columns}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        completeData={leadsData}
        calculateLastPage={calculateLastPage}
        pageSize={lastPage}
        lastPage={lastPage}
        waitListPropsChange={isWaitlistPropsChanged}
        isFilterApplied={isFilterApplied}
        handleMultipleFiltering={handleMultipleFiltering}
        sortEnabled={!isWaitlist}
      />
    </div>
  );
}
