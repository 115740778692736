import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { InvoiceAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING, INVOICE_ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import { Button, Icon } from "semantic-ui-react";
import InvoicesModal from "../../components/Modals/InvoicesModal";
import moment from "moment";
import BillDetailsModal from "../../components/Modals/BillDetailsModal";
import PaymentModal from "../../components/Modals/PaymentModal";
import axios from "axios";
import getAuthToken from "../../store/auth/authUtility";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const invoiceClient = getClient(ACCOUNTING);

class Invoices extends Component {
  state = {
    primary: this.props.selectedPrimary?.node || "",
    loading: false,
    tableData: [],
    offset: 0,
    invoiceCache: [],
  };

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Invoices",
    });
  }

  componentDidUpdate(prevProps) {
    const { selectedPrimary } = this.props;
    const { node } = selectedPrimary || {};

    if (node !== prevProps.selectedPrimary?.node) {
      this.setState(
        {
          primary: node,
          selectedMonthEvents: { ...this.state.selectedMonthEvents, montEvents: [] },
        },
        () => this.getLedger()
      );
    }
  }

  DateFormat = (value) => {
    return moment(value).format("l");
  };

  StatusFormat = (status) => {
    const statusMap = {
      approved: "Approved",
      created: "Created",
      issued: "Issued",
      paid: "Paid",
      partialPayment: "Partially Paid",
      voided: "Voided",
    };
    return statusMap[status] || "";
  };
  stateDataToPDF = () => {
    const doc = new jsPDF();
    const header = [
      "Number",
      "Status",
      "Amount",
      "Remaining",
      "Invoice",
      "Post",
      "Due",
    ];

    // Add table header
    doc.setFontSize(15);
    doc.setTextColor(40);
    doc.text("Invoices", 14, 15);
    console.log(this.state.tableData);
    // Convert data to array of arrays
    const data = this.state.tableData.map((item) => [
      item?.billDetailsModal?.props?.bill?.number,
      item.uiStatus,
      item.uiAmount,
      item.uiAmountPayable,
      item.uiInvoiceDate,
      item.uiPostedDate,
      item.uiDueDate,
    ]);

    // Add table
    doc.setFontSize(11);
    doc.setTextColor(0);
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      margin: { top: 20 },
      styles: { overflow: "linebreak", fontSize: 8 },
      minCellWidth: 30, // Set a minimum width for all columns
      headStyles: {
        fillColor: [214, 202, 232], // Set the background color to light purple
        textColor: 0, // Set the text color to black
      },
    });

    // Output PDF
    doc.save("Invoices.pdf");
  };

  download = async (e, props) => {
  const url = new URL(INVOICE_ACCOUNTING);
  url.searchParams.append("invoice_id", props.props._id);
  url.searchParams.append("action", "invoice");
  const response = await axios({
    method: "get",
    url: url.href,
    headers: {
      authorization: getAuthToken(),
    },
    responseType: "blob", 
  });
  // Create a Blob from the PDF Stream
  const file = new Blob([response.data], { type: "application/pdf" });
  // Build a URL from the file
  const fileURL = URL.createObjectURL(file);
  // Open the URL on new Window
  window.open(fileURL);
} 



  toastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  success = () => toast.success("Success!", this.toastOptions);
  failure = () => toast.error("Something went wrong!", this.toastOptions);

  getLedger = () => {
    try {
      this.setState({ loading: true });
      invoiceClient
        .query({
          query: InvoiceAccounting,
          variables: {
            location: this.props.selectedPrimary?.node?.id,
            credits: false,
            offset: this.state.offset,
            limit: 99999999,
          },
        })
        .then((res) => {
          if (res.data) {
            const fullData = get(res, "data.slLocationInvoices.data", []);
            const dataToPass = fullData.map((obj) => {
              const {
                amount,
                amountPayable,
                dueDate,
                invoiceDate,
                postedDate,
                status,
              } = obj;
              const billDetailsModal = (
                <BillDetailsModal
                  bill={obj}
                  user={this.props.user}
                  location={this.props}
                  invoice
                  getLedger={this.getLedger}
                  updateInvoice={this.props.updateInvoice}
                />
              );
              const uiStatus = this.StatusFormat(status);
              const uiAmount = `$ ${Number.parseFloat(amount || 0).toLocaleString("en")}`;
              const uiAmountPayable = `$ ${Number.parseFloat(
                amountPayable || 0
              ).toLocaleString("en")}`;
              const uiInvoiceDate = this.DateFormat(invoiceDate);
              const uiPostedDate = this.DateFormat(postedDate);
              const uiDueDate = this.DateFormat(dueDate);
              const actions =
                status !== "voided" && status !== "paid" ? (
                  <>
                    <PaymentModal
                      location={this.props}
                      updateInvoice={this.props.updateInvoice}
                      success={this.success}
                      bills={false}
                      bill={obj}
                      user={this.props.user}
                    />
                    <Button
                      size="tiny"
                      className="ml-3 button-hover-universal"
                      compact
                      icon
                      onClick={this.download}
                      props={obj}
                    >
                      <Icon name="file" />
                    </Button>
                  </>
                ) : (
                  <Button
                    size="tiny"
                    className="ml-3 button-hover-universal"
                    compact
                    icon
                    onClick={this.download}
                    props={obj}
                  >
                    <Icon name="file" />
                  </Button>
                );

              return {
                billDetailsModal,
                uiStatus,
                uiAmount,
                uiAmountPayable,
                uiInvoiceDate,
                uiPostedDate,
                uiDueDate,
                actions,
              };
            });

            this.setState({
              tableData: dataToPass,
              totalItems: get(res, "data.slLocationInvoices.totalItems"),
            });
          }

          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      invoiceCache: [response.data.createSlInvoice.slInvoice, ...prevState.invoiceCache],
    }));
  };

  updateInvoice = (status, invoiceId) => {
    const { invoiceCache } = this.state;

    if (status === "Delete") {
      this.setState({
        invoiceCache: invoiceCache.filter((invoice) => invoice._id !== invoiceId),
      });
    } else {
      const updatedInvoiceCache = invoiceCache.map((invoice) => {
        if (invoice._id === invoiceId) {
          return { ...invoice, status };
        }
        return invoice;
      });

      this.setState({ invoiceCache: updatedInvoiceCache });
    }
  };

  handleOffset = (x) => {
    this.setState({ offset: x });
  };

  render() {
    const { loading, tableData, offset } = this.state;
    const { selectedPrimary, user } = this.props;

    const fail = () =>
      toast.error("No agent with this ID exists in our system", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    const success = () =>
      toast.success("Success!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    const mainHeader = [
      "Number",
      "Status",
      "Amount",
      "Amount Remaining",
      "Invoice",
      "Posted",
      "Due",
      "Actions",
    ];

    return (
      <>
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title">Invoices</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <InvoicesModal
                  dataPush={this.dataPush}
                  fail={fail}
                  user={user}
                  success={success}
                  propertyId={selectedPrimary?.node?.id}
                />
                <Button
                  onClick={() => this.stateDataToPDF()}
                  className="ml-4 noPrint noPrint-button"
                  compact
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <GeneralAccountingTable
            mainHeader={mainHeader}
            mainCellData={tableData}
            updateInvoice={this.updateInvoice}
            getNewData={this.getNewData}
            property={selectedPrimary?.node?.customId}
            dataPush={this.dataPush}
            offset={offset}
            handleOffset={this.handleOffset}
            getLedger={this.getLedger}
            user={user}
            totalItems={this.state.totalItems}
            propertyId={selectedPrimary?.node?.id}
          />
        )}
      </>
    );
  }
}

export default withApollo(Invoices);